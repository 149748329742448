import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';

import { IncidentTypeEnum, ServiceTypeEnum } from '../../../models';
import { InstantDashboard } from '../../../models/dashboard.model';
import DashboardProvider from '../../../packages/dataProviders/resources/dashboard';
import {
	DASHBOARD_GRAFFITI_CARD_TITLE,
	DASHBOARD_INCIDENT_CARD_TITLE,
} from '../../../packages/helpers/explanations/dashboard';
import { useLine } from '../../../packages/hooks';
import DashboardCard from '../card';
import {
	findPriorityCount,
	findTrainsCountByIncidentType,
	sentenceWithFirstUpperLetter,
} from '../utils';

import GraffitiContent from './graffiti';
import IncidentsContent from './incident';
import Unacceptable from './unacceptable';

const InstantDashboardComponent = () => {
	const { selectedLine } = useLine();

	const [instantDashboard, setInstantDashboard] = useState<InstantDashboard>({
		graffiti: { railcarsCount: 0, surface: 0, trainsCount: 0 },
		incidents: [],
		overMal: 0,
		overNp: 0,
	});
	const locale = fr;
	const pbTrainsCount = findTrainsCountByIncidentType(
		instantDashboard.incidents,
		IncidentTypeEnum.PB
	);
	const diTrainsCount = findTrainsCountByIncidentType(
		instantDashboard.incidents,
		IncidentTypeEnum.DI
	);
	const priorityCount = findPriorityCount(instantDashboard.incidents);

	const date = format(new Date(), 'EEEE d MMMM', {
		locale: locale,
	});

	useEffect(() => {
		if (selectedLine)
			DashboardProvider.loadInstantDashboard(selectedLine).then(({ data }) =>
				setInstantDashboard(data)
			);
	}, [selectedLine]);

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			spacing={3}
			paddingTop={2}
		>
			<Grid item xs={7} display="flex" justifyContent="center">
				<Typography fontSize="40px" fontWeight="bold">
					{sentenceWithFirstUpperLetter(date)}
				</Typography>
			</Grid>
			<Grid item xs={5} display="flex" justifyContent="space-between">
				<Unacceptable
					serviceType={ServiceTypeEnum.MAL}
					count={instantDashboard.overMal}
				/>
				<Unacceptable
					serviceType={ServiceTypeEnum.NP}
					count={instantDashboard.overNp}
				/>
			</Grid>
			<Grid item xs={7}>
				<DashboardCard title={DASHBOARD_INCIDENT_CARD_TITLE} isToday={true}>
					<IncidentsContent
						pbTrainsCount={pbTrainsCount}
						diTrainsCount={diTrainsCount}
						priorityCount={priorityCount}
					/>
				</DashboardCard>
			</Grid>
			<Grid item xs={5}>
				<DashboardCard title={DASHBOARD_GRAFFITI_CARD_TITLE} isToday>
					<GraffitiContent
						trainsCount={instantDashboard.graffiti.trainsCount}
						railcarsCount={instantDashboard.graffiti.railcarsCount}
						surface={instantDashboard.graffiti.surface}
					/>
				</DashboardCard>
			</Grid>
		</Grid>
	);
};

export default InstantDashboardComponent;
