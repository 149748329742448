import { shallowEqual } from 'react-redux';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

import { UserCredential } from '../../models';
import { useTypedSelector } from '../../packages/hooks';
import { DataGridPro, Spinner } from '../../packages/ui';

const columns: GridColDef[] = [
	{
		field: 'registration_number',
		headerName: 'Compte matriculaire',
		type: 'string',
		width: 250,
	},
	{
		field: 'roles',
		headerName: 'Rôle',
		type: 'string',
		valueGetter: (params) =>
			params.row.roles.map((role: { name: any }) => role.name).join(', '),
		width: 250,
	},
	{ field: 'line_id', headerName: 'Ligne', type: 'string', width: 250 },
	{
		field: 'organisation',
		headerName: 'Entreprise',
		type: 'string',
		width: 250,
	},
];

const CredentialDataGrid = ({
	handleSelectCredential,
}: CredentialDataGridProps) => {
	const users = useTypedSelector(
		({ credentialsState }) =>
			credentialsState.users ? credentialsState.users : [],
		shallowEqual
	);

	const isLoaded = useTypedSelector(
		({ apiState }) => apiState.credentials.isLoaded
	);

	const onRowSelect = (params: GridRowParams) => {
		handleSelectCredential(params.row as unknown as UserCredential);
	};

	return isLoaded ? (
		<DataGridPro
			columns={columns}
			onRowClick={onRowSelect}
			rows={users}
			sx={{
				'& .css-axafay-MuiDataGrid-virtualScroller': {
					height: '65vh',
				},
				'& .MuiDataGrid-row': {
					backgroundColor: '#ffffff',
					border: 'solid 1px lightGrey',
					borderRadius: '0.5rem',
					margin: '0 0 0.8rem',
					maxWidth: '99.5%',
				},
				border: 'none',
				maxHeight: '70vh',
			}}
		/>
	) : (
		<Spinner />
	);
};

export default CredentialDataGrid;

type CredentialDataGridProps = {
	handleSelectCredential: (user: UserCredential) => void;
};
