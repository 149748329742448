import { env } from 'process';

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		window.location.hostname === '[::1]' ||
		/^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/.exec(
			window.location.hostname
		)
);

type Config = {
	onSuccess?: (registration: ServiceWorkerRegistration) => void;
	onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const register = (debug: boolean, config?: Config) => {
	if (!env.IS_LOCAL_ENV && 'serviceWorker' in navigator) {
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
		if (publicUrl.origin !== window.location.origin) {
			return;
		}

		window.addEventListener('load', () => {
			const swUrl = `${process.env.PUBLIC_URL}/sw.js${debug ? '?debug' : ''}`;

			if (isLocalhost) {
				checkValidServiceWorker(swUrl, config).catch((error) => {
					console.error('Error during service worker registration:', error);
				});
				navigator.serviceWorker.ready
					.then(() => {
						console.info(
							'This web app is being served cache-first by a service ' +
								'worker. To learn more, visit https://bit.ly/CRA-PWA'
						);
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			} else {
				registerValidSW(swUrl, config);
			}
		});
	}
};

const registerValidSW = (swUrl: string, config?: Config) => {
	const navigatorServiceWorkerController = (
		registration: ServiceWorkerRegistration
	) => {
		if (config?.onUpdate) {
			config.onUpdate(registration);
		}
	};
	navigator.serviceWorker
		.register(swUrl)
		.then((registration) => {
			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				if (installingWorker == null) {
					return;
				}
				installingWorker.onstatechange = () => {
					if (installingWorker.state === 'installed') {
						if (navigator.serviceWorker.controller) {
							navigatorServiceWorkerController(registration);
						} else {
							console.error('Content is cached for offline use.');
							if (config?.onSuccess) {
								config.onSuccess(registration);
							}
						}
					}
				};
			};
		})
		.catch((error) => {
			console.error('Error during service worker registration:', error);
		});
};

const checkValidServiceWorker = async (swUrl: string, config?: Config) => {
	try {
		const response = await fetch(swUrl, {
			headers: { 'Service-Worker': 'script' },
		});

		const contentType = response.headers.get('content-type');

		if (
			response.status === 404 ||
			(contentType != null && contentType.indexOf('javascript') === -1)
		) {
			const registration = await navigator.serviceWorker.ready;
			await registration.unregister();
			window.location.reload();
		} else {
			registerValidSW(swUrl, config);
		}
	} catch (error) {
		console.error(
			'No internet connection found. App is running in offline mode.'
		);
	}
};

export const unregister = () => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready
			.then((registration) => {
				registration.unregister().catch((error) => {
					console.error('Error during service worker registration:', error);
				});
			})
			.catch((error) => {
				console.error(error.message);
			});
	}
};
