import { useState } from 'react';
import { useDispatch } from 'react-redux';

import DialogBox from '../../../components/dialogBox';
import ReportingLineSelector from '../../../components/lineSelector';
import { useEntity, useLine, useTypedSelector } from '../../../packages/hooks';
import useCallbackPrompt from '../../../packages/hooks/usePrompt';
import { Button, SingleButtonsBloc } from '../../../packages/ui';
import { setSetting } from '../../../redux/actions';
import { saveSetting } from '../../../redux/thunks/setting';

import 'rc-slider/assets/index.css';
import './styles/settingPageSem.css';

const SemSetting = () => {
	const dispatch = useDispatch();
	const { isAdmin } = useEntity();
	const setting = useTypedSelector((state) => state.settingState);
	const [settingIsModified, setSettingIsModified] = useState(false);
	const { selectedLine } = useLine();

	const handleSave = (): void => {
		const newSetting = { ...setting };
		newSetting.line = selectedLine ?? '';
		dispatch(setSetting(newSetting));
		dispatch(saveSetting(newSetting));
		setSettingIsModified(false);
	};

	const { showPrompt, saveAndQuit, quitWithoutSaving } = useCallbackPrompt(
		settingIsModified,
		handleSave
	);

	const handleCancel = (): void => {
		setSettingIsModified(false);
	};

	return (
		<main>
			{isAdmin && (
				<div className="my-3">
					<ReportingLineSelector />
				</div>
			)}
			<DialogBox
				handleCancel={quitWithoutSaving}
				handleConfirm={saveAndQuit}
				showDialog={showPrompt}
			/>
			<div className="setting-page__content d-flex flex-column">
				<div>
					<div className="font-paris-bold setting-page__title">
						Paramètres de l'application
					</div>
					<div className="setting-page__title-separator  mt-3 mb-3" />
					<h3 className="fz-17 font-paris-bold mb-3">
						Prestation supplémentaire
					</h3>
					<h5 className="pb-4 fz-13">
						Activez ou désactivez la prestation à réaliser par le prestataire
					</h5>
					<span>Prestation supplémentaire&nbsp;&nbsp;</span>
				</div>

				<SingleButtonsBloc>
					<Button onClick={handleCancel} variant="text">
						Annuler
					</Button>
					<Button onClick={handleSave}>Valider</Button>
				</SingleButtonsBloc>
			</div>
		</main>
	);
};

export default SemSetting;
