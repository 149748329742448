import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';

import { SurfaceTypeEnum } from '../../../models';
import { ServiceDTO } from '../../../packages/dataProviders';
import {
	SERVICE_FORM_ERR_STRAIN,
	SERVICE_FORM_ERR_STYPE,
} from '../../../packages/helpers';
import {
	RStock,
	useDepartment,
	useForm,
	useLine,
	useRollingStockCard,
	useServiceType,
	useTypedSelector,
} from '../../../packages/hooks';
import { createServicesList } from '../../../redux/thunks/service';

import ServiceComponent from './component';

const ServicePopIn = ({ open, handleClose }: ServicePopinProps) => {
	const dispatch = useDispatch();
	const { selectedLine } = useLine();
	const { rsLabel } = useDepartment();
	const { serviceTypesCreate } = useServiceType();
	const { npHighLimit } = useTypedSelector((state) => state.settingState);

	const { handleClearFilterRsCard, handleFilterRsCard, rollingStockCardList } =
		useRollingStockCard(false);

	const validate = (values: any) => {
		const errors = {} as { [key in string]: string };
		if (isNil(values.serviceTypeId))
			errors.serviceType = SERVICE_FORM_ERR_STYPE;
		if (isNil(values.trains.find((train: RStock) => train.checked)))
			errors.trains = SERVICE_FORM_ERR_STRAIN;
		return errors;
	};

	const resetAndClose = () => {
		handleClose();
		handleClearFilterRsCard();
		reset();
	};

	const handleCreateService = () => {
		const services: ServiceDTO[] = [];
		values.trains.forEach((train: any) => {
			if (train.checked) {
				services.push({
					date: values.serviceDate ?? new Date(),
					rollingStockId: Number(train.id),
					selectedLine: selectedLine,
					serviceTypeId: values.serviceTypeId,
					surfaceTypeId: SurfaceTypeEnum.SMR,
					trainCode: train.name,
				});
			}
		});

		dispatch(
			createServicesList(
				services,
				serviceTypesCreate.find(({ id }) => id === values.serviceTypeId),
				npHighLimit
			)
		);
		resetAndClose();
	};

	const {
		errors,
		handleCheckOneOf,
		handleDateChange,
		handleSelect,
		isUntouched,
		setValues,
		handleSubmit,
		values,
		handleClose: reset,
	} = useForm({
		callback: handleCreateService,
		initFormValues: {
			serviceDate: null,
			serviceType: null,
			trains: rollingStockCardList,
		},
		validate,
	});

	useEffect(() => {
		setValues({ ...values, trains: rollingStockCardList });
	}, [rollingStockCardList]);

	return (
		<ServiceComponent
			errors={errors}
			handleCheckOneOf={handleCheckOneOf}
			handleDateChange={handleDateChange}
			handleFilterChange={handleFilterRsCard}
			handleSelect={handleSelect}
			handleSubmit={handleSubmit}
			open={open}
			resetAndClose={resetAndClose}
			serviceTypes={serviceTypesCreate}
			rsLabel={rsLabel}
			validateDisabled={isUntouched}
			values={values}
		/>
	);
};

export default ServicePopIn;

type ServicePopinProps = {
	open: boolean;
	handleClose: () => void;
};
