import { IncidentType, IncidentTypeRightsList } from '../../models';

export interface IncidentTypeState {
	incidentTypesCreate: IncidentType[];
	incidentTypesDelete: IncidentType[];
	incidentTypesExport: IncidentType[];
	incidentTypesSolve: IncidentType[];
}

export const SET_INCIDENT_TYPE = 'SET_INCIDENT_TYPE';

export interface SetIncidentType {
	type: typeof SET_INCIDENT_TYPE;
	payload: IncidentTypeRightsList;
}

export type IncidentTypeActionTypes = SetIncidentType;
