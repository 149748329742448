import { ReactComponent as Gr } from '../../../assets/icons/cleaning-icons/incident-df.svg';
import { ReactComponent as Pb } from '../../../assets/icons/cleaning-icons/incident-pb.svg';
import { ReactComponent as Di } from '../../../assets/icons/cleaning-icons/incident-sr.svg';
import { ReactComponent as Op } from '../../../assets/icons/cleaning-icons/special-operation.svg';

const incidentIcon: { [key in string]: any } = {
	DI: <Di />,
	GR: <Gr />,
	OP: <Op />,
	PB: <Pb />,
};

const IncidentSvgPrintable = ({ name }: { name: string }) => {
	return incidentIcon[name];
};

export default IncidentSvgPrintable;
