import { Button, SingleButtonsBloc } from '..';

const ValidationButtons = ({
	onValidate,
	onCancel,
	validateText = 'Valider',
	validateDisabled = false,
}: PopInFooterProps) => {
	return (
		<SingleButtonsBloc>
			<Button
				onClick={() => {
					onCancel();
				}}
				variant="text"
				style={{
					width: '7rem',
				}}
			>
				Annuler
			</Button>
			<Button
				disabled={validateDisabled}
				onClick={() => {
					onValidate();
				}}
				style={{
					width: '7rem',
				}}
			>
				{validateText}
			</Button>
		</SingleButtonsBloc>
	);
};

export default ValidationButtons;

type PopInFooterProps = {
	onValidate: () => void;
	onCancel: () => void;
	validateText?: string;
	validateDisabled?: boolean;
};
