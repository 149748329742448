import { ThunkAction } from 'redux-thunk';

import { EspaceEnum, ExceptionDTO, User } from '../../models';
import { PopupType } from '../../models/popup.model';
import { userService } from '../../packages/dataProviders';
import UserProvider from '../../packages/dataProviders/resources/user';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import { deleteUser, setUser } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

import { loadAndSetCredentials } from './credentials';

const { CREDENTIALS } = components;

type UserThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const createAndSetUser =
	(user: User, espace: EspaceEnum): UserThunk =>
	async (dispatch, _, api) => {
		const isUpdated = user.id ? 'Modified' : 'Created';
		let res;
		try {
			dispatch(api._request(CREDENTIALS));
			if (user.id !== null) {
				res = await UserProvider.updateUser(user);
			} else {
				res = await UserProvider.createUser(user);
			}
			if (res.ok) {
				dispatch(api._response(CREDENTIALS));
				dispatch(setUser(user));
				if (res.data.token) {
					const tokenFeedback = getTokenFeedback(res.data.token);
					dispatch(
						api.showFeedBack(
							exceptionDTOtoPopupState(tokenFeedback, PopupType.SUCCESS)
						)
					);
				} else {
					dispatch(api.showFeedBack(errorConf.success[`user${isUpdated}`]));
				}
				dispatch(loadAndSetCredentials(espace)).catch((errors) => {
					console.error('load and set credential on error', errors);
				});
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.data.message as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
				dispatch(api._error(CREDENTIALS, ''));
			}
		} catch (e) {
			dispatch(api._error(CREDENTIALS, e));
			dispatch(api.showFeedBack(errorConf.error[`userNot${isUpdated}`]));
		}
	};

export const deleteAndSetUser =
	(selectedUserId: number): UserThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(CREDENTIALS));
			const res = await userService.deleteUser(selectedUserId);
			if (res.ok) {
				dispatch(api._response(CREDENTIALS));
				dispatch(deleteUser(selectedUserId));
				dispatch(api.showFeedBack(errorConf.success['userDeleted']));
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.message as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
				dispatch(api._error(CREDENTIALS, ''));
			}
		} catch (e) {
			dispatch(api._error(CREDENTIALS, e));

			dispatch(api.showFeedBack(errorConf.error['userNotDeleted']));
		}
	};

function getTokenFeedback(token: string) {
	return `Compte créé avec succès, Le token d'authentification est le suivant (<span style="text-decoration: underline;">à communiquer au prestataire</span>) : \n\n<i>${token}</i> \n\n<strong><span style="text-decoration: underline;color : red">ATTENTION</span> : Ce sera la seule fois où ce token sera affiché, il ne vous sera plus possible de le consulter aprés la fermeture de cette fenêtre.</strong>`;
}
