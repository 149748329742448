import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';

import { SpecialOperationType } from '../../../../models/specialOperationType.model';
import { pluralize } from '../../../../packages/helpers';
import { useTypedSelector } from '../../../../packages/hooks/useTypedSelector';
import { getSpecialOperationType } from '../../../../redux/thunks/specialOperationType';

import { StyledCardContainer } from './styles';

const CardOpReporting = () => {
	const dispatch = useDispatch();

	const opReporting: SpecialOperationType[] = useTypedSelector(
		({ specialOperationTypeState }) => specialOperationTypeState.opReporting
	);

	useEffect(() => {
		if (isEmpty(opReporting)) {
			dispatch(getSpecialOperationType());
		}
	}, []);

	return (
		<Grid container xs={10}>
			{!isNil(opReporting) &&
				opReporting?.map((op) => (
					<Grid container xs={10} key={op.name} marginTop="16px">
						<StyledCardContainer>
							<Grid item xs={12} paddingLeft={2}>
								<Typography marginTop="12px" marginBottom="12px">
									Opération spéciale : {op.name}
								</Typography>
							</Grid>
							<Grid
								container
								xs={12}
								marginBottom="12px"
								marginRight="4px"
								paddingLeft={2}
							>
								<Grid container xs={8}>
									<Grid item xs={3} marginTop="4px">
										{pluralize(op.lines.length, 'Ligne')}{' '}
										{pluralize(op.lines.length, 'concernée')}:
									</Grid>
									<Grid item xs={6}>
										<Typography fontSize="16px" fontWeight="bold">
											{op.lines.join(', ')}
										</Typography>
									</Grid>
								</Grid>
								{op.endDate && (
									<Grid item xs={4} justifyContent="flex-end">
										Clôturé le : {op.endDate.toLocaleDateString()}
									</Grid>
								)}
							</Grid>
						</StyledCardContainer>
					</Grid>
				))}
		</Grid>
	);
};

export default CardOpReporting;
