import { Dispatch, SetStateAction } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IncidentSummary } from '../../../models';
import { TrackingBoardDTO } from '../../../packages/dataProviders';
import { ModalesState } from '../../../packages/hooks';
import { Spinner } from '../../../packages/ui';
import RollingStockAvailability from '../../availability';
import IncidentReport from '../../incidentReport';

import { StyledDataGrid } from './styles';

const RollingStockListComponent = ({
	colorNp,
	columns,
	handleCloseModal,
	handleOpenModal,
	incidentSummary,
	isLoaded,
	isPad,
	isResolvingIncident,
	openModalState,
	setIsResolvingIncident,
	stockAfterByCodeFilter,
	trainId,
}: RollingStockListComponentProps) => {
	const RowHeight = isPad ? 80 : 60;
	return (
		<>
			{isLoaded ? (
				<StyledDataGrid
					rows={stockAfterByCodeFilter}
					columns={columns}
					rowHeight={RowHeight}
					getRowClassName={(params) => `${params.row.isUsed ? '' : 'not-used'}`}
					sx={{
						'& .css-axafay-MuiDataGrid-virtualScroller': {
							height: '58vh',
						},
						'& .MuiDataGrid-row': {
							backgroundColor: '#ffffff',
							border: 'solid 1px lightGrey',
							borderRadius: '0.5rem',
							margin: '0 0 0.8rem',
							maxWidth: '99.5%',
						},
						border: 'none',
						maxHeight: '68vh',
					}}
				/>
			) : (
				<Spinner />
			)}

			<IncidentReport
				isResolvingIncident={isResolvingIncident}
				incidentSummary={incidentSummary}
				trainId={trainId}
				setIsResolvingIncident={setIsResolvingIncident}
				handleCloseModal={handleCloseModal}
				handleOpenModal={handleOpenModal}
				openModalState={openModalState}
			/>
			<RollingStockAvailability
				colorNp={colorNp}
				handleCloseModal={handleCloseModal}
				handleOpenModal={handleOpenModal}
				openModalState={openModalState}
				trainId={trainId}
			/>
		</>
	);
};
export default RollingStockListComponent;

type RollingStockListComponentProps = {
	colorNp: string;
	columns: GridColDef[];
	incidentSummary?: IncidentSummary;
	isLoaded: boolean;
	isPad: boolean;
	isResolvingIncident: boolean;
	setIsResolvingIncident: Dispatch<SetStateAction<boolean>>;
	stockAfterByCodeFilter: TrackingBoardDTO[];
	trainId?: number;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	openModalState: ModalesState;
};
