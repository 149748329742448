import { IncidentDTO, IncidentTypeEnum, Position } from '../../models';
import { ServiceDTO } from '../dataProviders';

export type EventType =
	| 'keepAlive'
	| 'usedStatusUpdate'
	| 'serviceUpdate'
	| 'serviceDelete'
	| 'incidentDelete'
	| 'incidentResolve'
	| 'npReminder'
	| 'degrafReminder'
	| 'stainReminder'
	| 'tagReminder'
	| 'windshieldReminder'
	| 'unitUpdate';

export type Handlers = {
	[key in EventType]: (e: MessageEvent) => any;
};

export interface UsedHistoryUpdatedData {
	trainId: number;
	isUsed: boolean;
}

export interface ServiceUpdateData extends ServiceDTO {
	trainCode: string;
	trainId: string;
}

export interface StationUpdateData extends Omit<ServiceDTO, 'date'> {
	date: string;
}

export interface IncidentCreateData {
	incident: IncidentDTO;
}

export interface IncidentDeleteData {
	incidentReportId: number;
	trainId: number;
}

export interface IncidentResolveData {
	incidentTypeId: IncidentTypeEnum;
	incidentReportId: number;
	trainId: number;
}

export enum PositionType {
	CREATE,
	UPDATE,
	DELETE,
}

export interface PositionUpdateData {
	type: PositionType;
	position: Position;
	trainId: number;
}

export enum AlertTypeEnum {
	NPReminder = 'npReminder',
	DegrafReminder = 'degrafReminder',
	StainReminder = 'stainReminder',
	TagReminder = 'tagReminder',
	WindshieldReminder = 'windShieldReminder',
}

export interface NotificationData {
	type: AlertTypeEnum;
	date: Date;
	dayCount: number;
	data: any;
}
