// Prestations
export const CREATE_MAL_SERVICE = 'createMALService';
export const CREATE_MEP_SERVICE = 'createMEPService';
export const CREATE_NC_SERVICE = 'createNCService';
export const CREATE_NCR_SERVICE = 'createNCRService';
export const CREATE_NP_SERVICE = 'createNPService';
export const CREATE_PB_SERVICE = 'createPBService';
export const CREATE_SERVICE = 'createService';
export const DELETE_MAL_SERVICE = 'deleteMALService';
export const DELETE_MEP_SERVICE = 'deleteMEPService';
export const DELETE_NC_SERVICE = 'deleteNCService';
export const DELETE_NCR_SERVICE = 'deleteNCRService';
export const DELETE_NP_SERVICE = 'deleteNPService';
export const DELETE_PB_SERVICE = 'deletePBService';
export const DELETE_SERVICE = 'deleteService';

// Anomalies
export const CREATE_SITE_POSITION = 'createSitePosition';

// Trains/éléments
export const SHOW_USED_TRAIN = 'showUsedTrain';
export const EDIT_USED_TRAIN = 'editUsedTrain';

// Exports
export const EXPORT_ALL = 'exportAll';
export const EXPORT_OP = 'exportOP';

// Menu
export const SHOW_DASHBOARD = 'showDashboard';
export const SHOW_OP = 'showSpecialOperationType';
export const SHOW_RS = 'showRS';
export const SHOW_HABILITY = 'showHability';
export const SHOW_SETTINGS = 'showSettings';
export const SHOW_RIGHT = 'showRight';
export const SHOW_ESPACE3 = 'showEspace3';
export const SHOW_ESPACE5 = 'showEspace5';
export const SHOW_ARCHIVE = 'showArchive';

//Availability
export const CREATE_AVAILABILITY = 'createAvailability';
export const CREATE_MULTIPLE_AVAILABILITY = 'createMultipleAvailability';
export const UPDATE_AVAILABILITY = 'updateAvailability';
export const DELETE_AVAILABILITY = 'deleteAvailability';

//Special operation type
export const CLOSE_SPECIAL_OPERATION_TYPE = 'closeSpecialOperationType';
export const CREATE_SPECIAL_OPERATION_TYPE = 'createSpecialOperationType';
export const UPDATE_SPECIAL_OPERATION_TYPE = 'updateSpecialOperationType';

//Special operation
export const DISPLAY_SPECIAL_OPERATION = 'showSpecialOperation';
