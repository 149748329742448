import Button from '../../atoms/Button';
import { DownloadIcon } from '../../icons';

const ExportBtn = ({ dataGridId }: { dataGridId?: string }) => {
	return dataGridId ? (
		<Button
			onClick={() => handleClicHiddeExportBtn(dataGridId)}
			variant="text"
			endIcon={<DownloadIcon />}
		>
			Exporter
		</Button>
	) : (
		<></>
	);
};

export default ExportBtn;

export const handleClicHiddeExportBtn = (dataGridId: string) => {
	const exportBtn = document.getElementById(
		`export-datagrid-btn-${dataGridId}`
	);
	if (exportBtn) {
		exportBtn.click();
	}
};
