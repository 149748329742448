import { useState } from 'react';

export const useModal = () => {
	const modalesState = {
		availabilityCreateForm: false,
		availabilityEditForm: false,
		availabilitySelect: false,
		export: false,
		incidentReportCreateForm: false,
		incidentReportEditForm: false,
		incidentSelect: false,
	};

	const [openModalState, setOpenModalState] =
		useState<ModalesState>(modalesState);

	const handleOpenModal = (modalName: keyof ModalesState) =>
		setOpenModalState({ ...openModalState, [modalName]: true });

	const handleCloseModal = (modalName?: keyof ModalesState) =>
		setOpenModalState(
			modalName ? { ...openModalState, [modalName]: false } : modalesState
		);

	return {
		handleCloseModal,
		handleOpenModal,
		openModalState,
		setOpenModalState,
	};
};

export type ModalesState = {
	availabilityCreateForm: boolean;
	availabilityEditForm: boolean;
	availabilitySelect: boolean;
	export: boolean;
	incidentReportCreateForm: boolean;
	incidentReportEditForm: boolean;
	incidentSelect: boolean;
};
