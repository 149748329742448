import { ThunkAction } from 'redux-thunk';

import { ExceptionDTO, PopupType } from '../../models';
import { UseRsReport } from '../../models/usedRs.model';
import UsedRsProvider from '../../packages/dataProviders/resources/rolllingStockUsed';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import {
	setRollingstockIsUsed,
	setRSUsedCount,
	updateUsedStatus,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { ROLLINGSTOCK, USED_UPDATE } = components;

type RollingStockThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const loadUsedRs =
	(searchedLine: string): RollingStockThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(ROLLINGSTOCK));
			const usedRs = await UsedRsProvider.loadUsedRs(searchedLine);
			if (usedRs.ok) {
				dispatch(setRSUsedCount(usedRs.data as unknown as UseRsReport));
				dispatch(api._response(ROLLINGSTOCK));
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							usedRs.data.message as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
				dispatch(api._error(ROLLINGSTOCK, ''));
			}
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(ROLLINGSTOCK, e));
		}
	};

export const toggleUsedStatus =
	(idCb: number, newIsUsed: boolean): RollingStockThunk =>
	async (dispatch, _, api) => {
		dispatch(updateUsedStatus(idCb, newIsUsed));
		dispatch(setRollingstockIsUsed(idCb, newIsUsed));
		try {
			dispatch(api._request(USED_UPDATE));
			await UsedRsProvider.saveUsedRs(idCb, newIsUsed);

			dispatch(api._response(USED_UPDATE));
		} catch (e) {
			dispatch(setRollingstockIsUsed(idCb, !newIsUsed));
			dispatch(api.showFeedBack(errorConf.warn.actionRejected));
			dispatch(api._error(USED_UPDATE, e));
		}
	};
