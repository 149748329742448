import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';

import { ServiceTypeCodeMap, ServiceTypeEnum } from '../../../../models';
import { ServiceReport } from '../../../../models/dashboard.model';
import ServiceReportProvider from '../../../../packages/dataProviders/resources/dashboard';

import ProgressBarDashboard from './ProgressBarDashboard';

const ServiceReporting = ({
	startDate,
	endDate,
	clickTrigger,
	selectedLine,
}: ServiceReportingProps) => {
	const [serviceReports, setServiceReports] = useState<ServiceReport | null>(
		null
	);

	const getDefaultServiceType = (
		serviceTypes: ServiceTypeEnum[]
	): ServiceReport => {
		return serviceTypes.map((serviceType) => ({
			count: 0,
			serviceType: serviceType,
			target: 0,
		}));
	};

	useEffect(() => {
		if (selectedLine) {
			ServiceReportProvider.loadServiceReport(
				selectedLine,
				startDate,
				endDate
			).then(({ data }) => setServiceReports(data));
		}
	}, [selectedLine, clickTrigger]);

	useEffect(() => {
		setServiceReports(getDefaultServiceType([1, 8, 2]));
	}, []);

	return (
		<Grid container margin={2}>
			{!isNil(serviceReports) &&
				serviceReports?.map((service) => (
					<Grid item xs={4} md={4} key={service.serviceType}>
						<Grid item display="flex" justifyContent="center" marginRight={9}>
							<Typography variant="h4">
								{ServiceTypeCodeMap.get(service.serviceType)}
							</Typography>
						</Grid>
						<Grid container>
							<Grid item xs={10} md={10}>
								<ProgressBarDashboard
									count={Number(service.count)}
									target={service.target}
								/>
							</Grid>
							<Grid item xs={1} md={1}>
								<Typography variant="h6">{service.target}</Typography>
							</Grid>
						</Grid>
						<Grid item display="flex" justifyContent="center" marginRight={9}>
							<Typography variant="h2">{Number(service.count)}</Typography>
						</Grid>
					</Grid>
				))}
		</Grid>
	);
};

export default ServiceReporting;

export type ServiceReportingProps = {
	startDate: Date;
	endDate: Date;
	clickTrigger: boolean;
	selectedLine: string;
};
