import cloneDeep from 'lodash/cloneDeep';

import { ServiceTypeEnum } from '../../models';
import { Availability } from '../../models/availability.model';
import {
	ApiRequestUsedUpdate,
	TrackingBoardDTO,
} from '../../packages/dataProviders';
import { ServiceUpdateData } from '../../packages/events/types';
import {
	calcStatusCount,
	MAL_DELTA_TIME,
	NC_DELTA_TIME,
	NCR_DELTA_TIME,
	NP_DELTA_TIME,
	PBS_DELTA_TIME,
	setCleanStatus,
	setRollingStocksOrder,
	UP,
} from '../../packages/helpers';
import { getIncidentsOrder } from '../../packages/helpers/incident.utils';
import { initRollingStockState as initState } from '../config/initialState';
import {
	CountState,
	DELETE_ROLLINGSTOCK_AVAILABILITY,
	DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID,
	DELETE_ROLLINGSTOCK_INCIDENT,
	DELETE_ROLLINGSTOCK_SERVICE,
	DELETE_ROLLINGSTOCK_SPECIAL_OPERATION,
	RollingStockActionTypes as ActionTypes,
	RollingStockState as State,
	SET_ROLLINGSTOCK,
	SET_ROLLINGSTOCK_FILTER_CODE,
	SET_ROLLINGSTOCK_FILTER_STATUS,
	SET_ROLLINGSTOCK_INCIDENT,
	SET_ROLLINGSTOCK_IS_USED,
	SET_ROLLINGSTOCK_IS_USED_LIST,
	SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT,
	SET_ROLLINGSTOCK_SORT,
	UPDATE_ROLLINGSTOCK_AVAILABILITY,
	UPDATE_ROLLINGSTOCK_INCIDENTS,
	UPDATE_ROLLINGSTOCK_SERVICE,
} from '../types/rollingStock.types';

import {
	IncidentTypeEnum,
	RSIncident,
} from './../../models/incidentType.model';

export const rollingStockReducer = (
	state = initState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case DELETE_ROLLINGSTOCK_AVAILABILITY:
			return {
				...state,
				rollingStocks: handlers[DELETE_ROLLINGSTOCK_AVAILABILITY](
					cloneDeep(state.rollingStocks),
					action.payload.availabilityId
				),
			};

		case DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID:
			return {
				...state,
				rollingStocks: handlers[DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID](
					cloneDeep(state.rollingStocks),
					action.payload.trainTechId,
					action.payload.actionType,
					action.payload.actionTypeId
				),
			};

		case DELETE_ROLLINGSTOCK_INCIDENT:
			return {
				...state,
				...handlers[DELETE_ROLLINGSTOCK_INCIDENT](
					cloneDeep(state.rollingStocks),
					action.payload.incidentReportId,
					action.payload.trainTechId,
					action.payload.npHighLimit
				),
			};

		case DELETE_ROLLINGSTOCK_SPECIAL_OPERATION:
			return {
				...state,
				rollingStocks: handlers[DELETE_ROLLINGSTOCK_SPECIAL_OPERATION](
					cloneDeep(state.rollingStocks),
					action.payload.specialOperationId,
					action.payload.trainTechId
				),
			};

		case SET_ROLLINGSTOCK:
			return {
				...state,
				...handlers[SET_ROLLINGSTOCK](
					action.trackingBoardDTO,
					action.npHighLimit
				),
			};

		case SET_ROLLINGSTOCK_IS_USED:
			return {
				...state,
				rollingStocks: handlers[SET_ROLLINGSTOCK_IS_USED](
					cloneDeep(state.rollingStocks),
					action.payload.id,
					action.payload.status
				),
			};

		case SET_ROLLINGSTOCK_IS_USED_LIST:
			return {
				...state,
				rollingStocks: handlers[SET_ROLLINGSTOCK_IS_USED_LIST](
					cloneDeep(state.rollingStocks),
					action.payload
				),
			};

		case SET_ROLLINGSTOCK_FILTER_STATUS:
			return {
				...state,
				filter: {
					...state.filter,
					status: action.status,
				},
			};

		case SET_ROLLINGSTOCK_FILTER_CODE:
			return {
				...state,
				filter: {
					...state.filter,
					code: action.code,
				},
			};

		case UPDATE_ROLLINGSTOCK_INCIDENTS:
			return {
				...state,
				rollingStocks: handlers[UPDATE_ROLLINGSTOCK_INCIDENTS](
					cloneDeep(state.rollingStocks),
					action.payload.incidentReport,
					action.payload.trainTechId
				),
			};

		case UPDATE_ROLLINGSTOCK_AVAILABILITY:
			return {
				...state,
				rollingStocks: handlers[UPDATE_ROLLINGSTOCK_AVAILABILITY](
					cloneDeep(state.rollingStocks),
					action.payload.availabilities,
					action.payload.trainTechId
				),
			};

		case UPDATE_ROLLINGSTOCK_SERVICE:
			return {
				...state,
				rollingStocks: handlers[UPDATE_ROLLINGSTOCK_SERVICE](
					cloneDeep(state.rollingStocks),
					action.service
				),
			};

		case SET_ROLLINGSTOCK_INCIDENT:
			return {
				...state,
				...handlers[SET_ROLLINGSTOCK_INCIDENT](
					cloneDeep(state.rollingStocks),
					action.payload.incident,
					action.payload.trainTechId,
					cloneDeep(state.statusCounts)
				),
			};

		case SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT:
			return {
				...state,
				statusCounts: handlers[SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT](
					cloneDeep(state.rollingStocks),
					action.payload.npHighLimit
				),
			};

		case DELETE_ROLLINGSTOCK_SERVICE:
			return {
				...state,
				lastServiceRemovalDate: Date.now(),
			};

		case SET_ROLLINGSTOCK_SORT:
			return {
				...state,
				rollingStocks: handlers[SET_ROLLINGSTOCK_SORT](
					cloneDeep(state.rollingStocks),
					action.payload.row,
					action.payload.key
				),
			};

		default:
			return state;
	}
};

export const handlers = {
	addIncidentAfterCreate(
		stateIncidents: RSIncident[],
		incident: RSIncident
	): RSIncident[] {
		const newIncidents = stateIncidents.map((stateIncident) =>
			cloneDeep(stateIncident)
		);
		if (
			!newIncidents.find(
				(stateIncident) => Number(stateIncident.id) === Number(incident.id)
			)
		) {
			newIncidents.push(incident);
		}
		return newIncidents;
	},

	DELETE_ROLLINGSTOCK_AVAILABILITY(
		rollingStocks: TrackingBoardDTO[],
		availabilityId: number
	): TrackingBoardDTO[] {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.availability) {
				if (rollingStock.availability.id === availabilityId) {
					rollingStock.availability = undefined;
				}
			}
		});
		return rollingStocks;
	},

	DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID(
		rollingStocks: TrackingBoardDTO[],
		trainTechId: number,
		actionType: string,
		actionTypeId?: number
	): TrackingBoardDTO[] {
		const isMatchingAction = (rollingStock: TrackingBoardDTO): boolean => {
			if (!rollingStock.availability) return false;

			switch (actionType) {
				case 'SERVICE':
					return rollingStock.availability.serviceTypeId === actionTypeId;
				case 'GR':
					return (
						rollingStock.availability.incidentTypeId === IncidentTypeEnum.GR
					);
				case 'PB':
					return (
						rollingStock.availability.incidentTypeId === IncidentTypeEnum.PB
					);
				case 'DI':
					return (
						rollingStock.availability.incidentTypeId === IncidentTypeEnum.DI
					);
				case 'OP':
					return (
						rollingStock.availability.specialOperationTypeId === actionTypeId
					);
				default:
					return false;
			}
		};

		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id === trainTechId && isMatchingAction(rollingStock)) {
				rollingStock.availability = undefined;
			}
		});

		return rollingStocks;
	},

	DELETE_ROLLINGSTOCK_INCIDENT(
		rollingStocks: TrackingBoardDTO[],
		incidentReportId: number,
		trainTechId: number,
		npHighLimit?: number
	): {
		rollingStocks: TrackingBoardDTO[];
		statusCounts: CountState;
	} {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id === trainTechId) {
				rollingStock.incidents = rollingStock.incidents.filter(
					(incident) => incident.id !== incidentReportId
				);
			}
			getIncidentsOrder(rollingStock.incidents);
		});
		rollingStocks.sort((a, b) => parseInt(a.trainCode) - parseInt(b.trainCode));
		rollingStocks = setRollingStocksOrder(rollingStocks);
		const statusCounts = calcStatusCount(rollingStocks, npHighLimit);
		return { rollingStocks, statusCounts };
	},

	DELETE_ROLLINGSTOCK_SERVICE(rollingStocks: TrackingBoardDTO[]): {
		rollingStocks: TrackingBoardDTO[];
		statusCounts: CountState;
	} {
		const statusCounts = calcStatusCount(rollingStocks);

		return { rollingStocks, statusCounts };
	},

	DELETE_ROLLINGSTOCK_SPECIAL_OPERATION(
		rollingStocks: TrackingBoardDTO[],
		specialOperationId: number,
		trainTechId: number
	): TrackingBoardDTO[] {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id === trainTechId) {
				rollingStock.specialOperations = rollingStock.specialOperations.filter(
					(specialOperation) => specialOperation.id !== specialOperationId
				);
			}
		});
		return rollingStocks;
	},

	RESOLVE_ROLLINGSTOCK_INCIDENT(
		rollingStocks: TrackingBoardDTO[],
		incidentTypeId: number,
		trainTechId: number
	): TrackingBoardDTO[] {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id == trainTechId) {
				rollingStock.incidents = rollingStock.incidents.filter(
					(incident) => incident.incidentTypeId !== incidentTypeId
				);
			}
			getIncidentsOrder(rollingStock.incidents);
		});
		rollingStocks.sort((a, b) => parseInt(a.trainCode) - parseInt(b.trainCode));
		rollingStocks = setRollingStocksOrder(rollingStocks);
		return rollingStocks;
	},

	SET_ROLLINGSTOCK(
		rollingStocks: TrackingBoardDTO[],
		npHighLimit: number
	): {
		rollingStocks: TrackingBoardDTO[];
		statusCounts: CountState;
	} {
		rollingStocks.forEach((rollingStock) => {
			if (rollingStock.isUsed === null) rollingStock.isUsed = false;
			setCleanStatus(rollingStock);
			getIncidentsOrder(rollingStock.incidents);
		});
		const statusCounts = calcStatusCount(rollingStocks, npHighLimit);
		rollingStocks = setRollingStocksOrder(rollingStocks);

		return { rollingStocks, statusCounts };
	},

	SET_ROLLINGSTOCK_INCIDENT(
		rollingStocks: TrackingBoardDTO[],
		incident: RSIncident,
		trainTechId: number,
		statusCounts: CountState
	): {
		rollingStocks: TrackingBoardDTO[];
		statusCounts: CountState;
	} {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id === trainTechId) {
				rollingStock.incidents = this.addIncidentAfterCreate(
					rollingStock.incidents,
					incident
				);
			}
			getIncidentsOrder(rollingStock.incidents);
		});
		if (incident.incidentTypeId === IncidentTypeEnum.GR) {
			statusCounts.degrafed++;
		}
		rollingStocks = setRollingStocksOrder(rollingStocks);

		return { rollingStocks, statusCounts };
	},

	SET_ROLLINGSTOCK_IS_USED(
		rollingStocks: TrackingBoardDTO[],
		id: number,
		newStatus: boolean
	): TrackingBoardDTO[] {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id === id) rollingStock.isUsed = newStatus;
		});
		rollingStocks = setRollingStocksOrder(rollingStocks);
		return rollingStocks;
	},

	SET_ROLLINGSTOCK_IS_USED_LIST(
		rollingStocks: TrackingBoardDTO[],
		newUsedHistory: ApiRequestUsedUpdate[]
	): TrackingBoardDTO[] {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			const rollingStockHistory = newUsedHistory.find(
				(usedHistory) => usedHistory.trainId === rollingStock.id
			);
			if (rollingStockHistory) rollingStock.isUsed = rollingStockHistory.isUsed;
		});
		return rollingStocks;
	},

	SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT(
		rollingStocks: TrackingBoardDTO[],
		npHighLimit: number
	): CountState {
		return calcStatusCount(rollingStocks, npHighLimit);
	},

	SET_ROLLINGSTOCK_SORT(
		rollingStocks: TrackingBoardDTO[],
		row: string,
		key: string
	): TrackingBoardDTO[] {
		const valueKeys: { [key: string]: keyof TrackingBoardDTO } = {
			mal: MAL_DELTA_TIME,
			nc: NC_DELTA_TIME,
			ncr: NCR_DELTA_TIME,
			np: NP_DELTA_TIME,
			pbs: PBS_DELTA_TIME,
		};
		const keyValue = valueKeys[row];
		const isUp = key === UP;

		const numerifyValue = (value: TrackingBoardDTO) =>
			Number((value[keyValue] as string).trim() || '0.5');

		return rollingStocks.sort(
			(first: TrackingBoardDTO, second: TrackingBoardDTO) =>
				numerifyValue(isUp ? first : second) -
				numerifyValue(isUp ? second : first)
		);
	},

	UPDATE_ROLLINGSTOCK_AVAILABILITY(
		rollingStocks: TrackingBoardDTO[],
		availability: Availability,
		trainTechId: number
	): TrackingBoardDTO[] {
		const rollingStockToUpdate = rollingStocks.find(
			(rollingStock) => rollingStock.id === trainTechId
		);
		if (rollingStockToUpdate) {
			rollingStockToUpdate.availability = availability;
		}
		return [...rollingStocks];
	},

	UPDATE_ROLLINGSTOCK_INCIDENTS(
		rollingStocks: TrackingBoardDTO[],
		incident: RSIncident,
		trainTechId: number
	): TrackingBoardDTO[] {
		rollingStocks.forEach((rollingStock: TrackingBoardDTO) => {
			if (rollingStock.id === trainTechId) {
				rollingStock.incidents = this.updateIncidentsState(
					rollingStock.incidents,
					incident
				);
			}
			getIncidentsOrder(rollingStock.incidents);
		});

		rollingStocks = setRollingStocksOrder(rollingStocks);

		return rollingStocks;
	},

	UPDATE_ROLLINGSTOCK_SERVICE(
		rollingStocks: TrackingBoardDTO[],
		{ date, trainCode, serviceTypeId }: ServiceUpdateData
	): TrackingBoardDTO[] {
		const updateRollingStocks = rollingStocks.map((rollingStock) => {
			if (rollingStock.trainCode === trainCode) {
				const newDate = new Date(date);
				switch (serviceTypeId) {
					case ServiceTypeEnum.NC:
						rollingStock.NC = newDate;
						break;
					case ServiceTypeEnum.NP:
						rollingStock.NP = newDate;
						break;
					case ServiceTypeEnum.PBS:
						rollingStock.PBS = newDate;
						break;
					case ServiceTypeEnum.NCR:
						rollingStock.NCR = newDate;
						break;
					case ServiceTypeEnum.MAL:
						rollingStock.MAL = newDate;
						break;
					case ServiceTypeEnum.MEP:
						rollingStock.MEP = newDate;
						break;
					default:
						break;
				}
				setCleanStatus(rollingStock);
			}
			return rollingStock;
		});

		return setRollingStocksOrder(updateRollingStocks);
	},

	updateIncidentsState(
		stateIncidents: RSIncident[],
		updatedIncident: RSIncident
	): RSIncident[] {
		const newIncidents = stateIncidents.map((incident) => {
			return incident.id === updatedIncident.id ? updatedIncident : incident;
		});

		return newIncidents;
	},
};

export default rollingStockReducer;
