import { ReactNode } from 'react';

import Logo from '../../navBar/logo';

import { StyledHeader, StyledLogo } from './styles';

const ModalHeader = ({ children, isOp }: ModalHeaderProps) => {
	return (
		<StyledHeader>
			{!isOp && (
				<StyledLogo>
					<Logo size="small" />
				</StyledLogo>
			)}
			{children}
		</StyledHeader>
	);
};

export default ModalHeader;

type ModalHeaderProps = {
	children?: ReactNode;
	isOp?: boolean;
};
