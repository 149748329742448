import {
	DATA_TYPE_INCIDENTS,
	DATA_TYPE_SERVICES,
} from './../packages/helpers/explanations/export';
import { ServiceTypeEnum } from './serviceType.model';

export interface Export {
	startDate: Date;
	endDate: Date;
	dataType: DataType;
	services: ServiceSelection[];
	rollingStockIds: number[];
	lines: string[];
	roleIds: number[];
	isAllLinesSelected: boolean;
}

export enum DataTypeEnum {
	SERVICE = 0,
	INCIDENT = 1,
	OP = 2,
}

type IdEnumNameObject = {
	id: DataTypeEnum;
	name: string;
};

export type ServiceSelection = {
	selected: boolean;
	id: ServiceTypeEnum;
	name: string;
	disabled?: boolean;
	key?: string;
};

export interface DataType extends IdEnumNameObject, IdEnumNameObject {}

export const dataTypeSelectObject = [
	{
		id: DataTypeEnum.SERVICE,
		name: DATA_TYPE_SERVICES,
	},
	{
		id: DataTypeEnum.INCIDENT,
		name: DATA_TYPE_INCIDENTS,
	},
];
