import { useTypedSelector } from './useTypedSelector';

export const useServiceType = () => {
	const { serviceTypesCreate, serviceTypesDelete, serviceTypesExport } =
		useTypedSelector(({ serviceTypeState }) => serviceTypeState);

	return {
		serviceTypesCreate: serviceTypesCreate || [],
		serviceTypesDelete: serviceTypesDelete || [],
		serviceTypesExport: serviceTypesExport || [],
	};
};
