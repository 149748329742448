import { FC, ReactElement, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { Station } from '../../../../models/station.model';
import StationDetailPopIn from '../../../modals/stationDetailPopIn';
import { getStationStatus, sortByStatus } from '../../utils';
import SemCard from '../SemCard/SemCard';

import './SemTable.css';

type Props = {
	stations: Station[];
	passage: number;
};

const SemTable: FC<Props> = ({ stations, passage }): ReactElement => {
	const [stationsArraySortedByStatus, setStationsArraySortedByStatus] =
		useState<Array<Station>>(stations);

	useEffect(() => {
		setStationsArraySortedByStatus(
			cloneDeep(stations).sort((a, b) => sortByStatus(a, b, passage))
		);
	}, [stations, passage]);

	const generateStationRow = (station: Station) => {
		const totalToUse = station[
			`totalPassage${passage}` as keyof Station
		] as string;
		const totalUnits = station[
			`totalUnitPassage${passage}` as keyof Station
		] as string;
		const status = getStationStatus(
			parseInt(totalToUse, 10),
			parseInt(totalUnits, 10)
		);
		const latestServicedDate = station[
			`lastStationServiceDate${passage}` as keyof Station
		] as string;
		return (
			<SemCard
				key={station.id}
				latestStationServicedDate={latestServicedDate}
				line={station.line}
				name={station.name}
				passage={passage}
				stationId={station.id}
				status={status}
				totalDone={totalToUse}
				totalUnits={totalUnits}
			/>
		);
	};
	return (
		<div className="sem-table__container">
			<div className="sem-table-title__status">Statut</div>
			<div className="sem-table-title__station">Station</div>
			<div className="sem-table-title__unit">Unité</div>
			<div className="sem-table-title__end">Fin</div>
			<div className="sem-table__cards">
				{stationsArraySortedByStatus?.map(generateStationRow)}
				<StationDetailPopIn />
			</div>
		</div>
	);
};

export default SemTable;
