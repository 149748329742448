import { Input } from '../../../../../packages/ui';

const IncidentReportComment = ({
	bgColor,
	comment,
	errors,
	handleChange,
	isEditableForm,
}: IncidentReportCommentProps) => {
	return (
		<Input
			value={comment ?? ''}
			helperText={errors.comment}
			name="comment"
			label="Commentaire"
			onChange={handleChange}
			fullWidth
			multiline
			rows={4}
			disabled={!isEditableForm}
			style={{
				backgroundColor: bgColor,
			}}
		/>
	);
};
export default IncidentReportComment;

type IncidentReportCommentProps = {
	bgColor: '#E8E8EA' | 'white';
	comment?: string;
	errors: {
		[key: string]: string;
	};
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isEditableForm?: boolean;
};
