import {
	RsUseActionTypes,
	SET_RS_USED_COUNT,
	UPDATE_USED_STATUS,
	UsedRsReportState,
} from '../types/rollingStockUsed.types';

export const setRSUsedCount = (
	usedRs: UsedRsReportState
): RsUseActionTypes => ({
	payload: usedRs,
	type: SET_RS_USED_COUNT,
});

export const updateUsedStatus = (
	idCb: number,
	newIsUsed: boolean
): RsUseActionTypes => ({
	payload: { idCb, newIsUsed },
	type: UPDATE_USED_STATUS,
});

export const rsUsedActions = {
	setRSUsedCount,
	updateUsedStatus,
};

export default rsUsedActions;
