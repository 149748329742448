import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import {
	IncidentSelected,
	IncidentTypeEnum,
	Item,
	RSIncident,
} from '../../../../../models';
import { SolvedIncidentReport } from '../../../../../models/incidentSolving.model';
import { RsSpecialOperation } from '../../../../../models/specialOperation.model';
import {
	INC_RESOLVE_TITLE,
	INC_SEL_SUBTITLE,
} from '../../../../../packages/helpers';
import {
	useIncidentType,
	useTypedSelector,
} from '../../../../../packages/hooks';
import { Button, Dialog } from '../../../../../packages/ui';
import { IncidentSvgPrintable } from '../../../../../packages/ui/icons';
import ResolveButtons from '../../../../../packages/ui/molecules/ResolveButtons';
import {
	resolveAndDeleteRollingStockIncident,
	resolvePartiallyAndUpdateRollingStockIncident,
} from '../../../../../redux/thunks/incidentReport';
import { solveSpecialOperation } from '../../../../../redux/thunks/specialOperation';
import Logo from '../../../../navBar/logo';

const IncidentResolveSelect = ({
	classNameIncident,
	handleClickIncident,
	handleClose,
	incidentSelected,
	isDFSelected,
	isTouched,
	open,
	rsCode,
	setIncidentSelected,
	setIsDFSelected,
	setIsResolvingIncident,
	trainId,
	trainNumber,
}: IncidentResolveSelectProps) => {
	const dispatch = useDispatch();
	const { incidentTypesSolve } = useIncidentType();

	const rollingStocks =
		useTypedSelector(
			({ rollingStockState }) => rollingStockState.rollingStocks
		) || [];

	const { npHighLimit } = useTypedSelector((state) => state.settingState);

	const selectedRs = rollingStocks.find(({ id }) => id === trainId);
	const specialOperations = selectedRs?.specialOperations ?? [];

	const getIncidentReportsSelected = (incidentSelectedId: number) =>
		selectedRs?.incidents.filter(
			(incidentReport) => incidentReport.incidentTypeId === incidentSelectedId
		) ?? [];

	const incidentTypesToSolveFiltered = incidentTypesSolve.filter(
		({ id: incidentTypeSolveId }) =>
			Array.from(new Set(selectedRs?.incidents ?? [])).some(
				({ incidentTypeId }) => incidentTypeId === incidentTypeSolveId
			)
	);

	const classNameSpecialOperation = (
		specialOperation: RsSpecialOperation
	): string => {
		return `incident-toggle ${
			specialOperation.id === incidentSelected?.specialOperation?.id
				? 'incident-selected'
				: ''
		}`;
	};

	const handleClickSpecialOperation = (
		specialOperation: RsSpecialOperation
	) => {
		setIsDFSelected(false);
		setIncidentSelected({ specialOperation: specialOperation });
	};

	const onResolveIncident = async () => {
		if (!isTouched) return;
		setIsDFSelected(false);
		if (incidentSelected?.incident && trainId) {
			const { incident } = incidentSelected;
			const solvedIncident: SolvedIncidentReport = {
				date: new Date(),
				incidentTypeId: incident.id,
				rollingStockId: trainId,
			};
			const incidentReportsSelected = getIncidentReportsSelected(incident.id);
			dispatch(
				resolveAndDeleteRollingStockIncident(
					incidentReportsSelected,
					incident.name,
					solvedIncident,
					trainId,
					npHighLimit
				)
			);
		}
		if (incidentSelected?.specialOperation && trainId) {
			const { specialOperation } = incidentSelected;
			dispatch(solveSpecialOperation(specialOperation.id, trainId));
		}
		handleClose();
	};

	const onResolveIncidentPartially = () => {
		if (!isTouched) return;
		setIsDFSelected(false);
		setIncidentSelected(null);

		if (incidentSelected?.incident?.id === IncidentTypeEnum.GR && trainId) {
			const { incident } = incidentSelected;

			const selectedIncidents = getIncidentReportsSelected(incident.id);
			const solvedIncident: SolvedIncidentReport = {
				incidentTypeId: incident.id,
				isPartial: true,
				rollingStockId: trainId,
			};
			selectedIncidents.forEach((selectedIncident) => {
				const selectedResolvePartiallyIncidents: RSIncident[] = [
					{
						code: incident.code,
						complementId: selectedIncident.complementId,
						id: selectedIncident.id,
						incidentTypeId: incident.id,
						isMultisolving: true,
						isPriority: selectedIncident.isPriority,
						name: incident.name,
					},
				];
				dispatch(
					resolvePartiallyAndUpdateRollingStockIncident(
						selectedResolvePartiallyIncidents,
						trainId,
						solvedIncident
					)
				);
			});
		}
		handleClose();
	};

	return (
		<Dialog
			open={open}
			titleContent={INC_RESOLVE_TITLE}
			content={
				<>
					<div className="incident-header">
						<div>
							<Logo size="small" />
						</div>
						<div className="incident-header-train-number">
							<Typography variant="h6">{trainNumber}</Typography>
							<Typography variant="h5">{rsCode}</Typography>
						</div>
					</div>
					<div className="incidents-list-container">
						<Typography
							className="incidents-list-title"
							variant="h5"
							style={{ marginBottom: '1rem' }}
						>
							{INC_SEL_SUBTITLE}
						</Typography>
						{incidentTypesToSolveFiltered?.map((incidentType) => (
							<Button
								key={incidentType.id}
								className={classNameIncident(incidentType)}
								onClick={() => handleClickIncident(incidentType)}
								data-testid="incident-button"
								style={{ width: '60%' }}
							>
								<div
									className={`svg-incident-container svg-incident svg-incident-symbol-${incidentType.code.toLowerCase()}`}
								/>
								<div className="incident-name-container">
									{incidentType.name}
								</div>
							</Button>
						))}
						{specialOperations.map((specialOperation) => (
							<Button
								key={specialOperation.id}
								className={classNameSpecialOperation(specialOperation)}
								onClick={() => handleClickSpecialOperation(specialOperation)}
								style={{ width: '60%' }}
							>
								<IncidentSvgPrintable name="OP" />
								<div className="incident-name-container">
									{specialOperation.specialOperationTypeName}
								</div>
							</Button>
						))}
					</div>
				</>
			}
			actionsContent={
				<ResolveButtons
					isDFSelected={isDFSelected}
					setIsResolvingIncident={setIsResolvingIncident}
					setIncidentSelected={setIncidentSelected}
					onCancel={handleClose}
					onValidate={onResolveIncident}
					onValidatePartially={onResolveIncidentPartially}
					validateDisabled={!isTouched}
				/>
			}
			onClose={handleClose}
		/>
	);
};

export default IncidentResolveSelect;

type IncidentResolveSelectProps = {
	classNameIncident: (incident: Item) => string;
	handleClickIncident: (item: Item) => void;
	handleClose: () => void;
	incidentSelected: IncidentSelected | null;
	isDFSelected: boolean;
	isTouched: boolean;
	open: boolean;
	rsCode?: string;
	setIncidentSelected: Dispatch<SetStateAction<IncidentSelected | null>>;
	setIsDFSelected: Dispatch<SetStateAction<boolean>>;
	setIsResolvingIncident: Dispatch<SetStateAction<boolean>>;
	trainId?: number;
	trainNumber: string;
};
