import { useEffect } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';

import { dateTimeService, pluralize } from '../../../packages/helpers';
import {
	useDepartment,
	useRight,
	useTypedSelector,
} from '../../../packages/hooks';
import { Button, Select } from '../../../packages/ui';

import '../header/style.css';

const SemHeader = ({
	onExportClick,
	onPeriodChange,
	selectedPeriod,
}: SemHeaderProps) => {
	const rsCount = useTypedSelector((state) => state.reportingState.rsCount);
	const { rsLabel } = useDepartment();
	const { canDisplayExportButton } = useRight();

	const getCurrentDateString = (): string[] => {
		return [
			`${dateTimeService.getCurrentMonthString()} ${dateTimeService.getCurrentYearString()}`,
			`${dateTimeService.getCurrentYearString()}`,
		];
	};

	const handleChange = (e: SelectChangeEvent) => {
		onPeriodChange(e.target.value);
	};

	useEffect(() => {
		onPeriodChange(getCurrentDateString()[0]);
		// eslint-disable-next-line
	}, []);

	return (
		<Grid
			container
			className="reporting-header-container"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item className="reporting-select" md={4} xs={12}>
				<Select
					data-testid="reporting-period-select"
					label="Période"
					onChange={handleChange}
					options={getCurrentDateString()}
					value={selectedPeriod}
					defaultValue={getCurrentDateString()[0]}
					width={160}
				/>
			</Grid>
			<Grid item md={4} xs={12}>
				<p className="font-paris-bold trains-count-text">
					{rsCount + ' ' + pluralize(rsCount, rsLabel)}
				</p>
				<p className="font-paris trains-count-subtitle">
					Reporting du mois en cours
				</p>
			</Grid>
			<Grid item className="reporting-header-export-button" md={4} xs={12}>
				{canDisplayExportButton() && (
					<Button label="Exporter" onClick={() => onExportClick()} />
				)}
			</Grid>
		</Grid>
	);
};

export default SemHeader;

type SemHeaderProps = {
	onExportClick: () => void;
	onPeriodChange: (period: string) => void;
	selectedPeriod: string;
};
