import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';

import {
	SettingNotificationIncident,
	SettingNotificationService,
} from '../../../models';

import NotificationIncident from './notificationIncident';
import NotificationService from './notificationService';

import './styles.css';

const SettingNotification = ({
	notificationServices,
	notificationIncidents,
	handleNotificationServicesChange,
	handleNotificationIncidentsChange,
	setSettingIsModified,
	npAlert,
}: SettingNotificationProps) => {
	return (
		<>
			<Typography variant="h5">Notifications</Typography>
			<Typography variant="subtitle1">
				Paramétrez les notifications que vous et vos équipes RATP et
				prestataires peuvent recevoir :
			</Typography>

			<NotificationService
				handleNotificationServicesChange={handleNotificationServicesChange}
				notificationServices={notificationServices}
				npAlert={npAlert}
				setSettingIsModified={setSettingIsModified}
			/>
			<NotificationIncident
				handleNotificationIncidentsChange={handleNotificationIncidentsChange}
				notificationIncidents={notificationIncidents}
				setSettingIsModified={setSettingIsModified}
			/>
		</>
	);
};

export default SettingNotification;

type SettingNotificationProps = {
	notificationServices: SettingNotificationService[];
	notificationIncidents: SettingNotificationIncident[];
	handleNotificationServicesChange: Dispatch<
		SetStateAction<SettingNotificationService[]>
	>;
	handleNotificationIncidentsChange: Dispatch<
		SetStateAction<SettingNotificationIncident[]>
	>;
	setSettingIsModified: Dispatch<SetStateAction<boolean>>;
	npAlert: number;
};
