import {
	Dispatch,
	SetStateAction,
	SyntheticEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	BrowserQRCodeReader,
	ChecksumException,
	FormatException,
	ReaderException,
	VideoInputDevice,
} from '@zxing/library';

import {
	DepartmentEnum,
	RollingStockQrFormat,
	ServiceStatus,
	ServiceTypeEnum,
	UnitQrFormat,
	User,
} from '../../models';
import { hidePopup, setServiceStatus, showPopup } from '../../redux/actions';
import { verifyAndLoadRollingStock } from '../../redux/thunks/rollingStock';
import { loadUnit } from '../../redux/thunks/unit';
import { Endpoints } from '../../routes/endpoint.config';
import SettingProvider from '../dataProviders/resources/setting';
import { errorConf, hasDepartment, hasSomeDepartments } from '../helpers/';

import { useTypedSelector } from './useTypedSelector';

const { MTS, SEM, RER } = DepartmentEnum;

function parseAndValidateRes(
	res: ScannerResult,
	user: User
): RollingStockQrFormat | UnitQrFormat {
	const qrInfo = JSON.parse(res.text);
	try {
		if (hasSomeDepartments(user, [MTS, RER])) {
			return validateMTSQRCode(qrInfo);
		} else if (hasDepartment(user, SEM)) {
			return validateSEMQRCode(qrInfo);
		} else {
			throw new ChecksumException();
		}
	} catch (error) {
		console.error(error);
		if (error instanceof ChecksumException) throw error;
		throw new FormatException();
	}
}

function validateMTSQRCode(qrInfo: any): RollingStockQrFormat {
	if (typeof qrInfo.id === 'string' && typeof qrInfo.materiel === 'string') {
		return qrInfo as RollingStockQrFormat;
	} else {
		throw new ChecksumException();
	}
}

function validateSEMQRCode(qrInfo: any): UnitQrFormat {
	if (
		typeof qrInfo.ligne === 'string' &&
		typeof qrInfo.idStation === 'number' &&
		typeof qrInfo.seqUnite === 'number'
	) {
		return qrInfo as UnitQrFormat;
	} else {
		throw new ChecksumException();
	}
}

function chooseDevice(
	devices: VideoInputDevice[],
	setIsInitialDeviceSelected: Dispatch<SetStateAction<boolean>>,
	setCurrentDeviceIdIndex: Dispatch<SetStateAction<number>>
): string {
	setIsInitialDeviceSelected(true);
	const backFacingId = devices.findIndex(({ label }) =>
		label.toLowerCase().includes('back')
	);
	if (backFacingId !== -1) {
		setCurrentDeviceIdIndex(backFacingId);
		return devices[backFacingId].deviceId;
	} else if (devices.length >= 1) {
		setCurrentDeviceIdIndex(0);
		return devices[0].deviceId;
	} else {
		throw new ReaderException();
	}
}

type ScannerResult = {
	format: number;
	numBits: number;
	rawBytes: Uint8Array;
	resultMetadata: Map<any, any>;
	resultPoints: [];
	text: string;
	timestamp: string;
};

const MS_BETWEEN_DECODING_ATTEMPTS = 1000;
const qrReader = new BrowserQRCodeReader();

export function useQrScanner(
	videoId: string
): (_: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useTypedSelector(({ userState }) => userState.user);

	const [currentDeviceIdIndex, setCurrentDeviceIdIndex] = useState<number>(0);
	const [isInitialDeviceSelected, setIsInitialDeviceSelected] =
		useState<boolean>(false);

	const selectedDeviceId = useRef<string>('0');
	const deviceOptions = useRef<VideoInputDevice[]>([]);
	const selectedLineUser =
		useTypedSelector(({ lineState }) => lineState.selectedLine) ?? '';

	const decodeOnce = useCallback(async () => {
		const setTimeoutCatchError = () => {
			decodeOnce().catch((error) => {
				console.error('Error during decoding:', error);
			});
		};

		const errorCb = () => {
			if (window.location.pathname === Endpoints.AGENTN_QR_SCANNER) {
				dispatch(showPopup(errorConf.error.invalidQrCode));
				setTimeout(() => {
					setTimeoutCatchError();
				}, MS_BETWEEN_DECODING_ATTEMPTS);
			} else {
				dispatch(hidePopup());
			}
		};

		const sucessCallback = () => {
			navigate(Endpoints.AGENTN_SELECTION);
		};

		// Bypass selection
		const sucessSemCallback = (line: string) => {
			SettingProvider.loadSetting(line)
				.then(({ data: settings }) => {
					if (settings) {
						dispatch(
							setServiceStatus(ServiceStatus.SELECTED, ServiceTypeEnum.NQ)
						);
						navigate(Endpoints.AGENTN_CONFIRM);
					} else {
						navigate(Endpoints.AGENTN_SELECTION);
					}
				})
				.catch(() => {
					navigate(Endpoints.AGENTN_SELECTION);
				});
		};
		try {
			const result = await qrReader.decodeOnceFromVideoDevice(
				selectedDeviceId.current,
				videoId
			);
			const newSurface = parseAndValidateRes(
				result as unknown as ScannerResult,
				user as User
			);
			const newerSurface = { ...newSurface, selectedLine: selectedLineUser };
			if (
				hasDepartment(user as User, MTS) ||
				hasDepartment(user as User, RER)
			) {
				dispatch(
					verifyAndLoadRollingStock(
						newerSurface as RollingStockQrFormat,
						sucessCallback,
						errorCb
					)
				);
			} else if (hasDepartment(user as User, SEM)) {
				const unitSurface = newSurface as UnitQrFormat;
				dispatch(
					loadUnit(
						unitSurface,
						() => sucessSemCallback(unitSurface.ligne),
						errorCb
					)
				);
			}
		} catch (error) {
			if (
				error instanceof FormatException ||
				error instanceof ChecksumException
			) {
				errorCb();
			}
		}
	}, [dispatch, navigate, videoId, selectedDeviceId, user, selectedLineUser]);

	const initDecoding = useCallback(async () => {
		if (!qrReader.isMediaDevicesSuported && !qrReader.canEnumerateDevices) {
			throw new ReaderException(
				"La lecture du QRCode n'est pas disponible sur cet appareil."
			);
		}
		// setting initial devices

		if (!isInitialDeviceSelected) {
			const videoInputDevices = await qrReader.getVideoInputDevices();
			deviceOptions.current = videoInputDevices;
			selectedDeviceId.current = chooseDevice(
				videoInputDevices,
				setIsInitialDeviceSelected,
				setCurrentDeviceIdIndex
			);
		} else {
			selectedDeviceId.current =
				deviceOptions.current[currentDeviceIdIndex].deviceId;
		}

		decodeOnce().catch((error) => {
			console.error('An error occurred during decoding initialization:', error);
		});
	}, [currentDeviceIdIndex, decodeOnce, isInitialDeviceSelected]);

	useEffect(() => {
		initDecoding().catch((error) => {
			console.error('An error occurred during decoding initialization:', error);
		});

		return () => {
			qrReader.reset();
		};
	}, [currentDeviceIdIndex]);

	return () => {
		setCurrentDeviceIdIndex(
			((currentDeviceIdIndex || 0) + 1) % deviceOptions.current.length
		);
	};
}
