import { User } from '../../models';

export interface CredentialsState {
	filter?: string;
	users?: User[];
	user?: User;
	id?: number;
}

export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_USER = 'RESPONSE_CREATE_USER';
export const DELETE_USER = 'DELETE_USER';

export interface SetCredentialsAction {
	type: typeof SET_CREDENTIALS;
	payload: User[];
}

export interface SetUserAction {
	type: typeof SET_USER;
	payload: User;
}

export interface DeleteUserAction {
	type: typeof DELETE_USER;
	payload: { id: number };
}

export type CredentialsActionTypes =
	| SetCredentialsAction
	| SetUserAction
	| DeleteUserAction;
