import { useLocation } from 'react-router-dom';
import {
	GridCsvExportOptions,
	GridCsvGetRowsToExportParams,
	gridExpandedSortedRowIdsSelector,
	GridToolbarContainer,
	GridToolbarContainerProps,
	useGridApiContext,
} from '@mui/x-data-grid-pro';

import Button from '../../atoms/Button';

const CustomToolbar = (props: GridToolbarContainerProps) => {
	const apiRef = useGridApiContext();
	const { pathname } = useLocation();
	const dataGridId = pathname.split('/')[1];

	const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) =>
		gridExpandedSortedRowIdsSelector(apiRef);

	return (
		<GridToolbarContainer {...props} sx={{ display: { xs: 'none' } }}>
			<Button
				id={`export-datagrid-btn-${dataGridId}`}
				onClick={() =>
					apiRef.current.exportDataAsCsv({
						delimiter: ';',
						fileName: 'smart-tracer',
						getRowsToExport: getRowsFromCurrentPage,
						utf8WithBom: true,
					} as GridCsvExportOptions)
				}
			>
				Exporter tableau
			</Button>

			<Button
				id={`print-datagrid-btn-${dataGridId}`}
				onClick={() =>
					apiRef.current.exportDataAsPrint({
						delimiter: ';',
						fileName: 'smart-tracer',
						getRowsToExport: getRowsFromCurrentPage,
						utf8WithBom: true,
					} as GridCsvExportOptions)
				}
			>
				Imprimer tableau
			</Button>
		</GridToolbarContainer>
	);
};
export default CustomToolbar;
