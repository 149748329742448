import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { isEmpty, isNil } from 'lodash';

import { DepartmentEnum } from '../../models';
import { IncidentRailcarDTO } from '../../models/incidentReport.model';

import { useLine } from './useLine';

export const useForm = ({
	callback,
	initFormValues = {},
	validate,
	validateOptions,
}: UseFormType) => {
	const [open, setOpen] = useState(false);
	const [values, setValues] = useState({} as any);
	const [files, setFiles] = useState<File[]>([]);
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [isUntouched, setIsUntouched] = useState(true);

	const { selectedLine } = useLine();

	const handleClose = () => {
		setValues(initFormValues);
		setFiles([]);
		setErrors({});
		setOpen(false);
		setIsUntouched(true);
	};

	const handleSubmit = () => {
		let checkedErrors = {};
		if (validate) {
			checkedErrors = validate(values, validateOptions);
			setErrors(checkedErrors);
		}
		if (Object.keys(checkedErrors).length === 0) {
			callback();
			handleClose();
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setValues({ ...values, [event.target?.name]: event.target?.value });
		setIsUntouched(false);
	};

	const handleDateChange = (name: string, value: Date) => {
		setValues({ ...values, [name]: value });
		setIsUntouched(false);
	};

	const handleSelect = (event: SelectChangeEvent, options?: any[]) => {
		const value = options
			? options.find(({ id }) => id === event.target?.value)
			: event.target?.value;
		setValues({
			...values,
			[event.target?.name]: value,
		});
		setIsUntouched(false);
	};

	const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setValues({ ...values, [event.target?.name]: event.target?.checked });
		setIsUntouched(false);
	};

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		setValues({ ...values, [event.target?.name]: isChecked });
		setIsUntouched(false);
	};

	const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		if (values[event.target?.name]?.lenght <= 0) return;
		const allCheckedValues = values[event.target?.name].map((el: any) => ({
			...el,
			checked: event.target?.checked,
		}));
		setValues({ ...values, [event.target?.name]: allCheckedValues });
		setIsUntouched(false);
	};

	const handleCheckOneOf = (
		event: React.ChangeEvent<HTMLInputElement>,
		of: string
	) => {
		event.persist();
		if (values[of]?.lenght <= 0) return;

		const allValues = values[of]?.map((el: any) =>
			Number(event.target.name) === Number(el.id)
				? {
						...el,
						checked: !el.checked,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  }
				: el
		);
		setValues({ ...values, [of]: allValues });
		setIsUntouched(false);
	};

	const handleElementSelectOneOf = (
		element: IdNameSelectObject | IncidentRailcarDTO,
		of: string
	) => {
		if (isNil(values[of]) || isEmpty(values[of])) return;
		const allValues = values[of]?.map((el: any) =>
			Number(element.id) === Number(el.id)
				? {
						...el,
						selected: !el.selected,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  }
				: el
		);
		setValues({ ...values, [of]: allValues });
		setIsUntouched(false);
	};
	const handleDeleteFile = (event: any, fileName: string, isFile = false) => {
		if (isFile) {
			setFiles(files.filter(({ name }) => name !== fileName));
			const updatedFiles = Array.from(values.files as File[]).filter(
				(file: File) => file.name !== fileName
			);
			setValues({
				...values,
				files: updatedFiles,
			});
		} else {
			setValues({
				...values,
				photos: values.photos?.filter((photo: string) => photo !== fileName),
			});
		}
	};

	const handleFiles = (event: any) => {
		event.persist();

		if (event?.target?.files) {
			setFiles([...files, ...event.target.files]);
			setValues({ ...values, files: [...files, ...event.target.files] });
			setIsUntouched(false);
		}
	};

	const handleResetFiles = () => {
		setFiles([]);
		setValues({ ...values, files: [] });
	};

	useEffect(() => {
		setValues(initFormValues);
	}, [selectedLine]);

	useEffect(() => {
		setValues(initFormValues);
	}, []);

	return {
		errors,
		files,
		handleChange,
		handleCheck,
		handleCheckAll,
		handleCheckChange,
		handleCheckOneOf,
		handleClose,
		handleDateChange,
		handleDeleteFile,
		handleElementSelectOneOf,
		handleFiles,
		handleResetFiles,
		handleSelect,
		handleSubmit,
		isUntouched,
		open,
		setErrors,
		setIsUntouched,
		setOpen,
		setValues,
		values,
	};
};

type UseFormType = {
	callback: () => void;
	validate?: (values: any, options?: ValidateOptions) => any;
	initFormValues?: { [key: string]: any };
	validateOptions?: ValidateOptions;
};

export type IdNameSelectObject = {
	id: number;
	name: string;
	selected?: boolean;
};
export type ValidateOptions = {
	department?: DepartmentEnum;
};
