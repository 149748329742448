import { SpecialOperation } from '../../../models/specialOperation.model';
import fetchJson from '../fetch/fetch-json';

const createSpecialOperation = async (
	specialOperation: SpecialOperation
): Promise<{
	data: number;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson('/special-operation/', {
		body: JSON.stringify(specialOperation),
		method: 'POST',
	});
};

const solveSpecialOperation = async (
	specialOperationId: number
): Promise<{
	data: SpecialOperation;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/special-operation/${specialOperationId}`, {
		method: 'PATCH',
	});
};

const deleteOperationSpecial = async (
	id: number
): Promise<{
	data: SpecialOperation;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/special-operation/${id}`, {
		method: 'DELETE',
	});
};

const loadOp = async (
	lineId: string,
	specialOperationType?: number
): Promise<{
	data: SpecialOperation[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/special-operation?lineId=${lineId}&specialOperationType=${specialOperationType}`
	);
};

export default {
	createSpecialOperation,
	deleteOperationSpecial,
	loadOp,
	solveSpecialOperation,
};
