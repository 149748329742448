import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import { SelectChangeEvent, Typography } from '@mui/material';

import {
	Export,
	Role,
	ServiceSelection,
	ServiceType,
} from '../../../../models';
import {
	rollingStockService,
	TrackingBoardDTO,
} from '../../../../packages/dataProviders';
import { getUniqueKey } from '../../../../packages/helpers';
import {
	EXPORT_ELEMENTS_TITLE,
	EXPORT_LINES_TITLE,
	EXPORT_PROFILS_TITLE,
} from '../../../../packages/helpers/explanations/export';
import {
	useDepartment,
	useLine,
	useServiceType,
	useTypedSelector,
} from '../../../../packages/hooks';
import { Checkbox } from '../../../../packages/ui';
import { getRoles } from '../../../../redux/thunks/roles';

import ExportData from './exportData';
import ExportPeriod from './exportPeriod';
import { StyledExportContainer } from './styles';

import '../../HistoryPopIn/Content/styles.css';

const changetoSelectObject = (array: string[]): SelectLineProps[] => {
	return array.map((element, index) => {
		return { label: element, value: index };
	});
};

const changeServiceTypesExportObjectToDataSelectionObject = (
	services: ServiceType[]
): ServiceSelection[] =>
	services.map((service) => {
		return {
			id: service.id,
			key: getUniqueKey(service.name),
			name: service.name,
			selected: true,
		};
	});

const ExportContent = ({
	errors,
	handleCheck,
	handleSelect,
	setRollingStockListIds,
	rollingStockListIds,
	values,
	setValues,
}: ExportContentProps) => {
	const dispatch = useDispatch();
	const { serviceTypesExport } = useServiceType();
	const [rollingStocks, setRollingStocks] = useState<TrackingBoardDTO[]>([]);
	const rollingStockList = useTypedSelector(
		({ rollingStockState }) => rollingStockState.rollingStocks
	);

	const { departmentEnum: department, rsLabel } = useDepartment();

	const { allLines, hasMultiLines, selectedLine } = useLine();

	const allLinesObject = changetoSelectObject(allLines);

	const stateTs = useTypedSelector((state) => state.historyPopInState);

	const handleLineSelectionChange = (lines: SelectLineProps[]) => {
		const selectedLines = lines.map((line) => line.label);

		if (selectedLines.length === 1)
			rollingStockService
				.loadRollingStock(selectedLines[0])
				.then((rollingstocks) => {
					setRollingStockListIds(rollingstocks.map((rs) => rs.id));
					setRollingStocks(rollingstocks);
				});
		setValues({ ...values, lines: selectedLines });
	};

	const handleElementsChange = (rollingStocks: TrackingBoardDTO[]) => {
		const selectedIds = rollingStocks.map((rollingStock) => rollingStock.id);
		setValues({
			...values,
			rollingStockIds: selectedIds,
		});
	};

	const placeholderByDepartement = useMemo(
		() => `Tous les ${rsLabel}s`,
		[department]
	);

	const handleUserProfilesFilterChange = (profils: Role[]) => {
		const selectedIds = profils.map((profil) => profil.id);
		setValues({ ...values, roleIds: selectedIds });
	};

	useEffect(() => {
		setRollingStockListIds(rollingStockList.map((rs) => rs.id));
		setRollingStocks(rollingStockList);
		setValues({
			...values,
			lines: selectedLine,
			rollingStockIds: rollingStockList.map((rs) => rs.id),
		});
		dispatch(getRoles());
	}, []);

	useEffect(() => {
		setValues({
			...values,
			rollingStockIds: rollingStockListIds,
			services:
				changeServiceTypesExportObjectToDataSelectionObject(serviceTypesExport),
		});
	}, [serviceTypesExport, rollingStockListIds]);
	return (
		<StyledExportContainer>
			<ExportPeriod setValues={setValues} values={values} />
			<ExportData
				errors={errors}
				handleSelect={handleSelect}
				setValues={setValues}
				values={values}
			/>
			<Typography variant="h5" my="1rem">
				{EXPORT_ELEMENTS_TITLE}
			</Typography>
			{values.lines?.length > 1 ? (
				<Typography color="var(--bg-color-grey-40)" variant="body2">
					{placeholderByDepartement}
				</Typography>
			) : (
				<Select
					color="#0a0082"
					labelField="trainCode"
					disabled={values.lines?.length > 1}
					multi
					onChange={(values) => handleElementsChange(values)}
					options={rollingStocks}
					placeholder={`${placeholderByDepartement} (${rollingStocks.length})`}
					searchBy="trainCode"
					values={[]}
					valueField="id"
				/>
			)}
			{hasMultiLines && (
				<>
					<Typography variant="h5" my="1rem">
						{EXPORT_LINES_TITLE}
					</Typography>
					<Checkbox
						name="isAllLinesSelected"
						label="Toutes les lignes"
						checked={values.isAllLinesSelected}
						onChange={handleCheck}
					/>
					{!values.isAllLinesSelected && (
						<Select
							color="#0a0082"
							multi
							onChange={(values) => handleLineSelectionChange(values)}
							options={allLinesObject}
							placeholder="Choisissez une ligne"
							searchBy="line"
							values={changetoSelectObject(values.lines)}
						/>
					)}
					<Typography style={{ fontSize: '0.86rem' }} color="error">
						{errors.lines}
					</Typography>
				</>
			)}
			<Typography variant="h5" my="1rem">
				{hasMultiLines ? '5.' : '4.'}
				{EXPORT_PROFILS_TITLE}
			</Typography>
			<Select
				color="#0a0082"
				labelField="name"
				multi
				onChange={(values) => handleUserProfilesFilterChange(values)}
				options={stateTs.usersProfilFilter}
				placeholder={`Tous les profils d'utilisateurs (${stateTs.usersProfilFilter.length})`}
				searchBy="name"
				valueField="id"
				values={[]}
			/>
		</StyledExportContainer>
	);
};

type SelectLineProps = {
	value: number;
	label: string;
};

export default ExportContent;

type ExportContentProps = {
	errors: { [key: string]: string };
	handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	rollingStockListIds: number[];
	setRollingStockListIds: Dispatch<SetStateAction<number[]>>;
	setValues: Dispatch<SetStateAction<Export>>;
	values: any;
};
