import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'lodash';

import LineSelector from '../../components/lineSelector';
import DeleteServicePopIn from '../../components/modals/deleteServicePopIn';
import Export from '../../components/modals/export';
import MultipleAvailability from '../../components/modals/multipleAvailability';
import ServicePopIn from '../../components/modals/servicePopIn';
import MultipleAvailabilityFooter from '../../components/multipleAvailability/footer';
import Header from '../../components/trackingBoard/header';
import RollingStockList from '../../components/trackingBoard/list';
import { DepartmentEnum } from '../../models';
import { MultipleAvailabilityTypeEnum } from '../../models/multipleAvailability';
import { initEventSource } from '../../packages/events/creator';
import { CREATE_MULTIPLE_AVAILABILITY } from '../../packages/helpers/rigths';
import {
	useDepartment,
	useEntity,
	useLine,
	useModal,
	useRight,
	useTypedSelector,
} from '../../packages/hooks';
import { openHistoryPopIn } from '../../redux/actions/historyPopIn.actions';
import { loadRightByType } from '../../redux/thunks/right';
import { loadAndSetRollingStock } from '../../redux/thunks/rollingStock';
import { loadSetting } from '../../redux/thunks/setting';

const TrackingRsPage = () => {
	const [openServicePopin, setOpenServicePopin] = useState(false);
	const { handleCloseModal, handleOpenModal, openModalState } = useModal();
	const dispatch = useDispatch();
	const [multipleAvailabilityType, setMultipleAvailabilityType] = useState<
		MultipleAvailabilityTypeEnum | undefined
	>(undefined);
	const [openMultipleAvailability, setOpenMultipleAvailability] =
		useState(false);
	const { can } = useRight();
	const { isAdmin, userTs } = useEntity();
	const { departmentEnum } = useDepartment();
	const { hasMultiLines: showSelector, selectedLine } = useLine();
	const { npHighLimit } = useTypedSelector((state) => state.settingState);

	const { lastServiceRemovalDate } = useTypedSelector(
		({ rollingStockState }) => {
			return {
				lastServiceRemovalDate: rollingStockState.lastServiceRemovalDate,
				rollingStocks: rollingStockState.rollingStocks,
			};
		}
	);

	const handleOpenExport = () => {
		if (departmentEnum === DepartmentEnum.SEM) {
			dispatch(openHistoryPopIn());
		} else {
			handleOpenModal('export');
		}
	};

	useEffect(() => {
		if (selectedLine) {
			dispatch(loadSetting(selectedLine));
			if (isAdmin) dispatch(loadRightByType());
		}
	}, [selectedLine]);

	useEffect(() => {
		if (!isEmpty(selectedLine) && !isNil(npHighLimit)) {
			dispatch(
				loadAndSetRollingStock(npHighLimit, selectedLine, departmentEnum)
			);
		}
	}, [dispatch, npHighLimit, lastServiceRemovalDate, selectedLine]);

	useEffect(() => {
		if (!isEmpty(selectedLine) && (isAdmin || showSelector) && userTs) {
			initEventSource(userTs, selectedLine, true);
		}
	}, [selectedLine, userTs, isAdmin]);

	return (
		<main data-testid="tracking-board-page">
			<Header
				onExportClick={handleOpenExport}
				handleOpenServicePopin={() => setOpenServicePopin(true)}
			/>
			<div className="content">
				{showSelector && <LineSelector />}
				<RollingStockList />
			</div>
			{can(CREATE_MULTIPLE_AVAILABILITY) && (
				<>
					<MultipleAvailabilityFooter
						setMultipleAvailabilityType={setMultipleAvailabilityType}
						setOpenMultipleAvailability={setOpenMultipleAvailability}
					/>
					<MultipleAvailability
						availabilityType={multipleAvailabilityType}
						openMultipleAvailability={openMultipleAvailability}
						setOpenMultipleAvailability={setOpenMultipleAvailability}
					/>
				</>
			)}
			<DeleteServicePopIn />
			<ServicePopIn
				open={openServicePopin}
				handleClose={() => setOpenServicePopin(false)}
			/>
			<Export
				open={openModalState.export}
				handleCloseModal={handleCloseModal}
			/>
		</main>
	);
};

export default TrackingRsPage;
