import { Setting, SummedSetting } from '../../../models';
import fetchJson from '../fetch/fetch-json';

const loadSetting = async (
	line: string
): Promise<{
	data: Setting;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/setting/line/${line}`);
};

const loadSummedSetting = async (): Promise<{
	data: SummedSetting;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson('/setting/sum');
};

const saveSetting = async (
	setting: Setting
): Promise<{
	data: string;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const options = {
		body: JSON.stringify(setting),
		method: 'POST',
	};
	return await fetchJson(`/setting`, options);
};

export default {
	loadSetting,
	loadSummedSetting,
	saveSetting,
};
