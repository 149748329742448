import { Card, IconButton } from '@mui/material';
import { styled } from '@mui/system';

import { DataGridPro } from '../../../packages/ui';
import theme from '../../../packages/ui/theme';

export const StyledAnoContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-around',
});

export const StyledIncidentContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-around',
});

export const StyledAnoCard = styled(Card)<{
	border?: string;
}>(({ border = `1px solid ${theme.palette.primary.main}` }) => ({
	border,
	margin: 'auto 0.5rem',
	maxHeight: '40px',
	minWidth: '40px',
}));

export const StyledIncidentCard = styled(Card)<{
	border?: string;
}>(({ border = `1px solid ${theme.palette.primary.main}` }) => ({
	border,
	margin: 'auto 0.5rem',
	maxHeight: '40px',
	minWidth: '40px',
}));

export const StyledServiceRender = styled('span')<{
	color: string;
}>(({ color }) => ({
	color,
}));

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
	'& .not-used': {
		'&:hover': {
			backgroundColor: `${theme.palette.grey[400]} !important`,
		},
		backgroundColor: `${theme.palette.grey[300]} !important`,
	},
}));

export const StyledNoHoverIconBtn = styled(IconButton)({
	'&:hover': {
		backgroundColor: 'transparent',
		cursor: 'default',
	},
});
