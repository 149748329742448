import {
	IncidentLocationDTO,
	IncidentRailcarDTO,
} from '../../models/incidentReport.model';

export const isEmptyString = (string: string | undefined) => {
	return string?.trim() === '' || string === undefined;
};

export const isEmptyLocationChecked = (
	locations: IncidentLocationDTO[] | undefined
) => {
	return !locations?.some((location) => location.checked);
};

export const isEmptyRailcarsChecked = (
	railcars: IncidentRailcarDTO[] | undefined
) => {
	return !railcars?.some((railcar) => railcar.selected);
};

export const isTooLongComment = (comment: string, maxLength = 250) => {
	return comment.length > maxLength;
};
