export { default as IncidentSvg } from './IncidentSvg';
export { default as IncidentSvgPrintable } from './IncidentSvgPrintable';
import { default as CustomAccesTime } from '@mui/icons-material/AccessTimeFilled';
import { default as CustomAddIcon } from '@mui/icons-material/Add';
import { default as CustomArchiveIcon } from '@mui/icons-material/Archive';
import { default as CustomAssignmentTurnedInIcon } from '@mui/icons-material/AssignmentTurnedIn';
import { default as CustomCheckBoxIcon } from '@mui/icons-material/CheckBox';
import { default as CustomCheckBoxOutlineBlankIcon } from '@mui/icons-material/CheckBoxOutlineBlank';
import { default as CustomClearIcon } from '@mui/icons-material/Clear';
import { default as CustomCloseIcon } from '@mui/icons-material/Close';
import { default as CustomDeleteIcon } from '@mui/icons-material/Delete';
import { default as CustomDoneIcon } from '@mui/icons-material/Done';
import { default as CustomDownloadIcon } from '@mui/icons-material/Download';
import { default as CustomEditIcon } from '@mui/icons-material/Edit';
import { default as CustomKeyboardArrowDownIcon } from '@mui/icons-material/KeyboardArrowDown';
import { default as CustomMoreVertIcon } from '@mui/icons-material/MoreVert';
import { default as CustomRailwayAlertIcon } from '@mui/icons-material/RailwayAlert';
import { default as CustomRemoveIcon } from '@mui/icons-material/Remove';
import { default as CustomRestartAltIcon } from '@mui/icons-material/RestartAlt';
import { default as CustomSaveAltIcon } from '@mui/icons-material/SaveAlt';
import { default as CustomLookingGlass } from '@mui/icons-material/Search';
import { default as CustomStarIcon } from '@mui/icons-material/Star';
import { default as CustomVisibility } from '@mui/icons-material/Visibility';
import { default as CustomVisibilityOff } from '@mui/icons-material/VisibilityOff';
import { default as CustomWarnings } from '@mui/icons-material/Warning';

export const AddIcon = CustomAddIcon;
export const DownloadIcon = CustomDownloadIcon;
export const AccessTime = CustomAccesTime;
export const ArchiveIcon = CustomArchiveIcon;
export const AssignmentTurnedInIcon = CustomAssignmentTurnedInIcon;
export const CheckBoxIcon = CustomCheckBoxIcon;
export const CheckBoxOutlineBlankIcon = CustomCheckBoxOutlineBlankIcon;
export const ClearIcon = CustomClearIcon;
export const CloseIcon = CustomCloseIcon;
export const DeleteIcon = CustomDeleteIcon;
export const DoneIcon = CustomDoneIcon;
export const EditIcon = CustomEditIcon;
export const ExportIcon = CustomSaveAltIcon;
export const KeyboardArrowDownIcon = CustomKeyboardArrowDownIcon;
export const MoreVertIcon = CustomMoreVertIcon;
export const RailwayAlertIcon = CustomRailwayAlertIcon;
export const RemoveIcon = CustomRemoveIcon;
export const RestartAltIcon = CustomRestartAltIcon;
export const SearchIcon = CustomLookingGlass;
export const StarIcon = CustomStarIcon;
export const Visibility = CustomVisibility;
export const VisibilityOff = CustomVisibilityOff;
export const WarningIcon = CustomWarnings;
