import { styled } from '@mui/system';

export const StyledHeaderContent = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
});

export const StyledTrainCard = styled('div')<{
	checked?: boolean;
}>(({ checked }) => ({
	backgroundColor: 'var(--bg-color-grey)',
	border: checked ? 'solid 1px var(--global-color-primary)' : 'none',
	borderRadius: '4px',
	display: 'flex',
	justifyContent: 'space-between',
	margin: '0.8rem auto',
	padding: '1rem',
	width: '90%',
}));

export const StyledCheckContainer = styled('div')({
	alignItems: 'center',
	display: 'flex',
});

export const StyledDeltaContainer = styled('div')({
	color: 'var(--text-color-danger)',
	marginLeft: '1rem',
});

export const StyledWarningValidation = styled('div')({
	color: 'var(--text-color-danger)',
	fontSize: '0.85rem',
	marginTop: '1rem',
	textAlign: 'center',
});
