/* eslint-disable no-mixed-spaces-and-tabs */
import {
	ExceptionDTO,
	ReportingUnitStat,
	Unit,
	UnitCount,
} from '../../../models';
import { HttpResponse } from '../apiTemplate';
import ApiService from '../apiTemplate/api';

class UnitService extends ApiService {
	constructor() {
		super();
		this.credentials = 'include';
		this.redirect = 'follow';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/json',
			},
			{
				key: 'ContentType',
				value: 'application/json',
			},
		]);
	}

	/**
	 * get unit
	 */
	public async getUnit(
		line: string,
		stationId: number,
		sequence: number
	): Promise<HttpResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/v1/unit/station/${stationId}/line/${line}/seq/${sequence}`,
				this.request()
			);
			const data = await res.json();
			const isOffline = res.status >= 500;

			return res.ok
				? { message: data, ok: true }
				: {
						isOffline,
						message: isOffline
							? 'offline'
							: ((await res.json()) as ExceptionDTO),
						ok: false,
				  };
		} catch (error) {
			console.error(`failed retrieve unit information`);
			return {
				isOffline: true,
				message: 'offline',
				ok: false,
			};
		}
	}

	/**
	 * get units from station
	 * @param stationId
	 * @param line
	 * @param stationId
	 * @returns
	 */
	public async getUnitsFromStationId(
		stationId: number,
		line: string,
		passage: number
	): Promise<Array<Unit>> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/v1/unit/station/${stationId}/line/${line}?passage=${passage}`,
				this.request()
			);
			const data = await res.json();

			return data as Unit[];
		} catch (error) {
			throw Error(`failed retrieve unit information`);
		}
	}

	/**
	 * get
	 */
	public async getCountUnits(line?: string): Promise<UnitCount> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/v1/unit/count/line/${line}`,
				this.request()
			);
			const unitCounts = await res.json();
			return {
				normalUnitCount: Number(unitCounts.normalUnitCount),
				spTerminusUnitCount: Number(unitCounts.spTerminusUnitCount),
				spUnitCount: Number(unitCounts.spUnitCount),
				terminusUnitCount: Number(unitCounts.terminusUnitCount),
			};
		} catch (error) {
			throw Error(`failed to get lines list`);
		}
	}

	/**
	 * get
	 */
	public async getUnitStats(
		beginDate: Date,
		endDate: Date,
		line?: string
	): Promise<ReportingUnitStat> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${
					this.endPoint
				}/v1/unit/stat/line/${line}?beginDate=${beginDate.toISOString()}&endDate=${endDate.toISOString()}`,
				this.request()
			);
			return (await res.json()) as ReportingUnitStat;
		} catch (error) {
			throw Error(`failed to get units list`);
		}
	}
}

export const unitService = new UnitService();
