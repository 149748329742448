import {
	Box,
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { ServiceProps } from '.';

type Order = 'asc' | 'desc';

interface HeadCell {
	id: keyof ServiceProps;
	label: string;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'lineId',
		label: 'N° de ligne',
	},
	{
		id: 'trainCode',
		label: 'N° de train',
	},
	{
		id: 'name',
		label: 'Type de prestation',
	},
	{
		id: 'date',
		label: 'Date',
	},
	{
		id: 'hour',
		label: 'Heure',
	},
];

function TableHeader({
	numSelected,
	onRequestSort,
	onSelectAllClick,
	order,
	orderBy,
	rowCount,
}: Readonly<TableHeaderProps>) {
	const createSortHandler =
		(property: keyof ServiceProps) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all services',
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

export type TableHeaderProps = {
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof ServiceProps
	) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
};

export default TableHeader;
