import { EventHandler, FC, ReactElement } from 'react';

import './CardButton.css';

type Props = {
	action?: EventHandler<any>;
	bgColor: string;
	textColor: string;
	text: string;
};

const CardButton: FC<Props> = ({
	text,
	bgColor,
	textColor,
	action,
}): ReactElement => {
	return (
		<section className="card-button-container">
			<div
				className="inclined-square"
				style={{ backgroundColor: bgColor }}
			></div>
			<button
				className="square"
				style={{ backgroundColor: bgColor, border: 'none' }}
				onClick={action}
				onKeyDown={action}
			>
				<span
					className="font-paris-bold text-card-button"
					style={{ color: textColor }}
				>
					{text}
				</span>
			</button>
		</section>
	);
};

export default CardButton;
