import { useRef } from 'react';

import { useQrScanner } from '../../packages/hooks';

import './styles.css';

const Camera = () => {
	const VIDEO_ID = 'video';
	const videoRef = useRef<HTMLVideoElement>(null);
	const toggle = useQrScanner(VIDEO_ID);

	return (
		<div className="video-container">
			<video id={VIDEO_ID} ref={videoRef}>
				<track kind="captions" label="QrCode" default />
			</video>
			<button onClick={toggle} />
		</div>
	);
};

export default Camera;
