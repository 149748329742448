import { ExceptionDTO } from '../../../models/exception.model';
import { PopupType } from '../../../models/popup.model';

import { ErrorConf, NotifyCode } from './exception.config';

function getTitle(errMessage: string | undefined) {
	if (errMessage) {
		const parsed = errMessage.split(', ');
		return parsed[0];
	}
	return '';
}

function getBody(errMessage: string): string {
	if (errMessage) {
		const parsed = errMessage.split(', ');
		parsed.shift();
		return parsed.join(', ');
	}
	return '';
}

export function exceptionDTOtoPopupState(
	err: string | ExceptionDTO,
	popupType: PopupType,
	hasBackground = true,
	isClosable = true
) {
	let message;
	let title;
	if (typeof err === 'string') {
		message = getBody(err);
		title = getTitle(err);
	} else {
		message = getBody(err.message);
		title = getTitle(err.message);
	}
	return {
		hasBackground,
		isClosable,
		message,
		popupType,
		title,
	};
}

const { SUCCESS, WARN, ERROR } = PopupType;

export const errorConf: ErrorConf = {
	error: {
		incidentReportNotDeleted: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_020,
			ERROR,
			false,
			false
		),
		incidentReportNotResolved: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_022,
			ERROR,
			false,
			false
		),
		incidentReportNotUpdated: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_021,
			ERROR,
			false,
			false
		),
		invalidQrCode: exceptionDTOtoPopupState(NotifyCode.ERR_FUNC_003, ERROR),
		serviceNotDeleted: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_018,
			ERROR,
			false,
			false
		),
		servicesNotDeleted: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_019,
			ERROR,
			false,
			false
		),
		specialOperationNotSolved: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_025,
			ERROR,
			false,
			false
		),
		specialOperationTypeNotClosed: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_023,
			ERROR,
			false,
			false
		),
		specialOperationTypeNotUpdated: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_024,
			ERROR,
			false,
			false
		),
		userNotCreated: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_006,
			ERROR,
			false,
			false
		),
		userNotDeleted: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_008,
			ERROR,
			false,
			false
		),
		userNotModified: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_007,
			ERROR,
			false,
			false
		),
	},
	none: {},
	success: {
		incidentCreated: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_006,
			SUCCESS,
			false,
			false
		),
		incidentPositionCreated: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_007,
			SUCCESS,
			false,
			false
		),
		incidentReportDeleted: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_012,
			SUCCESS,
			false,
			false
		),
		serviceDeleted: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_009,
			SUCCESS,
			false,
			false
		),
		servicesDeleted: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_010,
			SUCCESS,
			false,
			false
		),
		settingUpdated: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_004,
			SUCCESS,
			false,
			false
		),
		userCreated: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_001,
			SUCCESS,
			false,
			false
		),
		userDeleted: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_003,
			SUCCESS,
			false,
			false
		),
		userModified: exceptionDTOtoPopupState(
			NotifyCode.INF_FUNC_002,
			SUCCESS,
			false,
			false
		),
	},
	warn: {
		actionRejected: exceptionDTOtoPopupState(NotifyCode.ERR_TECH_002, WARN),
		invalidLogin: exceptionDTOtoPopupState(
			NotifyCode.ERR_FUNC_001,
			WARN,
			false,
			false
		),
		invalidTrainCode: exceptionDTOtoPopupState(NotifyCode.ERR_FUNC_002, WARN),
		offlineMode: exceptionDTOtoPopupState(NotifyCode.ERR_TECH_003, WARN),
	},
};
