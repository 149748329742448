import Header from '../../components/header';
import TableRight from '../../components/right/rightDataGrid';

const RightPage = () => {
	return (
		<main>
			<Header
				subTitle="Gérez les permissions pour chaque rôle"
				title="Droits"
				dataGridId="right"
			/>
			<div className="content">
				<TableRight />
			</div>
		</main>
	);
};

export default RightPage;
