import { combineReducers } from 'redux';

import { apiReducer } from './api.reducer';
import { confirmationPopInReducer } from './confirmationPopIn.reducer';
import { credentialsReducer } from './credentials.reducer';
import { deleteServicePopInReducer } from './deleteServicePopIn.reducer';
import { espaceReducer } from './espace.reducer';
import { historyPopInReducer } from './historyPopIn.reducer';
import incidentTypeReducer from './incidentType.reducer';
import { lineReducer } from './line.reducer';
import { notificationPopInReducer } from './notificationPopIn.reducer';
import { passageReducer } from './passage.reducer';
import { popupReducer } from './popup.reducer';
import reportingReducer from './reporting.reducer';
import { rightReducer } from './right.reducer';
import { rightByTypeReducer } from './rightByType.reducer';
import { roleReducer } from './role.reducer';
import { rollingStockReducer } from './rollingStock.reducer';
import rollingStockUsedReducer from './rollingStockUsed.reducer';
import { serviceReducer } from './service.reducer';
import serviceTypeReducer from './serviceType.reducer';
import settingReducer from './setting.reducer';
import specialOperationTypeReducer from './specialOperationType.reducer';
import stationReducer from './station.reducer';
import { stationDetailPopInReducer } from './stationDetailPopIn.reducer';
import { userReducer } from './user.reducer';

export const reducerObject = {
	apiState: apiReducer,
	confirmationPopInState: confirmationPopInReducer,
	credentialsState: credentialsReducer,
	deleteServicePopInState: deleteServicePopInReducer,
	espaceState: espaceReducer,
	historyPopInState: historyPopInReducer,
	incidentTypeState: incidentTypeReducer,
	lineState: lineReducer,
	notificationPopInState: notificationPopInReducer,
	passageState: passageReducer,
	popupState: popupReducer,
	reportingState: reportingReducer,
	rightByTypeState: rightByTypeReducer,
	rightState: rightReducer,
	roleState: roleReducer,
	rollingStockState: rollingStockReducer,
	rollingStockUsedState: rollingStockUsedReducer,
	serviceState: serviceReducer,
	serviceTypeState: serviceTypeReducer,
	settingState: settingReducer,
	specialOperationTypeState: specialOperationTypeReducer,
	stationDetailPopInState: stationDetailPopInReducer,
	stationState: stationReducer,
	userState: userReducer,
};

export const rootReducer = combineReducers(reducerObject);

export type RootState = ReturnType<typeof rootReducer>;
