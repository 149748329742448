import { UseRsReport } from '../../models/usedRs.model';

export const SET_RS_USED_COUNT = 'SET_RS_USED_COUNT';
export const UPDATE_USED_STATUS = 'UPDATE_USED_STATUS';

export interface SetRSUsedCountAction {
	type: typeof SET_RS_USED_COUNT;
	payload: UseRsReport;
}

export interface UpdateUsedStatusAction {
	type: typeof UPDATE_USED_STATUS;
	payload: {
		idCb: number;
		newIsUsed: boolean;
	};
}

export type UsedRsReportState = UseRsReport;

export type RsUseActionTypes = SetRSUsedCountAction | UpdateUsedStatusAction;
