import { EventHandler } from 'react';

import {
	StyledHeaderCard,
	StyledNumberHeaderCard,
	StyledStingHeaderCard,
} from './styles';

const CountCard = ({
	title,
	isActive,
	isNP,
	count,
	handler,
}: CountCardProps) => {
	const borderCss = isActive ? '4px solid var(--bg-color-blue)' : '';
	return (
		<StyledHeaderCard border={borderCss} onClick={handler}>
			<StyledNumberHeaderCard
				data-testid="numberCardHeader"
				color={isNP ? '#C7406E' : '#4A4A4F'}
			>
				{count}
			</StyledNumberHeaderCard>

			<StyledStingHeaderCard>{title}</StyledStingHeaderCard>
		</StyledHeaderCard>
	);
};

export default CountCard;

export type CountCardProps = {
	isNP?: boolean;
	count: number;
	handler: EventHandler<any>;
	isActive: boolean;
	title: string;
};
