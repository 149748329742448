import { Station } from '../../models/station.model';
import { StationUpdateData } from '../../packages/events/types';
import {
	SET_STATIONS,
	StationActionTypes,
	UPDATE_STATION,
} from '../types/station.types';

export const setStations = (stations: Station[]): StationActionTypes => ({
	payload: { state: stations },
	type: SET_STATIONS,
});

export const updateStation = (
	service: StationUpdateData
): StationActionTypes => ({
	payload: { service: service },
	type: UPDATE_STATION,
});

export const stationActions = {
	setStations,
	updateStation,
};

export default stationActions;
