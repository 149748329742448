export enum MultipleAvailabilityTypeEnum {
	NP = 2,
	DF = 0,
}

export interface MultipleAvailability {
	availabilityRollingStocks: RSWithPosition[];
	incidentTypeId?: MultipleAvailabilityTypeEnum;
	serviceTypeId?: MultipleAvailabilityTypeEnum;
	site: IdNameObject;
	lineId: string;
}

interface IdNameObject {
	id: number;
	name: string;
}

export interface RSWithPosition {
	id: number;
	name: string;
	checked: boolean;
	isUsedOrder: number;
	position?: IdNameObject;
	NpDeltaTime?: string;
}
