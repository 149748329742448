import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import { RollingStockFilter } from '../../../models';
import { MenuItem } from '../../../models/ui';
import { pluralize } from '../../../packages/helpers';
import {
	useDeleteServicePopIn,
	useRight,
	useTrackingBoardHeader,
} from '../../../packages/hooks';
import { Button, Dropdown, SearchBar } from '../../../packages/ui';
import Chip from '../../../packages/ui/atoms/Chip';
import {
	AddIcon,
	DownloadIcon,
	ExportIcon,
	MoreVertIcon,
	RemoveIcon,
} from '../../../packages/ui/icons/index';
import { setFilterCode, setFilterStatus } from '../../../redux/actions';
import { getServiceTypes } from '../../../redux/thunks/serviceType';

import CountCard from './countCard';

import './styles.css';

const { TO_CLEAN, TO_DEGRAF } = RollingStockFilter;

const Header = ({ onExportClick, handleOpenServicePopin }: HeaderProps) => {
	const dispatch = useDispatch();
	const { openDeleteServicePopIn } = useDeleteServicePopIn();
	const { stockcounts, status, stockCountText } = useTrackingBoardHeader();

	const {
		canDisplayExportButton,
		canDisplayServiceCreateButon,
		canDisplayServiceDeleteButon,
	} = useRight();

	const handleFilterClick = (statusClicked: RollingStockFilter) => {
		statusClicked === status
			? dispatch(setFilterStatus(undefined))
			: dispatch(setFilterStatus(statusClicked));
	};

	const handleFilterChange = (value: string) => {
		dispatch(setFilterCode(value));
		dispatch(setFilterStatus(undefined));
	};

	const isPad = useMediaQuery('(max-width:900px)');
	const isDesktop = !isPad;

	const menuItems: MenuItem[] = [
		{
			content: (
				<>
					<AddIcon htmlColor="#5D5C6D" style={{ paddingRight: '10px' }} />
					<span>Renseigner prestation</span>
				</>
			),
			isValid: isPad,
			key: 'create-service',
			onClick: handleOpenServicePopin,
		},
		{
			content: (
				<>
					<RemoveIcon htmlColor="#5D5C6D" style={{ paddingRight: '10px' }} />
					<span>Supprimer prestation</span>
				</>
			),
			isValid: isPad,
			key: 'delete-service',
			onClick: openDeleteServicePopIn,
		},
		{
			content: (
				<>
					<ExportIcon htmlColor="#5D5C6D" style={{ paddingRight: '10px' }} />
					<span>Exporter l'historique</span>
				</>
			),
			key: 'export',
			onClick: onExportClick,
		},
	];

	useEffect(() => {
		dispatch(getServiceTypes());
	}, []);

	return (
		<Grid container className={'traking-header-container'} columnGap={3}>
			{isDesktop && (
				<Grid
					item
					className="traking-actions"
					xs={2}
					container
					flexDirection="column"
					gap={2}
				>
					{canDisplayServiceCreateButon() && (
						<Button
							className="h5 service-button-relative"
							id="history-btn"
							label="Renseigner prestation"
							onClick={handleOpenServicePopin}
							startIcon={<AddIcon />}
						/>
					)}
					{canDisplayServiceDeleteButon() && (
						<Button
							className="h5 service-button-relative"
							id="history-btn"
							label="Supprimer prestation"
							onClick={openDeleteServicePopIn}
							startIcon={<RemoveIcon />}
						/>
					)}
				</Grid>
			)}
			<Grid
				item
				className="tracking-search"
				xs={5}
				style={{ paddingLeft: isDesktop ? '12rem' : '0' }}
				textAlign="center"
			>
				{isDesktop ? (
					<Typography variant="h4">{stockCountText}</Typography>
				) : (
					<></>
				)}
				<SearchBar
					onSubmit={(value) => handleFilterChange(value as string)}
					name="tracking-board-searchBar"
					isPad={isPad}
					stockCountText={stockCountText}
				/>
			</Grid>

			{isDesktop ? (
				<>
					<Grid item xs={1}>
						<CountCard
							count={stockcounts.toClean}
							handler={() => handleFilterClick(TO_CLEAN)}
							isActive={status === TO_CLEAN}
							isNP
							title={pluralize(stockcounts.toClean, 'NP inacceptable')}
						/>
					</Grid>
					<Grid item xs={1}>
						<CountCard
							count={stockcounts.degrafed}
							handler={() => handleFilterClick(TO_DEGRAF)}
							isActive={status === TO_DEGRAF}
							title={pluralize(stockcounts.degrafed, 'Graffiti') + ' à traiter'}
						/>
					</Grid>
				</>
			) : (
				<Grid item xs={4}>
					<Stack direction="row" spacing={3}>
						<Chip
							label={`${stockcounts.toClean} ${pluralize(
								stockcounts.toClean,
								'NP inacceptable'
							)}`}
							onClick={() => {
								handleFilterClick(TO_CLEAN);
							}}
							color={status === TO_CLEAN ? 'secondary' : 'default'}
						/>
						<Chip
							label={`${pluralize(stockcounts.degrafed, 'graffiti')} à traiter`}
							onClick={() => handleFilterClick(TO_DEGRAF)}
							color={status === TO_DEGRAF ? 'secondary' : 'default'}
						/>
					</Stack>
				</Grid>
			)}

			{canDisplayExportButton() && isDesktop && (
				<Grid
					item
					className="traking-header-export-button"
					xs={1}
					container
					alignItems="flex-end"
					justifyContent="flex-end"
				>
					<Button
						endIcon={<DownloadIcon />}
						label="Exporter"
						onClick={() => onExportClick()}
						variant="text"
						style={{
							fontSize: '1.1rem',
						}}
					/>
				</Grid>
			)}
			{isPad && (
				<Grid item xs={1}>
					<Dropdown
						buttonId="nav-dropdown"
						buttonStyle={{
							border: 'none',
							color: 'black',
							width: '20px',
						}}
						buttonTitle={<MoreVertIcon fontSize="large" />}
						items={menuItems}
						variant="outlined"
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default Header;

type HeaderProps = {
	onExportClick: () => void;
	handleOpenServicePopin: () => void;
};
