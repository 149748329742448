import {
	Dialog as MuiDialog,
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
} from '@mui/material';

import { DialogProps } from '../../../models/ui';
import DialogTitle from '../atoms/DialogTitle';

const Dialog = ({
	open,
	className = '',
	content,
	onClose,
	titleContent,
	actionsContent,
	...props
}: DialogProps) => {
	return (
		<MuiDialog
			fullWidth
			maxWidth="md"
			open={open}
			className={className}
			{...props}
		>
			<DialogTitle onClose={onClose}>{titleContent}</DialogTitle>
			<MuiDialogContent dividers style={{ padding: '0' }}>
				{content}
			</MuiDialogContent>
			<MuiDialogActions>{actionsContent}</MuiDialogActions>
		</MuiDialog>
	);
};

export default Dialog;
