import { useDispatch } from 'react-redux';

import * as deleteServiceActions from '../../redux/actions/deleteServicePopIn.actions';
import { ServiceDTO, ServiceQuery, serviceService } from '../dataProviders';

export const useDeleteServicePopIn = () => {
	const dispatch = useDispatch();

	function closeDeleteServicePopIn() {
		dispatch(deleteServiceActions.closeDeleteServicePopIn());
	}

	function openDeleteServicePopIn() {
		dispatch(deleteServiceActions.openDeleteServicePopIn());
	}

	async function getServicesByQuery(
		query: ServiceQuery
	): Promise<ServiceDTO[] | undefined> {
		const resPromise = serviceService.getServicesByQuery(query);
		const res = await resPromise;
		if (res.ok && res.services) {
			return res.services;
		} else {
			return undefined;
		}
	}

	return {
		closeDeleteServicePopIn,
		getServicesByQuery,
		openDeleteServicePopIn,
	};
};
