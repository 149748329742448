import { store } from '../../../redux';
import { openNotificationPopIn } from '../../../redux/actions';
import { localStorageFuncs } from '../../helpers/';
import { AlertTypeEnum, NotificationData } from '../types';

export const tagReminderHandler = (e: MessageEvent) => {
  const notification = JSON.parse(e.data) as NotificationData;
  localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
  const title = 'Alerte Tag intérieur';
  const subTitle = `Les trains suivants ont été vandalisés avec un tag intérieur:`;
  const confirmTxt = 'OK';
  store.dispatch(
    openNotificationPopIn(
      title,
      subTitle,
      notification.data as string[],
      confirmTxt,
      AlertTypeEnum.TagReminder,
    ),
  );
};

export default tagReminderHandler;
