import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useBlockNavigation from './useBlockNavigation';

const useCallbackPrompt = (when: boolean, handleSave: () => void) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<any>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const handleBlockedNavigation = useCallback(
		(nextLocation: any) => {
			if (
				!confirmedNavigation &&
				nextLocation.location.pathname !== location.pathname
			) {
				setShowPrompt(true);
				setLastLocation(nextLocation);
				return false;
			}
			return true;
		},
		[confirmedNavigation, location]
	);

	const navigateOut = () => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	};

	const saveAndQuit = useCallback(() => {
		if (handleSave) {
			handleSave();
		}
		navigateOut();
	}, [handleSave]);

	const quitWithoutSaving = useCallback(() => {
		navigateOut();
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location?.pathname);
			setConfirmedNavigation(false);
		}
	}, [confirmedNavigation, lastLocation]);

	useBlockNavigation(handleBlockedNavigation, when);

	return { quitWithoutSaving, saveAndQuit, showPrompt };
};
export default useCallbackPrompt;
