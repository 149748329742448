import { Grid, Typography } from '@mui/material';

import { SPECIAL_OPERATION_TITLE } from '../../../packages/helpers/explanations/specialOperation';

const SpecialOperationHeader = () => {
	return (
		<Grid padding="24px">
			<Typography width="100%" variant="h2">
				{SPECIAL_OPERATION_TITLE}
			</Typography>
		</Grid>
	);
};

export default SpecialOperationHeader;
