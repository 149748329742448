/* eslint-disable no-mixed-spaces-and-tabs */
import appEnv from '../../configuration/appEnv';
import { ServiceQuery, TotalServiceQuery } from '../servicesService';
import { RollingstockQuery } from '..';

import { ApiMethod, KeyValue } from './api.types';

/**
 * Class to configure api requests using fetch
 * @param {sting} _accessToken - example string as means of authenticating
 */
export class ApiService {
	private _method = 'POST';
	private _headers: string[][] = [];
	private _endPoint = appEnv.backendUrl; // comment out react app api url if testing serviceworker
	private _redirect: RequestRedirect = 'error';
	private _credentials: RequestCredentials = 'include';

	get headers(): string[][] {
		return this._headers;
	}

	get endPoint(): string {
		return this._endPoint;
	}

	set endPoint(newEndPoint: string) {
		this._endPoint = newEndPoint;
	}

	set method(newMethod: ApiMethod) {
		this._method = newMethod;
	}

	set redirect(newRedirect: RequestRedirect) {
		this._redirect = newRedirect;
	}

	set credentials(newCredentials: RequestCredentials) {
		this._credentials = newCredentials;
	}
	/**
	 * function to determine headers a fetch request
	 * @param {KeyValue} headers - key-value pairs specifying headers to use
	 */
	public setHeaders(headers: KeyValue<string, string>[]): this {
		headers.forEach((header) => {
			if (
				Object.prototype.hasOwnProperty.call(header, 'key') &&
				Object.prototype.hasOwnProperty.call(header, 'value')
			) {
				this._headers.push([header.key, header.value]); // fetch api requires a nested array of string
			}
		});
		return this;
	}

	public resetHeaders(): this {
		this._headers = [];
		return this;
	}

	public setMethod(newMethod: ApiMethod): this {
		this._method = newMethod;
		return this;
	}

	/**
	 * @param queryOptions - any type of api query (key-val) pairs
	 */
	public getSearchParams(
		queryOptions?: ServiceQuery | RollingstockQuery | TotalServiceQuery
	): string {
		if (!queryOptions) return '';
		const urlSearchParams = new URLSearchParams();
		Object.entries(queryOptions).forEach(([key, val]) =>
			urlSearchParams.append(key, val as string)
		);
		return `/?${urlSearchParams.toString()}`;
	}

	/**
	 * function to package up valid request data
	 * @param {RequestInit} body
	 * @returns {RequestInit} - valid fetch request initializer
	 */
	public request<T>(body?: T): RequestInit {
		return body
			? ({
					body: JSON.stringify(body),
					credentials: this._credentials,
					headers: this._headers,
					method: this._method,
					redirect: this._redirect,
			  } as RequestInit)
			: ({
					credentials: this._credentials,
					headers: this._headers,
					method: this._method,
					redirect: this._redirect,
			  } as RequestInit);
	}
}

/**
 * class to make valid request data body
 * send along with the fetch request
 * helps in tailloring requestbodies for each request
 */
export class RequestBody<T> {
	constructor(private _requestBody: T) {}

	get requestBody(): T {
		return this._requestBody;
	}

	set requestBody(newRequestBody: T) {
		this._requestBody = newRequestBody;
	}
}

export default ApiService;
