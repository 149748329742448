import { ThunkAction } from 'redux-thunk';

import ServiceTypeProvider from '../../packages/dataProviders/resources/serviceType';
import { errorConf } from '../../packages/helpers/popup/exception.utils';
import { setServiceTypes } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { SERVICETYPE } = components;

type ServiceTypeThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const getServiceTypes =
	(): ServiceTypeThunk => async (dispatch, _, api) => {
		try {
			const { data: serviceTypes } =
				await ServiceTypeProvider.loadServiceTypes();
			dispatch(setServiceTypes(serviceTypes));
		} catch (e) {
			dispatch(api._error(SERVICETYPE, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};
