import { Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@mui/material';

import { dateTimeService } from '../../../../packages/helpers';
import Button from '../../../../packages/ui/atoms/Button';
import DatePicker from '../../../../packages/ui/atoms/DatePicker';

const SelectPeriod = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	setClickTrigger,
}: SelectPeriodProps) => {
	const todayMax = true;
	const MIN_DATE = dateTimeService.getRelativeDate(endDate, { year: -1 });
	const MAX_DATE = dateTimeService.getRelativeDate(
		startDate,
		{ year: +1 },
		todayMax
	);
	const MAX_START_DATE = new Date();

	const handleStartDateChange = (date: Date) => {
		let newEndDate;
		if (date > endDate) {
			newEndDate = dateTimeService.getRelativeDate(
				date,
				{ month: +1 },
				todayMax
			);
		} else newEndDate = endDate;
		setStartDate(date);
		setEndDate(newEndDate);
	};

	const handleEndDateChange = (date: Date) => {
		let newStartDate;
		if (date < startDate) {
			newStartDate = dateTimeService.getRelativeDate(date, { month: -1 });
		} else {
			newStartDate = startDate;
		}

		setEndDate(new Date(date.setHours(23, 59, 0)));
		setStartDate(newStartDate);
	};

	const handleSearchClick = () => {
		setClickTrigger((value: boolean) => !value);
	};

	return (
		<Grid
			container
			margin={2}
			style={{
				borderRadius: '16px',
				boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.8)',
			}}
		>
			<Grid
				item
				xs={4}
				display="flex"
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Typography variant="h5">{'Sélectionner une période :'}</Typography>
			</Grid>
			<Grid item xs={2} margin={2}>
				<DatePicker
					label="Période du"
					minDate={MIN_DATE}
					maxDate={MAX_START_DATE}
					onChange={(newValue) =>
						newValue ? handleStartDateChange(newValue) : startDate
					}
					value={startDate}
				/>
			</Grid>

			<Grid item xs={3} margin={2}>
				<DatePicker
					label="Au"
					maxDate={MAX_DATE}
					onChange={(newValue) =>
						newValue ? handleEndDateChange(newValue) : startDate
					}
					value={endDate}
				/>
			</Grid>
			<Grid item xs={1} margin={2}>
				<Button style={{ width: '100%' }} onClick={handleSearchClick}>
					Charger
				</Button>
			</Grid>
		</Grid>
	);
};

export default SelectPeriod;

export type SelectPeriodProps = {
	startDate: Date;
	endDate: Date;
	setStartDate: Dispatch<SetStateAction<Date>>;
	setEndDate: Dispatch<SetStateAction<Date>>;
	setClickTrigger: Dispatch<SetStateAction<boolean>>;
};
