import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { PeriodDashboard } from '../../../../models/dashboard.model';
import { ServiceTypeMap } from '../../../../models/serviceType.model';
import dashboardProvider from '../../../../packages/dataProviders/resources/dashboard';
import { getUniqueKey } from '../../../../packages/helpers';
import DashboardCard from '../../card';

import GraffitiReporting from './graffitiReporting';
import IncidentsReporting from './incidentsReporting';
import ServiceReporting from './serviceReporting';

const PeriodDashboardReporting = ({
	startDate,
	endDate,
	selectedLine,
	clickTrigger,
}: PeriodReportingProps) => {
	const [periodDashboardData, setPeriodDashboardData] =
		useState<PeriodDashboard>();

	useEffect(() => {
		if (selectedLine) {
			dashboardProvider
				.loadPeriodDashboard(selectedLine, startDate, endDate)
				.then((item) => {
					return setPeriodDashboardData(item.data);
				});
		}
	}, [selectedLine, clickTrigger]);

	return periodDashboardData ? (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			spacing={3}
		>
			{periodDashboardData.serviceReport.map((data) => (
				<Grid item xs={2} key={getUniqueKey(data.serviceType.toString())}>
					<DashboardCard
						title={ServiceTypeMap.get(data.serviceType)}
						isToday={false}
					>
						<ServiceReporting
							count={data.count}
							serviceType={data.serviceType}
							lowLimitCount={data.lowLimitCount}
							hightLimitCount={data.hightLimitCount}
							lowLimit={data.lowLimit}
							hightLimit={data.hightLimit}
						/>
					</DashboardCard>
				</Grid>
			))}

			<Grid item xs={5}>
				<DashboardCard title="Graffitage" isToday={false}>
					<GraffitiReporting
						detection={periodDashboardData.graffitiReport.detection}
						resolution={periodDashboardData.graffitiReport.resolution}
						resolutionAverage={
							periodDashboardData.graffitiReport.resolutionAverage
						}
						usedGrafedTrainAverage={
							periodDashboardData.graffitiReport.usedGrafedTrainAverage
						}
					/>
				</DashboardCard>
			</Grid>
			<Grid item xs={3}>
				<DashboardCard title="Nombre d'anomalies signalées" isToday={false}>
					{periodDashboardData.incidentsReport.map((data) => (
						<IncidentsReporting
							key={getUniqueKey(data.incidentType.toString())}
							count={data.count}
							incidentType={data.incidentType}
							incidentTypeCode={data.incidentTypeCode}
						/>
					))}
				</DashboardCard>
			</Grid>
		</Grid>
	) : (
		<></>
	);
};

export default PeriodDashboardReporting;

export type PeriodReportingProps = {
	startDate: Date;
	endDate: Date;
	selectedLine: string;
	clickTrigger: boolean;
};
