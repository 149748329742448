import { initSettingState } from '../config/initialState';
import {
	SET_SETTING,
	SET_SETTING_DAY_NIGHT_INCIDENT,
	SET_SETTING_DAY_NIGHT_SERVICE,
	SET_SETTING_MAL_H_LIMIT,
	SET_SETTING_MAL_L_LIMIT,
	SET_SETTING_NC_TARGET,
	SET_SETTING_NCR_TARGET,
	SET_SETTING_NP_H_LIMIT,
	SET_SETTING_NP_L_LIMIT,
	SET_SETTING_NP_TARGET,
	SET_SETTING_RS_DISPLAY_TYPE,
	SET_SETTING_STATUS,
	SettingActionTypes as ActionTypes,
	SettingState,
} from '../types/setting.types';

export const settingReducer = (
	state = initSettingState,
	action: ActionTypes
) => {
	switch (action.type) {
		case SET_SETTING:
			return action.payload.state as SettingState;
		case SET_SETTING_DAY_NIGHT_INCIDENT:
			return {
				...state,
				dayNightIncidents: action.payload.dayNightIncidents,
			};
		case SET_SETTING_DAY_NIGHT_SERVICE:
			return {
				...state,
				dayNightServices: action.payload.dayNightServices,
			};
		case SET_SETTING_NC_TARGET:
			return {
				...state,
				ncTarget: action.payload.ncTarget,
			};
		case SET_SETTING_NCR_TARGET:
			return {
				...state,
				ncrTarget: action.payload.ncrTarget,
			};
		case SET_SETTING_NP_TARGET:
			return {
				...state,
				npTarget: action.payload.npTarget,
			};
		case SET_SETTING_NP_H_LIMIT:
			return {
				...state,
				npHighLimit: action.payload.npHighLimit,
			};
		case SET_SETTING_NP_L_LIMIT:
			return {
				...state,
				npLowLimit: action.payload.npLowLimit,
			};
		case SET_SETTING_MAL_H_LIMIT:
			return {
				...state,
				malHighLimit: action.payload.malHighLimit,
			};
		case SET_SETTING_MAL_L_LIMIT:
			return {
				...state,
				malLowLimit: action.payload.malLowLimit,
			};
		case SET_SETTING_RS_DISPLAY_TYPE:
			return {
				...state,
				rollingStockDisplayType: action.payload.rollingStockDisplayType,
			};
		case SET_SETTING_STATUS:
			return {
				...state,
				status: action.payload.status,
			};
		default:
			return state;
	}
};

export default settingReducer;
