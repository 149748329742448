import { useCallback, useEffect, useState } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';

import {
	defaultSelect,
	ParamsExportHistory,
	PERIOD_STRINGS,
	PeriodOptions,
} from '../../../../../models';
import { dateTimeService } from '../../../../../packages/helpers';
import { DatePicker, Select } from '../../../../../packages/ui';

const { LAST_MONTH_START_DAY, LAST_MONTH_END_DAY } = PeriodOptions;
const { TODAY, WEEK, LAST_MONTH, OTHER } = PeriodOptions;
const { START_DATE, END_DATE } = ParamsExportHistory;

const HistoryPeriod = ({
	onEndDateChange,
	onStartDateChange,
}: HistoryPeriodProps) => {
	const [startDate, setStartDate] = useState(new Date(new Date()));
	const [endDate, setEndDate] = useState(dateTimeService.getHistoryDate(TODAY));
	const [selectedPeriod, setSelectedPeriod] = useState({
		...defaultSelect,
		[TODAY]: true,
	});

	useEffect(() => {
		onStartDateChange(startDate);
		// eslint-disable-next-line
	}, [startDate]);

	useEffect(() => {
		onEndDateChange(endDate);
		// eslint-disable-next-line
	}, [endDate]);

	const handlePeriodChange = useCallback((event: SelectChangeEvent) => {
		switch (PERIOD_STRINGS.get(event.target.value)) {
			case TODAY:
				setSelectedPeriod({ ...defaultSelect, [TODAY]: true });
				setStartDate(new Date(new Date().setHours(0, 0, 0)));
				setEndDate(dateTimeService.getHistoryDate(TODAY));
				break;
			case WEEK:
				setSelectedPeriod({ ...defaultSelect, [WEEK]: true });
				setStartDate(dateTimeService.getHistoryDate(WEEK));
				setEndDate(dateTimeService.getHistoryDate(TODAY));
				break;
			case LAST_MONTH:
				setSelectedPeriod({ ...defaultSelect, [LAST_MONTH]: true });
				setStartDate(dateTimeService.getHistoryDate(LAST_MONTH_START_DAY));
				setEndDate(dateTimeService.getHistoryDate(LAST_MONTH_END_DAY));
				break;
			case OTHER:
				setSelectedPeriod({ ...defaultSelect, [OTHER]: true });
				break;
		}
	}, []);

	const handleChangeDate = (date: Date, name: string) => {
		name === START_DATE ? setStartDate(date) : setEndDate(date);
	};

	const formatPeriodValue = (selectedPeriod: {
		[key in string]: boolean;
	}): string => {
		const values = Array.from(PERIOD_STRINGS).find(
			([, key]) =>
				key ===
				Object.keys(selectedPeriod).find(
					(value) => selectedPeriod[value] === true
				)
		);
		return values ? values[0] : '';
	};

	return (
		<Grid container spacing={2}>
			<Grid item md={4} xs={8}>
				<Select
					label="Période"
					onChange={handlePeriodChange}
					options={Array.from(PERIOD_STRINGS).map(([key]) => key)}
					width={120}
					value={formatPeriodValue(selectedPeriod)}
					isStringValues
				/>
			</Grid>
			<Grid item md={4} xs={6}>
				<DatePicker
					disabled={!selectedPeriod.other}
					label="Du"
					value={startDate}
					onChange={(newValue) =>
						newValue ? handleChangeDate(newValue, START_DATE) : startDate
					}
				/>
			</Grid>
			<Grid item md={4} xs={6}>
				<DatePicker
					disabled={!selectedPeriod.other}
					label="Au"
					value={endDate}
					onChange={(newValue) =>
						newValue ? handleChangeDate(newValue, END_DATE) : startDate
					}
				/>
			</Grid>
		</Grid>
	);
};

export default HistoryPeriod;

type HistoryPeriodProps = {
	onStartDateChange: (date: Date) => void;
	onEndDateChange: (date: Date) => void;
};
