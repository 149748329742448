import { IncidentReport, Position } from './';

export interface RollingStock {
	id: number;
	train_id: string;
	equipment: string;
	train_code: string;
	line_id: string;
	line_code: string;
	creation_date: Date;
	affectation_date: Date;
	modification_date: Date;
	position: Position;
	incidents: IncidentReport[];
	specialOperations: [];
}

export enum RollingStockFilter {
	TO_CLEAN = 'toBeCleaned',
	TO_DEGRAF = 'toBeDegrafed',
}

export interface PrefixInfo {
	prefixTxt: string;
	startPtrn: RegExp;
	fullPtrn: RegExp;
	defaultFilter: string;
	maxLength: number;
}

export type RollingStockPositionDTO = {
	positionId: number;
	rollingStockId: number;
};
