import { ThunkAction } from 'redux-thunk';

import { ExceptionDTO, PopupType } from '../../models';
import { SpecialOperationType } from '../../models/specialOperationType.model';
import SpecialOperationTypeProvider from '../../packages/dataProviders/resources/specialOperationType';
import { NotifyCode } from '../../packages/helpers/popup/exception.config';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import {
	closeSpecialOperationType,
	createNewSpecialOperationType,
	setSpecialOperationTypeInProgress,
	setSpecialOperationTypeReporting,
	updateSpecialOperationType,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { SPECIAL_OPERATION_TYPE } = components;

type SpecialOperationTypeThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const getSpecialOperationType =
	(): SpecialOperationTypeThunk => async (dispatch, _, api) => {
		try {
			const { data: specialOperationType } =
				await SpecialOperationTypeProvider.loadSpecialOperationType();
			dispatch(
				setSpecialOperationTypeInProgress(
					specialOperationType.opInProgress as unknown as SpecialOperationType[]
				)
			);
			dispatch(
				setSpecialOperationTypeReporting(
					specialOperationType.opReporting as unknown as SpecialOperationType[]
				)
			);
		} catch (e) {
			dispatch(api._error(SPECIAL_OPERATION_TYPE, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const createAndSetSpecialOperationType =
	(specialOperationType: SpecialOperationType): SpecialOperationTypeThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(SPECIAL_OPERATION_TYPE));
			res = await SpecialOperationTypeProvider.createSpecialOperationType(
				specialOperationType
			);

			if (res.ok) {
				const { data: newSpecialOperation } = res;
				dispatch(api._response(SPECIAL_OPERATION_TYPE));
				dispatch(createNewSpecialOperationType(newSpecialOperation));
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							NotifyCode.INF_FUNC_015 as string,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(SPECIAL_OPERATION_TYPE, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const updateAndSetSpecialOperation =
	(specialOperationType: SpecialOperationType): SpecialOperationTypeThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(SPECIAL_OPERATION_TYPE));
			res = await SpecialOperationTypeProvider.updateSpecialOperationType(
				specialOperationType
			);
			if (res.ok) {
				dispatch(api._response(SPECIAL_OPERATION_TYPE));
				const { data: updatedSpecialOperation } = res;
				dispatch(
					updateSpecialOperationType({
						...updatedSpecialOperation,
						startDate: res.data.start_date as Date,
					})
				);
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							NotifyCode.INF_FUNC_017 as string,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(SPECIAL_OPERATION_TYPE, e));
			dispatch(
				api.showFeedBack(errorConf.error['specialOperationTypeNotUpdated'])
			);
		}
	};

export const closeAndSetSpecialOperationType =
	(specialOperationType: SpecialOperationType): SpecialOperationTypeThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(SPECIAL_OPERATION_TYPE));
			res = await SpecialOperationTypeProvider.closeOperationSpecialType(
				specialOperationType.id as number
			);
			if (res.ok) {
				dispatch(api._response(SPECIAL_OPERATION_TYPE));
				dispatch(closeSpecialOperationType(specialOperationType));
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							NotifyCode.INF_FUNC_016 as string,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(SPECIAL_OPERATION_TYPE, e));
			dispatch(
				api.showFeedBack(errorConf.error['specialOperationTypeNotClosed'])
			);
		}
	};
