import {
	RSDisplayTypeEnum,
	Setting,
	SettingDayNightIncident,
	SettingDayNightService,
} from '../../models/setting.model';
import {
	SET_SETTING,
	SET_SETTING_DAY_NIGHT_INCIDENT,
	SET_SETTING_DAY_NIGHT_SERVICE,
	SET_SETTING_MAL_H_LIMIT,
	SET_SETTING_MAL_L_LIMIT,
	SET_SETTING_NC_TARGET,
	SET_SETTING_NCR_TARGET,
	SET_SETTING_NP_H_LIMIT,
	SET_SETTING_NP_L_LIMIT,
	SET_SETTING_NP_TARGET,
	SET_SETTING_RS_DISPLAY_TYPE,
	SET_SETTING_STATUS,
	SettingActionTypes,
} from '../types/setting.types';

export const setSetting = (setting: Setting): SettingActionTypes => ({
	payload: { state: setting },
	type: SET_SETTING,
});

export const setSettingNCTarget = (ncTarget: number): SettingActionTypes => ({
	payload: { ncTarget: ncTarget },
	type: SET_SETTING_NC_TARGET,
});

export const setSettingNCRTarget = (ncrTarget: number): SettingActionTypes => ({
	payload: { ncrTarget: ncrTarget },
	type: SET_SETTING_NCR_TARGET,
});

export const setSettingNPTarget = (npTarget: number): SettingActionTypes => ({
	payload: { npTarget: npTarget },
	type: SET_SETTING_NP_TARGET,
});

export const setSettingNPLowLimit = (
	npLowLimit: number
): SettingActionTypes => ({
	payload: { npLowLimit: npLowLimit },
	type: SET_SETTING_NP_L_LIMIT,
});

export const setSettingNPHighLimit = (
	npHighLimit: number
): SettingActionTypes => ({
	payload: { npHighLimit: npHighLimit },
	type: SET_SETTING_NP_H_LIMIT,
});

export const setSettingMALLowLimit = (
	malLowLimit: number
): SettingActionTypes => ({
	payload: { malLowLimit: malLowLimit },
	type: SET_SETTING_MAL_L_LIMIT,
});

export const setSettingMALHighLimit = (
	malHighLimit: number
): SettingActionTypes => ({
	payload: { malHighLimit: malHighLimit },
	type: SET_SETTING_MAL_H_LIMIT,
});

export const setSettingStatus = (status: boolean): SettingActionTypes => ({
	payload: { status: status },
	type: SET_SETTING_STATUS,
});

export const setSettingRSDispalyType = (
	rollingStockDisplayType: RSDisplayTypeEnum
): SettingActionTypes => ({
	payload: { rollingStockDisplayType: rollingStockDisplayType },
	type: SET_SETTING_RS_DISPLAY_TYPE,
});

export const setSettingDayNightService = (
	dayNightServices: SettingDayNightService[]
): SettingActionTypes => ({
	payload: { dayNightServices: dayNightServices },
	type: SET_SETTING_DAY_NIGHT_SERVICE,
});

export const setSettingDayNightIncident = (
	dayNightIncidents: SettingDayNightIncident[]
): SettingActionTypes => ({
	payload: { dayNightIncidents: dayNightIncidents },
	type: SET_SETTING_DAY_NIGHT_INCIDENT,
});

export const settingActions = {
	setSetting,
	setSettingDayNightIncident,
	setSettingDayNightService,
	setSettingMALHighLimit,
	setSettingMALLowLimit,
	setSettingNCRTarget,
	setSettingNCTarget,
	setSettingNPHighLimit,
	setSettingNPLowLimit,
	setSettingNPTarget,
	setSettingRSDispalyType,
};

export default settingActions;
