import {
	Checkbox as MuiCheckbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
} from '@mui/material';

import { CheckboxProps } from '../../../models/ui';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from '../icons';

const Checkbox = ({
	checked,
	color = 'primary',
	defaultChecked,
	disabled,
	helperText,
	icon = <CheckBoxOutlineBlankIcon />,
	label,
	labelPlacement = 'end',
	name,
	onChange,
	required,
	sx,
	size = 'medium',
	checkedIcon = <CheckBoxIcon />,
}: CheckboxProps) => (
	<FormControl
		component="fieldset"
		defaultChecked={defaultChecked}
		required={required}
		name={name}
		id={name}
	>
		<FormControlLabel
			color={color}
			control={
				<MuiCheckbox
					checked={checked}
					checkedIcon={checkedIcon}
					icon={icon}
					name={name}
					onChange={onChange}
					size={size}
				/>
			}
			sx={sx}
			disabled={disabled}
			label={label}
			labelPlacement={labelPlacement}
		/>

		<FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>
	</FormControl>
);

export default Checkbox;
