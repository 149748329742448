import { Typography } from '@mui/material';
import Slider from 'rc-slider';

import './styles.css';

const SettingLastMaL = ({
	handleMalRangeChange,
	malRangeValues,
}: SettingLastMalProps) => {
	const createSliderWithTooltip = Slider.createSliderWithTooltip;
	const Range = createSliderWithTooltip(Slider.Range);

	function handleChangeMal(values: number[]): void {
		handleMalRangeChange(values[0], values[1]);
	}

	return (
		<>
			<Typography variant="h5">Seuil d'alerte du dernier MAL</Typography>
			<Typography variant="subtitle1">
				Ajustez les jauges des seuils d’alerte à votre convenance
			</Typography>
			<div className="setting-page__mal-alert-slider mt-5">
				<Range
					count={3}
					defaultValue={malRangeValues}
					handleStyle={[
						{ backgroundColor: 'orange', borderColor: 'orange' },
						{ backgroundColor: 'red', borderColor: 'red' },
						{ display: 'none' },
					]}
					marks={{
						0: { label: '0', style: { bottom: '20px' } },
						50: { label: '50', style: { bottom: '20px' } },
					}}
					max={50}
					min={0}
					onAfterChange={handleChangeMal}
					pushable={true}
					railStyle={{ backgroundColor: 'black' }}
					tipFormatter={(value) => `${value}j`}
					tipProps={{ placement: 'bottom', visible: true }}
					trackStyle={[
						{ backgroundColor: 'orange' },
						{ backgroundColor: 'red' },
					]}
				/>
			</div>
		</>
	);
};

export default SettingLastMaL;

type SettingLastMalProps = {
	handleMalRangeChange: (lowMalLimit: number, highMalLimit: number) => void;
	malRangeValues: number[];
};
