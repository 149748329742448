import { User } from '../../models';
import {
	CredentialsActionTypes,
	DELETE_USER,
	SET_CREDENTIALS,
	SET_USER,
} from '../types/credentials.types';

export const setCredentials = (users: User[]): CredentialsActionTypes => {
	return {
		payload: users,
		type: SET_CREDENTIALS,
	};
};

export const setUser = (user: User): CredentialsActionTypes => {
	return {
		payload: user,
		type: SET_USER,
	};
};

export const deleteUser = (id: number): CredentialsActionTypes => {
	return {
		payload: { id },
		type: DELETE_USER,
	};
};

export const credentialsActions = {
	deleteUser,
	setCredentials,
	setUser,
};

export default credentialsActions;
