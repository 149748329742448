import { isEmpty } from 'lodash';
import { ThunkAction } from 'redux-thunk';

import {
	ServiceStatus,
	ServiceType,
	ServiceTypeEnum,
	SettingDayNightService,
} from '../../models';
import { PopupType } from '../../models/popup.model';
import {
	ServiceDTO,
	ServiceQuery,
	serviceService,
} from '../../packages/dataProviders';
import {
	localStorageFuncs,
	transformToValidTrainCode,
} from '../../packages/helpers';
import { NotifyCode } from '../../packages/helpers/popup/exception.config';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import {
	deleteRollingStockAvailabilityByRsId,
	setNightServiceStatus,
	setReportingNCNP,
	SetRollingStockServiceStatusCount,
	setServiceStatus,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
import { ServiceReporting } from '../types/reporting.types';

const { SERVICES, SERVICE } = components;
const { NC, NP, NCR, MEP, NQ, PS, PBS } = ServiceTypeEnum;

type ServiceThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;
export const createServices =
	(
		services: ServiceDTO[],
		serviceType?: ServiceType | undefined,
		npHighLimit?: number
	): ServiceThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SERVICES));
			const res = await serviceService.createServices(services);
			if (res.ok) {
				localStorageFuncs.set('lastSyncTimeStamp', Date.now());
				if (serviceType)
					dispatch(api.showFeedBack(getFeedBackMessage(serviceType)));
				if (serviceType?.code === 'NP' && npHighLimit)
					dispatch(SetRollingStockServiceStatusCount(npHighLimit));
				dispatch(api._response(SERVICES));
				services.forEach((service) => {
					if (service.rollingStockId) {
						dispatch(
							deleteRollingStockAvailabilityByRsId(
								service.rollingStockId,
								'SERVICE',
								service.serviceTypeId
							)
						);
					}
				});
			} else {
				dispatch(api.showFeedBack(errorConf.warn.actionRejected));
				dispatch(api._error(SERVICES, res.message));
			}
		} catch (e) {
			dispatch(api._error(SERVICES, e));
		}
	};

export const changeServiceStatus =
	(type: ServiceTypeEnum, status: ServiceStatus): ServiceThunk =>
	async (dispatch) => {
		dispatch(setServiceStatus(status, type));
	};

export const getServices =
	(
		query: ServiceQuery,
		dayNightService: SettingDayNightService[]
	): ServiceThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SERVICES));
			if (query.trainCode)
				query.trainCode = transformToValidTrainCode(query.trainCode ?? '');
			const res = await serviceService.getServicesSince(query);
			if (!res.ok) {
				throw Error;
			} else {
				dispatch(api._response(SERVICES));
				dispatch(setNightServiceStatus(res.services, dayNightService));
			}
		} catch (e) {
			dispatch(api._error(SERVICES, e));
			dispatch(api.showFeedBack(errorConf.warn.invalidTrainCode));
		}
	};

export const getMostRecentService =
	(serviceId: number): ServiceThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SERVICES));
			const res = await serviceService.getMostRecentService(serviceId);
			if (!res.ok) {
				throw Error;
			} else {
				dispatch(api._response(SERVICES));
			}
		} catch (e) {
			dispatch(api._error(SERVICES, e));
		}
	};

export const createServicesList =
	(
		services: ServiceDTO[],
		serviceType: ServiceType | undefined,
		npHighLimit?: number
	): ServiceThunk =>
	async (dispatch) => {
		const servicesToCreate: ServiceDTO[] = [...services];
		if (!isEmpty(servicesToCreate)) {
			dispatch(createServices(servicesToCreate, serviceType, npHighLimit));
		}
	};

export const loadCleanings =
	(
		beginDate: Date,
		endDate: Date,
		lineId?: string,
		department?: string
	): ServiceThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SERVICES));

			const response = await serviceService.findTotalServices({
				beginDate: beginDate.toISOString(),
				department: department,
				endDate: endDate.toISOString(),
				lineId: lineId,
				serviceIds: [NP, NC, NCR, NQ, PS, PBS],
			});
			if (response.ok && response.services) {
				const newServices: ServiceReporting[] = response.services?.map(
					(service) => {
						return {
							monthCount: service.monthCount,
							type: service.type,
							yearCount: service.yearCount,
						};
					}
				);
				dispatch(api._response(SERVICES));
				dispatch(setReportingNCNP(newServices));
			}
		} catch (e) {
			dispatch(api._error(SERVICES, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

const getFeedBackMessage = (serviceType: ServiceType) => {
	let message = NotifyCode.INF_FUNC_005 as string;
	message = message.replace('?', serviceType.name);
	message = message.replace(
		'&',
		[NC, NP, NCR, MEP, PBS].includes(serviceType.id) ? '' : 'e'
	);
	return exceptionDTOtoPopupState(message, PopupType.SUCCESS, false, false);
};

export const deleteService =
	(
		serviceId: number,
		successCb?: () => void,
		errorCb?: () => void
	): ServiceThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SERVICE));
			const res = await serviceService.deleteServiceById(serviceId);
			if (res.ok) {
				dispatch(api._response(SERVICE));
				if (successCb) successCb();
			} else {
				dispatch(api._error(SERVICE, res.message));
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(res.message, PopupType.ERROR)
					)
				);
			}
		} catch (e) {
			api._error(SERVICE, e);
			console.error(e);
			if (errorCb) errorCb();
		}
	};

export const deleteServices =
	(
		serviceIds: number[],
		serviceTypeIds: string,
		successCb?: () => void,
		errorCb?: () => void
	): ServiceThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SERVICE));
			const res = await serviceService.deleteServicesByIds(
				serviceIds,
				serviceTypeIds
			);
			if (res.ok) {
				dispatch(api._response(SERVICE));
				if (successCb) successCb();
			} else {
				dispatch(api._error(SERVICE, res.message));
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(res.message, PopupType.ERROR)
					)
				);
			}
		} catch (e) {
			api._error(SERVICE, e);
			console.error(e);
			if (errorCb) errorCb();
		}
	};
