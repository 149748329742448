import { Grid } from '@mui/material';

import { SPECIAL_OPERATION_REPORTING_TITLE } from '../../../packages/helpers/explanations/specialOperation';
import TitleDivider from '../titleDivider';

import CardOpReporting from './cardOpReporting';

const reportingOPs: any = [];

const OpReporting = () => {
	return (
		<Grid container>
			<TitleDivider title={SPECIAL_OPERATION_REPORTING_TITLE}></TitleDivider>
			<Grid item xs={12}>
				{reportingOPs && <CardOpReporting />}
			</Grid>
		</Grid>
	);
};

export default OpReporting;
