export const SP_MODAL_TITLE = 'Créer une opération spéciale';
export const SP_MODAL_HEADER_TITLE =
	"Définir les paramètres d'une opération spéciale";

export const SP_MODAL_DESCRIPTION_TITLE = 'Titre et descriptif';
export const SP_MODAL_DESCRIPTION_NAME = "Nom de l'opération";
export const SP_MODAL_DESCRIPTION_EXPLANATION =
	'Ce nom apparaitra dans la liste des anomalies';
export const SP_MODAL_DESCRIPTION_DETAILS = "Détails de l'opération";

export const SP_MODAL_PERIOD_TITLE = 'Période effective';
export const SP_MODAL_PERIOD_START_DATE = "Début de l'opération";

export const SP_MODAL_LINES_TITLE = 'Lignes impactées et droits';
export const SP_MODAL_LINES_IMPACTED = 'Lignes impactées';
export const SP_MODAL_LINES_EXPLANATION =
	'Sélectionner les lignes concernées par cette opération spéciale';

export const NAME_LIMITED_CHARACTERS = 100;
export const DESCRIPTION_LIMITED_CHARACTERS = 500;

export const SP_ERROR_FORM_NAME_REQUIRED =
	"Veuillez renseigner un nom d'opération";
export const SP_ERROR_FORM_NAME_LENGHT = `Le nom ne peut pas avoir plus de ${NAME_LIMITED_CHARACTERS} charactères`;
export const SP_ERROR_FORM_DESCRIPTION_REQUIRED =
	"Veuillez renseigner les détails de l'opération";
export const SP_ERROR_FORM_DESCRIPTION_LENGHT = `Les détails ne peuvent pas avoir plus de ${DESCRIPTION_LIMITED_CHARACTERS} charactères`;
export const SP_ERROR_FORM_LINES = 'Vous devez sélectionner au moins une ligne';
export const SPECIAL_OPERATION_TITLE = 'Gestion des opérations spéciales';
export const SPECIAL_OPERATION_IN_PROGRESS_TITLE =
	'Opération spéciale en cours';
export const SPECIAL_OPERATION_REPORTING_TITLE =
	'Historique des opérations spéciales';

// clotûre
export const CLOSE_BUTTON_TEXT = 'Clôturer';
export const CLOSE_CONFIRMATION_HEADER = 'Valider les modifications';
export const CLOSE_CONFIRMATION_CONTENT_PART1 =
	"Voulez-vous mettre un terme à l'opération spéciale ";
export const CLOSE_CONFIRMATION_CONTENT_PART2 =
	' ? Tous les éléments concernés par cette opération spéciale seront impactés.';

// Modification
export const SP_UPDATE_TITLE_MODAL = 'Modifier une opération spéciale';
