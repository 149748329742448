import { useState } from 'react';
import { Grid } from '@mui/material';

import { useLine } from '../../../packages/hooks';
import DashboardCard from '../card';

import PeriodDashboardReporting from './periodDashboardReporting';
import SelectPeriod from './selectPeriod';
import ServiceReporting from './serviceReporting';

const PeriodDashboard = () => {
	const today = new Date();
	const firstDayMonthExpired = new Date(
		today.getFullYear(),
		today.getMonth() - 1,
		1
	);
	const lastDayMonthExpired = new Date(
		new Date(today.setDate(0)).setHours(23, 59, 0)
	);
	const [clickTrigger, setClickTrigger] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<Date>(firstDayMonthExpired);
	const [endDate, setEndDate] = useState<Date>(lastDayMonthExpired);
	const { selectedLine } = useLine();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			rowGap={3}
		>
			<SelectPeriod
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				setClickTrigger={setClickTrigger}
			/>
			<Grid item xs={12} display="flex" justifyContent="center">
				<PeriodDashboardReporting
					startDate={startDate}
					endDate={endDate}
					selectedLine={selectedLine}
					clickTrigger={clickTrigger}
				/>
			</Grid>
			<Grid item xs={12}>
				<DashboardCard
					title={'Suivi contractuel des Prestations'}
					isToday={false}
				>
					<ServiceReporting
						startDate={startDate}
						endDate={endDate}
						selectedLine={selectedLine}
						clickTrigger={clickTrigger}
					/>
				</DashboardCard>
			</Grid>
		</Grid>
	);
};

export default PeriodDashboard;
