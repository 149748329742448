import { Dispatch, SetStateAction, useState } from 'react';

import { IncidentSelected, IncidentSummary } from '../../models';
import { ModalesState, useLine, useTypedSelector } from '../../packages/hooks';
import IncidentReportForm from '../modals/incidentReport/IncidentReportCreateForm';
import IncidentDetails from '../modals/incidentReport/IncidentReportEditForm';
import IncidentSelect from '../modals/incidentReport/IncidentSelect';

const IncidentReport = ({
	handleCloseModal,
	handleOpenModal,
	incidentSummary,
	isResolvingIncident,
	openModalState,
	setIsResolvingIncident,
	trainId,
}: IncidentReportProps) => {
	const [incidentSelected, setIncidentSelected] =
		useState<IncidentSelected | null>(null);

	const { selectedLine } = useLine();

	const rollingStockList = useTypedSelector(
		({ rollingStockState }) => rollingStockState.rollingStocks
	);
	const rsCode = rollingStockList.find((t) => t.id === trainId)?.trainCode;

	const canOpenModal = (): boolean => {
		return !!selectedLine && !!trainId;
	};

	return (
		<>
			{canOpenModal() && (
				<>
					<IncidentSelect
						incidentSelected={incidentSelected}
						isResolvingIncident={isResolvingIncident}
						open={openModalState.incidentSelect}
						rsCode={rsCode}
						setIncidentSelected={setIncidentSelected}
						setIsResolvingIncident={setIsResolvingIncident}
						handleCloseModal={handleCloseModal}
						handleOpenModal={handleOpenModal}
						trainId={trainId}
					/>
					{incidentSelected && trainId && (
						<IncidentReportForm
							incidentSelected={incidentSelected}
							open={openModalState.incidentReportCreateForm}
							rsCode={rsCode}
							selectedLine={selectedLine}
							setIncidentSelected={setIncidentSelected}
							handleCloseModal={handleCloseModal}
							trainId={trainId}
						/>
					)}
					{incidentSummary && (
						<IncidentDetails
							open={openModalState.incidentReportEditForm}
							onClose={handleCloseModal}
							incidentSummary={incidentSummary}
						/>
					)}
				</>
			)}
		</>
	);
};

export default IncidentReport;

type IncidentReportProps = {
	isResolvingIncident: boolean;
	setIsResolvingIncident: Dispatch<SetStateAction<boolean>>;
	incidentSummary?: IncidentSummary;
	trainId?: number;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	openModalState: ModalesState;
};
