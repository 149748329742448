import { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { isNil } from 'lodash';

import { AvailabilityPosition } from '../../../../models';
import {
	MultipleAvailability,
	MultipleAvailabilityTypeEnum,
	RSWithPosition,
} from '../../../../models/multipleAvailability';
import AvailabilityProvider from '../../../../packages/dataProviders/resources/availability';
import { Select } from '../../../../packages/ui';
import Checkbox from '../../../../packages/ui/atoms/Checkbox';

import {
	StyledCheckContainer,
	StyledDeltaContainer,
	StyledTrainCard,
	StyledWarningValidation,
} from './styles';

const TrainCardList = ({
	errors,
	values,
	handleCheckOneOf,
	setValues,
	availabilityType,
}: TrainListCardProps) => {
	const [positionOptions, setPositionOptions] = useState<
		AvailabilityPosition[]
	>([]);
	const { site, availabilityRollingStocks } = values;

	const showNpDeltaTime = (rollingStockid: number) => {
		const NpDeltaTime = availabilityRollingStocks.find(
			({ id }) => id === rollingStockid
		)?.NpDeltaTime;
		return isNil(NpDeltaTime) ? '' : `${NpDeltaTime}j`;
	};

	const handleSelectRSWithPosition = (
		e: SelectChangeEvent,
		options: AvailabilityPosition[],
		rollingStockId: number
	) => {
		const positionSelected = options.find(
			({ id }) => id === Number(e.target?.value)
		);

		if (!positionSelected) return;

		const newAvailabilityRollingStocks = availabilityRollingStocks.map(
			(availabilityRollingStock) =>
				availabilityRollingStock.id === rollingStockId
					? {
							...availabilityRollingStock,
							position: {
								id: positionSelected.id,
								name: positionSelected.name,
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: // eslint-disable-next-line no-mixed-spaces-and-tabs
					  availabilityRollingStock
		);

		setValues({
			...values,
			availabilityRollingStocks: newAvailabilityRollingStocks,
		});
	};

	const resetPositions = () => {
		const resetAvailabilityRollingStocks = availabilityRollingStocks.map(
			(rollingStock) => ({
				...rollingStock,
				position: null,
			})
		);
		setValues({
			...values,
			availabilityRollingStocks: resetAvailabilityRollingStocks,
		});
	};

	const sortNpdeltatime = (rs: RSWithPosition[]) => {
		let sortedRs = [...rs];
		if (availabilityType === MultipleAvailabilityTypeEnum.NP) {
			sortedRs = [...rs].filter(({ NpDeltaTime }) => NpDeltaTime !== '0');
		}

		sortedRs
			.sort((a, b) => {
				const deltaA = !isNil(a.NpDeltaTime) ? Number(a.NpDeltaTime) : -1;
				const deltaB = !isNil(b.NpDeltaTime) ? Number(b.NpDeltaTime) : -1;
				return deltaB - deltaA;
			})
			.sort((a, b) => b.isUsedOrder - a.isUsedOrder);

		return sortedRs;
	};

	useEffect(() => {
		if (site) {
			resetPositions();
			AvailabilityProvider.loadAvailabilityPositions(site.id).then(
				({ data: positions }) => setPositionOptions(positions)
			);
		}
	}, [site]);

	return (
		<>
			{errors.selectedRSA && (
				<StyledWarningValidation>{errors.selectedRSA}</StyledWarningValidation>
			)}
			{sortNpdeltatime(availabilityRollingStocks).map(
				({ checked, id, name, position }) => (
					<StyledTrainCard checked={checked} key={id}>
						<StyledCheckContainer>
							<Checkbox
								checked={checked}
								label={name}
								onChange={(event) =>
									handleCheckOneOf(event, 'availabilityRollingStocks')
								}
								name={id.toString()}
								sx={{
									' & .css-novu8p-MuiTypography-root': { fontWeight: 'bold' },
								}}
							/>
							<StyledDeltaContainer>{showNpDeltaTime(id)}</StyledDeltaContainer>
						</StyledCheckContainer>
						<Select
							defaultValue={position}
							label="Position"
							name="position"
							onChange={(e) =>
								handleSelectRSWithPosition(e, positionOptions, id)
							}
							options={positionOptions}
							value={position}
							width={'267px'}
							disabled={!checked}
						/>
					</StyledTrainCard>
				)
			)}
		</>
	);
};
export default TrainCardList;

type TrainListCardProps = {
	errors: { [key: string]: string };
	values: MultipleAvailability;
	handleCheckOneOf: (event: ChangeEvent<HTMLInputElement>, of: string) => void;
	setValues: Dispatch<any>;
	availabilityType: MultipleAvailabilityTypeEnum | undefined;
};
