import { Unit } from '../../models';

export type StationDetailPopInState = {
  meta: {
    open: boolean;
  };
  stationName: string;
  stationId: number;
  units?: Unit[];
  passage?: number;
};

export const CLOSE_STATION_DETAIL_POPIN = 'CLOSE_STATION_DETAIL_POPIN';
export const OPEN_STATION_DETAIL_POPIN = 'OPEN_STATION_DETAIL_POPIN';
export const INIT_STATION_DETAIL_POPIN = 'INIT_STATION_DETAIL_POPIN';
export const VALIDATED_STATION_DETAIL_POPIN = 'VALIDATED_STATION_DETAIL_POPIN';
export const SET_UNITS_STATION_DETAIL_POPIN = 'SET_UNITS_STATION_DETAIL_POPIN';

export interface CloseStationDetailPopInAction {
  type: typeof CLOSE_STATION_DETAIL_POPIN;
}
export interface OpenStationDetailPopInAction {
  type: typeof OPEN_STATION_DETAIL_POPIN;
  payload: {
    stationName: string;
    stationId: number;
  };
}
export interface SetUnitsStationDetailPopInAction {
  type: typeof SET_UNITS_STATION_DETAIL_POPIN;
  payload: { units: Unit[] };
}

export interface InitStationDetailPopInAction {
  type: typeof INIT_STATION_DETAIL_POPIN;
  payload: {
    stationName: string;
    stationId: number;
    units: Unit[];
    passage: number;
  };
}

export type StationDetailPopInActionTypes =
  | CloseStationDetailPopInAction
  | OpenStationDetailPopInAction
  | SetUnitsStationDetailPopInAction
  | InitStationDetailPopInAction;
