import { SpecialOperationType } from '../../models';

export interface SpecialOperationTypeState {
	opInProgress: SpecialOperationType[];
	opReporting: SpecialOperationType[];
}

export const CREATE_SPECIAL_OPERATION_TYPE = 'CREATE_SPECIAL_OPERATION_TYPE';
export const UPDATE_SPECIAL_OPERATION_TYPE = 'UPDATE_SPECIAL_OPERATION_TYPE';
export const CLOSE_SPECIAL_OPERATION_TYPE = 'CLOSE_SPECIAL_OPERATION_TYPE';
export const SET_OP_IN_PROGRESS = 'SET_OP_IN_PROGRESS';
export const SET_OP_REPORTING = 'SET_OP_REPORTING';

export interface CreateSpecialOperationTypeAction {
	type: typeof CREATE_SPECIAL_OPERATION_TYPE;
	payload: SpecialOperationType;
}

export interface UpdateSpecialOperationTypeAction {
	type: typeof UPDATE_SPECIAL_OPERATION_TYPE;
	payload: SpecialOperationType;
}

export interface CloseSpecialOperationTypeAction {
	type: typeof CLOSE_SPECIAL_OPERATION_TYPE;
	payload: SpecialOperationType;
}
export interface SetOpInProgressTypeAction {
	type: typeof SET_OP_IN_PROGRESS;
	payload: SpecialOperationType[];
}

export interface SetOpReportingTypeAction {
	type: typeof SET_OP_REPORTING;
	payload: SpecialOperationType[];
}

export type SpecialOperationTypeActionTypes =
	| CreateSpecialOperationTypeAction
	| UpdateSpecialOperationTypeAction
	| CloseSpecialOperationTypeAction
	| SetOpInProgressTypeAction
	| SetOpReportingTypeAction;
