import { initIncidentTypeState } from '../config/initialState';
import {
	IncidentTypeActionTypes as ActionTypes,
	IncidentTypeState as State,
	SET_INCIDENT_TYPE,
} from '../types';

export const incidentTypeReducer = (
	state = initIncidentTypeState,
	action: ActionTypes
): State => {
	if (action.type === SET_INCIDENT_TYPE) {
		return {
			...state,
			incidentTypesCreate: action.payload.incidentTypesCreate,
			incidentTypesDelete: action.payload.incidentTypesDelete,
			incidentTypesExport: action.payload.incidentTypesExport,
			incidentTypesSolve: action.payload.incidentTypesSolve,
		};
	}
	return state;
};

export default incidentTypeReducer;
