import { isEmpty } from 'lodash';

import { Availability } from '../../models/availability.model';
import { RSA_FORM_ERROR_SITE } from '../../packages/helpers/explanations/rollingStockAvailability';

export const getEmptyRollingStockAvailability = (): Availability =>
	({
		id: undefined,
		incidentTypeId: undefined,
		position: undefined,
		positionId: undefined,
		rollingStockId: undefined,
		serviceTypeId: undefined,
		site: undefined,
		specialOperationTypeId: undefined,
	} as unknown as Availability);

export const validate = (values: any) => {
	const errors = {} as { [key in string]: string };
	const { site } = values;
	if (isEmpty(site)) errors.site = RSA_FORM_ERROR_SITE;
	return errors;
};
