import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Camera from '../../components/scanner';
import { useDepartment } from '../../packages/hooks';
import { CodeButton } from '../../packages/ui';
import { resetSurface } from '../../redux/actions';

import './styles/scannerPage.css';

const QrScanner = () => {
	const dispatch = useDispatch();
	const { hasMtsDepartment, hasSemDepartment } = useDepartment();
	const label = `SAISIR LE NUMERO\n${
		hasMtsDepartment ? 'DU TRAIN' : 'DE LA VOITURE'
	}`;

	useEffect(() => {
		dispatch(resetSurface());
	}, [dispatch]);

	return (
		<main className="scan-page page">
			<Camera />
			{!hasSemDepartment && (
				<CodeButton label={label} variant="code-btn-scanner" />
			)}
		</main>
	);
};

export default QrScanner;
