import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';

import { pluralize } from '../../../../packages/helpers';
import {
	INCIDENT_DI_NAME,
	INCIDENT_PB_NAME,
	INCIDENT_TYPE,
} from '../../../../packages/helpers/explanations/dashboard';
import {
	IncidentSvgPrintable,
	WarningIcon,
} from '../../../../packages/ui/icons';

import { StyledPrio, StyledPriorityCount, StyledSvgContainer } from './styles';

const Box = ({ isMiddle, children, isTwoElement }: BoxProps) => {
	return (
		<Grid
			item
			xs={4}
			height="7.5rem"
			borderBottom="solid 1px var(--border-color-divider)"
			display="flex"
			flexDirection={isTwoElement ? 'column' : 'row'}
			justifyContent="center"
			alignItems="center"
			style={{
				backgroundColor: isMiddle ? 'var(--bg-color-green-dark-opacity)' : '',
			}}
		>
			{children}
		</Grid>
	);
};

const IncidentsContent = ({
	pbTrainsCount,
	diTrainsCount,
	priorityCount,
}: IncidentsContentProps) => {
	const trainsCountText =
		pluralize(pbTrainsCount + diTrainsCount, 'Train') +
		pluralize(pbTrainsCount + diTrainsCount, ' impacté') +
		' :';
	return (
		<Grid container>
			<Box>
				<Typography fontSize="1.125rem">{INCIDENT_TYPE}</Typography>
			</Box>
			<Box isMiddle isTwoElement>
				<StyledSvgContainer>
					<IncidentSvgPrintable name="PB" />
				</StyledSvgContainer>
				<div>
					<Typography variant="h5">{INCIDENT_PB_NAME}</Typography>
				</div>
			</Box>
			<Box isTwoElement>
				<StyledSvgContainer>
					<IncidentSvgPrintable name="DI" />
				</StyledSvgContainer>
				<div>
					<Typography variant="h5">{INCIDENT_DI_NAME}</Typography>
				</div>
			</Box>
			<Box>
				<Typography fontSize="1.125rem">{trainsCountText}</Typography>
			</Box>
			<Box isMiddle>
				<Typography fontSize="2.25rem" fontWeight="bold">
					{pbTrainsCount}
				</Typography>
			</Box>
			<Box isTwoElement>
				<StyledPriorityCount>
					<Typography fontSize="2.25rem" fontWeight="bold">
						{priorityCount}
					</Typography>
					<WarningIcon
						sx={{
							left: '1.5rem',
							position: 'absolute',
							top: '0',
						}}
						fontSize="small"
						color="error"
					/>
				</StyledPriorityCount>
				<StyledPrio>
					<Typography fontSize="1.125rem">
						{pluralize(diTrainsCount, 'prioritaire') + ' sur '}
					</Typography>
					<Typography fontSize="2.25rem" fontWeight="bold">
						{diTrainsCount}
					</Typography>
				</StyledPrio>
			</Box>
		</Grid>
	);
};

export default IncidentsContent;

interface BoxProps {
	children: ReactNode;
	isMiddle?: boolean;
	isTwoElement?: boolean;
}

interface IncidentsContentProps {
	pbTrainsCount: number;
	diTrainsCount: number;
	priorityCount: number;
}
