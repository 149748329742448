import { store } from '../../../redux';
import { openNotificationPopIn } from '../../../redux/actions';
import { localStorageFuncs } from '../../helpers/';
import { AlertTypeEnum, NotificationData } from '../types';

export const degrafReminderHandler = (e: MessageEvent) => {
  const notification = JSON.parse(e.data) as NotificationData;
  localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
  const title = 'Alerte dégraffitage';
  const subTitle = `Les trains suivants nécessitent un dégraffitage :`;
  const confirmTxt = 'OK';
  store.dispatch(
    openNotificationPopIn(
      title,
      subTitle,
      notification.data as string[],
      confirmTxt,
      AlertTypeEnum.DegrafReminder,
    ),
  );
};

export default degrafReminderHandler;
