import { cookieService } from './cookie';

export type LocalStorageEntries =
	| 'smtr-uuid'
	| 'smtr-appEnv'
	| 'lastSyncTimeStamp'
	| `last_location_0`
	| `last_location_1`
	| `last_location_2`
	| `last_location_3`
	| `last_location_4`
	| `selected-line`
	| `selected-passage`
	| `smtr-grid-settings`;

export const localStorageFuncs = {
	clear: () => {
		const dataGridValueSave = window.localStorage.getItem(`smtr-grid-settings`);
		window.localStorage.clear();
		if (dataGridValueSave)
			window.localStorage.setItem(`smtr-grid-settings`, dataGridValueSave);
	},
	del: (key: LocalStorageEntries) =>
		window.localStorage.getItem(key) && window.localStorage.removeItem(key),
	get: (key: LocalStorageEntries) => window.localStorage.getItem(key),
	set: (key: LocalStorageEntries, val: string | number) =>
		window.localStorage.setItem(
			key,
			typeof val === 'number' ? val.toString() : val
		),
};

export async function triggerSyncEvent() {
	try {
		const reg = await navigator.serviceWorker.ready;
		await reg.sync.register('bg-sync');
	} catch (error) {
		console.error(
			'An error occurred while registering the synchronization:',
			error
		);
	}
}

export const clearAllCache = () => {
	localStorageFuncs.clear();
	cookieService.clear();
};
