import { Card, FormControl, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';

const important = (value: string): string => {
	return value + ' !important';
};

export const SyledFormControl = styled(FormControl)({
	alignItems: 'center',
	display: 'flex',
	fontSize: 'large',
	height: important('calc(100vh - 270px)'),
	marginTop: '0.5rem',
	overflow: 'auto',
	width: '100%',
});

export const SyledRadioGroup = styled(RadioGroup)({
	alignItems: 'center',
	width: '98%',
});

export const SyledRadioCard = styled(Card)({
	alignItems: 'center',
	borderRadius: '0.2rem',
	cursor: 'pointer',
	display: important('flex'),
	marginTop: '1rem',
	minHeight: '4rem',
	padding: '1rem 3rem',
	width: '70%',
});
