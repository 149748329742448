import {
	applyMiddleware,
	compose,
	legacy_createStore as createStore,
} from 'redux';
import thunk from 'redux-thunk';

import { apiThunk } from '../actions';
import { rootReducer } from '../reducers';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
	}
}

const composeEnchancers =
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWares = [
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('redux-immutable-state-invariant').default(),
	thunk.withExtraArgument(apiThunk),
];

const store = createStore(
	rootReducer,
	composeEnchancers(applyMiddleware(...middleWares))
);

export default store;
