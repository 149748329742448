import { Station } from '../../../models/station.model';
import fetchJson from '../fetch/fetch-json';
import { Count } from '../rollingStockService';

const getStations = async (): Promise<{
	data: Station[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/station`);
};

const getStationsByLine = async (
	lineId: string
): Promise<{
	data: Station[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/station/line/${lineId}`);
};

const getCountStations = async (
	line: string
): Promise<{
	data: Count;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/station/count/line/${line}`);
};

export default { getCountStations, getStations, getStationsByLine };
