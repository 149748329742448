import {
	ApiActionTypes,
	ApiThunks,
	components,
	ERROR,
	REQUEST,
	RESPONSE,
} from '../types/api.types';

import { showPopup } from '.';

export const request = (component: components): ApiActionTypes => ({
	component,
	type: REQUEST,
});

export const response = (component: components): ApiActionTypes => ({
	component,
	type: RESPONSE,
});

export const error = (component: components, err: any): ApiActionTypes => ({
	component,
	error: err,
	type: ERROR,
});

export const apiActions = {
	error,
	request,
	response,
};

export const apiThunk: ApiThunks = {
	_error: (component, err) => error(component, err),
	_request: (component) => request(component),
	_response: (component) => response(component),
	showFeedBack: (config) => showPopup(config),
};

export default apiActions;
