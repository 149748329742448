import { useEffect, useState } from 'react';
import { Card, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';

import { PhotoThumbnailProps } from '../../../models/ui';
import appEnv from '../../configuration/appEnv';
import { DeleteIcon } from '../icons';

const PhotoThumbnail = ({
	urlLink = '',
	handleDeleteFile,
	file,
	disabled,
}: PhotoThumbnailProps) => {
	const [alt, setAlt] = useState('');
	const [source, setSource] = useState<string>('');
	const [renderImg, setRenderImg] = useState(true);

	const getImg = async () => {
		if (file) {
			setSource(URL.createObjectURL(file));
			setAlt(file.name);
		} else if (urlLink && appEnv.env === 'local') {
			setSource(appEnv.backendUrl + '/v1/incident-report-photos/' + urlLink);
			setAlt(urlLink);
		} else if (urlLink) {
			setSource(`/api/v1/incident-report/photos/${urlLink}`);
			setAlt(urlLink);
		} else {
			setRenderImg(false);
		}
	};

	useEffect(() => {
		getImg();
	}, [file, urlLink]);

	return renderImg ? (
		<StyledMediaCard disabled={disabled}>
			<StyledImg alt={alt} crossOrigin="anonymous" src={source} />
			<StyledAction
				disabled={disabled}
				onClick={(e) =>
					handleDeleteFile(e, file ? file.name : urlLink, !isEmpty(file))
				}
			>
				<IconButton disabled={disabled}>
					<DeleteIcon color="primary" />
				</IconButton>
				<Typography variant="h6" color="primary">
					Supprimer
				</Typography>
			</StyledAction>
		</StyledMediaCard>
	) : (
		<></>
	);
};
export default PhotoThumbnail;

const StyledMediaCard = styled(Card)<{
	disabled?: boolean;
}>(({ disabled }) => ({
	borderRadius: '20px',
	display: 'flex',
	elevation: 6,
	flexDirection: 'column',
	height: 188,
	opacity: disabled ? 0.5 : 1,
	width: 230,
}));

const StyledImg = styled('img')(() => ({
	borderRadius: '20px',
	height: '75%',
	margin: 'auto',
	width: 'auto',
}));

const StyledAction = styled('div')<{
	disabled?: boolean;
}>(({ disabled }) => ({
	alignItems: 'center',
	cursor: disabled ? 'cursor' : 'pointer',
	display: 'flex',
	height: '25%',
	justifyContent: 'center',
	width: '100%',
}));
