import { store } from '../../../redux';
import { initEventSource } from '../creator';
let keepAliveTimer: NodeJS.Timeout | undefined = undefined;
/**
 * Keep alive function
 */
const keepAliveHandler = () => {
	const userState = store.getState().userState;
	if (!userState.authenticated || !userState.user) return;
	if (keepAliveTimer !== undefined) clearTimeout(keepAliveTimer);
	keepAliveTimer = setTimeout(() => {
		if (userState.user) {
			initEventSource(userState.user);
		}
	}, 35 * 1000);
};

export default keepAliveHandler;
