import { ThunkAction } from 'redux-thunk';

import IncidentTypeProvider from '../../packages/dataProviders/resources/incidentType';
import { errorConf } from '../../packages/helpers/popup/exception.utils';
import { setIncidentTypes } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { INCIDENT_TYPE } = components;

type IncidentTypeThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const getIncidentTypes =
	(): IncidentTypeThunk => async (dispatch, _, api) => {
		try {
			dispatch(api._request(INCIDENT_TYPE));
			const { data: incidentTypes } =
				await IncidentTypeProvider.loadIncidentTypes();
			dispatch(api._response(INCIDENT_TYPE));
			dispatch(setIncidentTypes(incidentTypes));
		} catch (e) {
			dispatch(api._error(INCIDENT_TYPE, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};
