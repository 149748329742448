import { Button, SingleButtonsBloc } from '../../../packages/ui';

import './styles.css';

const SettingSavePopIn = ({
	isVisible,
	message,
	title,
	onValidate,
	onCancel,
	cancelButtonText,
	confirmButtonText,
}: SettingSavePopInProps) => {
	return (
		<>
			{isVisible && (
				<div className="container-background" id="popup-container">
					<div
						className={`popup-save-setting popup-save container-setting-dimension popup-pos-height`}
						id="popup"
					>
						<div className="popup-header">
							<h3 className="title-text font-paris-bold">{title}</h3>
						</div>
						<div className="popup-save-message-text font-paris">{message}</div>
						<SingleButtonsBloc>
							<Button
								onClick={() => {
									onCancel();
								}}
								variant="text"
							>
								{cancelButtonText}
							</Button>
							<Button
								onClick={() => {
									onValidate();
								}}
							>
								{confirmButtonText}
							</Button>
						</SingleButtonsBloc>
					</div>
				</div>
			)}
		</>
	);
};

export default SettingSavePopIn;

type SettingSavePopInProps = {
	isVisible: boolean;
	message: string;
	title: string;
	cancelButtonText: string;
	confirmButtonText: string;
	onValidate: () => void;
	onCancel: () => void;
};
