import { ChangeEvent, Dispatch, Fragment, SetStateAction } from 'react';
import { Typography } from '@mui/material';

import {
	DayNightEnum,
	IncidentTypeMap,
	ServiceTypeMap,
	SettingDayNightIncident,
	SettingDayNightService,
	User,
} from '../../../models';
import { hasSomeDepartments } from '../../../packages/helpers';
import { RadioGroup } from '../../../packages/ui';

import './styles.css';

const { DAY, NIGHT, DAYNIGHT, NEVER } = DayNightEnum;

const SettingDayNight = ({
	dayNightIncidents = [],
	dayNightServices,
	handleIncidentsChange,
	handleServicesChange,
	setSettingIsModified,
	user,
}: SettingDayNightProps) => {
	const handleServiceChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const newDayNightServices = dayNightServices.map((service) => {
			if (service.serviceTypeId === parseInt(e.target.name, 10)) {
				return {
					...service,
					dayNightType: parseInt(e.target.value, 10),
				};
			}
			return service;
		});
		handleServicesChange(newDayNightServices);
		setSettingIsModified(true);
	};

	const handleIncidentChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const newdayNightIncidents = dayNightIncidents.map((incident) => {
			if (incident.incidentTypeId === parseInt(e.target.name, 10)) {
				return {
					...incident,
					dayNightType: parseInt(e.target.value, 10),
				};
			}
			return incident;
		});

		handleIncidentsChange?.(newdayNightIncidents);
		setSettingIsModified(true);
	};

	const radioOptions = [
		{ label: 'Jour', value: DAY },
		{ label: 'Nuit', value: NIGHT },
		{ label: 'Jour et nuit', value: DAYNIGHT },
		{ label: 'Jamais', value: NEVER },
	];

	return (
		<>
			<Typography variant="h5">
				Affichage des prestations en fonction du moment de la journée
			</Typography>
			<Typography variant="subtitle1" mb={3}>
				Sélectionnez les types de prestation que vous souhaitez afficher sur
				l'écran du prestataire en journée et en nuit afin de faciliter son choix
				de prestation
			</Typography>

			{dayNightServices?.map((service) =>
				user && !hasSomeDepartments(user, service.departments) ? (
					<Fragment key={service.id} />
				) : (
					<RadioGroup
						key={service.id}
						label={ServiceTypeMap.get(service.serviceTypeId)}
						name={service.serviceTypeId?.toString()}
						onChange={handleServiceChange}
						options={radioOptions}
						radioGroupValue={
							dayNightServices.find(
								({ serviceTypeId }) => service.serviceTypeId === serviceTypeId
							)?.dayNightType
						}
					/>
				)
			)}

			{dayNightIncidents?.map((incident) => (
				<RadioGroup
					key={incident.id}
					label={IncidentTypeMap.get(incident.incidentTypeId)}
					name={incident.incidentTypeId?.toString()}
					onChange={handleIncidentChange}
					options={radioOptions}
					radioGroupValue={
						dayNightIncidents.find(
							({ incidentTypeId }) => incident.incidentTypeId === incidentTypeId
						)?.dayNightType
					}
				/>
			))}
		</>
	);
};

export default SettingDayNight;

type SettingDayNightProps = {
	dayNightIncidents?: SettingDayNightIncident[];
	dayNightServices: SettingDayNightService[];
	handleIncidentsChange?: Dispatch<SetStateAction<SettingDayNightIncident[]>>;
	handleServicesChange: Dispatch<SetStateAction<SettingDayNightService[]>>;
	setSettingIsModified: Dispatch<SetStateAction<boolean>>;
	user: User | undefined;
};
