import { ThunkAction } from 'redux-thunk';

import { EspaceEnum } from '../../models/user.model';
import CredentialsProvider from '../../packages/dataProviders/resources/credential';
import { errorConf } from '../../packages/helpers/popup/exception.utils';
import { setCredentials } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { CREDENTIALS } = components;

type CredentialsThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const loadAndSetCredentials =
	(espace: EspaceEnum): CredentialsThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(CREDENTIALS));
			const { data: users } = await CredentialsProvider.loadCredentials(espace);
			dispatch(api._response(CREDENTIALS));
			dispatch(setCredentials(users));
		} catch (e) {
			dispatch(api._error(CREDENTIALS, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};
