import { IconButton as MuiIconButton } from '@mui/material';

import { IconButtonProps, IconColor } from '../../../models/ui';
import { PRIMARY } from '../../helpers';

const IconButton = ({
	bgcolor = PRIMARY,
	children,
	disabled = false,
	label = 'iconBtn',
	onClick,
	onMouseDown,
}: IconButtonProps) => {
	return (
		<MuiIconButton
			component="div"
			aria-label={label}
			color={bgcolor as IconColor}
			disabled={disabled}
			onClick={onClick}
			onMouseDown={onMouseDown}
		>
			{children}
		</MuiIconButton>
	);
};
export default IconButton;
