import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SemPassages, SemServices } from '../../../components/sem/constants';
import SemHeader from '../../../components/sem/TrackingBoard/SemHeader/SemHeader';
import SemNettoyageBoard from '../../../components/sem/TrackingBoard/SemNettoyageBoard/SemNettoyageBoard';
import SemSupplementaireBoard from '../../../components/sem/TrackingBoard/SemSuplementaireBoard/SemSupplementaireBoard';
import { initEventSource } from '../../../packages/events/creator';
import { useEntity, useLine, useTypedSelector } from '../../../packages/hooks';
import { setSelectedLine as reduxSetSelectedLine } from '../../../redux/actions';
import { switchEspace } from '../../../redux/actions/espace.actions';
import {
	getSelectedLineUser,
	setSelectedLineUser,
} from '../../../redux/thunks/line';
import {
	getSelectedPassageUser,
	setSelectedPassageUser,
} from '../../../redux/thunks/passage';
import { loadSetting } from '../../../redux/thunks/setting';
import { getStationsByLine } from '../../../redux/thunks/station';

import './styles/trackingStPage.css';

const { PASSAGE_1, PASSAGE_4 } = SemPassages;
const { NETTOYAGE, SUPPLEMENTAIRE } = SemServices;

const STTrackingBoard = () => {
	const dispatch = useDispatch();
	const { selectedLine } = useLine();
	const { userTs } = useEntity();

	const selectedPassageUser = useTypedSelector(
		({ passageState }) => passageState.selectedPassage
	);

	const stations = useTypedSelector(
		({ stationState }) => stationState.stations
	);
	const [selectedPassage, setSelectedPassage] =
		useState<number>(selectedPassageUser);
	const [selectedPeriod, setSelectedPeriod] = useState<string>(NETTOYAGE);

	useEffect(() => {
		if (selectedLine) {
			dispatch(getStationsByLine(selectedLine));
			dispatch(reduxSetSelectedLine(selectedLine));
			dispatch(switchEspace(3));
			dispatch(loadSetting(selectedLine));
			dispatch(setSelectedLineUser(selectedLine));
			dispatch(getSelectedLineUser);
			setSelectedPeriod(NETTOYAGE);
		}
	}, [dispatch, selectedLine]);

	useEffect(() => {
		dispatch(setSelectedPassageUser(selectedPassage));
		dispatch(getSelectedPassageUser());
	}, [dispatch, selectedPassage]);

	useEffect(() => {
		if (selectedPeriod === SUPPLEMENTAIRE) {
			setSelectedPassage(PASSAGE_4);
		} else {
			setSelectedPassage(PASSAGE_1);
		}
	}, [selectedPeriod]);

	useEffect(() => {
		if (userTs !== undefined) {
			initEventSource(userTs, selectedLine, true);
		}
	}, [selectedLine, userTs]);

	const handlePeriodChange = (value: string) => {
		setSelectedPeriod(value);
	};

	return (
		<main>
			<SemHeader
				onPeriodChange={handlePeriodChange}
				selectedLine={selectedLine}
				selectedPassage={selectedPassage}
				selectedPeriod={selectedPeriod}
				setSelectedPassage={setSelectedPassage}
			/>
			<div className="content">
				{selectedPeriod === NETTOYAGE && (
					<SemNettoyageBoard
						selectedLine={selectedLine}
						selectedPassage={selectedPassage}
						stations={stations}
					/>
				)}
				{selectedPeriod === SUPPLEMENTAIRE && (
					<SemSupplementaireBoard stations={stations} />
				)}
			</div>
		</main>
	);
};

export default STTrackingBoard;
