import { store } from '../../../redux';
import { updateStation } from '../../../redux/actions/station.actions';
import { localStorageFuncs } from '../../helpers/';
import { StationUpdateData } from '../types';

export const unitUpdateHandler = (e: MessageEvent) => {
  const data = JSON.parse(e.data) as StationUpdateData;
  localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
  store.dispatch(updateStation(data));
};

export default unitUpdateHandler;
