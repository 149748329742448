import { store } from '../../../redux';
import { setRollingstockIsUsedList } from '../../../redux/actions';
import { localStorageFuncs } from '../../helpers/';
import { UsedHistoryUpdatedData } from '../types';

export const usedStatusUpdateHandler = (e: MessageEvent) => {
  const data = JSON.parse(e.data) as UsedHistoryUpdatedData[];
  localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
  store.dispatch(setRollingstockIsUsedList(data));
};

export default usedStatusUpdateHandler;
