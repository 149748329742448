import { useDispatch } from 'react-redux';

import { useDepartment, useTypedSelector } from '../../../packages/hooks';
import { Dialog as MuiDialog, ValidationButtons } from '../../../packages/ui';
import { closeHistoryPopIn } from '../../../redux/actions/historyPopIn.actions';
import { getHistoryExport } from '../../../redux/thunks/history';

import HistoryPopInContent from './Content';

const HistoryPopIn = () => {
	const dispatch = useDispatch();

	const stateTs = useTypedSelector((state) => state.historyPopInState);

	const { departmentEnum: department } = useDepartment();

	const onValidateInput = () => {
		if (department !== undefined) {
			dispatch(getHistoryExport(stateTs, department));
		}
	};

	const closePopIn = () => {
		dispatch(closeHistoryPopIn());
	};

	return (
		<MuiDialog
			open={stateTs.meta.open}
			titleContent="Exporter"
			content={<HistoryPopInContent />}
			actionsContent={
				<ValidationButtons onCancel={closePopIn} onValidate={onValidateInput} />
			}
			onClose={closePopIn}
		/>
	);
};

export default HistoryPopIn;
