import { Dispatch, SetStateAction } from 'react';
import { SelectChangeEvent, Typography } from '@mui/material';

import { SpecialOperationType } from '../../../../models';
import {
	SP_MODAL_DESCRIPTION_DETAILS,
	SP_MODAL_DESCRIPTION_EXPLANATION,
	SP_MODAL_DESCRIPTION_NAME,
	SP_MODAL_DESCRIPTION_TITLE,
	SP_MODAL_HEADER_TITLE,
	SP_MODAL_LINES_EXPLANATION,
	SP_MODAL_LINES_IMPACTED,
	SP_MODAL_LINES_TITLE,
	SP_MODAL_PERIOD_START_DATE,
	SP_MODAL_PERIOD_TITLE,
} from '../../../../packages/helpers/explanations/specialOperation';
import { useLine } from '../../../../packages/hooks';
import { DatePicker, Input, MultipleSelect } from '../../../../packages/ui';
import { IncidentSvgPrintable } from '../../../../packages/ui/icons';
import ModalHeader from '../../header';

import { StyledContentContainer, StyledFieldContainer } from './styles';

const SpecialOperationFormContent = ({
	errors,
	handleChange,
	handleDateChange,
	setIsUntouched,
	setValues,
	values,
}: SpecialOperationFormContentProps) => {
	const { allLines } = useLine();

	const handleMultipleSelect = (e: SelectChangeEvent<string[]>) => {
		setValues({ ...values, lines: e.target.value });
		setIsUntouched(false);
	};

	return (
		<>
			<ModalHeader isOp>
				<IncidentSvgPrintable name="OP" />
				<Typography variant="h5" ml="1rem">
					{SP_MODAL_HEADER_TITLE}
				</Typography>
			</ModalHeader>
			<StyledContentContainer>
				<Typography fontSize="1.063rem" fontWeight="bold">
					1. {SP_MODAL_DESCRIPTION_TITLE}
				</Typography>
				<StyledFieldContainer>
					<Input
						helperText={errors.name}
						name="name"
						fullWidth
						label={SP_MODAL_DESCRIPTION_NAME}
						onChange={handleChange}
						required
						value={values.name}
					/>
					<Typography variant="body2">
						{SP_MODAL_DESCRIPTION_EXPLANATION}
					</Typography>
				</StyledFieldContainer>
				<StyledFieldContainer>
					<Input
						helperText={errors.description}
						name="description"
						fullWidth
						label={SP_MODAL_DESCRIPTION_DETAILS}
						multiline
						onChange={handleChange}
						required
						rows={4}
						value={values.description}
					/>
				</StyledFieldContainer>
				<Typography fontSize="1.063rem" fontWeight="bold">
					2. {SP_MODAL_PERIOD_TITLE}
				</Typography>
				<StyledFieldContainer>
					<DatePicker
						fullWidth
						label={SP_MODAL_PERIOD_START_DATE}
						onChange={(newValue) => handleDateChange('startDate', newValue)}
						value={values.startDate}
					/>
				</StyledFieldContainer>
				<Typography fontSize="1.063rem" fontWeight="bold">
					3. {SP_MODAL_LINES_TITLE}
				</Typography>
				<StyledFieldContainer>
					<MultipleSelect
						helperText={errors.lines}
						name="lines"
						label={SP_MODAL_LINES_IMPACTED}
						onChange={handleMultipleSelect}
						options={allLines}
						value={values.lines ?? []}
					/>
					<Typography variant="body2">{SP_MODAL_LINES_EXPLANATION}</Typography>
				</StyledFieldContainer>
			</StyledContentContainer>
		</>
	);
};

export default SpecialOperationFormContent;

type SpecialOperationFormContentProps = {
	errors: { [key: string]: string };
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleDateChange: any;
	setIsUntouched: Dispatch<SetStateAction<boolean>>;
	setValues: Dispatch<SetStateAction<SpecialOperationType>>;
	values: any;
};
