// prettier-ignore
import { RsService } from "../../components/modals/deleteServicePopIn";
import {
	CLOSE_DELETE_SERVICE_POPIN,
	DeleteServicePopInActionType,
	OPEN_DELETE_SERVICE_POPIN,
	SET_CHECKED_SERVICES_DELETE_SERVICES_POPIN,
	SET_SERVICE_END_DATE_DELETE_SERVICES_POPIN,
	SET_SERVICE_START_DATE_DELETE_SERVICES_POPIN,
	SET_SERVICE_TO_DELETE_DELETE_SERVICE_POPIN,
	SET_SERVICE_TRAIN_CODE_DELETE_SERVICES_POPIN,
	SET_SERVICE_TYPE_DELETE_SERVICES_POPIN,
	SET_SERVICES_DELETE_SERVICES_POPIN,
} from '../types';

export const openDeleteServicePopIn = (): DeleteServicePopInActionType => ({
	type: OPEN_DELETE_SERVICE_POPIN,
});
export const closeDeleteServicePopIn = (): DeleteServicePopInActionType => ({
	type: CLOSE_DELETE_SERVICE_POPIN,
});
export const setServicesDeleteServicesPopIn = (
	servicesList: RsService[]
): DeleteServicePopInActionType => ({
	payload: servicesList,
	type: SET_SERVICES_DELETE_SERVICES_POPIN,
});
export const setServiceStartDateDeleteServicesPopIn = (
	serviceStartDate: string
): DeleteServicePopInActionType => ({
	payload: serviceStartDate,
	type: SET_SERVICE_START_DATE_DELETE_SERVICES_POPIN,
});
export const setServiceEndDateDeleteServicesPopIn = (
	serviceEndDate: string
): DeleteServicePopInActionType => ({
	payload: serviceEndDate,
	type: SET_SERVICE_END_DATE_DELETE_SERVICES_POPIN,
});
export const setServiceTypeDeleteServicesPopIn = (
	serviceTypeId: string
): DeleteServicePopInActionType => ({
	payload: serviceTypeId,
	type: SET_SERVICE_TYPE_DELETE_SERVICES_POPIN,
});
export const setServiceTrainCodeDeleteServicesPopIn = (
	serviceTrainCode: string
): DeleteServicePopInActionType => ({
	payload: serviceTrainCode,
	type: SET_SERVICE_TRAIN_CODE_DELETE_SERVICES_POPIN,
});
export const setCheckedServicesDeleteServicesPopIn = (
	serviceIds: number[]
): DeleteServicePopInActionType => ({
	payload: serviceIds,
	type: SET_CHECKED_SERVICES_DELETE_SERVICES_POPIN,
});
export const setServiceToDeleteDeleteServicesPopIn = (
	serviceId: number
): DeleteServicePopInActionType => ({
	payload: serviceId,
	type: SET_SERVICE_TO_DELETE_DELETE_SERVICE_POPIN,
});

const DeleteServicesPopinActions = {
	closeDeleteServicePopIn,
	openDeleteServicePopIn,
	setCheckedServicesDeleteServicesPopIn,
	setServiceEndDateDeleteServicesPopIn,
	setServicesDeleteServicesPopIn,
	setServiceStartDateDeleteServicesPopIn,
	setServiceToDeleteDeleteServicesPopIn,
	setServiceTrainCodeDeleteServicesPopIn,
	setServiceTypeDeleteServicesPopIn,
};

export default DeleteServicesPopinActions;
