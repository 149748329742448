import { styled } from '@mui/system';

export const StyledCardContainer = styled('div')(() => ({
	border: `2px solid var(--bg-color-blue-light)`,
	width: '100%',
}));

export const StyledEndCardContainer = styled('div')(() => ({
	alignItems: 'center',
	backgroundColor: '#00AA91',
	borderBottomRightRadius: '13px',
	borderTopRightRadius: '13px',
	boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.8)',
	color: 'var(--text-color-white)',
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
}));

export const StyledSettingLogo = styled('div')(() => ({
	color: 'var(--text-color-white)',
	height: '32px',
	justifyContent: 'flex-end',
	paddingLeft: '10px',
	paddingRight: '10px',
	width: '32px',
}));
