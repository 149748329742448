import jwt from 'jsonwebtoken';

import { User } from '../../../models';
import appEnv from '../../configuration/appEnv';
import { localStorageFuncs } from '../../helpers';
import { cookieService } from '../../helpers/cookie';
import ApiService from '../apiTemplate/api';

import { Token } from './auth.api.types';

class AuthService extends ApiService {
	constructor() {
		super();
		this.credentials = 'include';
		this.endPoint = `${this.endPoint}/auth`;
		this.redirect = 'follow';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/json',
			},
			{
				key: 'Content-Type',
				value: 'application/json',
			},
		]);
	}

	/**
	 * Check le status de la connexion du compte avec le SSO
	 */
	public async checkStatusLoginSSO() {
		this.setMethod('GET');
		try {
			const request = await fetch(`${this.endPoint}/check-auth`, {
				credentials: 'include',
				method: 'GET',
			});
			return request.status === 200;
		} catch (err) {
			console.error(`Unable to connect due to --> ${err}`);
			return false;
		}
	}

	/**
	 * Retourne les informations utilisateur en fonction du token présent dans les cookies retourné par le SSO.
	 * @param cookies - cookies du navigateur
	 * @returns {User}
	 */
	public async checkLoginSSO() {
		try {
			const userToken = await cookieService.getCookie('authorization');

			const statusLoginSSO = await this.checkStatusLoginSSO();

			if (userToken && statusLoginSSO) {
				return this.getJwtToUser(userToken);
			} else {
				return null;
			}
		} catch (err) {
			throw Error(`login failed due to --> ${err}`);
		}
	}

	/**
	 * logout
	 */
	public async logout() {
		this.setMethod('GET');
		try {
			await fetch(`${this.endPoint}/logout`, {
				credentials: 'include',
				mode: 'no-cors',
			});
			localStorageFuncs.del('smtr-appEnv');
		} catch (err) {
			throw Error(`logout failed due to --> ${err}`);
		}
	}

	/**
	 * login
	 */
	public async login() {
		window.location.href = `${appEnv.backendUrl ?? '/api'}/auth/login`; // use localhost when testing sw
	}

	private getJwtToUser(token: string): User {
		const decoded = jwt.decode(token) as Token;
		if (!decoded?.payload) throw Error('Invalid token');
		return decoded.payload;
	}
}

export const authService = new AuthService();
