import { CSSProperties } from 'react';

import { ProgressBar } from '../../../packages/ui';

import './styles.css';

const ServiceCard = ({
	count,
	info,
	objective,
	objectiveLabelPrefix = '',
	periode,
	theoreticalObjectiveAtTime,
	title,
}: ServiceCardProps) => {
	const DANGER_LIMIT = 33;
	const WARNING_LIMIT = 66;

	const getPercentage = (
		count: number | undefined,
		objective: number,
		digits?: number
	): number => {
		if (typeof count === 'undefined' || count === 0 || objective === 0) {
			return 0;
		}
		if (count >= objective) {
			return 100;
		}
		return Number(((count / objective) * 100).toFixed(digits ?? 3));
	};

	const percentage = Math.ceil(getPercentage(count, objective));

	const theoreticalPercentage = getPercentage(
		theoreticalObjectiveAtTime,
		objective
	);

	const getColor = (): 'success' | 'warning' | 'error' => {
		if (percentage <= DANGER_LIMIT) return 'error';
		if (percentage > WARNING_LIMIT) return 'success';
		return 'warning';
	};

	const getStyle = (): CSSProperties => {
		const margin = percentage > 0 ? percentage + 2 : 4;
		return percentage <= DANGER_LIMIT
			? { marginLeft: `${margin}%  ` }
			: { display: 'none' };
	};

	const getCount = (): string => {
		const displayedCount =
			count > objective ? `${count}/${objective}` : `${count}`;
		if (percentage <= DANGER_LIMIT) return '';
		return `${displayedCount}`;
	};

	const getOffsetForAdvancement = (percentageRaw: number): string => {
		if (percentageRaw > 75) {
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			if (context) {
				context.font = '17px bold';
				const metrics = context.measureText(
					`Prévisionnel ${theoreticalObjectiveAtTime}`
				);
				const textWidth = metrics.width;
				return `calc(${percentageRaw}% - ${textWidth}px)`;
			}
		}
		return `${percentageRaw}%`;
	};

	return (
		<div data-testid="reporting-card" className="reporting-card">
			<div className="reporting-card__header">
				<div className="reporting-card-title-group">
					<div className="reporting-card-title font-paris-bold mr-2 ">
						{title}
					</div>
				</div>
				<div className="reporting-card-subtitle font-paris font-grey-lighter">
					{info}
				</div>
			</div>
			<div className="reporting-card__text">
				<div className="font-paris reporting-card-period">{periode}</div>
				<div className="font-paris">{`${objectiveLabelPrefix} ${objective}`}</div>
			</div>
			<div className="reporting-card-progress-bar">
				<p
					className="reporting-card-percentage font-paris-bold"
					style={getStyle()}
				>
					{count}
				</p>
				<ProgressBar
					progressColor={getColor()}
					value={percentage}
					label={getCount()}
				/>
				{theoreticalObjectiveAtTime && (
					<div className="reporting-card-advancement">
						<div
							className="reporting-card-advancement__text"
							style={{ left: getOffsetForAdvancement(theoreticalPercentage) }}
						>
							{`Prévisionnel ${theoreticalObjectiveAtTime}`}
						</div>
						<div
							className="reporting-card-advancement__bar"
							style={{ left: `${theoreticalPercentage}%` }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ServiceCard;

type ServiceCardProps = {
	count: number;
	info?: string;
	objective: number;
	objectiveLabelPrefix?: string;
	periode: string;
	theoreticalObjectiveAtTime?: number;
	title: string;
};
