import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { EXPORT_MODAL_TITLE } from '../../../packages/helpers/explanations/export';
import { ModalesState, useForm, useLine } from '../../../packages/hooks';
import { Dialog as MuiDialog, ValidationButtons } from '../../../packages/ui';
import { getExport } from '../../../redux/thunks/export';

import { getEmptyExport, getFormattedExport, validate } from './config';
import ExportContent from './content';

const Export = ({ handleCloseModal, open }: ExportProps) => {
	const dispatch = useDispatch();
	const { allLines, selectedLine } = useLine();
	const [rollingStockListIds, setRollingStockListIds] = useState<number[]>([]);
	const handleCreateExport = () => {
		const formattedValues = getFormattedExport(
			values,
			rollingStockListIds,
			allLines
		);
		dispatch(getExport(formattedValues));

		setValues(getEmptyExport(selectedLine));
		setErrors({});
		handleCloseModal('export');
	};

	const handleClose = () => {
		setValues(getEmptyExport(selectedLine));
		setErrors({});
		handleCloseModal('export');
	};

	const {
		errors,
		setErrors,
		handleCheck,
		handleSelect,
		handleSubmit,
		values,
		setValues,
	} = useForm({
		callback: handleCreateExport,
		initFormValues: getEmptyExport(selectedLine),
		validate,
	});
	return (
		<MuiDialog
			open={open}
			titleContent={EXPORT_MODAL_TITLE}
			content={
				<ExportContent
					errors={errors}
					handleCheck={handleCheck}
					handleSelect={handleSelect}
					rollingStockListIds={rollingStockListIds}
					setRollingStockListIds={setRollingStockListIds}
					values={values}
					setValues={setValues}
				/>
			}
			actionsContent={
				<ValidationButtons onCancel={handleClose} onValidate={handleSubmit} />
			}
			onClose={handleClose}
		/>
	);
};

export default Export;

type ExportProps = {
	open: boolean;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
};
