import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/button/arrow-back-blue.svg';
import { ModalesState } from '../../hooks';
import { Button, SingleButtonsBloc } from '..';

const PopInFooterWithGoBack = ({
	onValidate,
	onCancel,
	handleOpenModal,
	validateText = 'Valider',
	validateDisabled = false,
}: PopInFooterProps) => {
	return (
		<>
			<Button
				onClick={() => {
					onCancel();
					handleOpenModal('availabilitySelect');
				}}
				variant="text"
				style={{
					width: '7rem',
				}}
			>
				<ArrowBackIcon style={{ marginRight: '3px' }} />
				Retour
			</Button>
			<SingleButtonsBloc>
				<Button
					onClick={() => {
						onCancel();
					}}
					variant="text"
					style={{
						width: '7rem',
					}}
				>
					Annuler
				</Button>
				<Button
					disabled={validateDisabled}
					onClick={() => {
						onValidate();
					}}
					style={{
						width: '7rem',
					}}
				>
					{validateText}
				</Button>
			</SingleButtonsBloc>
		</>
	);
};

export default PopInFooterWithGoBack;

type PopInFooterProps = {
	onValidate: () => void;
	onCancel: () => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	validateText?: string;
	validateDisabled?: boolean;
};
