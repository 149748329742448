import { Chip as MuiChip } from '@mui/material';

import { ChipProps } from '../../../models/ui';

const Chip = ({
	label,
	color,
	variant = 'outlined',
	onClick,
	onDelete,
	disabled = false,
	sx,
	...props
}: ChipProps) => {
	return (
		<MuiChip
			label={label}
			color={color}
			variant={variant}
			onClick={onClick}
			onDelete={onDelete}
			disabled={disabled}
			sx={sx}
			{...props}
		/>
	);
};

export default Chip;
