import { useDispatch } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';

import { Availability } from '../../../../../models/availability.model';
import { Button, SingleButtonsBloc } from '../../../../../packages/ui';
import { WarningIcon } from '../../../../../packages/ui/icons';
import theme from '../../../../../packages/ui/theme';
import {
	deleteAndSetAvailability,
	updateAndSetAvailability,
} from '../../../../../redux/thunks/availability';

const ActionConfirmation = ({
	availabilityId,
	isDeletableMode,
	newAvailabilities,
	onCancel,
	onClose,
	selectedLine,
	rsId,
}: PopInFooterProps) => {
	const dispatch = useDispatch();

	const handleDelete = () => {
		dispatch(deleteAndSetAvailability(availabilityId, rsId));
		onCancel();
	};

	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

	const handleUpdate = () => {
		dispatch(
			updateAndSetAvailability(
				newAvailabilities,
				availabilityId,
				selectedLine,
				rsId
			)
		);
		onCancel();
	};

	return (
		<Grid container alignItems={'center'}>
			<Grid item xs={0} paddingLeft={2}>
				<WarningIcon sx={{ color: '#FF5A00' }} />
			</Grid>
			<Grid item xs={isPad ? 6.4 : 7.5} paddingLeft={1}>
				{isDeletableMode
					? 'Voulez-vous supprimer cette  mise à disposition '
					: 'Confirmez les modifications '}
				?
			</Grid>
			<Grid item paddingLeft={isPad || (isDeletableMode && !isPad) ? 3 : 12}>
				<SingleButtonsBloc
					style={{ paddingLeft: !isDeletableMode && isPad ? '20px' : '0' }}
				>
					<Button
						onClick={() => {
							onCancel();
						}}
						variant="text"
						style={{
							width: '8rem',
						}}
					>
						Annuler
					</Button>
					<Button
						onClick={() => {
							isDeletableMode ? handleDelete() : handleUpdate();
							onClose();
						}}
						style={{
							backgroundColor: isDeletableMode ? '#FF1400' : '#0A007D',
							borderColor: isDeletableMode ? '#FF1400' : '#0A007D',
							borderRadius: '8px',
							color: 'white',
						}}
					>
						{isDeletableMode ? 'Supprimer et fermer' : 'Confirmer '}
					</Button>
				</SingleButtonsBloc>
			</Grid>
		</Grid>
	);
};

export default ActionConfirmation;

type PopInFooterProps = {
	availabilityId: number;
	isDeletableMode: boolean;
	newAvailabilities: Availability[];
	onCancel: () => void;
	onClose: () => void;
	selectedLine: string;
	rsId: string | undefined;
};
