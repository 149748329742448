import { shallowEqual, useDispatch } from 'react-redux';

import { ServiceDTO, ServiceQuery } from '../../../packages/dataProviders';
import { DELETE, errorConf } from '../../../packages/helpers';
import {
	useConfirmationPopIn,
	useDeleteServicePopIn,
	useLine,
	useTypedSelector,
} from '../../../packages/hooks';
import {
	Dialog as MuiDialog,
	Modal,
	ValidationButtons,
} from '../../../packages/ui';
import { showPopup } from '../../../redux/actions';
import { closeConfirmationPopIn } from '../../../redux/actions/confirmationPopIn.actions';
import {
	setCheckedServicesDeleteServicesPopIn,
	setServicesDeleteServicesPopIn,
	setServiceToDeleteDeleteServicesPopIn,
} from '../../../redux/actions/deleteServicePopIn.actions';
import { deleteServices } from '../../../redux/thunks/service';
import { PopupState } from '../../../redux/types';

import ConfirmationContent from './Content/ConfirmationContent';
import DeleteServiceContent from './Content';

import './styles.css';

const CONFIRM_DELETE_ITEMS =
	'Etes-vous sûr de vouloir supprimer ces éléments ?';
const CONFIRM_DELETE_ITEM = 'Etes-vous sûr de vouloir supprimer cet élément ?';

const DeleteServicePopIn = () => {
	const dispatch = useDispatch();
	const { selectedLine } = useLine();

	const { getServicesByQuery } = useDeleteServicePopIn();
	const {
		checkedServicesTs,
		meta,
		serviceEndDateTs,
		serviceStartDateTs,
		servicesTs,
		serviceToDeleteTs,
		serviceTrainCodeTs,
		serviceTypeIdTs,
	} = useTypedSelector(({ deleteServicePopInState }) => {
		return {
			checkedServicesTs: deleteServicePopInState.checkedItems,
			meta: deleteServicePopInState.meta,
			serviceEndDateTs: deleteServicePopInState.serviceEndDate,
			serviceStartDateTs: deleteServicePopInState.serviceStartDate,
			servicesTs: deleteServicePopInState.services,
			serviceToDeleteTs: deleteServicePopInState.serviceToDelete,
			serviceTrainCodeTs: deleteServicePopInState.serviceTrainCode,
			serviceTypeIdTs: deleteServicePopInState.serviceTypeId,
		};
	}, shallowEqual);
	const { metaConfirmation } = useTypedSelector(
		({ confirmationPopInState }) => {
			return {
				metaConfirmation: confirmationPopInState.meta,
			};
		},
		shallowEqual
	);

	const { closeDeleteServicePopIn } = useDeleteServicePopIn();
	const { openConfirmationPopIn } = useConfirmationPopIn();

	const fetchServices = async (query: ServiceQuery, message: PopupState) => {
		const services = await getServicesByQuery(query);
		const convertedServices: RsService[] =
			services?.map((item) => {
				return { checked: false, service: item };
			}) ?? [];
		dispatch(setServicesDeleteServicesPopIn(convertedServices));
		dispatch(setCheckedServicesDeleteServicesPopIn([]));
		dispatch(setServiceToDeleteDeleteServicesPopIn(-1));
		dispatch(showPopup(message));
	};

	const onDeleteServices = () => {
		const successCb = () => {
			const successMessage =
				serviceToDeleteTs !== undefined
					? errorConf.success.serviceDeleted
					: errorConf.success.servicesDeleted;
			fetchServices(
				{
					dateEnd: serviceEndDateTs,
					dateStr: serviceStartDateTs,
					line: selectedLine,
					serviceTypeId: serviceTypeIdTs,
					trainCode: serviceTrainCodeTs,
				},
				successMessage
			).catch((error) => {
				console.error('An error occurred during the fetch operation:', error);
			});
		};

		const errorCb = () => {
			const errorMessage =
				serviceToDeleteTs !== undefined
					? errorConf.error.serviceNotDeleted
					: errorConf.error.servicesNotDeleted;
			dispatch(showPopup(errorMessage));
		};
		const servicesToDelete =
			serviceToDeleteTs !== undefined
				? [serviceToDeleteTs.service.id ?? 0]
				: checkedServicesTs;
		dispatch(deleteServices(servicesToDelete, serviceTypeIdTs, successCb, errorCb));
	};

	const handleClosePopIn = () => {
		closeDeleteServicePopIn();
	};

	const handleCloseConfirmation = () => {
		closeConfirmationPopIn();
	};

	const handleValidate = () => {
		dispatch(setServiceToDeleteDeleteServicesPopIn(-1));
		openConfirmationPopIn();
	};

	const getCheckedServices = () => {
		return serviceToDeleteTs !== undefined
			? [serviceToDeleteTs]
			: servicesTs.filter((item) => item.checked);
	};

	return (
		<>
			<MuiDialog
				open={meta.open}
				titleContent="Supprimer une prestation"
				onClose={handleClosePopIn}
				content={
					<DeleteServiceContent
						lineId={selectedLine}
						onValidate={handleValidate}
					/>
				}
				actionsContent={
					<ValidationButtons
						onCancel={closeDeleteServicePopIn}
						onValidate={handleValidate}
						validateDisabled={checkedServicesTs.length === 0}
						validateText={DELETE}
					/>
				}
			/>
			<Modal
				open={metaConfirmation.open}
				headerContent={
					serviceToDeleteTs !== undefined
						? CONFIRM_DELETE_ITEM
						: CONFIRM_DELETE_ITEMS
				}
				content={
					<ConfirmationContent
						listItems={getCheckedServices()}
						onValidate={onDeleteServices}
					/>
				}
				onClose={handleCloseConfirmation}
			/>
		</>
	);
};

export default DeleteServicePopIn;

export type RsService = {
	checked: boolean;
	service: ServiceDTO;
};
