import { SemPassages } from '../../components/sem/constants';
import {
	DepartmentEnum,
	EspaceEnum,
	HistoryDefaultIncidentsSelection,
	HistoryDefaultSelectedLinesSelection,
	HistoryDefaultServicesSelection,
	HistoryDefaultTrainUsageSelection,
	IncidentTypeEnum,
	ServiceStatus,
	ServiceTypeEnum,
} from '../../models';
import {
	defaultDayNightServices,
	defaultNotificationIncidents,
	defaultNotificationServices,
	RSDisplayTypeEnum,
} from '../../models/setting.model';
import { localStorageFuncs } from '../../packages/helpers';
import {
	ApiState,
	AppStore,
	ConfirmationPopInState,
	CredentialsState,
	DeleteServicePopInState,
	HistoryPopInState,
	LineState,
	NotificationPopInState,
	PassageState,
	PopupState,
	ReportingState,
	RightByTypeState,
	RightState,
	RolesState,
	RollingStockState,
	ServiceState,
	ServiceTypeState,
	SettingState,
	StationDetailPopInState,
	UsedRsReportState,
	UserState,
} from '../types';
import { EspaceState } from '../types/espace.types';
import { IncidentTypeState } from '../types/incidentType.type';
import { SpecialOperationTypeState } from '../types/specialOperationType.types';
import { StationState } from '../types/station.types';

const { MTS, RER, SEM } = DepartmentEnum;
const TODAY_DATE = new Date();

export const initApiState: ApiState = {
	credentials: {
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	incidentReport: {
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	incidentTypes: {
		isError: false,
		isLoaded: false,
	},
	lines: {
		isError: false,
		isLoaded: false,
	},
	right: {
		errorInfo: undefined,
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	rightType: {
		errorInfo: undefined,
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	roles: {
		isError: false,
		isLoaded: false,
	},
	rollingstock: {
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	rollingstockused: {
		errorInfo: undefined,
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	services: {
		isError: false,
		isLoaded: false,
		isUpdated: false,
	},
	serviceTypes: {
		isError: false,
		isLoaded: false,
	},
};

export const initRollingStockState: RollingStockState = {
	filter: {
		code: undefined,
		status: undefined,
	},
	lastServiceRemovalDate: Date.now(),
	lines: [],
	rollingStocks: [],
	statusCounts: {
		degrafed: 0,
		toClean: 0,
		total: 0,
		used: 0,
	},
};

export const initRollingStockUsedState: UsedRsReportState = {
	total: 0,
	usedCount: 0,
	usedRs: [
		{
			id: 0,
			isUsed: true,
			lastUpdateDate: new Date(),
			trainCode: '',
		},
	],
};

const { NC, NP, NCR, MEP, NQ, PS, PBS } = ServiceTypeEnum;
const { PB, DI, GR } = IncidentTypeEnum;
const { PASSAGE_1 } = SemPassages;

export const defaultServiceStatus = [
	{
		departments: [MTS, RER, SEM],
		isDisabled: false,
		name: 'Nettoyage Pare-Brise',
		status: ServiceStatus.ENABLE,
		type: PBS,
	},
	{
		departments: [MTS, RER, SEM],
		isDisabled: false,
		name: 'Nettoyage Courant',
		status: ServiceStatus.ENABLE,
		type: NC,
	},
	{
		departments: [MTS, RER, SEM],
		isDisabled: false,
		name: 'Nettoyage Patrimonial',
		status: ServiceStatus.ENABLE,
		type: NP,
	},
	{
		departments: [MTS, RER],
		isDisabled: false,
		name: 'Nettoyage Courant Renforcé',
		status: ServiceStatus.ENABLE,
		type: NCR,
	},
	{
		departments: [MTS, RER, SEM],
		isDisabled: false,
		name: 'Maintien En Propreté',
		status: ServiceStatus.ENABLE,
		type: MEP,
	},
	{
		departments: [SEM],
		isDisabled: false,
		name: 'Nettoyage Quotidien',
		status: ServiceStatus.ENABLE,
		type: NQ,
	},
	{
		departments: [SEM],
		isDisabled: false,
		name: 'Prestation Supplémentaire',
		status: ServiceStatus.ENABLE,
		type: PS,
	},
];

export const initServiceState: ServiceState = {
	equipment: '',
	incidents: [],
	services: defaultServiceStatus,
	specialOperations: [],
	trainCode: '',
	trainId: '',
};

export const initReportingState: ReportingState = {
	incidents: [
		{ monthCount: 0, type: DI, yearCount: 0 },
		{ monthCount: 0, type: GR, yearCount: 0 },
		{ monthCount: 0, type: PB, yearCount: 0 },
	],
	periode: '',
	rsCount: 0,
	selectedLine: '',
	services: [
		{ monthCount: 0, type: NC, yearCount: 0 },
		{ monthCount: 0, type: NCR, yearCount: 0 },
		{ monthCount: 0, type: NP, yearCount: 0 },
		{ monthCount: 0, type: NQ, yearCount: 0 },
		{ monthCount: 0, type: PS, yearCount: 0 },
	],
	unitCount: {
		normalUnitCount: 0,
		spTerminusUnitCount: 0,
		spUnitCount: 0,
		terminusUnitCount: 0,
	},
	unitsStats: { monthStats: [], yearStats: [] },
};

export const initCredentialsState: CredentialsState = {
	id: undefined,
	user: undefined,
	users: [],
};

export const initLineState: LineState = {
	lines: [],
	selectedLine: localStorageFuncs.get('selected-line') ?? '',
};

export const initPassageState: PassageState = {
	selectedPassage:
		parseInt(localStorageFuncs.get('selected-passage') ?? '', 10) || PASSAGE_1,
};

export const initRoleState: RolesState = {
	roles: [],
};

export const initServiceTypeState: ServiceTypeState = {
	serviceTypesCreate: [],
	serviceTypesDelete: [],
	serviceTypesExport: [],
};

export const initUserState: UserState = {
	authenticated: false,
	user: undefined,
};

export const initPopupState: PopupState = {
	hasBackground: true,
	isClosable: true,
	isVisible: false,
	message: undefined,
	popupType: undefined,
	title: undefined,
};

export const initIncidentTypeState: IncidentTypeState = {
	incidentTypesCreate: [],
	incidentTypesDelete: [],
	incidentTypesExport: [],
	incidentTypesSolve: [],
};

export const initDeleteServicePopInState: DeleteServicePopInState = {
	checkedItems: [],
	meta: {
		open: false,
		validated: false,
	},
	serviceEndDate: new Date(
		TODAY_DATE.getFullYear(),
		TODAY_DATE.getMonth(),
		TODAY_DATE.getDate(),
		TODAY_DATE.getHours(),
		TODAY_DATE.getMinutes(),
		0
	).toISOString(),
	services: [],
	serviceStartDate: new Date(
		TODAY_DATE.getFullYear(),
		TODAY_DATE.getMonth() - 1,
		TODAY_DATE.getDate(),
		TODAY_DATE.getHours(),
		TODAY_DATE.getMinutes(),
		0
	).toISOString(),

	serviceToDelete: undefined,
	serviceTrainCode: '',
	serviceTypeId: '',
};

export const initNotificationPopInState: NotificationPopInState = {
	confirmTxt: '',
	open: false,
	queue: [],
	rsCodes: [],
	subTitle: '',
	title: '',
	type: undefined,
};

export const initHistoryPopInState: HistoryPopInState = {
	endDate: new Date(),
	itemsFilter: [],
	meta: {
		open: false,
		validated: false,
	},
	selectedLines: HistoryDefaultSelectedLinesSelection,
	servicesSelection: [
		...HistoryDefaultServicesSelection,
		...HistoryDefaultIncidentsSelection,
		HistoryDefaultTrainUsageSelection,
	],
	startDate: new Date(),
	usersProfilFilter: [],
};

export const initSettingState: SettingState = {
	dayNightIncidents: [],
	dayNightServices: defaultDayNightServices,
	line: '',
	malHighLimit: 0,
	malLowLimit: 0,
	ncrTarget: 0,
	ncTarget: 0,
	notificationIncidents: defaultNotificationIncidents,
	notificationServices: defaultNotificationServices,
	npHighLimit: 0,
	npLowLimit: 0,
	npTarget: 0,
	rollingStockDisplayType: RSDisplayTypeEnum.BY_CODE,
	status: false,
};

export const initRightState: RightState = { rights: [] };

export const initRightByTypeState: RightByTypeState = [];

export const initStationState: StationState = {
	stations: [],
};

export const initEspace: EspaceState = {
	espace: EspaceEnum.ESPACE_5,
};

export const initStationDetailPopInState: StationDetailPopInState = {
	meta: {
		open: false,
	},
	passage: 1,
	stationId: 0,
	stationName: '',
	units: [],
};

export const initConfirmationPopInState: ConfirmationPopInState = {
	meta: {
		open: false,
		validated: false,
	},
};

export const initSpecialOperationTypeState: SpecialOperationTypeState = {
	opInProgress: [],
	opReporting: [],
};

export const initStore: AppStore = {
	apiState: initApiState,
	credentialsState: initCredentialsState,
	historyPopInState: initHistoryPopInState,
	incidentTypeState: initIncidentTypeState,
	lineState: initLineState,
	notificationPopInState: initNotificationPopInState,
	passageState: initPassageState,
	popupState: initPopupState,
	reportingState: initReportingState,
	rightByTypeState: initRightByTypeState,
	rightState: initRightState,
	roleState: initRoleState,
	rollingStockState: initRollingStockState,
	rollingStockUsedState: initRollingStockUsedState,
	serviceState: initServiceState,
	serviceTypeState: initServiceTypeState,
	settingState: initSettingState,
	specialOperationTypeState: initSpecialOperationTypeState,
	stationDetailPopInState: initStationDetailPopInState,
	stationState: initStationState,
	userState: initUserState,
};

export default initStore;
