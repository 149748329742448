export const MAL = 'mal';
export const NC = 'nc';
export const NCR = 'ncr';
export const NP = 'np';
export const PBS = 'pbs';

export const NP_LABEL = 'Nettoyage patrimonial';
export const DF_LABEL = 'Dégraffitage';

export const MULTIPLE_AVAILABILITY = 'Mise à disposition multiple';

export const NP_DELTA_TIME = 'NpDeltaTime';
export const NC_DELTA_TIME = 'NcDeltaTime';
export const NCR_DELTA_TIME = 'NcrDeltaTime';
export const MAL_DELTA_TIME = 'MalDeltaTime';
export const PBS_DELTA_TIME = 'PbsDeltaTime';

export const DOWN = 'down';
export const UP = 'up';

export const MAX_PAD_WIDTH = 992;
export const MAX_MOBILE_WIDTH = 576;

// Colors
export const DANGER = 'danger';
export const DEFAULT = 'default';
export const ERROR = 'error';
export const INFO = 'info';
export const INHERIT = 'inherit';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const SUCCESS = 'success';
export const WARNING = 'warning';

// Actions
export const DELETE = 'Supprimer';
export const ADD = 'Ajouter';
export const CANCEL = 'Annuler';
export const EDIT = 'Modifier';
export const RETURN = 'Retour';
export const VALIDATE = 'Valider';
