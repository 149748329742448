import { Right } from '../../models/right.model';

export interface RightState  {
	rights: Right [];
}

export const SET_RIGHT = 'SET_RIGHT';
export const UPDATE_RIGHT = 'UPDATE_RIGHT';

export interface SetRightAction {
	type: typeof SET_RIGHT;
	payload: { state: Right[] };
}

export interface UpdateRight {
	type: typeof UPDATE_RIGHT;
	payload : {state: Right};
}

export type RightActionTypes = 

|SetRightAction
|UpdateRight;