import { useLocation } from 'react-router-dom';

import { useDepartment, useLine } from '../../../packages/hooks';
import LineSvgPrintable from '../../../packages/ui/icons/LineSvgPrintable';
import { Endpoints } from '../../../routes/endpoint.config';

import './styles.css';

const Logo = ({ size = 'medium' }: LogoProps) => {
	const { HABILITATION, RIGHT, OP } = Endpoints;
	const { selectedLine } = useLine();
	const { hasRerDepartment, department } = useDepartment();
	const { pathname } = useLocation();

	const isGenericPage =
		pathname === HABILITATION || pathname === RIGHT || pathname === OP;

	const getLogoCssClass = (): string => {
		let cssClass = `logo-out-${size} round bg-grey p-1`;
		if (selectedLine) {
			cssClass += 'font-paris-bold';
		} else {
			cssClass += 'font-grover';
		}
		if (hasRerDepartment) cssClass += ` square-logo-out`;
		return cssClass;
	};

	return (
		<div className={getLogoCssClass()}>
			<div className={`svg-line-container-${size} `}>
				<LineSvgPrintable
					line={isGenericPage ? department ?? '' : selectedLine}
				/>
			</div>
		</div>
	);
};

export default Logo;

interface LogoProps {
	size?: 'small' | 'medium' | 'large';
}
