import { PopupType } from '../../models/popup.model';

export interface PopupState {
  isVisible?: boolean;
  message?: string;
  title?: string;
  hasBackground?: boolean;
  isClosable?: boolean;
  popupType?: PopupType;
}

export const HIDE = 'HIDE';
export const SHOW = 'SHOW';

export interface Hide {
  type: typeof HIDE;
}

export interface Show {
  type: typeof SHOW;
  payload: PopupState;
}

export type PopupActionTypes = Hide | Show;
