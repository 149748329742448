import { AlertTypeEnum } from '../../packages/events/types';

export type NotificationItem = {
  title: string;
  subTitle: string;
  rsCodes: string[];
  confirmTxt: string;
  type: AlertTypeEnum | undefined;
};

export type NotificationPopInState = NotificationItem & {
  open: boolean;
  queue: NotificationItem[];
};

export const CLOSE_NOTIFICATION_POPIN = 'CLOSE_NOTIFICATION_POPIN';
export const OPEN_NOTIFICATION_POPIN = 'OPEN_NOTIFICATION_POPIN';
export const ADD_TO_QUEUE_NOTIFICATION_POPIN = 'ADD_TO_QUEUE_NOTIFICATION_POPIN';

export interface CloseNotificationPopInAction {
  type: typeof CLOSE_NOTIFICATION_POPIN;
}
export interface OpenNotificationPopInAction {
  type: typeof OPEN_NOTIFICATION_POPIN;
  payload: NotificationItem;
}
export interface AddToNotificationQueuePopInAction {
  type: typeof ADD_TO_QUEUE_NOTIFICATION_POPIN;
  payload: NotificationItem;
}
export type NotificationPopInActionTypes =
  | CloseNotificationPopInAction
  | OpenNotificationPopInAction
  | AddToNotificationQueuePopInAction;
