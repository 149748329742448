import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { ServiceQuery } from '../../../../packages/dataProviders';
import {
	useDeleteServicePopIn,
	useServiceType,
	useTypedSelector,
} from '../../../../packages/hooks';
import {
	setCheckedServicesDeleteServicesPopIn,
	setServiceEndDateDeleteServicesPopIn,
	setServicesDeleteServicesPopIn,
	setServiceStartDateDeleteServicesPopIn,
	setServiceToDeleteDeleteServicesPopIn,
	setServiceTrainCodeDeleteServicesPopIn,
	setServiceTypeDeleteServicesPopIn,
} from '../../../../redux/actions/deleteServicePopIn.actions';
import { RsService } from '..';

import DeleteServiceFilters from './filters';
import CustomTable from './itemsTable';

import './styles.css';

const DeleteServiceContent = ({ lineId, onValidate }: DeleteServiveProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [endDate, setEndDate] = useState(new Date());

	const dispatch = useDispatch();
	const { getServicesByQuery } = useDeleteServicePopIn();
	const { serviceTypesDelete } = useServiceType();

	const { serviceStartDateTs, serviceTypeIdTs, serviceTrainCodeTs } =
		useTypedSelector(({ deleteServicePopInState }) => {
			return {
				serviceEndDateTs: endDate.toISOString(),
				serviceStartDateTs: deleteServicePopInState.serviceStartDate,
				serviceTrainCodeTs: deleteServicePopInState.serviceTrainCode,
				serviceTypeIdTs: deleteServicePopInState.serviceTypeId,
			};
		});

	const fetchServices = async (query: ServiceQuery) => {
		setIsLoading(true);
		const services = await getServicesByQuery(query);
		const convertedServices: RsService[] =
			services?.map((item) => {
				return { checked: false, service: item };
			}) ?? [];
		dispatch(setServicesDeleteServicesPopIn(convertedServices));
		dispatch(setCheckedServicesDeleteServicesPopIn([]));
		dispatch(setServiceToDeleteDeleteServicesPopIn(-1));
		setIsLoading(false);
	};

	const onStartDateChange = (startDate: string) => {
		dispatch(setServiceStartDateDeleteServicesPopIn(startDate));
	};

	const onEndDateChange = (endDate: string) => {
		dispatch(setServiceEndDateDeleteServicesPopIn(endDate));
	};

	const onServiceChange = (serviceType: string) => {
		dispatch(setServiceTypeDeleteServicesPopIn(serviceType));
	};

	const onTrainCodeChange = (trainCode: string) => {
		dispatch(setServiceTrainCodeDeleteServicesPopIn(trainCode));
	};

	const applyFilters = () => {
		fetchServices({
			dateEnd: endDate.toISOString(),
			dateStr: serviceStartDateTs,
			line: lineId,
			serviceTypeId: serviceTypeIdTs,
			trainCode: serviceTrainCodeTs,
		}).catch((error) => {
			console.error('An error occurred during the fetch operation:', error);
		});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<DeleteServiceFilters
					onEndDateChange={onEndDateChange}
					onSearch={applyFilters}
					onServiceChange={onServiceChange}
					onStartDateChange={onStartDateChange}
					onTrainCodeChange={onTrainCodeChange}
					services={serviceTypesDelete}
					setDateFin={setEndDate}
				/>
			</Grid>

			<Grid item xs={12}>
				<CustomTable isLoading={isLoading} onValidate={onValidate} />
			</Grid>
		</Grid>
	);
};

export default DeleteServiceContent;

type DeleteServiveProps = {
	lineId: string;
	onValidate: () => void;
};
