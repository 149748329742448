import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ServiceTypeEnum, SurfaceTypeEnum, Unit } from '../../../models';
import { ServiceDTO } from '../../../packages/dataProviders';
import { mobileCheck } from '../../../packages/helpers';
import { useTypedSelector } from '../../../packages/hooks';
import { Modal } from '../../../packages/ui';
import { closeStationDetailPopIn } from '../../../redux/actions';
import { getStationsByLine } from '../../../redux/thunks/station';
import { createServiceAndReloadUnits } from '../../../redux/thunks/stationDetailPopIn';
import { StationDetailPopInState } from '../../../redux/types';
import { SemPassages } from '../../sem/constants';

import StationDetailPopInRow from './detailsRow';

import './styles.css';

const { PASSAGE_4 } = SemPassages;

const StationDetailPopIn = () => {
	const dispatch = useDispatch();

	const stationDetailPopInState: StationDetailPopInState = useTypedSelector(
		(globalState) => globalState.stationDetailPopInState
	);
	const passage = stationDetailPopInState.passage;
	const selectedLine = useTypedSelector(
		(state) => state.lineState.selectedLine
	);

	const closePopIn = () => {
		dispatch(getStationsByLine(selectedLine));
		dispatch(closeStationDetailPopIn());
	};

	const onConfirm = (unit: Unit) => {
		const serviceId =
			passage === PASSAGE_4 ? ServiceTypeEnum.PS : ServiceTypeEnum.NQ;
		const service: ServiceDTO = {
			date: new Date(),
			lineId: unit.line,
			sequenceId: unit.sequence,
			serviceTypeId: serviceId,
			stationId: unit.stationId,
			surfaceTypeId: SurfaceTypeEnum.SGS,
		};
		if (passage) {
			dispatch(
				createServiceAndReloadUnits(unit.stationId, unit.line, service, passage)
			);
		}
	};

	const headerLabel = useMemo((): string => {
		let label = stationDetailPopInState.stationName;
		label = mobileCheck()
			? `${label} - P${passage}`
			: `${label} - Passage ${passage}`;
		return label;
	}, [passage, stationDetailPopInState]);

	return (
		<Modal
			content={
				<div className="station-detail-table__container">
					<div className="station-detail-table-title__status">Statut</div>
					<div className="station-detail-table-title__unit">Unité</div>
					<div className="station-detail-table-title__end">Fin</div>
					<div className="station-detail-table__cards">
						{stationDetailPopInState.units?.map((unit) => (
							<StationDetailPopInRow
								key={unit.name}
								onValidate={onConfirm}
								passage={passage}
								unit={unit}
							/>
						))}
					</div>
				</div>
			}
			headerContent={headerLabel}
			onClose={closePopIn}
			open={stationDetailPopInState.meta.open}
		/>
	);
};

export default StationDetailPopIn;
