import { ThunkAction } from 'redux-thunk';

import { ExceptionDTO, PopupType } from '../../models';
import { Availability } from '../../models/availability.model';
import AvailabilityProvider from '../../packages/dataProviders/resources/availability';
import { errorConf, exceptionDTOtoPopupState } from '../../packages/helpers';
import { NotifyCode } from '../../packages/helpers/popup/exception.config';
import {
	deleteRollingStockAvailability,
	updateRollingStockAvailability,
} from '../actions/rollingStock.actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { AVAILABILITY } = components;

type AvailabilityThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

const getFeedBackMessageCreate = (incidentSelectedName: string) => {
	let message = NotifyCode.INF_FUNC_008 as string;
	message = message.replace('?', incidentSelectedName);

	return exceptionDTOtoPopupState(message, PopupType.SUCCESS, false, false);
};

const getFeedBackMessageUpdate = (rsId: string | undefined) => {
	let message = NotifyCode.INF_FUNC_013 as string;
	message = message.replace('?', rsId ?? '');

	return exceptionDTOtoPopupState(message, PopupType.SUCCESS, false, false);
};

const getFeedBackMessageDelete = (rsId: string | undefined) => {
	let message = NotifyCode.INF_FUNC_014 as string;
	message = message.replace('?', rsId ?? '');

	return exceptionDTOtoPopupState(message, PopupType.SUCCESS, false, false);
};

export const createAvailability =
	(
		availabilities: Availability[],
		line: string,
		incidentSelectedName: string
	): AvailabilityThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(AVAILABILITY));
			res = await AvailabilityProvider.createAvailabilities(
				availabilities,
				line
			);

			if (res.ok) {
				dispatch(api._response(AVAILABILITY));
				const newAvailability = res.data;
				if (Array.isArray(newAvailability)) {
					newAvailability.forEach((availability) => {
						dispatch(
							updateRollingStockAvailability(availability as Availability)
						);
					});
				}
				dispatch(
					api.showFeedBack(getFeedBackMessageCreate(incidentSelectedName))
				);
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(AVAILABILITY, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const deleteAndSetAvailability =
	(availabilityId: number, rsId: string | undefined): AvailabilityThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(AVAILABILITY));
			res = await AvailabilityProvider.deleteAvailability(availabilityId);

			if (res.ok) {
				dispatch(api._response(AVAILABILITY));
				dispatch(deleteRollingStockAvailability(availabilityId));
				dispatch(api.showFeedBack(getFeedBackMessageDelete(rsId)));
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(AVAILABILITY, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const updateAndSetAvailability =
	(
		availabilities: Availability[],
		availabilityId: number,
		line: string,
		rsId: string | undefined
	): AvailabilityThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(AVAILABILITY));
			res = await AvailabilityProvider.updateAvailabilities(
				availabilities,
				availabilityId,
				line
			);

			if (res.ok) {
				dispatch(api._response(AVAILABILITY));
				const newAvailability = res.data;
				dispatch(
					updateRollingStockAvailability(newAvailability as Availability)
				);
				dispatch(api.showFeedBack(getFeedBackMessageUpdate(rsId)));
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(AVAILABILITY, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};
