import { FormControlLabel, Radio } from '@mui/material';

import { SyledFormControl, SyledRadioCard, SyledRadioGroup } from './styles';

const RadioCardGroup = ({ data, onChange }: RadioCardGroupProps) => {
	return (
		<SyledFormControl>
			<SyledRadioGroup
				aria-labelledby="demo-radio-buttons-group-label"
				defaultValue="radio"
				name="radio-buttons-group"
				onChange={(e) => {
					return onChange((e.target as HTMLInputElement).value);
				}}
			>
				{data.map(({ name, id }) => (
					<SyledRadioCard key={id}>
						<FormControlLabel value={id} control={<Radio />} label={name} />
					</SyledRadioCard>
				))}
			</SyledRadioGroup>
		</SyledFormControl>
	);
};

export default RadioCardGroup;

type RadioCardGroupProps = {
	data: RadioCardGroupPropsData[];
	onChange: (e: string) => void;
};

export type RadioCardGroupPropsData = {
	checked: boolean;
	id: number;
	name: string;
};
