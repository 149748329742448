import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import Header from '../../components/header';
import LineSection from '../../components/usedRS/lineSection';
import UsedRollingStockList from '../../components/usedRS/list';
import { UsedRs } from '../../models/usedRs.model';
import { useDepartment } from '../../packages/hooks/useDepartment';
import { useRsUsed } from '../../packages/hooks/useRsUsed';

const UsedRsPage = () => {
	const [usedRsFiltered, setUsedRsFiltered] = useState<UsedRs[]>([]);
	const { rsLabelPluralize } = useDepartment();
	const { stockCountText, usedRs } = useRsUsed();

	const handleFilterChange = (value: string) => {
		setUsedRsFiltered(
			usedRs.filter(({ trainCode }) => trainCode.includes(value))
		);
	};

	useEffect(() => {
		setUsedRsFiltered(usedRs);
	}, [usedRs]);

	return (
		<main>
			<Header title={`${rsLabelPluralize} utilisés`} />
			<div className="content" data-testid="used-train-page">
				<Grid container spacing={2} justifyContent="space-between">
					<Grid item lg={3} md={4} xs={12}>
						<LineSection
							stockCountText={stockCountText}
							handleFilter={handleFilterChange}
						/>
					</Grid>
					<Grid item md={8} xs={12}>
						<UsedRollingStockList usedRs={usedRsFiltered} />
					</Grid>
				</Grid>
			</div>
		</main>
	);
};

export default UsedRsPage;
