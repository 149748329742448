import { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { openStationDetailPopIn } from '../../../../redux/actions';
import { loadStationUnits } from '../../../../redux/thunks/stationDetailPopIn';
import SemStatus, { StatusEnum } from '../SemStatus/SemStatus';

import './SemCard.css';

type Props = {
	status: string;
	name: string;
	stationId: number;
	line: string;
	passage: number;
	totalDone: string;
	totalUnits: string;
	latestStationServicedDate: string;
};

const SemCard: FC<Props> = ({
	status,
	name,
	stationId,
	line,
	passage,
	totalDone,
	totalUnits,
	latestStationServicedDate,
}): ReactElement => {
	const dispatch = useDispatch();

	const openPopIn = () => {
		dispatch(loadStationUnits(stationId, name, line, passage));
		dispatch(openStationDetailPopIn(name, stationId));
	};

	const getServiceDate = () => {
		if (latestStationServicedDate && status === StatusEnum.DONE) {
			return format(new Date(latestStationServicedDate), 'HH:mm');
		}

		return '-';
	};

	return (
		<section
			className={'sem-card-container'}
			onClick={openPopIn}
			onKeyDown={openPopIn}
			role="button"
			tabIndex={0}
		>
			<div className={'sem-card__status'}>
				<SemStatus status={status} />
			</div>
			<div className={'sem-card__station'}>{name}</div>
			<div className={'sem-card__unit'}>
				{totalDone}/{totalUnits}
			</div>
			<div className={'sem-card__end'}>{getServiceDate()}</div>
		</section>
	);
};

export default SemCard;
