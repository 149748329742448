// prettier-ignore
import { CLOSE_CONFIRMATION_POPIN,ConfirmationPopInActionType,OPEN_CONFIRMATION_POPIN } from "../types";

export const openConfirmationPopIn = (): ConfirmationPopInActionType => ({
	type: OPEN_CONFIRMATION_POPIN,
});
export const closeConfirmationPopIn = (): ConfirmationPopInActionType => ({
	type: CLOSE_CONFIRMATION_POPIN,
});

const ConfirmationPopinActions = {
	closeConfirmationPopIn,
	openConfirmationPopIn,
};

export default ConfirmationPopinActions;
