import stainReminderHandler from './handlers/StainReminderHandler';
import {
	degrafReminderHandler,
	incidentDeleteHandler,
	incidentResolveHandler,
	keepAliveHandler,
	npReminderHandler,
	ServiceDeleteHandler,
	ServiceUpdateHandler,
	tagReminderHandler,
	unitUpdateHandler,
	usedStatusUpdateHandler,
	windshieldReminderHandler,
} from './handlers';
import { Handlers } from './types';

export const handlers: Handlers = {
	degrafReminder: (e: MessageEvent) => {
		degrafReminderHandler(e);
	},

	incidentDelete: (e: MessageEvent) => {
		incidentDeleteHandler(e);
	},

	incidentResolve: (e: MessageEvent) => {
		incidentResolveHandler(e);
	},

	keepAlive: () => {
		keepAliveHandler();
	},

	npReminder: (e: MessageEvent) => {
		npReminderHandler(e);
	},

	serviceDelete: (e: MessageEvent) => {
		ServiceDeleteHandler(e);
	},

	serviceUpdate: (e: MessageEvent) => {
		ServiceUpdateHandler(e);
	},

	stainReminder: (e: MessageEvent) => {
		stainReminderHandler(e);
	},

	tagReminder: (e: MessageEvent) => {
		tagReminderHandler(e);
	},

	unitUpdate: (e: MessageEvent) => {
		unitUpdateHandler(e);
	},

	usedStatusUpdate: (e: MessageEvent) => {
		usedStatusUpdateHandler(e);
	},

	windshieldReminder: (e: MessageEvent) => {
		windshieldReminderHandler(e);
	},
};
