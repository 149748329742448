import { styled } from '@mui/system';

export const StyledCardContainer = styled('div')<{
	isToday: boolean;
}>(({ isToday }) => ({
	border: `2px solid ${
		isToday ? 'var(--bg-color-blue-light)' : 'var(--bg-color-green-dark)'
	}`,
	borderRadius: '16px',
	width: '100%',
}));

export const StyledCardHeader = styled('div')<{
	isToday: boolean;
}>(({ isToday }) => ({
	alignItems: 'center',
	backgroundColor: isToday
		? 'var(--bg-color-blue-light)'
		: 'var(--bg-color-green-dark)',
	borderTopLeftRadius: '13px',
	borderTopRightRadius: '13px',
	boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.8)',
	color: 'var(--text-color-white)',
	display: 'flex',
	fontSize: '24px',
	height: '74px',
	paddingLeft: '15px',
}));
