import {
	Box,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select as MuiSelect,
} from '@mui/material';

import { MultipleSelectProps } from '../../../models/ui';
import { getUniqueKey } from '../../helpers';

const MultipleSelect = ({
	label,
	helperText = '',
	name,
	placeholder = '',
	onChange,
	options,
	value,
}: MultipleSelectProps) => {
	return (
		<FormControl size="small" sx={{ m: 1, width: 300 }}>
			<InputLabel id="multiple-chip-label">{label}</InputLabel>
			<MuiSelect
				key={label}
				placeholder={placeholder}
				label={label.toString()}
				labelId="multiple-chip-label"
				id="multiple-chip"
				name={name ?? label}
				multiple
				value={value ?? ''}
				onChange={onChange}
				input={<OutlinedInput id="select-multiple-chip" label={label} />}
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selected.map((value) => (
							<Chip key={value} label={value} />
						))}
					</Box>
				)}
			>
				{options?.map((option) => (
					<MenuItem key={getUniqueKey(option)} value={option}>
						{option}
					</MenuItem>
				))}
			</MuiSelect>
			<FormHelperText error>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default MultipleSelect;
