import { Link } from 'react-router-dom';

import { Button } from '../../../packages/ui';
import { Endpoints } from '../../../routes/endpoint.config';

import { StyledSpecialOperationRsImpactedFooter } from './styles';

const Footer = () => {
	return (
		<StyledSpecialOperationRsImpactedFooter>
			<Link to={Endpoints.OP}>
				<Button variant="contained">Revenir à la page de gestion</Button>
			</Link>
		</StyledSpecialOperationRsImpactedFooter>
	);
};

export default Footer;
