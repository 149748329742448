export const DASHBOARD_TITLE = 'Tableau de bord';
export const USED_ROLLINGSTOCKS = 'Etat du parc utilisé';
export const ROLLINGSTOCKS_BY_PERIOD = 'Etat du parc sur une période';

// Instant Dashboard
export const DASHBOARD_INCIDENT_CARD_TITLE = 'Anomalies à traiter';
export const INCIDENT_TYPE = "Type d'anomalie :";
export const INCIDENT_PB_NAME = 'Pare-brise sale';
export const INCIDENT_DI_NAME = 'Signalement intérieur';
export const DASHBOARD_GRAFFITI_CARD_TITLE = 'Graffiti à traiter';
