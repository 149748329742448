import { cloneDeep, isEmpty } from 'lodash';

import { initNotificationPopInState } from '../config/initialState';
import * as types from '../types/notificationPopIn.types';

export const notificationPopInReducer = (
	state = initNotificationPopInState,
	action: types.NotificationPopInActionTypes
): types.NotificationPopInState => {
	switch (action.type) {
		case types.CLOSE_NOTIFICATION_POPIN:
			return handleClosePopIn(state);
		case types.OPEN_NOTIFICATION_POPIN:
			return handleOpenPopIn(state, action.payload);
		default:
			return state;
	}

	function handleOpenPopIn(
		oldState: types.NotificationPopInState,
		payload: types.NotificationItem
	): types.NotificationPopInState {
		if (!oldState.open) {
			return {
				confirmTxt: payload.confirmTxt,
				open: true,
				queue: [],
				rsCodes: payload.rsCodes,
				subTitle: payload.subTitle,
				title: payload.title,
				type: payload.type,
			};
		} else if (
			!oldState.queue.find((el) => el.type === payload.type) &&
			oldState.type !== payload.type
		) {
			const newQueue = cloneDeep(oldState.queue);
			newQueue.push({
				confirmTxt: payload.confirmTxt,
				rsCodes: payload.rsCodes,
				subTitle: payload.subTitle,
				title: payload.title,
				type: payload.type,
			});
			return {
				...oldState,
				queue: newQueue,
			};
		}
		return oldState;
	}

	function handleClosePopIn(
		oldState: types.NotificationPopInState
	): types.NotificationPopInState {
		if (isEmpty(oldState.queue)) {
			return {
				confirmTxt: '',
				open: false,
				queue: [],
				rsCodes: [],
				subTitle: '',
				title: '',
				type: undefined,
			};
		} else {
			const notification = oldState.queue[0];
			return {
				confirmTxt: notification.confirmTxt,
				open: true,
				queue: oldState.queue.slice(1),
				rsCodes: notification.rsCodes,
				subTitle: notification.subTitle,
				title: notification.title,
				type: notification.type,
			};
		}
	}
};
