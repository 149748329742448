import { FC, ReactElement, SyntheticEvent, useEffect, useState } from 'react';

import { ReactComponent as Play } from '../../../assets/icons/button/polygoneOk.svg';

import './OkButton.css';

type Props = {
	isValidInput: boolean;
	action?: (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
};
const OkButton: FC<Props> = ({ isValidInput, action }): ReactElement => {
	const [svgClassName, setSvgClassName] = useState('play-icon');
	const [labelClassName, setLabelClassName] = useState('ok-label');

	useEffect(() => {
		setSvgClassName(isValidInput ? 'play-icon' : 'play-icon-disabled');
		setLabelClassName(isValidInput ? 'ok-label' : 'ok-label disabled');
	}, [isValidInput]);

	return (
		<button className="ok-btn" disabled={!isValidInput} onClick={action}>
			<div className="white-card">
				<div className={labelClassName}>OK</div>
				<div className={svgClassName}>
					<Play />
				</div>
			</div>
		</button>
	);
};

export default OkButton;
