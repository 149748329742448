import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CodeSymbol } from '../../../assets/icons/button/keyboard-numbers.svg';
import { Endpoints } from '../../../routes/endpoint.config';

import './CodeButton.css';

type Props = { label: string; variant: 'code-btn-landing' | 'code-btn-scanner' };

const CodeButton: FC<Props> = ({ label, variant }): ReactElement => {
  const navigate = useNavigate();
  const handleRedirect = () => navigate(Endpoints.AGENTN_BY_CODE);

  return (
    <button className="code-btn" id={variant} onClick={handleRedirect}>
      <div className="card-container">
        <div className="round svg-container">
          <CodeSymbol />
        </div>
        <div className="code-button-text">{label}</div>
      </div>
    </button>
  );
};

export default CodeButton;
