import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@mui/material';

import {
	ServiceTypeEnum,
	ServiceTypeMap,
	SettingNotificationService,
} from '../../../../models';
import { getUniqueKey } from '../../../../packages/helpers';
import { Checkbox, Switch } from '../../../../packages/ui';

import './styles.css';

type NotificationServiceProps = {
	notificationServices: SettingNotificationService[];
	handleNotificationServicesChange: Dispatch<
		SetStateAction<SettingNotificationService[]>
	>;
	setSettingIsModified: Dispatch<SetStateAction<boolean>>;
	npAlert: number;
};

const NotificationService = ({
	handleNotificationServicesChange,
	notificationServices,
	npAlert,
	setSettingIsModified,
}: NotificationServiceProps) => {
	const hourValues = 24;
	const freqValues = [0, 2, 4, 6, 12];

	const handleEnableService = (e: ChangeEvent<HTMLInputElement>): void => {
		const newNotificationServices = notificationServices.map((service) => {
			if (service.serviceTypeId === parseInt(e.target.name.slice(2))) {
				return { ...service, isEnabled: !service.isEnabled };
			}
			return service;
		});
		handleNotificationServicesChange(newNotificationServices);
		setSettingIsModified(true);
	};

	const handleServiceRoleChange = (
		e: ChangeEvent<HTMLInputElement>,
		roleId: string
	): void => {
		const newNotificationServices = notificationServices.map((service) => {
			if (service.serviceTypeId === parseInt(e.target.name.slice(3))) {
				const newRoles = service.roles.map((role) => {
					if (role.roleId === parseInt(roleId, 10)) {
						return { ...role, isEnabled: !role.isEnabled };
					}
					return role;
				});
				return { ...service, roles: newRoles };
			}
			return service;
		});
		handleNotificationServicesChange(newNotificationServices);
		setSettingIsModified(true);
	};

	const handleChangeHourPicker = (e: ChangeEvent<HTMLSelectElement>): void => {
		const newNotificationServices = notificationServices.map((service) => {
			if (service.serviceTypeId === parseInt(e.target.id.slice(3))) {
				return { ...service, [e.target.name]: parseInt(e.target.value) };
			}
			return service;
		});
		handleNotificationServicesChange(newNotificationServices);
		setSettingIsModified(true);
	};

	return (
		<form>
			{notificationServices.map((notification) => (
				<div key={`s-${notification.serviceTypeId}`}>
					<div className="setting-notification__item-header">
						<Grid container>
							<Grid item md={5} xs={12}>
								<Grid container flexDirection="row">
									<Switch
										checked={notification.isEnabled}
										handler={handleEnableService}
										name={`s-${notification.serviceTypeId}`}
									/>
									<Typography variant="body1">
										{ServiceTypeMap.get(notification.serviceTypeId)}
									</Typography>
								</Grid>
								{notification.isEnabled &&
									notification.serviceTypeId === ServiceTypeEnum.NP && (
										<span>{`(déclenché au bout de ${npAlert} jours)`}</span>
									)}
								{!notification.isEnabled && (
									<Typography variant="caption">
										*être informé sur les trains nécessitant un nettoyage
										patrimonial au plus vite.
									</Typography>
								)}
							</Grid>
							{notification.isEnabled && (
								<>
									<Grid item lg={3} md={3} sm={12} xs={12}>
										<span className={'notification__select__time__label'}>
											Horaire de notification :
										</span>
										<select
											data-testid="select-hour"
											id={`sh-${notification.serviceTypeId}`}
											name="hour"
											onChange={handleChangeHourPicker}
											value={notification.hour}
										>
											{[...Array(hourValues)].map((e, i) => (
												<option key={getUniqueKey(i.toString())} value={i}>
													{i}h00
												</option>
											))}
										</select>
									</Grid>
									<Grid item lg={3} md={3} sm={12} xs={12}>
										<span className={'notification__select__time__label'}>
											Fréquence de rappel :
										</span>
										<select
											id={`sf-${notification.serviceTypeId}`}
											name="freqReminder"
											onChange={handleChangeHourPicker}
											value={notification.freqReminder}
										>
											{freqValues.map((e) => (
												<option key={getUniqueKey(e.toString())} value={e}>
													{e === 0 ? 'jamais' : `${e}h`}
												</option>
											))}
										</select>
									</Grid>
								</>
							)}
						</Grid>
					</div>
					{notification.isEnabled && (
						<div className="setting-notification__item-content">
							<p className="notification__roles__title">
								Afficher cette notification pour :
							</p>
							{notification.roles.map((role) => (
								<Checkbox
									checked={role.isEnabled}
									key={role.roleId}
									label={role.name}
									name={`sr-${notification.serviceTypeId}`}
									onChange={(e) =>
										handleServiceRoleChange(e, role.roleId.toString())
									}
								/>
							))}
						</div>
					)}
				</div>
			))}
		</form>
	);
};

export default NotificationService;
