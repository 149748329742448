import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { isEmpty, isEqual, isNil } from 'lodash';

import {
	AvailabilityPosition,
	IncidentTypeMap,
	ServiceTypeEnum,
} from '../../../../models';
import { Availability } from '../../../../models/availability.model';
import { AvailabilitySite } from '../../../../models/site.model';
import AvailabilityProvider from '../../../../packages/dataProviders/resources/availability';
import {
	RSA_EDIT_FORM_SUBTITLE,
	RSA_EDIT_FORM_TITLE,
} from '../../../../packages/helpers/explanations/rollingStockAvailability';
import {
	ModalesState,
	useForm,
	useLine,
	useTypedSelector,
} from '../../../../packages/hooks';
import { Dialog } from '../../../../packages/ui';
import CloseButton from '../../../../packages/ui/molecules/CloseButton';
import { updateAndSetAvailability } from '../../../../redux/thunks/availability';
import {
	getEmptyRollingStockAvailability,
	validate,
} from '../../../availability/config';
import Logo from '../../../navBar/logo';
import { StyledServiceRender } from '../../../trackingBoard/list/styles';
import AvailabilityFormContent from '../availabilityFormContent';

import ActionConfirmation from './footer/ActionConfirmation';
import AvailabilityDetailHeader from './header/AvailabilityDetailHeader';

const AvailabiltyDetails = ({
	colorNp,
	daysCount,
	isEditableMode,
	open,
	setIsEditableMode,
	handleCloseModal,
	rsId,
	trainId,
	trainNumber,
}: AvailabiltyDetailsProps) => {
	const dispatch = useDispatch();
	const [sites, setSites] = useState<AvailabilitySite[]>([]);
	const [positions, setPositions] = useState<AvailabilityPosition[]>([]);
	const [isDeletableMode, setIsDeletableMode] = useState(false);
	const [isSelectedAction, setIsSelectedAction] = useState(false);
	const { selectedLine } = useLine();
	const rollingstocks =
		useTypedSelector(
			({ rollingStockState }) => rollingStockState.rollingStocks
		) || [];

	const rollingstock = rollingstocks.filter(({ id }) => id === trainId);
	const availability: Availability | undefined = rollingstock[0]?.availability;

	const getAvailabilityReason = (): string => {
		if (isNil(availability)) return '';

		const { incidentTypeId, serviceTypeId } = availability;
		if (isEqual(serviceTypeId, ServiceTypeEnum.NP))
			return 'Nettoyage Patrimonial';
		if (!isNil(incidentTypeId))
			return IncidentTypeMap.get(incidentTypeId) ?? '';
		return 'Opération Spéciale';
	};

	const availabilityId = Number(availability?.id);

	const handleUpdateAvailability = () => {
		const newAvailabilities: Availability[] = [
			{
				incidentTypeId: availability?.incidentTypeId
					? availability.incidentTypeId
					: undefined,
				positionId: values.position.id,
				rollingStockId: trainId,
				serviceTypeId: availability?.serviceTypeId
					? availability.serviceTypeId
					: undefined,
			},
		];

		dispatch(
			updateAndSetAvailability(
				newAvailabilities,
				availabilityId,
				selectedLine,
				rsId
			)
		);
		handleCloseModal();
	};

	const handleCancel = () => {
		setIsDeletableMode(false);
		setIsEditableMode(false);
		setIsSelectedAction(false);
	};

	const { errors, handleSelect, values, setValues } = useForm({
		callback: handleUpdateAvailability,
		initFormValues: getEmptyRollingStockAvailability(),
		validate,
	});

	const handleClose = () => {
		handleCloseModal('availabilityEditForm');
	};

	useEffect(() => {
		if (selectedLine)
			AvailabilityProvider.loadAvailabilitySites(selectedLine).then(
				({ data: sites }) => setSites(sites)
			);
	}, [selectedLine]);

	useEffect(() => {
		if (values.site) {
			setValues({ ...values, position: undefined });
			AvailabilityProvider.loadAvailabilityPositions(values.site.id).then(
				({ data: positions }) => setPositions(positions)
			);
		}
	}, [values.site]);

	return (
		<Dialog
			open={open}
			titleContent={RSA_EDIT_FORM_TITLE}
			content={
				<>
					<div className="incident-header">
						<div className="rollingstock-availability-header">
							<div>
								<Logo size="small" />
							</div>
							<div className="incident-header-train-number">
								<Typography variant="h6">{trainNumber}</Typography>
								<Typography variant="h5">{rsId}</Typography>
							</div>
						</div>
						{!isEmpty(daysCount) && (
							<div className="lastNp-header">
								<Typography variant="h6">{'Dernier NP:'}</Typography>
								<StyledServiceRender color={colorNp}>
									<Typography variant="h5">{daysCount}</Typography>
								</StyledServiceRender>
							</div>
						)}
					</div>
					<AvailabilityDetailHeader
						isSelectedAction={isSelectedAction}
						setIsDeletableMode={setIsDeletableMode}
						setIsEditableMode={setIsEditableMode}
						setIsSelectedAction={setIsSelectedAction}
					/>
					<Grid
						container
						paddingTop={5}
						paddingBottom={4}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<Grid item xs={6} alignSelf="flexStart">
							<Grid container padding={3} flexDirection="column" rowGap={3}>
								<Grid item xs={12}>
									<Typography variant="h5">{RSA_EDIT_FORM_SUBTITLE}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="h5" color={'#0a007d'}>
										{getAvailabilityReason()}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="h5">Détails :</Typography>
								</Grid>
								<Grid item xs={12}>
									<AvailabilityFormContent
										availability={
											isEditableMode ? (values as Availability) : availability
										}
										isEditableMode={isEditableMode}
										errors={errors}
										handleSelect={handleSelect}
										positions={positions}
										sites={sites}
										values={values}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			}
			actionsContent={
				<>
					{isSelectedAction ? (
						<ActionConfirmation
							availabilityId={availabilityId}
							isDeletableMode={isDeletableMode}
							newAvailabilities={values}
							onCancel={handleCancel}
							onClose={handleCloseModal}
							selectedLine={selectedLine}
							rsId={rsId}
						/>
					) : (
						<CloseButton onCancel={handleCloseModal} />
					)}
				</>
			}
			onClose={handleClose}
		/>
	);
};

export default AvailabiltyDetails;

type AvailabiltyDetailsProps = {
	colorNp: string;
	daysCount: string;
	isEditableMode: boolean;
	open: boolean;
	setIsEditableMode: Dispatch<SetStateAction<boolean>>;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	rsId: string | undefined;
	trainId: number | undefined;
	trainNumber: string;
};
