import { isEmpty } from 'lodash';

import { Export, PeriodOptions } from '../../../models';
import { dateTimeService } from '../../../packages/helpers';
import {
	EXPORT_FORM_ERR_DATATYPE,
	EXPORT_FORM_ERR_LINES,
} from '../../../packages/helpers/explanations/export';

const { TODAY } = PeriodOptions;

export const getEmptyExport = (selectedLine: string): Export =>
	({
		endDate: dateTimeService.getHistoryDate(TODAY),
		isAllLinesSelected: false,
		lines: [selectedLine],
		roleIds: [],
		rollingStockIds: [],
		services: [],
		startDate: new Date(new Date().setHours(0, 0, 0)),
	} as unknown as Export);

export const validate = (exportValues: Export) => {
	const errors = {} as { [key in string]: string };
	const { dataType, lines } = exportValues;

	if (isEmpty(dataType)) errors.dataType = EXPORT_FORM_ERR_DATATYPE;
	if (isEmpty(lines)) errors.lines = EXPORT_FORM_ERR_LINES;
	return errors;
};

export const getFormattedExport = (
	values: Export,
	rollingstockList: number[],
	allLines: string[]
): Export => {
	return {
		...values,
		lines: values.isAllLinesSelected ? allLines : values.lines,
		rollingStockIds: getRollingstockIds(
			values.lines,
			values.rollingStockIds,
			rollingstockList
		),
	};
};

const getRollingstockIds = (
	lines: string[],
	rollingstockIds: number[],
	rollingstockList: number[]
): number[] => {
	if (lines.length > 1) return [];
	if (lines.length === 1 && rollingstockList.length === rollingstockIds.length)
		return [];
	return rollingstockIds;
};
