import { FC } from 'react';

import { Station } from '../../../../models';
import SemTable from '../SemTable/SemTable';

import './../SemHeader/SemHeader.css';

type Props = {
  stations: Station[];
};

export const SemNettoyageBoard: FC<Props> = ({ stations }) => {
  return (
    <div className="sem-container sem-container-supplementaire">
      <div className="sem-table-passage hide-xlarge-screen">
        <h3 className="sem-passage__title">Prestation Supplémentaire</h3>
        <SemTable passage={4} stations={stations} />
      </div>

      <div className="show-xlarge-screen">
        <h3 className="sem-passage__title">Prestation Supplémentaire</h3>
        <SemTable passage={4} stations={stations} />
      </div>
    </div>
  );
};

export default SemNettoyageBoard;
