import * as fileSaver from 'file-saver';
import { ThunkAction } from 'redux-thunk';

import { Export } from '../../models';
import { PopupType } from '../../models/popup.model';
import { HttpResponse } from '../../packages/dataProviders';
import { exportService } from '../../packages/dataProviders/exportService';
import { pluralize } from '../../packages/helpers';
import { dateTimeService } from '../../packages/helpers/dateTime';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import { ActionTypes, ApiThunks, AppStore } from '../types';

type ExportThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;
export const getExport =
	(exportParams: Export): ExportThunk =>
	async (dispatch, _, api) => {
		try {
			const response = await exportService.getExport(exportParams);
			if (response.ok && response.message.size > 0) {
				saveFile(
					response,
					exportParams.startDate,
					exportParams.endDate,
					exportParams.lines
				);
			} else {
				const exception = 'Pas de donnée disponible sur cet export' as string;
				dispatch(
					api.showFeedBack(exceptionDTOtoPopupState(exception, PopupType.WARN))
				);
			}
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.actionRejected));
		}
	};

const saveFile = (
	data: HttpResponse,
	startDate: Date,
	endDate: Date,
	lines: string[]
) => {
	const blob = data.message as Blob;
	fileSaver.saveAs(
		blob,
		`Suivi du nettoyage ${pluralize(lines.length, 'de')} ${pluralize(
			lines.length,
			'ligne'
		)} ${lines.join()} du ${dateTimeService.getDateYYYYMMDD(
			startDate
		)} au ${dateTimeService.getDateYYYYMMDD(endDate)}.xlsx`
	);
};
