import { useDispatch } from 'react-redux';

import { User } from '../../../../models';
import {
	Button,
	DualButtonsBloc,
	ValidationButtons,
} from '../../../../packages/ui';
import { deleteAndSetUser } from '../../../../redux/thunks/user';

const FormFooter = ({
	handleClose,
	user,
	handleSubmit,
	isUntouched,
}: FormFooterProps) => {
	const isEditMod = !!user.id;

	const dispatch = useDispatch();

	const handleDeleteUser = () => {
		dispatch(deleteAndSetUser(user.id));
		handleClose();
	};

	return (
		<DualButtonsBloc>
			<div>
				{isEditMod && (
					<Button onClick={handleDeleteUser} color="error">
						Supprimer
					</Button>
				)}
			</div>
			<ValidationButtons
				onCancel={handleClose}
				onValidate={handleSubmit}
				validateDisabled={isUntouched}
			/>
		</DualButtonsBloc>
	);
};

export default FormFooter;

type FormFooterProps = {
	user: User;
	handleClose: () => void;
	handleSubmit: () => void;
	isUntouched: boolean;
};
