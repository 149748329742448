// prettier-ignore
import { isEmpty, isNil } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import {
	DayNightEnum,
	ServiceStatus,
	ServiceTypeEnum,
	SettingDayNightService,
} from '../../models';
import { ServiceDTO } from '../../packages/dataProviders';
import { defaultServiceStatus, initServiceState } from '../config/initialState';
import {
	RESET_SERVICE_STATUS,
	RESET_SURFACE,
	ServiceActionTypes as ActionTypes,
	ServiceOption,
	ServiceState as State,
	SET_DAY_STATUSES,
	SET_NIGHT_STATUSES,
	SET_SELECTION,
	SET_SERVICE_STATUS,
	SET_SURFACE,
} from '../types/service.types';

const { SELECTED, ENABLE, DISABLE } = ServiceStatus;

const serviceTypeNC = (
	NCR: ServiceOption | undefined,
	NP: ServiceOption | undefined,
	MEP: ServiceOption | undefined,
	PBS: ServiceOption | undefined
) => {
	if (NP?.status === SELECTED) NP.status = ENABLE;
	if (MEP?.status === SELECTED) MEP.status = ENABLE;
	if (NCR?.status === SELECTED) NCR.status = ENABLE;
	if (PBS?.status === SELECTED) PBS.status = ENABLE;
};

const serviceTypeNP = (
	NC: ServiceOption | undefined,
	NCR: ServiceOption | undefined,
	MEP: ServiceOption | undefined,
	PBS: ServiceOption | undefined
) => {
	if (NC?.status === SELECTED) NC.status = ENABLE;
	if (MEP?.status === SELECTED) MEP.status = ENABLE;
	if (NCR?.status === SELECTED) NCR.status = ENABLE;
	if (PBS?.status === SELECTED) PBS.status = ENABLE;
};

const serviceTypeMEP = (
	NC: ServiceOption | undefined,
	NCR: ServiceOption | undefined,
	NP: ServiceOption | undefined,
	PBS: ServiceOption | undefined
) => {
	if (NC?.status === SELECTED) NC.status = ENABLE;
	if (NP?.status === SELECTED) NP.status = ENABLE;
	if (NCR?.status === SELECTED) NCR.status = ENABLE;
	if (PBS?.status === SELECTED) PBS.status = ENABLE;
};

const serviceTypeNCR = (
	NC: ServiceOption | undefined,
	NP: ServiceOption | undefined,
	MEP: ServiceOption | undefined,
	PBS: ServiceOption | undefined
) => {
	if (NC?.status === SELECTED) NC.status = ENABLE;
	if (NP?.status === SELECTED) NP.status = ENABLE;
	if (MEP?.status === SELECTED) MEP.status = ENABLE;
	if (PBS?.status === SELECTED) PBS.status = ENABLE;
};

const serviceTypePBS = (
	NC: ServiceOption | undefined,
	NP: ServiceOption | undefined,
	MEP: ServiceOption | undefined,
	NCR: ServiceOption | undefined
) => {
	if (NC?.status === SELECTED) NC.status = ENABLE;
	if (NP?.status === SELECTED) NP.status = ENABLE;
	if (MEP?.status === SELECTED) MEP.status = ENABLE;
	if (NCR?.status === SELECTED) NCR.status = ENABLE;
};

export const serviceReducer = (
	state = initServiceState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case SET_SURFACE: {
			const {
				trainId,
				trainCode,
				equipment,
				position,
				stationName,
				stationId,
				sequenceId,
				lineId,
				unitName,
				incidents,
				specialOperations,
			} = action.payload;
			return {
				...state,
				equipment: equipment ?? state.equipment,
				incidents: incidents ?? state.incidents,
				lineId: lineId ?? state.lineId,
				position: position ?? state.position,
				sequenceId: sequenceId ?? state.sequenceId,
				specialOperations: specialOperations ?? state.specialOperations,
				stationId: stationId ?? state.stationId,
				stationName: stationName ?? state.stationName,
				trainCode: trainCode ?? state.trainCode,
				trainId: trainId ?? state.trainId,
				unitName: unitName ?? state.unitName,
			};
		}
		case RESET_SURFACE:
			return {
				...initServiceState,
			};

		case SET_SELECTION:
			return {
				...state,
				selection: action.payload,
			};

		case SET_SERVICE_STATUS: {
			const { payload } = action;
			const { status, typeService } = payload;
			return {
				...state,
				services: handlers[SET_SERVICE_STATUS](
					cloneDeep(state.services),
					status,
					typeService
				),
			};
		}

		case RESET_SERVICE_STATUS:
			return {
				...state,
				services: defaultServiceStatus,
			};

		case SET_NIGHT_STATUSES: {
			const { ServicesDTOS, dayNightServices } = action;
			return {
				...state,
				services: handlers[SET_NIGHT_STATUSES](
					cloneDeep(state.services),
					ServicesDTOS,
					dayNightServices
				),
			};
		}

		case SET_DAY_STATUSES:
			return {
				...state,
				services: handlers[SET_DAY_STATUSES](
					cloneDeep(state.services),
					action.dayNightServices
				),
			};

		default:
			return state;
	}
};

export const handlers = {
	getServiceByType(
		services: ServiceOption[],
		serviceType: ServiceTypeEnum
	): ServiceOption | undefined {
		const servicesFiltred = services.filter((s) => s.type === serviceType);
		return isEmpty(servicesFiltred) ? undefined : servicesFiltred[0];
	},

	SET_DAY_STATUSES(
		stateServices: ServiceOption[],
		dayNightServices: SettingDayNightService[]
	): ServiceOption[] {
		if (isEmpty(dayNightServices)) return stateServices;
		stateServices.forEach((service) => {
			const settingService = dayNightServices.find(
				(s) => s.serviceTypeId === service.type
			);
			service.status =
				settingService?.dayNightType === DayNightEnum.NIGHT ? DISABLE : ENABLE;
			service.isDisabled = settingService?.dayNightType === DayNightEnum.NIGHT;
			service.departments = settingService?.departments ?? [];
		});
		return stateServices;
	},

	SET_NIGHT_STATUSES(
		stateServices: ServiceOption[],
		nightServicesDTOS: ServiceDTO[],
		dayNightServices: SettingDayNightService[]
	): ServiceOption[] {
		if (!isEmpty(dayNightServices)) {
			stateServices.forEach((service) => {
				const settingService = dayNightServices.find(
					(s) => s.serviceTypeId === service.type
				);
				service.status =
					settingService?.dayNightType === DayNightEnum.DAY ? DISABLE : ENABLE;
				service.isDisabled = settingService?.dayNightType === DayNightEnum.DAY;
			});
		}
		nightServicesDTOS.forEach(({ serviceTypeId }) => {
			const stateService = stateServices.find(
				(service) => service.type === serviceTypeId
			);
			if (isNil(stateService)) return;
			stateService.status = DISABLE;
			stateService.isProcessed = true;
			stateService.departments = stateService?.departments ?? [];
		});
		return stateServices;
	},
	SET_SERVICE_STATUS(
		services: ServiceOption[],
		newStatus: ServiceStatus,
		serviceTypeToUpdate: ServiceTypeEnum
	): ServiceOption[] {
		services.forEach((service) => {
			if (serviceTypeToUpdate === service.type) service.status = newStatus;
			return service;
		});

		return this.updateNPNCStatus(services, serviceTypeToUpdate);
	},

	toServiceIndex(stEnum: ServiceTypeEnum) {
		return stEnum - 1;
	},

	updateNPNCStatus(
		services: ServiceOption[],
		serviceType: ServiceTypeEnum
	): ServiceOption[] {
		const NC = this.getServiceByType(services, ServiceTypeEnum.NC);
		const NP = this.getServiceByType(services, ServiceTypeEnum.NP);
		const PBS = this.getServiceByType(services, ServiceTypeEnum.PBS);
		const MEP = this.getServiceByType(services, ServiceTypeEnum.MEP);
		const NCR = this.getServiceByType(services, ServiceTypeEnum.NCR);

		switch (serviceType) {
			case ServiceTypeEnum.NC:
				serviceTypeNC(NCR, NP, MEP, PBS);
				break;
			case ServiceTypeEnum.NP:
				serviceTypeNP(NC, NCR, MEP, PBS);
				break;
			case ServiceTypeEnum.PBS:
				serviceTypePBS(NC, NP, MEP, NCR);
				break;
			case ServiceTypeEnum.MEP:
				serviceTypeMEP(NC, NCR, NP, PBS);
				break;
			case ServiceTypeEnum.NCR:
				serviceTypeNCR(NC, NP, MEP, PBS);
				break;
		}
		return services;
	},
};

export default serviceReducer;
