import { useEffect, useState } from 'react';

import { MAX_MOBILE_WIDTH, MAX_PAD_WIDTH } from '../helpers/constants';

export const useMediaQuery = () => {
	const queryPadSize = `(max-width: ${MAX_PAD_WIDTH}px)`;
	const queryMobileSize = `(max-width: ${MAX_MOBILE_WIDTH}px)`;

	const getMatches = (query: string): boolean => {
		return window.matchMedia(query).matches;
	};

	const [matchesPad, setMatchesPad] = useState<boolean>(
		getMatches(queryPadSize)
	);
	const [matchesMobile, setMatchesMobile] = useState<boolean>(
		getMatches(queryMobileSize)
	);

	const handleChange = () => {
		const matchMobile = getMatches(queryMobileSize);
		setMatchesMobile(matchMobile);
		setMatchesPad(getMatches(queryPadSize) && !matchMobile);
	};

	useEffect(() => {
		const matchPadMedia = window.matchMedia(queryPadSize);
		const matchMobileMedia = window.matchMedia(queryMobileSize);

		handleChange();
		matchPadMedia.addEventListener('change', handleChange);
		matchMobileMedia.addEventListener('change', handleChange);

		return () => {
			matchPadMedia.removeEventListener('change', handleChange);
			matchMobileMedia.removeEventListener('change', handleChange);
		};
	}, [queryPadSize]);

	return {
		isDesktop: !(matchesPad || matchesMobile),
		isMobile: matchesMobile,
		isPad: matchesPad,
		matchesPad,
	};
};
