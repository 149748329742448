import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { ModalProps } from '../../../models/ui';

const style = {
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	left: '50%',
	p: 4,
	position: 'absolute' as const,
	top: '50%',
	transform: 'translate(-50%, -50%)',
};

const BasicModal = ({ content, headerContent, open, onClose }: ModalProps) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography
					id="modal-modal-title"
					variant="h4"
					justifyContent="center"
					color="var(--global-color-primary-darker)"
				>
					{headerContent}
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					{content}
				</Typography>
			</Box>
		</Modal>
	);
};

export default BasicModal;
