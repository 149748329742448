import { initLineState } from '../config/initialState';
import {
	LineActionTypes as ActionTypes,
	LineState as State,
	SET_LINES,
	SET_SELECTED_LINE,
} from '../types';

export const lineReducer = (
	state = initLineState,
	action: ActionTypes
): State => {
	if (action.type === SET_LINES) {
		return {
			...state,
			lines: action.payload,
		};
	} else if (action.type === SET_SELECTED_LINE) {
		return {
			...state,
			selectedLine: action.payload,
		};
	} else {
		return state;
	}
};

export default lineReducer;
