import { initPassageState } from '../config/initialState';
import {
	PassageActionTypes as ActionTypes,
	PassageState as State,
	SET_SELECTED_PASSAGE,
} from '../types';

export const passageReducer = (
	state = initPassageState,
	action: ActionTypes
): State => {
	if (action.type === SET_SELECTED_PASSAGE) {
		return {
			...state,
			selectedPassage: action.payload,
		};
	} else {
		return state;
	}
};

export default passageReducer;
