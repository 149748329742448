import { SolvedIncidentReport } from '../../../models/incidentSolving.model';
import fetchJson from '../fetch/fetch-json';

const solveIncidentReport = async (
	solvedIncidentReport: SolvedIncidentReport
): Promise<{
	data: SolvedIncidentReport;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const options = {
		body: JSON.stringify(solvedIncidentReport),
		method: 'POST',
	};
	return await fetchJson(`/incident-solving`, options);
};

export default { solveIncidentReport };
