import { Dispatch, SetStateAction } from 'react';

import { MultipleAvailabilityTypeEnum } from '../../../models/multipleAvailability';
import { MenuItem } from '../../../models/ui';
import {
	DF_LABEL,
	getUniqueKey,
	MULTIPLE_AVAILABILITY,
	NP_LABEL,
} from '../../../packages/helpers';
import { Dropdown } from '../../../packages/ui';
import { KeyboardArrowDownIcon } from '../../../packages/ui/icons';

import { StyledMultipleAvailability } from './styles';

const MultipleAvailabilityFooter = ({
	setMultipleAvailabilityType,
	setOpenMultipleAvailability,
}: MultipleAvailabilityFooterProps) => {
	const menuItems: MenuItem[] = [
		{
			content: NP_LABEL,
			key: getUniqueKey(NP_LABEL),
			onClick: () => {
				setMultipleAvailabilityType(MultipleAvailabilityTypeEnum.NP);
				setOpenMultipleAvailability(true);
			},
		},
		{
			content: DF_LABEL,
			key: getUniqueKey(DF_LABEL),
			onClick: () => {
				setMultipleAvailabilityType(MultipleAvailabilityTypeEnum.DF);
				setOpenMultipleAvailability(true);
			},
		},
	];

	return (
		<StyledMultipleAvailability>
			<Dropdown
				buttonId="multiple-availability"
				buttonTitle={MULTIPLE_AVAILABILITY}
				endIcon={<KeyboardArrowDownIcon />}
				isMultipleAvailabity
				items={menuItems}
				variant="outlined"
			/>
		</StyledMultipleAvailability>
	);
};

export default MultipleAvailabilityFooter;

export type MultipleAvailabilityFooterProps = {
	setMultipleAvailabilityType: Dispatch<
		SetStateAction<MultipleAvailabilityTypeEnum | undefined>
	>;
	setOpenMultipleAvailability: Dispatch<SetStateAction<boolean>>;
};
