import { Card, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledHeaderCard = styled(Card)<{ border?: string }>(
	({ border }) => ({
		border: border ?? '',
		height: 80,
		maxWidth: 90,
		padding: 5,
	})
);

export const StyledNumberHeaderCard = styled(Typography)(() => ({
	fontSize: 50,
	lineHeight: 1,
	textAlign: 'center',
}));

export const StyledStingHeaderCard = styled(Typography)(() => ({
	color: 'text.secondary',
	fontSize: 13,
	lineHeight: 1,
	textAlign: 'center',
}));
