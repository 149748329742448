import { Typography } from '@mui/material';

import { IncidentType, Item } from '../../../../../models';
import {
	INC_SEL_SUBTITLE,
	INC_SEL_TITLE,
} from '../../../../../packages/helpers';
import { Button, Dialog, PopInFooter } from '../../../../../packages/ui';
import Logo from '../../../../navBar/logo';

const IncidentCreateSelect = ({
	classNameIncident,
	handleClick,
	handleClose,
	incidentTypesCreate,
	isTouched,
	onValidateInput,
	open,
	rsCode,
	trainNumber,
}: IncidentCreateSelectProps) => {
	return (
		<Dialog
			open={open}
			titleContent={INC_SEL_TITLE}
			content={
				<>
					<div className="incident-header">
						<div>
							<Logo size="small" />
						</div>
						<div className="incident-header-train-number">
							<Typography variant="h6">{trainNumber}</Typography>
							<Typography variant="h5">{rsCode}</Typography>
						</div>
					</div>
					<div className="incidents-list-container">
						<Typography
							className="incidents-list-title"
							variant="h5"
							style={{ marginBottom: '1rem' }}
						>
							{INC_SEL_SUBTITLE}
						</Typography>
						{incidentTypesCreate?.map((incidentType) => (
							<Button
								key={incidentType.id}
								className={classNameIncident(incidentType)}
								onClick={() => handleClick(incidentType)}
								data-testid="incident-button"
								style={{ width: '60%' }}
							>
								<div
									className={`svg-incident-container svg-incident svg-incident-symbol-${incidentType.code.toLowerCase()}`}
								/>
								<div className="incident-name-container">
									{incidentType.name}
								</div>
							</Button>
						))}
					</div>
				</>
			}
			actionsContent={
				<PopInFooter
					onCancel={handleClose}
					onValidate={onValidateInput}
					validateDisabled={!isTouched}
				/>
			}
			onClose={handleClose}
		/>
	);
};

export default IncidentCreateSelect;

type IncidentCreateSelectProps = {
	classNameIncident: (incident: Item) => string;
	handleClick: (item: Item) => void;
	handleClose: () => void;
	incidentTypesCreate: IncidentType[];
	isTouched: boolean;
	onValidateInput: () => void;
	open: boolean;
	rsCode: string | undefined;
	trainNumber: string;
};
