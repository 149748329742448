import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Availability } from '../../../models/availability.model';
import { MultipleAvailabilityTypeEnum } from '../../../models/multipleAvailability';
import { DF_LABEL, NP, NP_LABEL } from '../../../packages/helpers';
import { useForm, useLine, useRollingStockCard } from '../../../packages/hooks';
import { Dialog, ValidationButtons } from '../../../packages/ui';
import { createAvailability } from '../../../redux/thunks/availability';

import {
	getEmptyMultipleAvailability,
	getFormattedMultipleAvailabilities,
	validate,
} from './config';
import MultipleAvailabilityContent from './content';

const MultipleAvailabilityForm = ({
	availabilityType,
	openMultipleAvailability,
	setOpenMultipleAvailability,
}: MultipleAvailabilityProps) => {
	const { selectedLine } = useLine();
	const dispatch = useDispatch();
	const multipleAvailabilityLabel =
		availabilityType === MultipleAvailabilityTypeEnum.NP
			? NP.toUpperCase()
			: DF_LABEL;

	const {
		rollingStockCardList,
		grafedRollingStockCardList,
		handleFilterRsCard,
		handleClearFilterRsCard,
	} = useRollingStockCard(true);

	const getIncidentSelectedName = (): string => {
		return availabilityType === 0 ? DF_LABEL : NP_LABEL;
	};

	const handleCreateMultipleAvailabilities = () => {
		if (availabilityType === 0 || availabilityType === 2) {
			const availabilities: Availability[] = getFormattedMultipleAvailabilities(
				values,
				availabilityType
			);
			dispatch(
				createAvailability(
					availabilities,
					selectedLine,
					getIncidentSelectedName()
				)
			);
		}
		handleClose();
	};

	const {
		errors,
		handleSelect,
		handleSubmit,
		handleCheckOneOf,
		isUntouched,
		values,
		setValues,
	} = useForm({
		callback: handleCreateMultipleAvailabilities,
		initFormValues: getEmptyMultipleAvailability(
			availabilityType as MultipleAvailabilityTypeEnum,
			selectedLine
		),
		validate,
	});

	const handleClose = () => {
		setOpenMultipleAvailability(false);
		handleClearFilterRsCard();
	};

	useEffect(() => {
		setValues({
			...values,
			availabilityRollingStocks:
				availabilityType === MultipleAvailabilityTypeEnum.DF
					? grafedRollingStockCardList
					: rollingStockCardList,
		});
	}, [rollingStockCardList, grafedRollingStockCardList, availabilityType]);

	return (
		<Dialog
			open={openMultipleAvailability}
			onClose={handleClose}
			titleContent={`Mise à disposition pour ${multipleAvailabilityLabel}`}
			content={
				<MultipleAvailabilityContent
					errors={errors}
					handleCheckOneOf={handleCheckOneOf}
					handleSelect={handleSelect}
					multipleAvailability={values}
					availabilityType={availabilityType}
					values={values}
					setValues={setValues}
					handleFilterChange={handleFilterRsCard}
				/>
			}
			actionsContent={
				<ValidationButtons
					onValidate={handleSubmit}
					onCancel={handleClose}
					validateDisabled={isUntouched}
				/>
			}
		/>
	);
};

export default MultipleAvailabilityForm;

type MultipleAvailabilityProps = {
	availabilityType: MultipleAvailabilityTypeEnum | undefined;
	openMultipleAvailability: boolean;
	setOpenMultipleAvailability: Dispatch<SetStateAction<boolean>>;
};
