import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-pro';

import { RightByType } from '../../models/rightByType.model';
import { useDepartment, useTypedSelector } from '../../packages/hooks';
import { DataGridPro } from '../../packages/ui';
import { createRightThunk, updateRightThunk } from '../../redux/thunks/right';
import { RightByTypeState } from '../../redux/types';

const columns: GridColDef[] = [
	{
		field: 'right',
		headerClassName: 'super-app-theme--header',
		headerName: 'Droits',
		type: 'string',
		width: 290,
	},
	{
		editable: true,
		field: 'admin',
		headerName: 'Admin',
		type: 'boolean',
		valueGetter: (params) => params.row.admin?.isActive,
		width: 120,
	},
	{
		editable: true,
		field: 'proprete',
		headerName: 'Responsable propreté',
		type: 'boolean',
		valueGetter: (params) => params.row.proprete?.isActive,
		width: 200,
	},

	{
		editable: true,
		field: 'prestataire',
		headerName: 'Agent de maîtrise prestataire',
		type: 'boolean',
		valueGetter: (params) => params.row.prestataire?.isActive,
		width: 250,
	},
	{
		editable: true,
		field: 'transport',
		headerName: 'Transport',
		type: 'boolean',
		valueGetter: (params) => params.row.transport?.isActive,
		width: 150,
	},
	{
		editable: true,
		field: 'propretesncf',
		headerName: 'Responsable propreté SNCF',
		type: 'boolean',
		valueGetter: (params) => params.row.propretesncf?.isActive,
		width: 200,
	},
	{
		editable: true,
		field: 'transportmal',
		headerClassName: 'bold',
		headerName: 'Transport mal',
		type: 'boolean',
		valueGetter: (params) => params.row.transportmal?.isActive,
		width: 150,
	},
];

const TableRight = () => {
	const dispatch = useDispatch();
	const { hasMtsDepartment } = useDepartment();
	const rightByTypes: RightByTypeState = useTypedSelector(
		({ rightByTypeState }) => rightByTypeState
	);

	const [refreshCounter, setRefreshCounter] = useState(0);
	const [gridRows, setGridRows] = useState<RightByType[]>(rightByTypes);

	const handleCellClick = async (params: any) => {
		const { id: idRightType } = params;
		const { field: role } = params;
		const { idRight, idRole } = params.row[role];
		dispatch(
			idRight
				? updateRightThunk(idRight)
				: createRightThunk(idRightType, idRole)
		);

		const updatedRows = gridRows.map((row: any) => {
			if (row.id === params.row.id) {
				return {
					...row,
					[role]: {
						...row[role],
						idRight: idRight,
						isActive: !row[role]?.isActive,
					},
				};
			}
			return row;
		});

		setGridRows(updatedRows);
		setRefreshCounter((prev) => prev + 1);
	};

	const filteredColumns = hasMtsDepartment
		? columns.filter((column) => column.field !== 'propretesncf')
		: columns;

	return (
		<DataGridPro
			columns={filteredColumns}
			getTreeDataPath={(row: any) => row.path.split('-')}
			key={refreshCounter}
			onCellEditStart={handleCellClick}
			overrideCss={{
				'.MuiDataGrid-cell': {
					padding: '0',
				},
			}}
			rows={gridRows}
			treeData
			sx={{
				'& .css-axafay-MuiDataGrid-virtualScroller': {
					height: '65vh',
				},
				'& .MuiDataGrid-row': {
					backgroundColor: '#ffffff',
					border: 'solid 1px lightGrey',
					borderRadius: '0.5rem',
					margin: '0 0 0.8rem',
					maxWidth: '99.5%',
				},
				border: 'none',
				maxHeight: '70vh',
			}}
		/>
	);
};

export default TableRight;
