import { EventHandler, useEffect, useState } from 'react';

import { TrackingBoardDTO } from '../../packages/dataProviders';
import RadioCardGroup, {
	RadioCardGroupPropsData,
} from '../../packages/ui/molecules/RadioCardGroup';

const CodesTrainList = ({ data, validateEnter }: CodesTrainListProps) => {
	const [filteredTrains, setFilteredTrains] = useState<
		RadioCardGroupPropsData[]
	>([]);

	useEffect(() => {
		const trains: RadioCardGroupPropsData[] = data.map((rStock) => ({
			checked: false,
			id: rStock.id,
			name: rStock.trainCode,
		}));
		trains.sort(
			(a, b) =>
				Number(a.name.replace(/\D/g, '')) - Number(b.name.replace(/\D/g, ''))
		);
		setFilteredTrains(trains);
	}, [data]);

	return <RadioCardGroup data={filteredTrains} onChange={validateEnter} />;
};

export default CodesTrainList;

type CodesTrainListProps = {
	data: TrackingBoardDTO[];
	validateEnter: EventHandler<any>;
};
