import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		cancel: true;
		done: true;
	}
}

const palette = {
	background: {
		border: '#E8EDF2',
		default: '#f1f3f6',
		green: '#00AA91',
		lightGrey: '#EEFAFB',
		orange: '#FF7722',
		paper: 'white',
		shadowGreen: '#01917c',
		shadowOrange: '#e3691e',
	},
	danger: {
		main: '#ff4136',
	},
	delete: {
		main: '#F44336',
	},
	disabled: {
		tealBlue: '#CEECEE',
	},
	error: {
		dark: '#f003',
		main: '#D83451',
	},
	primary: {
		alabaster: '#FAFAFA',
		contrastText: 'white',
		light: '#cad5e4',
		loader: '#46C0C6',
		main: '#0a007d',
		orange: '#e28413',
	},
	quaternary: {
		light: '#f1f3f6',
		main: '#CAD5E4',
	},
	secondary: {
		darkBlue: '#005466',
		duckBlue: '#008299',
		light: '#5b5b5b',
		lightGrey: '#FCFCFD',
		main: '#779fa1',
	},
	success: {
		main: '#96be00',
	},
	tertiary: {
		contrastText: 'dark',
		light: '#dee4ed',
		main: 'white',
	},
	text: {
		primary: '#000',
		secondary: '#464646',
	},
	warning: {
		main: '#e28413',
	},
};

const theme = createTheme({
	breakpoints: {
		values: {
			lg: 1201,
			md: 1024,
			sm: 600,
			xl: 1536,
			xs: 0,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 'bold',
					height: '2.5rem',
					letterSpacing: 0,
					lineHeight: '1rem',
					textTransform: 'none',
				},
			},
			variants: [
				{
					props: { variant: 'cancel' },
					style: {
						':hover': {
							backgroundColor: `${palette.background.shadowOrange}`,
						},
						backgroundColor: palette.background.orange,
						borderRadius: '8px',
						boxShadow: `6px 6px ${palette.background.shadowOrange}`,
						color: 'white',
						fontSize: '22px',
						fontWeight: '700',
						padding: '30px',
					},
				},
				{
					props: { variant: 'done' },
					style: {
						':hover': {
							backgroundColor: `${palette.background.shadowGreen}`,
						},
						backgroundColor: palette.background.green,
						borderRadius: '8px',
						boxShadow: `6px 6px ${palette.background.shadowGreen}`,
						color: 'white',
						fontSize: '28px',
						fontWeight: '700',
						padding: '40px',
					},
				},
			],
		},
		MuiChip: {
			variants: [
				{
					props: { color: 'secondary' },
					style: {
						backgroundColor: '#00AA91 !important',
						borderColor: '#00AA91',
						color: 'white',
					},
				},
				{
					props: { color: 'default' },
					style: {
						backgroundColor: 'white !important',
					},
				},
			],
		},
		MuiLinearProgress: {
			variants: [
				{
					props: { color: 'success' },
					style: {
						'& .MuiLinearProgress-bar': {
							backgroundColor: '#198250 !important',
						},
					},
				},
				{
					props: { color: 'warning' },
					style: {
						'& .MuiLinearProgress-bar': {
							backgroundColor: '#FFBE00 !important',
						},
					},
				},
				{
					props: { color: 'error' },
					style: {
						'& .MuiLinearProgress-bar': {
							backgroundColor: '#FF1400 !important',
						},
					},
				},
			],
		},
	},
	palette,
	typography: {
		fontFamily: 'Parisine Ptf, Arial, Helvetica, sans-serif',
		fontSize: 16,
		h1: {
			fontSize: '3rem',
			fontWeight: 'bold',
		},
		h2: {
			fontSize: '1.5rem',
			fontWeight: 'bold',
		},
		h3: {
			fontSize: '1.4rem',
			fontWeight: 'bold',
		},
		h4: {
			fontSize: '1.3rem',
			fontWeight: 'bold',
		},
		h5: {
			fontSize: '1.2rem',
			fontWeight: 'bold',
		},
		h6: {
			fontSize: '0.9rem',
			fontWeight: 'bold',
		},
		subtitle1: {
			fontSize: '0.8rem',
		},
		subtitle2: {
			fontSize: '0.8rem',
			fontWeight: 'bold',
		},
	},
});

export default theme;
