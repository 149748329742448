import { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';

import {
	MultipleAvailability,
	MultipleAvailabilityTypeEnum,
} from '../../../../models/multipleAvailability';
import { AvailabilitySite } from '../../../../models/site.model';
import AvailabilityProvider from '../../../../packages/dataProviders/resources/availability';
import { useLine } from '../../../../packages/hooks';
import { SearchBar, Select } from '../../../../packages/ui';
import theme from '../../../../packages/ui/theme';
import ModalHeader from '../../header';

import TrainCardList from './list';
import { StyledHeaderContent } from './styles';

const MultipleAvailabilityContent = ({
	multipleAvailability,
	availabilityType,
	errors,
	handleCheckOneOf,
	handleFilterChange,
	handleSelect,
	values,
	setValues,
}: MultipleAvailabilityContentProps) => {
	const { selectedLine } = useLine();
	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
	const [siteOptions, setSiteOptions] = useState<AvailabilitySite[]>([]);

	useEffect(() => {
		if (selectedLine)
			AvailabilityProvider.loadAvailabilitySites(selectedLine).then(
				({ data: sites }) => setSiteOptions(sites)
			);
	}, [selectedLine]);

	return (
		<>
			<ModalHeader>
				<StyledHeaderContent>
					<Select
						defaultValue={multipleAvailability.site}
						helperText={errors.site}
						label="Site"
						name="site"
						onChange={handleSelect}
						options={siteOptions}
						required
						value={multipleAvailability.site}
						width={isPad ? '300px' : '438px'}
					/>
					<SearchBar
						onSubmit={(value) => handleFilterChange(value as string)}
						name="multiple-availability-searchBar"
					/>
				</StyledHeaderContent>
			</ModalHeader>
			<TrainCardList
				values={values}
				errors={errors}
				handleCheckOneOf={handleCheckOneOf}
				setValues={setValues}
				availabilityType={availabilityType}
			/>
		</>
	);
};

export default MultipleAvailabilityContent;
type MultipleAvailabilityContentProps = {
	errors: { [key: string]: string };
	handleCheckOneOf: (event: ChangeEvent<HTMLInputElement>, of: string) => void;
	handleFilterChange: (contain: string) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	multipleAvailability: MultipleAvailability;
	availabilityType: MultipleAvailabilityTypeEnum | undefined;
	values: any;
	setValues: Dispatch<any>;
};
