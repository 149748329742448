import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle as MuiDialogTitle, IconButton } from '@mui/material';

import { DialogTitleProps } from '../../../models/ui';

const DialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<MuiDialogTitle
			sx={{
				backgroundColor: '#0a007d',
				color: 'white',
				fontSize: '1.2rem',
				m: 0,
				p: 2,
				textAlign: 'flex-start',
			}}
			{...other}
		>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						color: 'white',
						position: 'absolute',
						right: 8,
						top: 8,
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default DialogTitle;
