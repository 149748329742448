import { Grid } from '@mui/material';

import { IncidentPeriodReporting } from '../../../../../models/dashboard.model';
import { IncidentTypeMap } from '../../../../../models/incidentType.model';
import IncidentSvgPrintable from '../../../../../packages/ui/icons/IncidentSvgPrintable';
import { StyledIncidentCard } from '../../../../trackingBoard/list/styles';

const IncidentsReporting = ({
	count,
	incidentTypeCode,
	incidentType,
}: IncidentPeriodReporting) => {
	return (
		<Grid container>
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					backgroundColor:
						incidentTypeCode === 'PB'
							? 'var(--bg-color-green-dark-opacity)'
							: '',
					height: '15vh',
				}}
			>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={8}>
							<Grid
								container
								display="flex"
								alignItems="center"
								fontSize="1rem"
								columnGap={5}
							>
								<Grid item xs={1} marginLeft={3}>
									<StyledIncidentCard elevation={6} border="none">
										<IncidentSvgPrintable name={incidentTypeCode} />
									</StyledIncidentCard>
								</Grid>
								<Grid item xs={6}>
									{`${IncidentTypeMap.get(incidentType)}
						${incidentTypeCode === 'PB' ? 'sale' : ''}`}
								</Grid>
							</Grid>
						</Grid>

						<Grid
							item
							xs={4}
							display="flex"
							alignItems="center"
							flexDirection="column"
							fontSize="1.8rem"
							fontWeight="bold"
						>
							{count}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default IncidentsReporting;
