import { HistorySelectionTypeEnum } from '../redux/types';

import { IncidentTypeEnum } from './incidentType.model';
import { ServiceTypeEnum } from './serviceType.model';

const { NC, NP, MAL, NCR, MEP } = ServiceTypeEnum;
const { PB, GR, DI } = IncidentTypeEnum;
const { IncidentReport, Service, TrainUsage, Sem } = HistorySelectionTypeEnum;

export enum PeriodOptions {
	TODAY = 'today',
	WEEK = 'sevenDays',
	LAST_MONTH = 'lastMonth',
	LAST_YEAR = 'lastYear',
	OTHER = 'other',
	LAST_MONTH_START_DAY = 'lastMonthStartDay',
	LAST_MONTH_END_DAY = 'lastMonthEndDay',
}
const { TODAY, WEEK, LAST_MONTH, LAST_YEAR, OTHER } = PeriodOptions;

export const PERIOD_STRINGS: Map<string, PeriodOptions> = new Map([
	["Aujourd'hui", TODAY],
	['7 derniers jours', WEEK],
	['Dernier mois', LAST_MONTH],
	['Autre', OTHER],
]);

export enum ParamsExportHistory {
	START_DATE = 'startDate',
	END_DATE = 'endDate',
	START_HOUR = 'startHour',
	END_HOUR = 'endHour',
	LINE = 'line',
}

export const defaultSelect = {
	[LAST_MONTH]: false,
	[LAST_YEAR]: false,
	[OTHER]: false,
	[TODAY]: false,
	[WEEK]: false,
};

export const HistoryDefaultServicesSelection = [
	{ id: NC, name: 'Nettoyage Courant', selected: true, type: Service },
	{
		id: NCR,
		name: 'Nettoyage Courant Renforcé',
		selected: true,
		type: Service,
	},
	{ id: NP, name: 'Nettoyage Patrimonial', selected: true, type: Service },
	{ id: MAL, name: 'Machine à laver', selected: true, type: Service },
	{ id: MEP, name: 'Maintien En Propreté', selected: true, type: Service },
];

export const HistoryDefaultIncidentsSelection = [
	{ id: PB, name: 'Pare-Brise', selected: true, type: IncidentReport },
	{
		id: DI,
		name: 'Signalement Intérieur',
		selected: true,
		type: IncidentReport,
	},
	{ id: GR, name: 'Dégraffitage', selected: true, type: IncidentReport },
];

export const HistoryDefaultTrainUsageSelection = {
	id: 0,
	name: 'Utilisation train',
	selected: true,
	type: TrainUsage,
};

export const HistoryDefaultElementUsageSelection = {
	id: 0,
	name: 'Utilisation élément',
	selected: true,
	type: TrainUsage,
};

export const HistoryDefaultSemSelection = [
	{ id: 0, name: 'Réalisées', selected: true, type: Sem },
	{
		id: 1,
		name: 'Non réalisées',
		selected: false,
		type: Sem,
	},
];

export const HistoryDefaultSelectedLinesSelection = [];

export type HistoryRequestDTO = {
	rollingStockIds: number[];
	serviceTypeIds: number[];
	incidentTypeIds: number[];
	startDate: string;
	endDate: string;
};
