import { Grid, Typography, useMediaQuery } from '@mui/material';

import { useRsUsed } from '../../../packages/hooks/useRsUsed';
import { SearchBar } from '../../../packages/ui';
import theme from '../../../packages/ui/theme';
import LineSelector from '../../lineSelector';
import Logo from '../../navBar/logo';

const LineSection = ({
	stockCountText,
	handleFilter,
	countOperationSpecial,
}: LineSelectionProps) => {
	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

	const newStockCountText = useRsUsed(countOperationSpecial);

	const countRsImpacted = newStockCountText
		? newStockCountText.stockCountText
		: stockCountText;

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item md={12} xs={6} mt={3}>
				{!isPad && <Typography variant="h5">Sélectionner une ligne</Typography>}
				<LineSelector showAll={false} isUsedTrain={true} />
			</Grid>

			<Grid item md={12} xs={6} textAlign="center" mt={2}>
				<Grid container spacing={3} alignItems="center">
					{!isPad && (
						<>
							<Grid item xs={12}>
								<Logo />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h4">{countRsImpacted}</Typography>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<SearchBar
							onSubmit={(value) => handleFilter(value as string)}
							name="tracking-board-searchBar"
							isPad={isPad}
							stockCountText={countRsImpacted}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LineSection;

type LineSelectionProps = {
	stockCountText?: string;
	handleFilter: (value: string) => void;
	countOperationSpecial?: number;
};
