import { UsedRs } from '../../../models/usedRs.model';
import { HttpResponse } from '../apiTemplate';
import fetchJson from '../fetch/fetch-json';

const saveUsedRs = async (
	trainId: number,
	isUsed: boolean
): Promise<{
	data: UsedRs;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const requestData = {
		isUsed: isUsed,
		trainId: trainId,
	};
	const options = {
		body: JSON.stringify(requestData),
		method: 'POST',
	};

	return await fetchJson('/used-rs/', options);
};

const loadUsedRs = async (
	line: string
): Promise<{
	data: HttpResponse;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/used-rs/${line}`);
};

export default { loadUsedRs, saveUsedRs };
