import { isEmpty } from 'lodash';

import { SpecialOperationType } from '../../../models/specialOperationType.model';
import {
	DESCRIPTION_LIMITED_CHARACTERS,
	NAME_LIMITED_CHARACTERS,
	SP_ERROR_FORM_DESCRIPTION_LENGHT,
	SP_ERROR_FORM_DESCRIPTION_REQUIRED,
	SP_ERROR_FORM_LINES,
	SP_ERROR_FORM_NAME_LENGHT,
	SP_ERROR_FORM_NAME_REQUIRED,
} from '../../../packages/helpers/explanations/specialOperation';

export const getEmptyspecialOperationType = (): SpecialOperationType =>
	({
		description: undefined,
		lines: [],
		name: undefined,
		startDate: new Date(),
	} as unknown as SpecialOperationType);

export const validate = (values: any) => {
	const errors = {} as { [key in string]: string };
	const { name, description, lines } = values;
	if (name && name.length > NAME_LIMITED_CHARACTERS)
		errors.name = SP_ERROR_FORM_NAME_LENGHT;
	if (isEmpty(name)) errors.name = SP_ERROR_FORM_NAME_REQUIRED;
	if (description && description.length > DESCRIPTION_LIMITED_CHARACTERS)
		errors.description = SP_ERROR_FORM_DESCRIPTION_LENGHT;
	if (isEmpty(description))
		errors.description = SP_ERROR_FORM_DESCRIPTION_REQUIRED;
	if (lines.length < 1) errors.lines = SP_ERROR_FORM_LINES;
	return errors;
};
