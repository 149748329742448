import { Grid, Typography } from '@mui/material';

import { IncidentLocationDTO } from '../../../../../models';
import { Checkbox } from '../../../../../packages/ui';

const IncidentReportLocation = ({
	errors,
	handleCheckOneOf,
	isEditableForm,
	locations,
}: IncidentReportLocationProps) => {
	return (
		<>
			<Grid item xs={12}>
				<Typography style={{ fontSize: '1rem' }} variant="subtitle1">
					Localisation
				</Typography>
			</Grid>
			{locations.map((location) => (
				<Grid key={location.id} item xs={4}>
					<Checkbox
						label={location.name}
						onChange={(event) => handleCheckOneOf(event, 'locations')}
						checked={location.checked ?? false}
						name={location.id.toString()}
						disabled={!isEditableForm}
					/>
				</Grid>
			))}
			<Grid item xs={12}>
				<Typography
					style={{ fontSize: '0.86rem', marginTop: -20 }}
					color="error"
				>
					{errors.locations}
				</Typography>
			</Grid>
		</>
	);
};
export default IncidentReportLocation;

type IncidentReportLocationProps = {
	errors: { [key: string]: string };
	handleCheckOneOf: (
		event: React.ChangeEvent<HTMLInputElement>,
		of: string
	) => void;
	isEditableForm?: boolean;
	locations: IncidentLocationDTO[];
};
