import { Dispatch, SetStateAction } from 'react';

import { IncidentSelected } from '../../../models';
import { Button, SingleButtonsBloc } from '..';

const ResolveButtons = ({
	isDFSelected,
	setIsResolvingIncident,
	onValidate,
	onValidatePartially,
	onCancel,
	setIncidentSelected,
	validateDisabled = false,
}: PopInFooterProps) => {
	return (
		<>
			<Button
				onClick={() => {
					onCancel();
				}}
				variant="text"
				style={{
					width: '7rem',
				}}
			>
				Annuler
			</Button>
			<SingleButtonsBloc>
				<Button
					disabled={!isDFSelected}
					onClick={() => {
						onValidatePartially();
						setIsResolvingIncident(false);
						setIncidentSelected(null);
					}}
					style={{
						width: '12rem',
					}}
				>
					Résolution partielle
				</Button>
				<Button
					disabled={validateDisabled}
					onClick={() => {
						onValidate();
						setIsResolvingIncident(false);
						setIncidentSelected(null);
					}}
					style={{
						width: '12rem',
					}}
				>
					Résolution complète
				</Button>
			</SingleButtonsBloc>
		</>
	);
};

export default ResolveButtons;

type PopInFooterProps = {
	isDFSelected: boolean;
	setIsResolvingIncident: Dispatch<SetStateAction<boolean>>;
	onValidate: () => void;
	onValidatePartially: () => void;
	onCancel: () => void;
	setIncidentSelected: Dispatch<SetStateAction<IncidentSelected | null>>;
	validateDisabled?: boolean;
};
