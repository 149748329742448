import { Dispatch, SetStateAction } from 'react';

import { Button } from '../../../packages/ui';

import { StyledSpecialOperationFooter } from './styles';

const Footer = ({ setOpenSpecialOperationForm }: FooterProps) => {
	return (
		<StyledSpecialOperationFooter>
			<Button
				onClick={() => {
					setOpenSpecialOperationForm(true);
				}}
				variant="contained"
			>
				+ Ajouter une opération spéciale
			</Button>
		</StyledSpecialOperationFooter>
	);
};

export default Footer;

export type FooterProps = {
	setOpenSpecialOperationForm: Dispatch<SetStateAction<boolean>>;
};
