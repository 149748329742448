import { ReactNode } from 'react';

import { StyledCardContainer, StyledCardHeader } from './styles';

import '../../../assets/styles/constants/_colors.css';

const DashboardCard = ({ title, isToday, children }: CardProps) => {
	return (
		<StyledCardContainer isToday={isToday}>
			<StyledCardHeader isToday={isToday}>{title}</StyledCardHeader>
			{children}
		</StyledCardContainer>
	);
};

export default DashboardCard;

type CardProps = {
	title?: string;
	isToday: boolean;
	children: ReactNode;
};
