import { PrefixInfo, User } from '../../models';
import {
	LOGIN,
	LOGOUT,
	SET_USER_META,
	UserActionTypes,
} from '../types/user.types';

/**
 * authorization Action functions
 */
export const userLogin = (
	user: User,
	prefixInfo?: PrefixInfo
): UserActionTypes => ({
	prefixInfo,
	type: LOGIN,
	user,
});

export const userLogout = (): UserActionTypes => ({
	type: LOGOUT,
});

export const setUserMeta = (prefixInfo: PrefixInfo): UserActionTypes => ({
	payload: prefixInfo,
	type: SET_USER_META,
});

export const userActions = {
	login: userLogin,
	logout: userLogout,
	setUserMeta,
};

export default userActions;
