import {
	InstantDashboard,
	PeriodDashboard,
	ServiceReport,
} from '../../../models/dashboard.model';
import fetchJson from '../fetch/fetch-json';

const loadInstantDashboard = async (
	lineId: string
): Promise<{
	data: InstantDashboard;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/dashboard/instant-dashboard?lineId=${lineId}`);
};

const loadPeriodDashboard = async (
	line: string,
	startDate: Date,
	endDate: Date
): Promise<{
	data: PeriodDashboard;
	ok: boolean;
	status: number;
	statusText: string;
}> => {

	return await fetchJson(`/dashboard/reporting-period?line=${line}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
	);
};

const loadServiceReport = async (
	line: string,
	startDate: Date,
	endDate: Date
): Promise<{
	data: ServiceReport;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/dashboard/serviceReport/list?line=${line}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
	);
};

export default {
	loadInstantDashboard,
	loadPeriodDashboard,
	loadServiceReport,
};
