import { FC, memo, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isNil from 'lodash/isNil';

import { ReactComponent as Close } from '../../../assets/icons/button/close-small.svg';
import { ReactComponent as WarnTriangle } from '../../../assets/icons/frames/warn-triangle.svg';
import { PopupType } from '../../../models/popup.model';
import { useTypedSelector } from '../../../packages/hooks';
import { hidePopup } from '../../../redux/actions';

import './Popup.css';

const Popup: FC = (): ReactElement => {
	const dispatch = useDispatch();
	const { isVisible, message, title, popupType, hasBackground, isClosable } =
		useTypedSelector(({ popupState }) => popupState);

	let popStylingClass = '';
	switch (popupType) {
		case PopupType.ERROR:
			popStylingClass = 'popup-error';
			break;
		case PopupType.SUCCESS:
			popStylingClass = 'popup-succes';
			break;
		case PopupType.WARN:
			popStylingClass = 'popup-danger';
			break;
		default:
			break;
	}

	useEffect(() => {
		if (!isNil(isClosable) && !isClosable) {
			setTimeout(() => dispatch(hidePopup()), 4000);
		}
	}, [isVisible, isClosable, dispatch]);

	const handleHide = () => {
		dispatch(hidePopup());
	};

	return (
		<>
			{isVisible && (
				<div
					className={hasBackground ? `container-background` : ``}
					id="popup-container"
				>
					<div
						className={`${popStylingClass} container-dimension popup-pos-height`}
						id="popup"
					>
						{title?.includes('Erreur Code ou Ligne') && (
							<WarnTriangle className="mx-auto w-100" />
						)}
						<button
							className="close-icon"
							hidden={!isClosable}
							onClick={handleHide}
							onKeyDown={handleHide}
							type="button"
						>
							<Close />
						</button>
						<div className="popup-header">
							<h3 className="title-text font-paris-bold">{title}</h3>
						</div>
						<div
							className="message-text font-paris"
							dangerouslySetInnerHTML={{ __html: message ?? '' }}
						></div>
					</div>
				</div>
			)}
		</>
	);
};

export default memo(Popup);
