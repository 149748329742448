import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';

import { ReactComponent as ArrowBackIcon } from '../../assets/icons/button/arrow-back.svg';
import { ReactComponent as CloseSemIcon } from '../../assets/icons/button/close-white-sem.svg';
import { useDepartment } from '../../packages/hooks';
import { Endpoints } from '../../routes/endpoint.config';

import LastSync from './lastSync';
import MetroLogo from './logo';

import './styles.css';

const MobileNavBar = () => {
	const navigate = useNavigate();
	const { hasSemDepartment } = useDepartment();
	const { pathname: currentPathname } = useLocation();

	return (
		<>
			{!hasSemDepartment && currentPathname === Endpoints.AGENTN ? (
				<AppBar
					position="sticky"
					className="justify-content-between nav"
					id="nav-phone"
				>
					<Grid
						container
						alignItems="flex-start"
						height="100%"
						justifyContent="center"
					>
						<Grid item xs={4} mt={1}>
							<Link className="pl-md-3 pl-0" to={Endpoints.AGENTN}>
								<div className="smart-tracer-phone p-md-2 font-montserrat-bold">
									Smart Tracer
									<LastSync />
								</div>
							</Link>
						</Grid>
						<Grid item xs={4}>
							{!hasSemDepartment && <MetroLogo />}
						</Grid>
						<Grid item xs={4} textAlign="right" mt={2}>
							<Link
								state={{ previousPage: currentPathname }}
								to={{ pathname: Endpoints.LOGOUT }}
							>
								<span className="smart-tracer-phone-disconnect">
									Se déconnecter
								</span>
							</Link>
						</Grid>
					</Grid>
				</AppBar>
			) : (
				<AppBar className="nav" id="nav-phone">
					<Grid
						container
						justifyContent="flex-start"
						height={100}
						alignItems="center"
					>
						<Link onClick={() => navigate(-1)} to={'#'}>
							{currentPathname === Endpoints.AGENTN_QR_SCANNER ? (
								<Grid item xs={4}>
									<CloseSemIcon />
								</Grid>
							) : (
								<Grid item xs={4}>
									<ArrowBackIcon />
								</Grid>
							)}
						</Link>
						{currentPathname === Endpoints.AGENTN_QR_SCANNER && (
							<Grid item xs={8} ml={10}>
								<div className="smart-tracer-phone p-md-2 font-montserrat-bold">
									Scanner le code
								</div>
							</Grid>
						)}
						{currentPathname === Endpoints.AGENTN_SELECTION && (
							<Grid item xs={6} ml={10}>
								<div className="smart-tracer-phone p-md-2 font-montserrat-bold">
									Choix de la prestation
								</div>
							</Grid>
						)}
						{currentPathname === Endpoints.AGENTN_BY_CODE && (
							<Grid item xs={6} ml={10}>
								<div className="smart-tracer-phone p-md-2 font-montserrat-bold">
									Choisir l'élément
								</div>
							</Grid>
						)}
						{currentPathname === Endpoints.AGENTN_CONFIRM && (
							<Grid item xs={6} ml={10}>
								<div className="smart-tracer-phone p-md-2 font-montserrat-bold">
									Validation
								</div>
							</Grid>
						)}
					</Grid>
				</AppBar>
			)}
		</>
	);
};

export default MobileNavBar;
