import { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { IncidentReport } from '../../models';
import IncidentReportProvider from '../../packages/dataProviders/resources/incidentReport';
import {
	INC_SOLVED_NOT_FOUND,
	INC_SOLVED_SEARCHBAR_LABEL,
} from '../../packages/helpers';
import { SearchBar } from '../../packages/ui';

import IncidentReportSolvedDetail from './detailContent';

const IncidentReportSolved = () => {
	const [incidentReportSolved, setIncidentReportSolved] =
		useState<IncidentReport | null>();

	const handleSubmit = (incidentReportId: number) => {
		IncidentReportProvider.loadIncidentReportDetails(incidentReportId).then(
			({ data }) => {
				setIncidentReportSolved(data);
			}
		);
	};

	return (
		<Grid container spacing={3} sx={{ margin: '2rem auto', width: '80%' }}>
			<Grid item xs={12}>
				<SearchBar
					label={INC_SOLVED_SEARCHBAR_LABEL}
					onSubmit={(value) => handleSubmit(value as number)}
					type="number"
				/>
			</Grid>
			{incidentReportSolved &&
				(incidentReportSolved.solvingDate ? (
					<IncidentReportSolvedDetail incidentReport={incidentReportSolved} />
				) : (
					<Grid item xs={12} textAlign="center" marginTop={6}>
						<Typography variant="body2">{INC_SOLVED_NOT_FOUND}</Typography>
					</Grid>
				))}
		</Grid>
	);
};

export default IncidentReportSolved;
