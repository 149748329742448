import { Stack } from '@mui/material';

import { ConfirmationButtonsProps } from '../../../models/ui';

import MobileConfirmationButton from './MobileConfirmationButton';

const ConfirmationButtons = ({
	partialAction,
	confirmAction,
	isGraffiti,
}: ConfirmationButtonsProps) => {
	return (
		<Stack spacing={8} direction="column" alignItems="center">
			{isGraffiti && (
				<MobileConfirmationButton onClick={partialAction}>
					Partiel
				</MobileConfirmationButton>
			)}

			<MobileConfirmationButton onClick={confirmAction} isDone>
				FINI
			</MobileConfirmationButton>
		</Stack>
	);
};

export default ConfirmationButtons;
