import { Fragment, useEffect, useState } from 'react';

import { UnitCount, UnitStat } from '../../../models';
import { dateTimeService } from '../../../packages/helpers';
import { useLine, useTypedSelector } from '../../../packages/hooks';
import {
	DAILY_PASSAGE_FOR_NORMAL,
	DAILY_PASSAGE_FOR_TERMINUS,
	SEM_HOUR_CHANGE_PASSAGE_1_2,
	SEM_HOUR_PASSAGE_3,
} from '../../sem/constants';
import ServiceCard from '../serviceCard';
import UnitCard from '../unitCard';

const DAYS_IN_YEAR = 365;

const UnitsList = ({ counts, selectedPeriod }: UnitsListProps) => {
	const [unitStat, setUnitStat] = useState<UnitStat[]>([]);
	const { selectedLine } = useLine();
	const { unitCount, unitsStats } = useTypedSelector(
		(state) => state.reportingState
	);

	const getObjectiveNettoyage = () => {
		if (selectedPeriod === dateTimeService.getCurrentYearString()) {
			return DAYS_IN_YEAR * getTotalUnitPassages(unitCount);
		} else {
			return (
				dateTimeService.getTotalDaysInCurrentMonth() *
				getTotalUnitPassages(unitCount)
			);
		}
	};

	const getObjectivePrestaSup = () => {
		if (selectedPeriod === dateTimeService.getCurrentYearString()) {
			return (
				DAYS_IN_YEAR * (unitCount.spUnitCount + unitCount.spTerminusUnitCount)
			);
		} else {
			return (
				dateTimeService.getTotalDaysInCurrentMonth() *
				(unitCount.spUnitCount + unitCount.spTerminusUnitCount)
			);
		}
	};

	const getTheoreticalObjectivePrestaSup = () => {
		if (selectedPeriod === dateTimeService.getCurrentYearString()) {
			return (
				dateTimeService.getDayInYear() *
				(unitCount.spUnitCount + unitCount.spTerminusUnitCount)
			);
		} else {
			return (
				dateTimeService.getCurrentDayInMonth() *
				(unitCount.spUnitCount + unitCount.spTerminusUnitCount)
			);
		}
	};

	const getTheoreticalObjectiveNettoyage = () => {
		if (selectedPeriod === dateTimeService.getCurrentYearString()) {
			return (
				dateTimeService.getDayInYear() * getTotalUnitPassages(unitCount) +
				getObjectiveForToday()
			);
		} else {
			return (
				(dateTimeService.getCurrentDayInMonth() - 1) *
					getTotalUnitPassages(unitCount) +
				getObjectiveForToday()
			);
		}
	};

	const getObjectiveForToday = (): number => {
		const now = new Date();
		if (now.getHours() < SEM_HOUR_CHANGE_PASSAGE_1_2) {
			return unitCount.normalUnitCount + unitCount.terminusUnitCount;
		} else if (now.getHours() >= SEM_HOUR_PASSAGE_3) {
			return getTotalUnitPassages(unitCount);
		} else if (
			now.getHours() >= SEM_HOUR_CHANGE_PASSAGE_1_2 &&
			now.getHours() < SEM_HOUR_PASSAGE_3
		) {
			return (
				unitCount.normalUnitCount * DAILY_PASSAGE_FOR_NORMAL +
				unitCount.terminusUnitCount * DAILY_PASSAGE_FOR_NORMAL
			);
		}

		return 0;
	};

	const getTotalUnitPassages = (unitCount: UnitCount) => {
		return (
			unitCount.normalUnitCount * DAILY_PASSAGE_FOR_NORMAL +
			unitCount.terminusUnitCount * DAILY_PASSAGE_FOR_TERMINUS
		);
	};

	useEffect(() => {
		if (unitsStats) {
			if (selectedPeriod === dateTimeService.getCurrentYearString()) {
				setUnitStat(unitsStats.yearStats);
			} else {
				setUnitStat(unitsStats.monthStats);
			}
		}
	}, [selectedPeriod, unitsStats]);

	return (
		<Fragment>
			{(!selectedLine || selectedLine === '' || selectedLine === 'SEM') && (
				<ServiceCard
					count={counts.prestaSupCount}
					objective={getObjectivePrestaSup()}
					objectiveLabelPrefix={`${counts.prestaSupCount} passages sur`}
					periode={selectedPeriod}
					theoreticalObjectiveAtTime={getTheoreticalObjectivePrestaSup()}
					title="Prestation supplémentaire"
				/>
			)}

			<ServiceCard
				count={counts.nqCount}
				objective={getObjectiveNettoyage()}
				objectiveLabelPrefix={`${counts.nqCount} passages sur`}
				periode={selectedPeriod}
				theoreticalObjectiveAtTime={getTheoreticalObjectiveNettoyage()}
				title="Nettoyage Quotidien"
			/>
			{unitStat && unitStat.length > 0 && (
				<UnitCard
					periode={selectedPeriod}
					title="Unités manquantes"
					units={unitStat}
				/>
			)}
		</Fragment>
	);
};

export default UnitsList;

type UnitsListProps = {
	counts: {
		ncCount: number;
		ncrCount: number;
		npCount: number;
		nqCount: number;
		prestaSupCount: number;
	};
	selectedPeriod: string;
};
