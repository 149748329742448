import { styled } from '@mui/system';

export const StyledConfirmMobile = styled('div')<{
	isPartial?: boolean;
}>(({ isPartial }) => ({
	alignItems: 'center',
	backgroundColor: isPartial
		? 'var(--bg-color-orange)'
		: `var(--bg-color-jade)`,
	color: 'white',
	display: 'flex',
	flexDirection: 'column',
	height: '100vh',
	justifyContent: 'center',
	width: '100%',
}));
