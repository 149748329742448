import { User } from '../../../models';
import fetchJson from '../fetch/fetch-json';

const createUser = async (
	user: User
): Promise<{
	data: {
		result: string;
		token?: string;
		message?: string;
		code?: string | number;
		type?: string;
	};
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const options = {
		body: JSON.stringify(user),
		method: 'POST',
	};
	return await fetchJson(`/user`, options);
};

const updateUser = async (
	user: User
): Promise<{
	data: {
		result: string;
		token?: string;
		message?: string;
		code?: string | number;
		type?: string;
	};
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const options = {
		body: JSON.stringify(user),
		method: 'put',
	};
	return await fetchJson(`/user/${user.id}`, options);
};

const deleteUser = async (
	id: number
): Promise<{
	data: string;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/user/${id}`, { method: 'DELETE' });
};

export default { createUser, deleteUser, updateUser };
