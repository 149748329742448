import { ThunkAction } from 'redux-thunk';

import RightProvider from '../../packages/dataProviders/resources/right';
import {rightService} from '../../packages/dataProviders/rightService/';
import { errorConf } from '../../packages/helpers/popup/exception.utils';
import { setRight } from '../actions/right.actions';
import { createRight,setRightByType, updateRight} from '../actions/rightByType.actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { RIGHT } = components;
const { RIGHT_BY_TYPE } = components;

type RightThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const loadRight =
	(): RightThunk =>
	async (
		dispatch, _, api
	) => {
		try {
			dispatch(api._request(RIGHT));
			const { data: res }  = await RightProvider.loadRight();
			const right = res
			dispatch(setRight(right));
		} catch (e) {
			dispatch(api._error(RIGHT, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};


	export const loadRightByType =
	(): RightThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(RIGHT_BY_TYPE));
			const { data: res }  = await RightProvider.loadRightByType();
			const rightByTypes = res
			dispatch(setRightByType(rightByTypes));
		} catch (e) {
			dispatch(api._error(RIGHT_BY_TYPE, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};	

	export const updateRightThunk =
 (IdRight: number): RightThunk =>
 async (dispatch) => {

		try {

	const { data: res }  = await RightProvider.updateRight(IdRight);
	const right = res; 
 dispatch(updateRight(right));
		} catch (e) {
console.error(e);

		}
	};



export const createRightThunk =
 (idRight: number, idRole: number): RightThunk =>
 async (dispatch) => {
	try {
		const res = await rightService.createRight(idRight, idRole);
		const right = res;
		dispatch(createRight(right)	);
	} catch (e) {
		console.error(e);
	}
};