import { Grid } from '@mui/material';

import { ServicePeriodReporting } from '../../../../../models/dashboard.model';
import { ServiceTypeCodeMap } from '../../../../../models/serviceType.model';
import { pluralize } from '../../../../../packages/helpers';
import { Divider } from '../../../../../packages/ui';

const ServiceReporting = ({
	count,
	serviceType,
	lowLimitCount,
	hightLimitCount,
	lowLimit,
	hightLimit,
}: ServicePeriodReporting) => {
	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					backgroundColor: 'var(--bg-color-green-dark-opacity)',
					height: '15vh',
				}}
			>
				<Grid
					item
					xs={6}
					display="flex"
					alignItems="center"
					flexDirection="column"
					fontWeight={'bold'}
					fontSize={'2.2rem'}
				>
					{count}
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					alignItems="center"
					flexDirection="column"
					fontSize={'1rem'}
					style={{ marginRight: '1vw' }}
				>
					{ServiceTypeCodeMap.get(serviceType)}{' '}
					{pluralize(Number(count), 'réalisé')}
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					height: '15vh',
				}}
			>
				<Grid
					item
					xs={6}
					display="flex"
					alignItems="center"
					flexDirection="column"
					fontSize={'1.4rem'}
					fontWeight={'bold'}
				>
					{lowLimitCount}
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					alignItems="center"
					fontSize={'0.85rem'}
					style={{ marginRight: '1vw' }}
				>
					{ServiceTypeCodeMap.get(serviceType)}{' '}
					{pluralize(Number(lowLimitCount), 'réalisé')} avec un délai de moins
					de {lowLimit} {pluralize(Number(lowLimit), 'jour')}
				</Grid>
			</Grid>
			<Divider />
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					height: '15vh',
				}}
			>
				<Grid
					item
					xs={6}
					display="flex"
					alignItems="center"
					flexDirection="column"
					fontSize={'1.4rem'}
					fontWeight={'bold'}
				>
					{hightLimitCount}
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					alignItems="center"
					fontSize={'0.85rem'}
					style={{ marginRight: '1vw' }}
				>
					{ServiceTypeCodeMap.get(serviceType)}{' '}
					{pluralize(Number(hightLimitCount), 'réalisé')} avec un délai de plus
					de {hightLimit} {pluralize(Number(hightLimit), 'jour')}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ServiceReporting;
