import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	IncidentLocationDTO,
	IncidentRailcarDTO,
	IncidentSelected,
} from '../../../../models/incidentReport.model';
import IncidentReportProvider from '../../../../packages/dataProviders/resources/incidentReport';
import {
	ModalesState,
	useDepartment,
	useForm,
} from '../../../../packages/hooks';
import { useIncidentType } from '../../../../packages/hooks/useIncidentType';
import { Dialog, ValidationButtons } from '../../../../packages/ui';
import { createAndSetIncidentReport } from '../../../../redux/thunks/incidentReport';
import {
	getEmptyIncidentReport,
	getIncidentType,
	validate,
} from '../../../incidentReport/config';
import IncidentReportFormContentComponent from '../IncidentReportFormContent';

const IncidentReportForm = ({
	incidentSelected,
	open,
	rsCode,
	selectedLine,
	setIncidentSelected,
	handleCloseModal,
	trainId,
}: IncidentReportFormProps) => {
	const [locations, setLocations] = useState<IncidentLocationDTO[]>([]);
	const [railcars, setRailcars] = useState<IncidentRailcarDTO[]>([]);
	const { incidentTypesCreate } = useIncidentType();
	const dispatch = useDispatch();
	const { departmentEnum } = useDepartment();

	const modalTitle = `Signalement d'une anomalie : ${incidentSelected.incident?.name}`;

	const handleCloseIncidentReportForm = () => {
		handleCloseModal();
		setIncidentSelected(null);
		handleClose();
	};

	const handleCreateIncidentReport = () => {
		const incidentType = getIncidentType(
			incidentTypesCreate,
			values.incidentTypeId
		);
		dispatch(createAndSetIncidentReport(values, incidentType));
		handleCloseIncidentReportForm();
	};

	const {
		errors,
		handleChange,
		handleCheck,
		handleCheckOneOf,
		handleCheckChange,
		handleClose,
		handleDateChange,
		handleElementSelectOneOf,
		handleSelect,
		handleSubmit,
		files,
		isUntouched,
		setValues,
		values,
		handleFiles,
		handleDeleteFile,
	} = useForm({
		callback: handleCreateIncidentReport,
		initFormValues: getEmptyIncidentReport(
			incidentSelected?.incident?.id,
			selectedLine
		),
		validate,
		validateOptions: { department: departmentEnum },
	});

	useEffect(() => {
		if (incidentSelected.incident)
			IncidentReportProvider.loadIncidentLocations(
				incidentSelected?.incident?.id
			).then(({ data: locations }) => setLocations(locations));

		IncidentReportProvider.loadIncidentRailCars(trainId).then(
			({ data: railcars }) => setRailcars(railcars)
		);
	}, [incidentSelected]);

	useEffect(() => {
		setValues((values: any) => ({
			...values,
			incidentTypeId: incidentSelected?.incident?.id,
			locations: locations,
			railcars: railcars,
			rollingStockCode: rsCode,
			rollingStockId: trainId,
		}));
	}, [locations, railcars]);

	return (
		<Dialog
			open={open}
			titleContent={modalTitle}
			content={
				<IncidentReportFormContentComponent
					errors={errors}
					files={files}
					handleChange={handleChange}
					handleCheck={handleCheck}
					handleCheckChange={handleCheckChange}
					handleCheckOneOf={handleCheckOneOf}
					handleDateChange={handleDateChange}
					handleDeleteFile={handleDeleteFile}
					handleElementSelectOneOf={handleElementSelectOneOf}
					handleFiles={handleFiles}
					handleSelect={handleSelect}
					incidentReport={values}
					setValues={setValues}
				/>
			}
			actionsContent={
				<ValidationButtons
					onCancel={handleCloseIncidentReportForm}
					onValidate={handleSubmit}
					validateDisabled={isUntouched}
				/>
			}
			onClose={handleCloseIncidentReportForm}
		/>
	);
};

export default IncidentReportForm;

type IncidentReportFormProps = {
	incidentSelected: IncidentSelected;
	open: boolean;
	rsCode?: string;
	selectedLine: string;
	setIncidentSelected: Dispatch<SetStateAction<any>>;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	trainId: number;
};
