import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';

import { Checkbox } from '../../../../../packages/ui';

import ValidationIcon from './ValidationIcon';
import ValidationLabel from './ValidationLabel';

import '../../../../../assets/styles/constants/_colors.css';

const IncidentValidationSendSur = ({
	handleCheckChange,
	surface,
	impactedRailcar,
	photos,
	degradationSite,
	files,
	sent,
	isEditableForm,
}: IncidentValidationSendSurProps) => {
	const [isValid, setIsValid] = useState({
		hasDegradationSite: false,
		hasPhoto: false,
		hasSurface: false,
	});
	const isDisable: boolean =
		Object.values(isValid).find((value) => value === false) !== undefined;

	useEffect(() => {
		setIsValid({
			...isValid,
			hasDegradationSite: !isNil(degradationSite),
			hasPhoto:
				(!isNil(files) && !isEmpty(files)) ||
				(!isNil(photos) && !isEmpty(photos)),
			hasSurface: (!isNil(surface) && surface > 0) || !isNil(impactedRailcar),
		});
	}, [degradationSite, surface, impactedRailcar, files, photos]);

	useEffect(() => {
		const isDisable: boolean =
			Object.values(isValid).find((value) => value === false) !== undefined;
		if (sent && isDisable) {
			handleCheckChange({
				target: { checked: false, name: 'sent' },
			} as React.ChangeEvent<HTMLInputElement>);
		}
	}, [isValid]);

	return (
		<Grid item xs={12}>
			<Grid item xs={10}>
				<Typography
					variant="h6"
					fontSize={14}
					fontStyle={'italic'}
					fontWeight={'light'}
					color={!isEditableForm ? '#B0B0B0' : 'black'}
				>
					Transmettre la fiche si cette dernière contient suffisamment
					d'informations afin de justifier de son utilité auprès des services de
					SUR. Vous pourrez compléter et envoyer à SUR ultérieurement en
					"modifiant" l'anomalie.
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				display={'flex'}
				flexDirection={'column'}
				paddingTop={2}
			>
				<Grid item xs={12} display={'flex'} alignItems={'center'}>
					<ValidationIcon hasValue={isValid.hasDegradationSite} />
					<ValidationLabel
						label="Site de dégradation"
						isEditableForm={isEditableForm}
					/>
				</Grid>
				<Grid item xs={12} display={'flex'} alignItems={'center'}>
					<ValidationIcon hasValue={isValid.hasPhoto} />
					<ValidationLabel label="Photo(s)" isEditableForm={isEditableForm} />
				</Grid>
				<Grid item xs={12} display={'flex'} alignItems={'center'}>
					<ValidationIcon hasValue={isValid.hasSurface} />
					<ValidationLabel
						label="Surface graffée"
						isEditableForm={isEditableForm}
					/>
				</Grid>
			</Grid>
			<Grid item xs={10} paddingTop={3} display={'flex'}>
				<Checkbox
					name="sent"
					label=""
					checked={sent}
					onChange={handleCheckChange}
					disabled={isDisable || !isEditableForm}
				/>
				<Grid item xs={10}>
					<Typography
						variant="h6"
						fontSize={16}
						fontWeight={'light'}
						color={isDisable || !isEditableForm ? '#B0B0B0' : 'black'}
					>
						Transmettre à SUR la fiche de signalement de dégradation de matériel
						par graffiti.
					</Typography>
					<Typography
						variant="h6"
						fontSize={16}
						fontStyle={'italic'}
						fontWeight={'light'}
						color={isDisable || !isEditableForm ? '#B0B0B0' : 'black'}
					>
						Après l'envoi, il ne sera plus possible de modifier le signalement.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default IncidentValidationSendSur;

type IncidentValidationSendSurProps = {
	handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	sent: boolean;
	surface?: number;
	impactedRailcar?: { id: number; name: number };
	photos?: string[];
	isEditableForm?: boolean;
	degradationSite?: { id: number; name: string };
	files?: { name: string; lastModified: number }[];
};
