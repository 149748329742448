import { Role } from '../../models';

export interface RolesState {
	roles: Role[];
}

export const SET_ROLE = 'SET_ROLE';

export interface SetRole {
	type: typeof SET_ROLE;
	payload: Role[];
}

export type RoleActionTypes = SetRole;
