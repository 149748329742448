import { initEspace } from '../config/initialState';
import {
	EspaceState,
	SWITCH_ESPACE,
	SwitchEspaceActionTypes,
} from '../types/espace.types';

export const espaceReducer = (
	state = initEspace,
	action: SwitchEspaceActionTypes
): EspaceState => {
	if (action.type === SWITCH_ESPACE) {
		return {
			...state,
			espace: action.payload,
		};
	} else {
		return state;
	}
};
