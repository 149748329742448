import { RightByType } from '../../models/rightByType.model';

export type RightByTypeState = RightByType[];

export interface UpdateRightPayload {
	updatedRight: RightByType[];
}

export interface CreateRightPayload {
	createRight: RightByType[];
}

export const SET_RIGHT_BY_TYPE = 'SET_RIGHT_BY_TYPE';
export const UPDATE_RIGHT_BY_TYPE = 'UPDATE_RIGHT_BY_TYPE';
export const CREATE_RIGHT_BY_TYPE = 'CREATE_RIGHT_BY_TYPE';
export const LOAD_NEW_RIGHT_BY_TYPE = 'LOAD_NEW_RIGHT_BY_TYPE';

export interface SetRightByTypeAction {
	type: typeof SET_RIGHT_BY_TYPE;
	payload: { state: RightByType[] };
}

export interface UpdateRightByTypeAction {
	type: typeof UPDATE_RIGHT_BY_TYPE;
	payload: UpdateRightPayload;
}

export interface CreateRightByTypeAction {
	type: typeof CREATE_RIGHT_BY_TYPE;
	payload: CreateRightPayload;
}

export type RightByTypeActionTypes =
	| SetRightByTypeAction
	| UpdateRightByTypeAction
	| CreateRightByTypeAction;
