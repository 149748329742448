import { useState } from 'react';
import { isNil } from 'lodash';

import { IncidentSelected } from '../../models';
import { pluralize } from '../../packages/helpers';
import {
	ModalesState,
	useDepartment,
	useLine,
	useTypedSelector,
} from '../../packages/hooks';
import RollingStockAvailabilityCreateForm from '../modals/availability/availabilityCreateForm';
import AvailabiltyDetails from '../modals/availability/availabilityEditForm';
import RollingStockAvailabilitySelect from '../modals/availability/availabilitySelect';

const RollingStockAvailability = ({
	colorNp,
	handleCloseModal,
	handleOpenModal,
	openModalState,
	trainId,
}: RollingStockAvailabilityProps) => {
	const { hasRerDepartment } = useDepartment();
	const [incidentSelected, setIncidentSelected] =
		useState<IncidentSelected | null>(null);
	const [isEditableMode, setIsEditableMode] = useState(false);

	const { selectedLine } = useLine();

	const canOpenModal = (): boolean => {
		return !!selectedLine && !!trainId;
	};

	const rollingStockList = useTypedSelector(
		({ rollingStockState }) => rollingStockState.rollingStocks
	);

	const rsId = rollingStockList.find(({ id }) => id === trainId)?.trainCode;
	const NpDeltaTime = rollingStockList.find(
		({ id }) => id === trainId
	)?.NpDeltaTime;

	const trainLabel = `${hasRerDepartment ? "de l'élément :" : 'du train :'}`;
	const trainNumber = `Numéro ${trainLabel}`;
	const daysCount = isNil(NpDeltaTime)
		? ''
		: `${NpDeltaTime} ${pluralize(Number(NpDeltaTime), 'jour')}`;

	return (
		<>
			{canOpenModal() && (
				<>
					<RollingStockAvailabilitySelect
						colorNp={colorNp}
						daysCount={daysCount}
						incidentSelected={incidentSelected}
						open={openModalState.availabilitySelect}
						rsId={rsId}
						setIncidentSelected={setIncidentSelected}
						handleCloseModal={handleCloseModal}
						handleOpenModal={handleOpenModal}
						trainId={trainId}
						trainNumber={trainNumber}
					/>
					{incidentSelected && trainId && (
						<RollingStockAvailabilityCreateForm
							colorNp={colorNp}
							daysCount={daysCount}
							incidentSelected={incidentSelected}
							open={openModalState.availabilityCreateForm}
							rsId={rsId}
							setIncidentSelected={setIncidentSelected}
							setIsEditableMode={setIsEditableMode}
							handleCloseModal={handleCloseModal}
							handleOpenModal={handleOpenModal}
							trainId={trainId}
							trainNumber={trainNumber}
						/>
					)}

					<AvailabiltyDetails
						colorNp={colorNp}
						daysCount={daysCount}
						isEditableMode={isEditableMode}
						open={openModalState.availabilityEditForm}
						rsId={rsId}
						setIsEditableMode={setIsEditableMode}
						handleCloseModal={handleCloseModal}
						trainId={trainId}
						trainNumber={trainNumber}
					/>
				</>
			)}
		</>
	);
};

export default RollingStockAvailability;

type RollingStockAvailabilityProps = {
	colorNp: string;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	openModalState: ModalesState;
	trainId: number | undefined;
};
