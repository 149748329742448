import { EspaceEnum, User } from '../../../models';
import fetchJson from '../fetch/fetch-json';

const loadCredentials = async (
	espace: EspaceEnum
): Promise<{
	data: User[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/user/authority?userEspace=${espace}`);
};

export default { loadCredentials };
