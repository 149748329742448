import { ThunkAction } from 'redux-thunk';

import { Unit, UnitQrFormat } from '../../models';
import { unitService } from '../../packages/dataProviders';
import { errorConf } from '../../packages/helpers';
import {
	setReportingUnitCount,
	setReportingUnitStat,
	setSurface,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { UNIT } = components;

type UnitThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const loadUnit =
	(
		newSurface: UnitQrFormat,
		successCb?: () => void,
		errorCb?: () => void
	): UnitThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(UNIT));
			const { idStation, ligne, seqUnite } = newSurface;
			if (!Number.isInteger(idStation) || !Number.isInteger(seqUnite)) {
				console.error('Wrong QRCode format');
			}
			const res = await unitService.getUnit(
				ligne,
				Number(idStation),
				Number(seqUnite)
			);
			if (res.ok) {
				const unit = res.message as Unit;
				dispatch(
					setSurface({
						lineId: unit.line,
						sequenceId: unit.sequence,
						stationId: unit.StationId,
						stationName: unit.stationName,
						unitName: unit.name,
					})
				);
				dispatch(api._response(UNIT));
				if (successCb) successCb();
			} else if (res.isOffline) {
				dispatch(api._response(UNIT));
				dispatch(
					setSurface({
						lineId: ligne,
						sequenceId: parseInt(seqUnite, 10),
						stationId: parseInt(idStation, 10),
					})
				);
				if (successCb) successCb();
			} else {
				dispatch(api._error(UNIT, res.message));
			}
		} catch (e) {
			api._error(UNIT, e);
			console.error(e);
			if (errorCb) errorCb();
		}
	};

export const loadUnitCount =
	(lineId: string): UnitThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(UNIT));
			const res = await unitService.getCountUnits(lineId);
			dispatch(setReportingUnitCount(res));
			dispatch(api._response(UNIT));
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(UNIT, e));
		}
	};

export const loadUnitStats =
	(lineId: string, beginDate: Date, endDate: Date): UnitThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(UNIT));
			const res = await unitService.getUnitStats(beginDate, endDate, lineId);
			dispatch(setReportingUnitStat(res));
			dispatch(api._response(UNIT));
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(UNIT, e));
		}
	};
