import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, Typography } from '@mui/material';

import { ReactComponent as SquareBlue } from '../../../../assets/icons/button/square-blue.svg';
import { ReactComponent as SettingLogo } from '../../../../assets/icons/navigator/setting-filled.svg';
import { SpecialOperationType } from '../../../../models/specialOperationType.model';
import { pluralize } from '../../../../packages/helpers';
import {
	CLOSE_BUTTON_TEXT,
	CLOSE_CONFIRMATION_HEADER,
} from '../../../../packages/helpers/explanations/specialOperation';
import {
	CLOSE_SPECIAL_OPERATION_TYPE,
	UPDATE_SPECIAL_OPERATION_TYPE,
} from '../../../../packages/helpers/rigths';
import { useLine, useRight } from '../../../../packages/hooks';
import { IconButton, Modal } from '../../../../packages/ui';
import { EditIcon } from '../../../../packages/ui/icons';
import { closeAndSetSpecialOperationType } from '../../../../redux/thunks/specialOperationType';
import { Endpoints } from '../../../../routes/endpoint.config';
import ConfirmationContent from '../confirmationContent';

import {
	StyledCardContainer,
	StyledEndCardContainer,
	StyledSettingLogo,
} from './styles';

const CardOpInProgess = ({
	specialOperationType,
	setSpecialOperationToEdit,
	setOpenSpecialOperationTypeEditForm,
}: CardOpInProgessProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { allLines } = useLine();
	const { can } = useRight();
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const showManageElements = specialOperationType.lines.some((line) =>
		allLines.includes(line)
	);

	const handleEditClick = () => {
		setSpecialOperationToEdit(specialOperationType);
		setOpenSpecialOperationTypeEditForm(true);
	};

	const handleCloseSpecialOperationType = () => {
		dispatch(closeAndSetSpecialOperationType(specialOperationType));
		setOpenConfirmationModal(false);
	};

	return (
		<>
			<Grid item xs={10} key={specialOperationType.name} marginBottom="16px">
				<StyledCardContainer>
					<Grid container paddingLeft="8px">
						<Grid container xs={12}>
							<Grid item xs={6}>
								<Typography variant="h4" marginTop="12px" marginBottom="12px">
									Opération spéciale : {specialOperationType.name}
								</Typography>
							</Grid>
							{showManageElements && (
								<Grid item xs={6} paddingRight={2}>
									<Grid container justifyContent="flex-end">
										{can(UPDATE_SPECIAL_OPERATION_TYPE) && (
											<IconButton onClick={handleEditClick} bgcolor="primary">
												<EditIcon />
												<Typography paddingLeft={1} variant="h6">
													Modifier
												</Typography>
											</IconButton>
										)}
										{can(CLOSE_SPECIAL_OPERATION_TYPE) && (
											<IconButton
												onClick={() => setOpenConfirmationModal(true)}
												bgcolor="primary"
											>
												<SquareBlue />
												<Typography paddingLeft={1} variant="h6">
													{CLOSE_BUTTON_TEXT}
												</Typography>
											</IconButton>
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid container xs={12} marginRight="46px">
							{specialOperationType.description}
						</Grid>
						<Divider
							sx={{
								borderBottomWidth: '2px',
								borderColor: 'secondary',
								marginBottom: '8px',
								marginTop: '8px',
								width: '100%',
							}}
						/>
						<Grid container xs={12} marginBottom="12px" marginRight="4px">
							<Grid item xs={2} marginTop="2px">
								{pluralize(specialOperationType.lines.length, 'Ligne')}{' '}
								{pluralize(specialOperationType.lines.length, 'concernée')}:
							</Grid>
							<Typography fontSize="16px" fontWeight="bold">
								{specialOperationType.lines.join(', ')}
							</Typography>
						</Grid>
					</Grid>
				</StyledCardContainer>
			</Grid>
			{showManageElements && (
				<Grid
					item
					xs={2}
					display="flex"
					justifyContent="center"
					marginBottom="16px"
					onClick={() =>
						navigate(`${Endpoints.OPRS}?name=${specialOperationType.name}`)
					}
				>
					<StyledEndCardContainer>
						<Grid item xs={3} display="flex" justifyContent="flex-end">
							<StyledSettingLogo>
								<SettingLogo id="svg-setting-logo" />
							</StyledSettingLogo>
						</Grid>
						<Grid item xs={9} display="flex" justifyContent="flex-start">
							Gérer les éléments impactés
						</Grid>
					</StyledEndCardContainer>
				</Grid>
			)}
			<Modal
				content={
					<ConfirmationContent
						handleConfirm={handleCloseSpecialOperationType}
						handleClose={() => setOpenConfirmationModal(false)}
						name={specialOperationType.name}
					/>
				}
				headerContent={CLOSE_CONFIRMATION_HEADER}
				onClose={() => setOpenConfirmationModal(false)}
				open={openConfirmationModal}
			/>
		</>
	);
};

export default CardOpInProgess;

export type CardOpInProgessProps = {
	specialOperationType: SpecialOperationType;
	setSpecialOperationToEdit: Dispatch<
		SetStateAction<SpecialOperationType | undefined>
	>;
	setOpenSpecialOperationTypeEditForm: Dispatch<SetStateAction<boolean>>;
};
