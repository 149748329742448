import { Typography } from '@mui/material';

import {
	CLOSE_CONFIRMATION_CONTENT_PART1,
	CLOSE_CONFIRMATION_CONTENT_PART2,
} from '../../../../packages/helpers/explanations/specialOperation';
import { ValidationButtons } from '../../../../packages/ui';

const ConfirmationContent = ({
	handleConfirm,
	handleClose,
	name,
}: ConfirmationContentProps) => {
	return (
		<>
			<Typography variant="body1" mb={4}>
				{CLOSE_CONFIRMATION_CONTENT_PART1}
				<span style={{ fontWeight: 'bold' }}>{name}</span>
				{CLOSE_CONFIRMATION_CONTENT_PART2}
			</Typography>
			<ValidationButtons onCancel={handleClose} onValidate={handleConfirm} />
		</>
	);
};

export default ConfirmationContent;

type ConfirmationContentProps = {
	name: string;
	handleClose: () => void;
	handleConfirm: () => void;
};
