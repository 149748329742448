import appEnv from '../../configuration/appEnv';

const fetchJson = async (
	url: string,
	options = {},
	prefix = true,
	isFormData = false
) => {
	const versionPrefix = prefix ? '/v1' : '';

	const endPoint = appEnv.backendUrl ?? 'api/';
	const computedOptions = {
		...options,
		credentials: 'include' as RequestCredentials,
		headers: isFormData
			? undefined
			: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  },
		redirect: 'follow' as RequestRedirect,
	};

	return fetch(`${endPoint}${versionPrefix}${url}`, computedOptions)
		.then(async (response) => {
			try {
				const data = await response.json();
				return {
					data,
					ok: response.ok,
					status: response.status,
					statusText: response.statusText,
				};
			} catch (error) {
				throw new Error('Erreur de communication avec le serveur');
			}
		})
		.catch((e) => {
			throw new Error(e);
		});
};

export default fetchJson;
