import { FC, ReactElement } from 'react';
import { isEmpty } from 'lodash';

import './TitleSubtitle.css';

type Props = {
	title: string;
	subtitle1?: string;
	subtitle2?: string;
	subtitle3?: string;
};

const TitleSubtitle: FC<Props> = ({
	title,
	subtitle1,
	subtitle2,
	subtitle3,
}): ReactElement => {
	return (
		<div className="title-container">
			{!isEmpty(subtitle3) && (
				<h4 className="sub-title font-montserrat-thin font-grey">
					{subtitle3}
				</h4>
			)}
			<h3 className="main-title font-paris  " style={{ color: '#00AA91' }}>
				{title}
			</h3>
			<h4
				className="sub-title font-paris font-blue"
				style={{ color: '#0A0082' }}
			>
				{subtitle1}
			</h4>
			{!isEmpty(subtitle2) && (
				<h4 className="sub-title font-montserrat-thin font-grey font-italic">
					{subtitle2}
				</h4>
			)}
		</div>
	);
};

export default TitleSubtitle;
