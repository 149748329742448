export interface PassageState {
	selectedPassage: number;
}

export const SET_SELECTED_PASSAGE = 'SET_SELECTED_PASSAGE';

export interface SetSelectedPassage {
	type: typeof SET_SELECTED_PASSAGE;
	payload: number;
}

export type PassageActionTypes = SetSelectedPassage;
