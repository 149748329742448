import { ThunkAction } from 'redux-thunk';

import { localStorageFuncs } from '../../packages/helpers';
import { setSelectedLine } from '../actions/line.actions';
import { ActionTypes, ApiThunks, AppStore } from '../types';

type LineThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const getSelectedLineUser = (): LineThunk => async (dispatch) => {
  if (!localStorageFuncs.get('selected-line')) {
    localStorageFuncs.set('selected-line', '');
    dispatch(setSelectedLine(''));
  }
  const selectedLineUser = localStorage.getItem('selected-line');
  if (selectedLineUser) dispatch(setSelectedLine(selectedLineUser));
};

export const setSelectedLineUser =
  (line: string): LineThunk =>
  async (dispatch) => {
    localStorageFuncs.set('selected-line', line);
    dispatch(setSelectedLine(line));
  };
