import { first, isEmpty, isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Department, DepartmentEnum, RoleEnum, User } from '../../models';

import { localStorageFuncs } from './persistent.utils';

export function hasRole(user: User, roleIds: RoleEnum[]) {
	return !!user.roles.find((role) => roleIds.includes(role.id));
}

export function getRole(user: User): RoleEnum | -1 {
	return first(user.roles)?.id ?? -1;
}

/**
 * Checks if {departmentIds} include one of user's departments.
 * @param user
 * @param departmentIds
 * @returns
 */
export function hasSomeDepartments(
	user: User,
	departmentIds: DepartmentEnum[]
) {
	return !!user.departments.find((department) =>
		departmentIds?.includes(department.id)
	);
}

/**
 * Checks if user has the {type} department
 * @param user
 * @param type
 * @returns
 */
export function hasDepartment(user: User, type: DepartmentEnum): boolean {
	if (!user) return false;
	const departements = user.departments;
	return (
		!isEmpty(departements) &&
		departements.some((departement) => departement.id === type)
	);
}

/**
 * Checks if {departments} include {dep} department
 * @param dep
 * @param departments
 * @returns
 */
export function isDepartmentIn(
	dep: DepartmentEnum,
	departments: Department[] | undefined
) {
	if (isNil(departments)) return false;
	const deps = departments.map((el) => el.id);
	return deps.includes(dep);
}

export function getUuid() {
	if (!localStorageFuncs.get('smtr-uuid')) {
		localStorageFuncs.set('smtr-uuid', uuidv4());
	}
	return localStorage.getItem('smtr-uuid');
}
