import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { isNil } from 'lodash';

import { initializeAppEnv } from '../packages/configuration/initializeAppEnv';
import { localStorageFuncs } from '../packages/helpers';
import { NotifyCode } from '../packages/helpers/popup/exception.config';
import { Popup } from '../packages/ui';
import initializeMuiPro from '../packages/ui/muiPro';
import theme from '../packages/ui/theme';
import Routes from '../routes/routes';

import '../assets/styles/custom.css';

const App = () => {
	const [advertising, setAdvertising] = useState('Chargement...');
	const [isLoading, setIsLoading] = useState<boolean>(
		!isNil(window.___STTRCONF___)
	);

	const handleLoadConf = async () => {
		if (!isNil(window.___STTRCONF___)) {
			const env = initializeAppEnv();
			localStorageFuncs.set('smtr-appEnv', JSON.stringify(env));
			setIsLoading(false);
		} else {
			setAdvertising(NotifyCode.ERR_TECH_027);
		}
	};

	useEffect(() => {
		handleLoadConf();
		initializeMuiPro();

		const originalSetItem = window.localStorage.setItem;
		localStorage.setItem = function (key, value) {
			if (key === 'lastSyncTimeStamp') {
				const event = new Event('newLastSyncTimeStamp') as CustomEvent;
				event.value = value;
				event.key = key;
				document.dispatchEvent(event);
			}
			originalSetItem.apply(this, [key, value]);
		};

		// listens for last update events sent by the serviceWorker
		if (navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('message', (event) => {
				if (event.data?.timeStamp) {
					window.localStorage.setItem(
						'lastSyncTimeStamp',
						event.data.timeStamp
					);
				}
			});
		}
		window.addEventListener('sttrConfReady', handleLoadConf);

		return () => {
			window.removeEventListener('sttrConfReady', handleLoadConf);
		};
	}, []);

	if (isLoading) {
		return <div>{advertising}</div>;
	}

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes />
			</BrowserRouter>
			<Popup />
		</ThemeProvider>
	);
};

export default App;

interface CustomEvent extends Event {
	value: string;
	key: string;
}

declare global {
	interface Window {
		___STTRCONF___: {
			backendUrl: string;
			env: string;
			muiLicense?: string;
			version?: string;
		};
	}
}
