import { useState } from 'react';
import { Grid } from '@mui/material';

import SpecialOperationTypeCreateForm from '../../components/modals/specialOperationForm/createForm';
import SpecialOperationTypeEditForm from '../../components/modals/specialOperationForm/editForm';
import Footer from '../../components/specialOperation/footer';
import Header from '../../components/specialOperation/header';
import OpInProgress from '../../components/specialOperation/inProgress';
import OpReporting from '../../components/specialOperation/reporting';
import { SpecialOperationType } from '../../models';
import { CREATE_SPECIAL_OPERATION_TYPE } from '../../packages/helpers/rigths';
import { useRight } from '../../packages/hooks';

const SpecialOperationPage = () => {
	const { can } = useRight();
	const [
		openSpecialOperationTypeCreateForm,
		setOpenSpecialOperationTypeCreateForm,
	] = useState(false);
	const [
		openSpecialOperationTypeEditForm,
		setOpenSpecialOperationTypeEditForm,
	] = useState(false);
	const [specialOperationToEdit, setSpecialOperationToEdit] =
		useState<SpecialOperationType>();
	return (
		<main>
			<Header />
			<Grid container height="100%" padding="24px">
				<OpInProgress
					setOpenSpecialOperationTypeEditForm={
						setOpenSpecialOperationTypeEditForm
					}
					setSpecialOperationToEdit={setSpecialOperationToEdit}
				/>
				<OpReporting />
			</Grid>
			{can(CREATE_SPECIAL_OPERATION_TYPE) && (
				<Footer
					setOpenSpecialOperationForm={setOpenSpecialOperationTypeCreateForm}
				/>
			)}
			<SpecialOperationTypeCreateForm
				openSpecialOperationForm={openSpecialOperationTypeCreateForm}
				setOpenSpecialOperationForm={setOpenSpecialOperationTypeCreateForm}
			/>
			{specialOperationToEdit && (
				<SpecialOperationTypeEditForm
					open={openSpecialOperationTypeEditForm}
					setOpen={setOpenSpecialOperationTypeEditForm}
					specialOperationType={specialOperationToEdit}
					setSpecialOperationToEdit={setSpecialOperationToEdit}
				/>
			)}
		</main>
	);
};

export default SpecialOperationPage;
