import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { UsedRs } from '../../../models/usedRs.model';
import { capitalize } from '../../../packages/helpers';
import {
	useDepartment,
	useLine,
	useTypedSelector,
} from '../../../packages/hooks';
import { Button, DataGridPro, Spinner, Switch } from '../../../packages/ui';
import theme from '../../../packages/ui/theme';
import {
	loadUsedRs,
	toggleUsedStatus,
} from '../../../redux/thunks/rollingStockUsed';

const UsedRollingStockList = ({ usedRs }: { usedRs: UsedRs[] }) => {
	const dispatch = useDispatch();
	const { rsLabel, rsLabelwithUndefinedArticle } = useDepartment();
	const { selectedLine } = useLine();

	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

	const isLoaded = useTypedSelector(
		({ apiState }) => apiState.rollingstock.isLoaded
	);

	const onUsedStatusChange = (idCb: number, isUsed: boolean) =>
		dispatch(toggleUsedStatus(idCb, isUsed));

	const columns: GridColDef[] = [
		{
			field: 'elementCode',
			flex: 0.6,
			headerName: `N° ${rsLabelwithUndefinedArticle}`,
			renderCell: ({ row: { trainCode } }) => (
				<Typography variant="h5">{trainCode}</Typography>
			),
			type: 'string',
			valueGetter: ({ row: { trainCode } }) => trainCode,
		},
		{
			align: 'right',
			editable: true,
			field: 'isUsed',
			flex: 0.5,
			headerAlign: 'right',
			headerName: `${capitalize(rsLabel)} utilisé`,
			renderCell: (params) => (
				<Switch
					checked={params.row.isUsed}
					isGreen
					handler={() => onUsedStatusChange(params.row.id, !params.row.isUsed)}
					name="isUsed"
				/>
			),
			type: 'boolean',
		},
	];

	useEffect(() => {
		if (selectedLine) {
			dispatch(loadUsedRs(selectedLine));
		}
	}, [selectedLine]);

	const scrollToTop = () => {
		const virtualScroller = document.querySelector(
			'.css-axafay-MuiDataGrid-virtualScroller'
		);
		if (virtualScroller) {
			virtualScroller.scrollTo({ behavior: 'smooth', top: 0 });
		}
	};

	if (!isLoaded) return <Spinner />;

	return (
		<Grid container spacing={2} justifyContent="center">
			{isPad ? (
				<>
					<Grid item xs={12}>
						<DataGridPro
							rows={usedRs}
							columns={columns}
							rowHeight={80}
							sx={{
								'& .css-axafay-MuiDataGrid-virtualScroller': {
									maxHeight: '68vh',
								},
								'& .MuiDataGrid-row': {
									backgroundColor: '#ffffff',
									border: 'solid 1px lightGrey',
									borderRadius: '0.5rem',
									margin: '0 0 0.8rem',
									maxWidth: '97.6%',
								},
								border: 'none',
							}}
						/>
					</Grid>

					<Grid item xs={8}>
						<Button fullWidth onClick={scrollToTop}>
							<ArrowUpwardIcon fontSize="small" />
							<span>Haut de page</span>
							<ArrowUpwardIcon fontSize="small" />
						</Button>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<DataGridPro
						rows={usedRs}
						columns={columns}
						rowHeight={60}
						sx={{
							'& .css-axafay-MuiDataGrid-virtualScroller': {
								height: '70vh',
							},
							'& .MuiDataGrid-row': {
								backgroundColor: '#ffffff',
								border: 'solid 1px lightGrey',
								borderRadius: '0.5rem',
								margin: '0 0 0.8rem',
								maxWidth: '99.5%',
								minWidth: '99%',
							},
							border: 'none',
							height: '70vh',
						}}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default UsedRollingStockList;
