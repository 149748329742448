// prettier-ignore
import { initPopupState } from '../config/initialState';
import { HIDE, PopupActionTypes as ActionTypes, PopupState as State,SHOW } from '../types/popup.types';

export const popupReducer = (
	state = initPopupState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case HIDE:
			return {
				...state,
				hasBackground: true,
				isClosable: true,
				isVisible: false,
				message: undefined,
				popupType: undefined,
				title: undefined,
			};
		case SHOW:
			return { ...action.payload, isVisible: true };
		default:
			return state;
	}
};

export default popupReducer;
