import { HIDE, PopupActionTypes, PopupState, SHOW } from '../types';

export const hidePopup = (): PopupActionTypes => ({
	type: HIDE,
});

export const showPopup = (config: PopupState): PopupActionTypes => ({
	payload: config,
	type: SHOW,
});

export const popupActions = {
	hidePopup,
	showPopup,
};
