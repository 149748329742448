import { styled } from '@mui/system';

export const StyledPriorityCount = styled('div')`
	color: var(--text-color-red);
	position: relative;
	margin-top: 0.5rem;
`;

export const StyledSvgContainer = styled('div')`
	border: 1px solid var(--bg-color-grey-40);
	box-shadow: 0px 4px 4px 0px var(--bg-color-grey-40);
	border-radius: 4px;
	margin-bottom: 1rem;
`;

export const StyledPrio = styled('div')`
	display: flex;
	width: 50%;
	align-items: center;
	gap: 0.5rem;
`;
