export enum DepartmentEnum {
  MTS = 0,
  SEM = 1,
  RER = 2,
}

export interface Department {
  id: DepartmentEnum;
  code: string;
}
