import { FC } from 'react';

import { Station } from '../../../../models';
import { SemPassages } from '../../constants';
import SemTable from '../SemTable/SemTable';

import './../SemHeader/SemHeader.css';

type Props = {
	selectedLine: string;
	selectedPassage: number;
	stations: Station[];
};
const AutomatedLines = ['1', '14'];
const { PASSAGE_3 } = SemPassages;

export const SemNettoyageBoard: FC<Props> = ({
	selectedPassage,
	stations = [],
	selectedLine,
}) => {
	return (
		<div
			className={`sem-container sem-container-nettoyage${
				AutomatedLines.includes(selectedLine) ? '-automated' : ''
			}`}
		>
			{stations?.length > 0 && (
				<>
					<div className="sem-table-passage hide-xlarge-screen">
						<h3 className="sem-passage__title">Passage {selectedPassage}</h3>
						<SemTable
							passage={selectedPassage}
							stations={
								selectedPassage === PASSAGE_3
									? stations.filter((s) => s.terminus)
									: stations
							}
						/>
					</div>

					<div className="show-xlarge-screen">
						<h3 className="sem-passage__title">Passage 1</h3>
						<SemTable passage={1} stations={stations} />
					</div>
					<div className="show-xlarge-screen">
						<h3 className="sem-passage__title">Passage 2</h3>
						<SemTable passage={2} stations={stations} />
					</div>
					{!AutomatedLines.includes(selectedLine) && (
						<div className="show-xlarge-screen">
							<h3 className="sem-passage__title">Passage 3 : Terminus</h3>
							<SemTable
								passage={3}
								stations={stations.filter((s) => s.terminus)}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default SemNettoyageBoard;
