import { Grid, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';

import appEnv from '../../packages/configuration/appEnv';
import HistoryPopIn from '../modals/HistoryPopIn';
import NotificationPopIn from '../modals/notificationPopin';

import LastSync from './lastSync';
import MetroLogo from './logo';
import Menu from './menu';

const { version, muiLicense } = appEnv;

const NavigatorTablet = () => {
	return (
		<>
			<NotificationPopIn />
			<HistoryPopIn />
			<AppBar
				className="font-white font-paris bg-blue"
				id="nav-tablet"
				title={`Version: ${version} / mui license: ${muiLicense}`}
			>
				<Grid container>
					<Grid item xs={4} pt={1}>
						<Typography style={{ cursor: 'default' }} variant="h6">
							Smart Tracer
						</Typography>
						<LastSync />
					</Grid>
					<Grid item xs={4}>
						<MetroLogo />
					</Grid>
					<Grid item xs={4} pt={1} display="flex" justifyContent="end">
						<Menu />
					</Grid>
				</Grid>
			</AppBar>
		</>
	);
};

export default NavigatorTablet;
