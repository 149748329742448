export enum SemServices {
  NETTOYAGE = 'Nettoyage',
  SUPPLEMENTAIRE = 'Supplémentaire',
}

export enum SemPassages {
  PASSAGE_1 = 1,
  PASSAGE_2 = 2,
  PASSAGE_3 = 3,
  PASSAGE_4 = 4,
}

export const SEM_HOUR_CHANGE_PASSAGE_1_2 = 13;
export const SEM_HOUR_PASSAGE_3 = 18;

export const DAILY_PASSAGE_FOR_NORMAL = 2;
export const DAILY_PASSAGE_FOR_TERMINUS = 3;
