import { Divider, Grid } from '@mui/material';

import { pluralize } from '../../../../../packages/helpers';
import { IncidentSvgPrintable } from '../../../../../packages/ui/icons';
import { StyledIncidentCard } from '../../../../trackingBoard/list/styles';

const GraffitiReporting = ({
	detection,
	resolution,
	resolutionAverage,
	usedGrafedTrainAverage,
}: GraffitiReportingProps) => {
	const getAverage = () => {
		if (usedGrafedTrainAverage === null) return usedGrafedTrainAverage;
		return Number(parseFloat(usedGrafedTrainAverage).toFixed(1)) < 0
			? 0
			: parseFloat(usedGrafedTrainAverage).toFixed(1);
	};

	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					height: '21vh',
				}}
			>
				<Grid
					item
					xs={4}
					display={'flex'}
					alignItems="center"
					flexDirection={'column'}
					style={{
						height: '100%',
					}}
				>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						style={{ marginTop: '1vh' }}
					>
						<StyledIncidentCard elevation={6} border={'none'}>
							<IncidentSvgPrintable name={'GR'} />
						</StyledIncidentCard>
					</Grid>
					<Grid item xs={4} display={'flex'} alignItems="center">
						Train:
					</Grid>
					<Grid item xs={4} display={'flex'} alignItems="center">
						Voitures:
					</Grid>
				</Grid>
				<Grid
					item
					xs={5.5}
					display={'flex'}
					alignItems="center"
					flexDirection={'column'}
					style={{
						backgroundColor: 'var(--bg-color-warning-opacity)',
						height: '100%',
					}}
					fontWeight={'bold'}
				>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						marginRight={1}
					>
						Attaques
					</Grid>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						fontSize={'1.5rem'}
					>
						{detection.trainsCount}
					</Grid>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						fontSize={'1.5rem'}
					>
						{detection.railcarsCount}
					</Grid>
				</Grid>
				<Grid
					item
					xs={5.5}
					display={'flex'}
					alignItems="center"
					flexDirection={'column'}
					style={{
						backgroundColor: 'var(--bg-color-good-opacity)',
						height: '100%',
					}}
					fontWeight={'bold'}
				>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						marginRight={3.5}
					>
						Dégraffitage
					</Grid>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						fontSize={'1.5rem'}
					>
						{resolution.trainsCount}
					</Grid>
					<Grid
						item
						xs={4}
						display={'flex'}
						alignItems="center"
						fontSize={'1.5rem'}
					>
						{resolution.railcarsCount}
					</Grid>
				</Grid>
			</Grid>
			<Divider
				sx={{
					borderBottomWidth: '2px',
					width: '100%',
				}}
			/>
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					height: '12vh',
				}}
			>
				<Grid
					item
					xs={7}
					display={'flex'}
					alignItems="center"
					fontSize={'1rem'}
					style={{ marginLeft: '1vw' }}
				>
					Moyenne du temps de résolution (train avec date de résolution comprise
					dans période requêtée)
				</Grid>
				<Grid item xs={5} display={'flex'} alignItems="center">
					<Grid
						item
						xs={6}
						display={'flex'}
						alignItems="center"
						fontSize={'2.5rem'}
						fontWeight={'bold'}
						marginLeft={resolutionAverage === null ? 0 : '5vw'}
					>
						{resolutionAverage !== null
							? parseFloat(resolutionAverage).toFixed(1)
							: null}
					</Grid>
					<Grid item xs={5} display={'flex'} alignItems="center">
						{resolutionAverage !== null
							? pluralize(Number(resolutionAverage), 'jour')
							: 'Pas de résolution'}
					</Grid>
				</Grid>
			</Grid>
			<Divider
				sx={{
					borderBottomWidth: '1px',
					marginLeft: '1.6rem',
					width: '90%',
				}}
			/>
			<Grid
				item
				xs={12}
				display="flex"
				alignItems="center"
				style={{
					height: '12vh',
				}}
			>
				<Grid
					item
					xs={7}
					display={'flex'}
					alignItems="center"
					fontSize={'1rem'}
					style={{ marginLeft: '1vw' }}
				>
					Moyenne de trains grafittés utilisés par jour sur la période :
				</Grid>
				<Grid item xs={5} display={'flex'} alignItems="center">
					<Grid
						item
						xs={6}
						display={'flex'}
						alignItems="center"
						marginLeft={usedGrafedTrainAverage === null ? 0 : '5vw'}
						fontSize={'2.5rem'}
						fontWeight={'bold'}
					>
						{getAverage()}
					</Grid>
					<Grid item xs={5} display={'flex'} alignItems="center">
						{usedGrafedTrainAverage !== null
							? pluralize(Number(usedGrafedTrainAverage), 'train')
							: 'Aucun train grafitté'}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GraffitiReporting;

type GraffitiReportingProps = {
	detection: { railcarsCount: number; trainsCount: number };
	resolution: { railcarsCount: number; trainsCount: number };
	resolutionAverage: string;
	usedGrafedTrainAverage: string;
};
