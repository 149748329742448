import { memo, useEffect, useState } from 'react';

import { ReactComponent as Refresh } from '../../../assets/icons/frames/refresh.svg';
import { dateTimeService, localStorageFuncs } from '../../../packages/helpers';

const LastSync = () => {
	const [lastSyncTimeStamp, setLastSyncTimeStamp] = useState<number>(0);
	const [lastSyncHourAndDate, setLastSyncHourAndDate] = useState<{
		hour: string;
		datum: string;
	}>({
		datum: '00/00',
		hour: '00:00',
	});

	useEffect(() => {
		const originalTimeStamp = localStorageFuncs.get('lastSyncTimeStamp');
		if (originalTimeStamp) setLastSyncTimeStamp(Number(originalTimeStamp));
		const listener = (e: any) => {
			if (e.value) setLastSyncTimeStamp(Number(e.value));
		};
		window.document.addEventListener('newLastSyncTimeStamp', listener);
		return () => {
			window.document.removeEventListener('newLastSyncTimeStamp', listener);
		};
	}, []);

	useEffect(() => {
		if (lastSyncTimeStamp) {
			const [newHour, newDatum] = dateTimeService.getSyncDateString(
				new Date(lastSyncTimeStamp)
			);
			setLastSyncHourAndDate({
				datum: newDatum,
				hour: newHour,
			});
		}
	}, [lastSyncTimeStamp]);

	return (
		<div id="last-sync-container" data-testid="last-sync-container">
			<Refresh className="mr-2" />
			{`${lastSyncHourAndDate.datum} à ${lastSyncHourAndDate.hour}`}
		</div>
	);
};

export default memo(LastSync);
