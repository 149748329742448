import { useRef } from 'react';
import { Grid } from '@mui/material';

import { Button } from '../../packages/ui';
import PhotoThumbnail from '../../packages/ui/atoms/PhotoThumbnail';
import { AddIcon } from '../../packages/ui/icons';

const FilesUploader = ({
	urlsLink = [],
	files,
	handleFiles,
	name,
	disabled,
	handleDeleteFile,
}: FileUploaderProps) => {
	const hiddenFileInput = useRef<HTMLInputElement>(null);

	const handleClick = () => {
		if (hiddenFileInput.current) hiddenFileInput.current.click();
	};

	return (
		<Grid container spacing={1}>
			{files instanceof Array &&
				files?.map((file: File) => (
					<Grid item key={file.name}>
						<PhotoThumbnail
							file={file}
							handleDeleteFile={(e) => handleDeleteFile(e, file.name, true)}
							disabled={disabled}
						/>
					</Grid>
				))}
			{urlsLink instanceof Array &&
				urlsLink?.map((url: string) => (
					<Grid item key={url}>
						<PhotoThumbnail
							urlLink={url}
							handleDeleteFile={(e) => handleDeleteFile(e, url)}
							disabled={disabled}
						/>
					</Grid>
				))}
			<Grid item>
				<Button
					startIcon={<AddIcon />}
					style={{
						borderRadius: '15px',
						display: 'flex',
						flexDirection: 'column',
						height: '188px',
					}}
					disabled={disabled}
					onClick={handleClick}
				>
					Importer
				</Button>
				<input
					name={name}
					accept="image/*"
					multiple={true}
					id={'photoFile'}
					style={{ display: 'none', zIndex: 1 }}
					onChange={handleFiles}
					ref={hiddenFileInput}
					type="file"
				/>
			</Grid>
		</Grid>
	);
};

export default FilesUploader;
type FileUploaderProps = {
	files?: FileList;
	handleFiles: any;
	disabled: boolean;
	handleDeleteFile: (event: any, filename: string, isFile?: boolean) => void;
	urlsLink: any;
	name: string;
};
