import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';

import CodesTrainList from '../../components/codesTrainList';
import { PrefixInfo } from '../../models';
import { errorConf, PREFIXES } from '../../packages/helpers';
import { useDepartment, useLine, useTypedSelector } from '../../packages/hooks';
import OkButton from '../../packages/ui/Buttons/OkButton';
import ScannerButton from '../../packages/ui/Buttons/ScannerButton';
import { resetSurface, setSurface, showPopup } from '../../redux/actions';
import {
	loadAndSetRollingStock,
	verifyAndLoadRollingStock,
} from '../../redux/thunks/rollingStock';
import { loadSetting } from '../../redux/thunks/setting';
import { Endpoints } from '../../routes/endpoint.config';

import './styles/codePage.css';

const Code = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const settingState = useTypedSelector((state) => state.settingState);
	const { selectedLine: line } = useLine();
	const { departmentEnum } = useDepartment();
	const [isValidInput, setIsValidInput] = useState(false);
	const [selectedTrain, setSelectedTrain] = useState<{
		trainId: string;
		trainCode?: string;
	} | null>(null);
	const [prefixInfo, setPrefixInfo] = useState<PrefixInfo>();
	const [prefixTxt, setPrefixTxt] = useState('');

	const { rollingStocksTs } = useTypedSelector(
		({ rollingStockState: { rollingStocks } }) => {
			return {
				rollingStocksTs: rollingStocks,
			};
		}
	);

	const confirmationHandler = () => {
		const successCb = () => {
			navigate(Endpoints.AGENTN_SELECTION);
		};

		const errorCb = () => {
			dispatch(showPopup(errorConf.warn.invalidTrainCode));
			setIsValidInput(false);
		};

		dispatch(
			verifyAndLoadRollingStock(
				{ ...selectedTrain, selectedLine: line },
				successCb,
				errorCb
			)
		);
	};

	const changeHandler = (id: string) => {
		const selectedTrain = rollingStocksTs?.find(({ id: rsId }) =>
			isEqual(rsId, Number(id))
		);

		dispatch(setSurface({ trainCode: selectedTrain?.trainCode }));
		setSelectedTrain({ trainCode: selectedTrain?.trainCode, trainId: id });
		setIsValidInput(true);
	};

	useEffect(() => {
		if (line) {
			dispatch(
				loadAndSetRollingStock(settingState.npHighLimit, line, departmentEnum)
			);
			dispatch(loadSetting(line));
		}
	}, [dispatch, line, departmentEnum]);

	useEffect(() => {
		dispatch(resetSurface());
		dispatch(setSurface({ trainCode: prefixTxt }));
	}, [dispatch, prefixTxt]);

	useEffect(() => {
		if (line && !['3', '7'].includes(line)) {
			setPrefixInfo(PREFIXES[line]);
		}
	}, [line, prefixTxt]);

	useEffect(() => {
		if (prefixInfo) {
			setPrefixTxt(prefixInfo.prefixTxt);
		}
	}, [prefixInfo]);

	return (
		<main className="identification-container page">
			<div className="code-btn-group">
				<Link className="code-link" to={Endpoints.AGENTN_QR_SCANNER}>
					<ScannerButton />
				</Link>
				<div className="code-link">
					<OkButton action={confirmationHandler} isValidInput={isValidInput} />
				</div>
			</div>
			<CodesTrainList data={rollingStocksTs} validateEnter={changeHandler} />
		</main>
	);
};

export default Code;
