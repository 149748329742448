import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Header from '../../components/dashboard/header';
import InstantDashboardComponent from '../../components/dashboard/instantDashboard';
import PeriodDashboard from '../../components/dashboard/periodDashboard';
import TitleDivider from '../../components/dashboard/titleDivider';
import LineSelector from '../../components/lineSelector';
import {
	ROLLINGSTOCKS_BY_PERIOD,
	USED_ROLLINGSTOCKS,
} from '../../packages/helpers/explanations/dashboard';
import { captureScreenshotPdf } from '../../packages/helpers/screenshotPdf.utils';
import { useDepartment, useEntity, useLine } from '../../packages/hooks';
import { loadRightByType } from '../../redux/thunks/right';

import SemDashboardPage from './semDashboardPage';

const DashboardPage = () => {
	const dispatch = useDispatch();
	const { isAdmin } = useEntity();
	const { hasMultiLines } = useLine();
	const { hasSemDepartment } = useDepartment();

	useEffect(() => {
		if (isAdmin) dispatch(loadRightByType());
	}, []);

	return (
		<>
			{hasSemDepartment ? (
				<SemDashboardPage />
			) : (
				<main>
					<Header
						data-testid="dashbord-page"
						onClick={async () => await captureScreenshotPdf()}
					/>
					<div className="content-dashboard">
						{hasMultiLines && <LineSelector showAll={false} />}
						<TitleDivider title={USED_ROLLINGSTOCKS} />
						<InstantDashboardComponent />
						<TitleDivider title={ROLLINGSTOCKS_BY_PERIOD} />
						<PeriodDashboard />
					</div>
				</main>
			)}
		</>
	);
};

export default DashboardPage;
