import { MouseEvent, ReactNode } from 'react';
import { Badge as MuiBadge } from '@mui/material';

const Badge = ({
	children,
	color = 'success',
	badgeContent,
	invisible,
	onClick,
}: BadgeProps) => (
	<MuiBadge
		badgeContent={badgeContent}
		color={color}
		overlap="circular"
		invisible={invisible}
		onClick={onClick}
	>
		{children}
	</MuiBadge>
);

export default Badge;

type BadgeProps = {
	children?: ReactNode;
	color?:
		| 'default'
		| 'secondary'
		| 'primary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning';
	badgeContent: any;
	invisible?: boolean;
	onClick?: (event: MouseEvent) => void;
};
