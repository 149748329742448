import { ServiceTypeEnum } from './serviceType.model';

export enum ServiceStatus {
	ENABLE = 'enable',
	DISABLE = 'disable',
	SELECTED = 'selected',
	CONFIRMED = 'confirmed',
}

export interface Service {
	type: ServiceTypeEnum;
	status: ServiceStatus;
}

export interface RollingStockCodeFormat {
	trainId?: string;
	trainCode?: string;
	selectedLine?: string;
}

export interface RollingStockQrFormat {
	id: string;
	materiel: string;
	selectedLine?: string;
}

export interface UnitQrFormat {
	ligne: string;
	idStation: string;
	seqUnite: string;
}
