import { FC, PropsWithChildren, ReactElement, SyntheticEvent } from 'react';
import { animated } from 'react-spring';

import './TiltedButton.css';

type myProps = {
	variant:
		| 'btn-done'
		| 'btn-not-done'
		| 'btn-qr-landing'
		| 'btn-qr-success'
		| 'btn-logout'
		| 'btn-to-start';
	action: (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
	style?: any;
};
type Props = PropsWithChildren<myProps>;

const TiltedButton: FC<Props> = ({
	variant,
	children,
	action,
	style,
}): ReactElement => {
	if (variant === 'btn-done' || variant === 'btn-not-done') {
		return (
			<animated.button
				className={`tilted-btn`}
				id={variant}
				onClick={action}
				style={style}
			>
				{children}
			</animated.button>
		);
	}

	return (
		<button className={`tilted-btn`} id={variant} onClick={action}>
			{children}
		</button>
	);
};

export default TiltedButton;
