import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import { Button } from '@mui/material';

import { MobileConfirmationButtonProps } from '../../../models/ui';

const MobileConfirmationButton = ({
	children,
	isDone,
	onClick,
}: MobileConfirmationButtonProps) => {
	return (
		<Button
			style={{
				height: isDone ? '9.375rem' : 'auto',
				width: isDone ? '18.75rem' : '15.625rem',
			}}
			variant={isDone ? 'done' : 'cancel'}
			fullWidth
			onClick={onClick}
		>
			{isDone ? (
				<InsertEmoticonIcon
					style={{ fontSize: '2.8rem', paddingRight: '2.8rem' }}
					fontSize="large"
				/>
			) : (
				<MoodBadIcon style={{ fontSize: '2.2rem', paddingRight: '0.75rem' }} />
			)}
			{children}
		</Button>
	);
};

export default MobileConfirmationButton;
