import SettingSavePopIn from '../modals/settingSavePopIn';

const DialogBox = ({
	handleCancel,
	handleConfirm,
	showDialog,
}: DialogBoxProps) => {
	return (
		<SettingSavePopIn
			cancelButtonText="Ne pas enregistrer"
			confirmButtonText="Enregistrer"
			isVisible={showDialog}
			message="Souhaitez-vous enregistrer les modifications apportées aux paramètres ?"
			onCancel={handleCancel}
			onValidate={handleConfirm}
			title="Paramètres de l'application"
		/>
	);
};
export default DialogBox;

interface DialogBoxProps {
	handleCancel: () => void;
	handleConfirm: () => void;
	showDialog: boolean;
}
