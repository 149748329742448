import { Divider, Grid, Typography } from '@mui/material';

const TitleDivider = ({ title }: TitleDividerProps) => {
	return (
		<Grid container alignItems="center">
			<Typography variant="h2">{title}</Typography>
			<Divider
				sx={{
					borderBottomWidth: '2px',
					borderColor: 'var(--bg-color-blue-light)',
					marginLeft: '5px',
					width: '70%',
				}}
			/>
		</Grid>
	);
};

type TitleDividerProps = { title: string };

export default TitleDivider;
