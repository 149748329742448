import { SelectChangeEvent } from '@mui/material';

import { dateTimeService } from '../../../../packages/helpers';
import { Select } from '../../../../packages/ui';
import { SemServices } from '../../constants';

import './../SemHeader/SemHeader.css';

const { NETTOYAGE, SUPPLEMENTAIRE } = SemServices;

export const SemDropdown = ({ onPeriodChange }: Props) => {
	const handleChange = (e: SelectChangeEvent) => {
		onPeriodChange(e.target.value);
	};

	return (
		<div className="sem-selection">
			<div>Prestation {<span>de nettoyage</span>}</div>
			{
				<div className="sem-header-selection">
					<Select
						label=""
						options={[NETTOYAGE, SUPPLEMENTAIRE]}
						defaultValue={NETTOYAGE}
						onChange={handleChange}
					/>
				</div>
			}
			<div>&nbsp;du {dateTimeService.getReadableDate(new Date()).calendar}</div>
		</div>
	);
};

export default SemDropdown;

type Props = {
	onPeriodChange: (period: string) => void;
};
