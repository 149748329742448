/* eslint-disable no-mixed-spaces-and-tabs */
import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';

import {
	BasicIncident,
	IncidentSelected,
	IncidentTypeEnum,
	Item,
	ServiceTypeEnum,
} from '../../../../models';
import { RsSpecialOperation } from '../../../../models/specialOperation.model';
import {
	RSA_SELECT_SUBTITLE,
	RSA_SELECT_TITLE,
} from '../../../../packages/helpers/explanations/rollingStockAvailability';
import { ModalesState, useTypedSelector } from '../../../../packages/hooks';
import { useIncidentType } from '../../../../packages/hooks/useIncidentType';
import { Button, Dialog, PopInFooter } from '../../../../packages/ui';
import { StyledServiceRender } from '../../../trackingBoard/list/styles';
import ModalHeader from '../../header';

import './styles.css';

const RollingStockAvailabilitySelect = ({
	colorNp,
	daysCount,
	handleCloseModal,
	handleOpenModal,
	incidentSelected,
	open,
	rsId,
	setIncidentSelected,
	trainId,
	trainNumber,
}: RollingStockAvailabilitySelectProps) => {
	const { incidentTypesCreate } = useIncidentType();

	const rollingStocks =
		useTypedSelector(
			({ rollingStockState }) => rollingStockState.rollingStocks
		) || [];

	const selectedRs = rollingStocks.find(({ id }) => id === trainId);

	const incidentOptions = incidentTypesCreate.filter((incidentType) =>
		Array.from(
			new Set(
				selectedRs?.incidents?.map(({ incidentTypeId }) => incidentTypeId)
			)
		).some((incidentTypeId) => incidentTypeId === incidentType.id)
	);

	const specialOperationOptions: BasicIncident[] = (
		selectedRs?.specialOperations ?? []
	).map((op: RsSpecialOperation) => ({
		code: `OP-${op.specialOperationTypeId}`,
		id: IncidentTypeEnum.OP,
		name: `Opération spéciale : ${op.specialOperationTypeName}`,
	}));

	const serviceOptions = isEqual(daysCount, '0 jour')
		? []
		: [
				{
					code: 'NP',
					id: ServiceTypeEnum.NP,
					name: 'Nettoyage patrimonial',
				},
		  ];

	const isTouched = !!incidentSelected;

	const options = [
		...incidentOptions,
		...serviceOptions,
		...specialOperationOptions,
	];

	const classNameIncident = (incident: Item): string => {
		return `incident-toggle ${
			incident.code === incidentSelected?.incident?.code
				? 'incident-selected'
				: ''
		}`;
	};

	const handleClick = (item: Item) => {
		const id = item.id;
		const name = item.name;
		const code = item.code;
		setIncidentSelected({ incident: { code: code, id: id, name: name } });
	};

	const onValidateInput = () => {
		if (!isTouched) return;
		handleCloseModal('availabilitySelect');
		handleOpenModal('availabilityCreateForm');
	};

	const handleClose = () => {
		setIncidentSelected(null);
		handleCloseModal('availabilitySelect');
	};

	return (
		<Dialog
			open={open}
			titleContent={RSA_SELECT_TITLE}
			content={
				<>
					<ModalHeader>
						<div className="incident-header-train-number">
							<Typography variant="h6">{trainNumber}</Typography>
							<Typography variant="h5">{rsId}</Typography>
						</div>
						{!isEmpty(daysCount) && (
							<div className="lastNp-header">
								<Typography variant="h6">{'Dernier NP:'}</Typography>
								<StyledServiceRender color={colorNp}>
									<Typography variant="h5">{daysCount}</Typography>
								</StyledServiceRender>
							</div>
						)}
					</ModalHeader>
					<div className="incidents-list-container">
						<Typography
							className="incidents-list-title"
							variant="h5"
							style={{ marginBottom: '1rem' }}
						>
							{RSA_SELECT_SUBTITLE}
						</Typography>
						{options?.map((incidentType) => (
							<Button
								key={incidentType.code}
								className={classNameIncident(incidentType)}
								onClick={() => handleClick(incidentType)}
								data-testid="incident-button"
								style={{ width: '60%' }}
							>
								<div
									className={`svg-incident-container svg-incident svg-incident-symbol-${incidentType.code
										.slice(0, 2)
										.toLowerCase()}`}
								/>
								<div className="incident-name-container">
									{incidentType.name}
								</div>
							</Button>
						))}
					</div>
				</>
			}
			actionsContent={
				<PopInFooter
					onCancel={handleClose}
					onValidate={onValidateInput}
					validateDisabled={!isTouched}
				/>
			}
			onClose={handleClose}
		/>
	);
};

export default RollingStockAvailabilitySelect;

type RollingStockAvailabilitySelectProps = {
	colorNp: string;
	daysCount: string;
	incidentSelected: IncidentSelected | null;
	open: boolean;
	setIncidentSelected: Dispatch<SetStateAction<IncidentSelected | null>>;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	rsId: string | undefined;
	trainId: number | undefined;
	trainNumber: string;
};
