import { EspaceEnum } from '../../models';
import * as types from '../types/espace.types';
import { SWITCH_ESPACE } from '../types/espace.types';

export const switchEspace = (
	espace: EspaceEnum
): types.SwitchEspaceActionTypes => {
	return {
		payload: espace,
		type: SWITCH_ESPACE,
	};
};

export const espaceActions = {
	switchEspace,
};

export default espaceActions;
