import { ThunkAction } from 'redux-thunk';

import RoleProvider from '../../packages/dataProviders/resources/role';
import { errorConf } from '../../packages/helpers/popup/exception.utils';
import { setHistoryUsersProfilFilter, setRoles } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { ROLES } = components;

type RolesThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const getRoles = (): RolesThunk => async (dispatch, _, api) => {
	try {
		dispatch(api._request(ROLES));
		const { data: roles } = await RoleProvider.loadRoles();
		dispatch(setHistoryUsersProfilFilter(roles));
		dispatch(api._response(ROLES));
		dispatch(setRoles(roles));
	} catch (e) {
		dispatch(api._error(ROLES, e));
		dispatch(api.showFeedBack(errorConf.warn.offlineMode));
	}
};
