import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import ConfirmMobile from '../../components/confirmMobile';
import {
	IncidentTypeEnum,
	IncidentTypeMap,
	SurfaceTypeEnum,
} from '../../models';
import { SolvedIncidentReport } from '../../models/incidentSolving.model';
import IncidentSolvingProvider from '../../packages/dataProviders/resources/incidentSolving';
import SpecialOperationProvider from '../../packages/dataProviders/resources/specialOperation';
import { useLine, useTypedSelector } from '../../packages/hooks';
import ConfirmationButtons from '../../packages/ui/atoms/ConfirmationButton';
import { createServices } from '../../redux/thunks/service';
import { Endpoints } from '../../routes/endpoint.config';

import './styles/confirmationPage.css';

const { SMR } = SurfaceTypeEnum;

const getIncidentName = (type: IncidentTypeEnum | undefined): string => {
	if (type === 0) return 'le Graffiti';
	return type ? `le ${IncidentTypeMap.get(type)}` : '';
};

const Confirmation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { selectedLine } = useLine();
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [isPartial, setIsPartial] = useState(false);

	const { trainCode, trainId, selection } = useTypedSelector(
		({ serviceState }) => ({
			selection: serviceState.selection,
			trainCode: serviceState.trainCode ?? undefined,
			trainId: serviceState.trainId ?? undefined,
		})
	);
	const partialAction = () => {
		if (selection?.incident) {
			const solvedIncident: SolvedIncidentReport = {
				incidentTypeId: selection.incident.incidentTypeId,
				isPartial: true,
				rollingStockId: selection.incident.rollingStockId,
			};
			IncidentSolvingProvider.solveIncidentReport(solvedIncident).then();
		}
		setIsPartial(true);
		setIsConfirmed(true);
		setTimeout(() => {
			navigate(Endpoints.AGENTN);
		}, 1500);
	};
	const confirmAction = () => {
		if (selection?.service) {
			dispatch(
				createServices([
					{
						date: new Date(),
						lineId: selectedLine,
						serviceTypeId: selection.service.type,
						surfaceTypeId: SMR,
						trainCode,
						trainId,
					},
				])
			);
		}
		if (selection?.incident) {
			const solvedIncident: SolvedIncidentReport = {
				incidentTypeId: selection.incident.incidentTypeId,
				rollingStockId: selection.incident.rollingStockId,
			};
			IncidentSolvingProvider.solveIncidentReport(solvedIncident).then();
		}
		if (selection?.specialOperation?.id) {
			SpecialOperationProvider.solveSpecialOperation(
				selection.specialOperation.id
			).then();
		}
		setIsConfirmed(true);
		setTimeout(() => {
			navigate(Endpoints.AGENTN);
		}, 1500);
	};

	return (
		<Grid container spacing={3} justifyContent="center" pt={8}>
			{!isConfirmed && (
				<>
					{selection?.service && (
						<Grid
							item
							xs={12}
							className={`svg-action-container svg-confirmation svg-service-symbol-${selection.service.type}`}
						/>
					)}
					{selection?.incident && (
						<Grid
							item
							xs={12}
							className={`svg-action-container svg-confirmation svg-incident-symbol-${selection.incident.incidentTypeId}`}
						/>
					)}
					<Grid item xs={12} textAlign="center">
						<Typography variant="h2" m={2} color={`var(--text-color-jade)`}>
							Avez-vous fini de traiter{' '}
							{selection?.service
								? 'le Nettoyage ' + selection.service.name
								: getIncidentName(selection?.incident?.incidentTypeId)}
							{selection?.specialOperation &&
								"l'opération spéciale: " +
									selection?.specialOperation.specialOperationTypeName}
							?
						</Typography>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center">
						<ConfirmationButtons
							isGraffiti={selection?.incident?.incidentTypeId === 0}
							partialAction={partialAction}
							confirmAction={confirmAction}
						/>
					</Grid>
				</>
			)}
			{isConfirmed && <ConfirmMobile isPartial={isPartial} />}
		</Grid>
	);
};

export default Confirmation;
