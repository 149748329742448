import { Grid, Typography } from '@mui/material';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import { isEmpty } from 'lodash';

import { IncidentReport, IncidentTypeEnum } from '../../../models';
import {
	getUniqueKey,
	INC_IMPACTED_RAILCARS,
	INC_IS_BLOCKING_CASE,
	INC_IS_NOT_BLOCKING_CASE,
	INC_IS_NOT_OFFENSIVE,
	INC_IS_OFFENSIVE,
	INC_RAILCARS,
} from '../../../packages/helpers';
import DisplayPhoto from '../displayPhotos';

import { StyledDetail } from './styles';

const IncidentReportSolvedDetail = ({
	incidentReport,
}: IncidentReportSolvedDetailProps) => {
	const locale = fr;

	let blockCount = 0;
	const {
		surface,
		comment,
		complement,
		creationDate,
		incidentTypeId,
		isBlockingCase = false,
		isOffensive = false,
		locations,
		photos,
		impactedRailcar,
		railcars,
		registrant,
		degradationSite,
		degradationPosition,
		solvingDate,
		site,
	} = incidentReport;
	const isGR = incidentTypeId === IncidentTypeEnum.GR;
	const isDI = incidentTypeId === IncidentTypeEnum.DI;

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h5">{++blockCount}. Découverte</Typography>
				<Typography
					style={{ fontSize: '1rem', marginTop: '20px' }}
					variant="subtitle1"
				>
					Lieu de découverte
				</Typography>
			</Grid>
			<Grid item xs={6}>
				{site && <StyledDetail>{site?.name}</StyledDetail>}
			</Grid>
			<Grid item xs={6}>
				<StyledDetail>{registrant.name}</StyledDetail>
			</Grid>

			<Grid item xs={6}>
				<StyledDetail>
					{format(new Date(creationDate), 'dd/MM/yyyy HH:mm', {
						locale: locale,
					})}
				</StyledDetail>
			</Grid>
			<Grid item xs={6}>
				<StyledDetail>
					{isBlockingCase ? INC_IS_BLOCKING_CASE : INC_IS_NOT_BLOCKING_CASE}
				</StyledDetail>
			</Grid>
			{isGR && (
				<>
					<Grid item xs={12}>
						<Typography
							style={{ fontSize: '1rem', marginTop: '8px' }}
							variant="subtitle1"
						>
							Lieu de la dégradation
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<StyledDetail>{degradationSite?.name}</StyledDetail>
					</Grid>
					<Grid item xs={6}>
						<StyledDetail>{degradationPosition?.name}</StyledDetail>
					</Grid>
				</>
			)}
			{isDI && (
				<>
					<Grid item xs={12}>
						<Typography variant="h5">{++blockCount}. Nature</Typography>
					</Grid>
					<Grid item xs={12}>
						<StyledDetail>{complement?.name}</StyledDetail>
					</Grid>
					<Grid item xs={12}>
						{!isEmpty(comment) && <StyledDetail>{comment}</StyledDetail>}
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<Typography variant="h5">{++blockCount}. Détails</Typography>
			</Grid>
			{!isEmpty(locations) && (
				<Grid item xs={6}>
					<Typography style={{ fontSize: '1rem' }} variant="subtitle1">
						Localisation
					</Typography>
					<StyledDetail>
						<Grid container spacing={1}>
							{locations.map((location) => (
								<Grid item xs={6} key={getUniqueKey(location.name)}>
									{location.name}
								</Grid>
							))}
						</Grid>
					</StyledDetail>
				</Grid>
			)}
			<Grid item xs={6}>
				{!isEmpty(railcars) && (
					<>
						<Typography style={{ fontSize: '1rem' }} variant="subtitle1">
							{INC_RAILCARS}
						</Typography>
						<StyledDetail>
							<Grid container spacing={1}>
								{railcars.map((railcar) => (
									<Grid item xs={6} key={getUniqueKey(railcar.name)}>
										{railcar.name}
									</Grid>
								))}
							</Grid>
						</StyledDetail>
					</>
				)}
			</Grid>
			{isGR && (
				<>
					<Grid item xs={6}>
						<Typography
							style={{ fontSize: '1rem', marginTop: '8px' }}
							variant="subtitle1"
						>
							Surface estimée
						</Typography>
						<StyledDetail>{surface}</StyledDetail>
					</Grid>
					<Grid item xs={6}>
						<Typography
							style={{
								fontSize: '1rem',
								marginTop: '8px',
							}}
							variant="subtitle1"
						>
							{INC_IMPACTED_RAILCARS}
						</Typography>
						<StyledDetail>{impactedRailcar?.name}</StyledDetail>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{++blockCount}. Spécificités</Typography>
					</Grid>
					<Grid item xs={6}>
						<StyledDetail>
							{isOffensive ? INC_IS_OFFENSIVE : INC_IS_NOT_OFFENSIVE}
						</StyledDetail>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{++blockCount}. Photos</Typography>
					</Grid>
					{!isEmpty(photos) &&
						photos?.map((url: string) => (
							<DisplayPhoto key={url} urlLink={url} />
						))}
				</>
			)}
			<Grid item xs={12}>
				<Typography variant="h5">{++blockCount}. Résolution</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography style={{ fontSize: '1rem' }} variant="subtitle1">
					Date de résolution
				</Typography>
				<StyledDetail>
					{format(new Date(solvingDate ?? ''), 'dd/MM/yyyy HH:mm', {
						locale: locale,
					})}
				</StyledDetail>
			</Grid>
		</>
	);
};

interface IncidentReportSolvedDetailProps {
	incidentReport: IncidentReport;
}

export default IncidentReportSolvedDetail;
