import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const ProgressBar = ({ label, value, progressColor }: ProgressBarProps) => {
	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ alignItems: 'center', display: 'flex' }}>
				<Box sx={{ mr: 1, width: '100%' }}>
					<LinearProgress
						color={progressColor}
						sx={{
							'& .MuiLinearProgress-bar': {
								borderRadius: 8,
								boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.3)',
							},
							bgcolor: '#D9D9D9',
							borderRadius: 8,
							height: 25,
						}}
						variant="determinate"
						value={value}
						className={`bg-${progressColor}`}
					/>
					<Typography
						style={{
							bottom: 0,
							color: 'white',
							left: `${value - 4}%`,
							position: 'absolute',
							transform: 'translateX(-50%)',
						}}
					>
						{label}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default ProgressBar;

type ProgressBarProps = {
	value: number;
	label: string;
	progressColor: 'success' | 'warning' | 'error';
};
