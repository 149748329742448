import jwt from 'jsonwebtoken';
import { isEqual } from 'lodash';

import { User } from '../../../models';
import { cookieService } from '../../helpers/cookie';
import fetchJson from '../fetch/fetch-json';

const checkStatusLoginSSO = async () => {
	const res = await fetchJson(`/auth/check-auth`, {}, false);
	return isEqual(res?.status, 200);
};

const getJwtToUser = (token: string): User => {
	const decoded = jwt.decode(token) as Token;
	return decoded?.payload ?? null;
};

const checkLoginSSO = async () => {
	const userToken = cookieService.getCookie('authorization');
	const statusLoginSSO = await checkStatusLoginSSO();
	if (userToken && statusLoginSSO) return getJwtToUser(userToken);
	return null;
};

const logout = async () => {
	await fetchJson(`/auth/logout`, { mode: 'no-cors' }, false);
};

const login = async () => {
	const response = await fetch('app-env.json');
	const data = await response.json();
	window.___STTRCONF___ = data;
	window.location.href = `${data.backendUrl ?? '/api'}/auth/login`;
};

export default { checkLoginSSO, login, logout };

type Token = {
	payload: User;
};
