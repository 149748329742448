import { styled } from '@mui/system';

export const StyledDetail = styled('div')({
	backgroundColor: 'var(--bg-color-grey-disabled)',
	borderRadius: '5px',
	color: 'var(--text-color-grey-lighter)',
	padding: '1rem',
	textAlign: 'center',
	width: '80%',
});
