import { Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@mui/material';

import IconButton from '../../../../../packages/ui/atoms/IconButton';
import { DeleteIcon, EditIcon } from '../../../../../packages/ui/icons';

const AvailabilityDetailHeader = ({
	isSelectedAction,
	setIsDeletableMode,
	setIsEditableMode,
	setIsSelectedAction,
}: AvailabilityDetailHeaderProps) => {
	const handleEditClick = () => {
		setIsEditableMode(true);
		setIsSelectedAction(true);
	};

	const handleDeleteClick = () => {
		setIsDeletableMode(true);
		setIsSelectedAction(true);
	};

	return (
		<Grid container alignItems={'center'}>
			<Grid item xs={8}>
				<Typography variant="h3" paddingLeft={2}>
					Mise à disposition
				</Typography>
			</Grid>

			<Grid item xs={4} paddingRight={2}>
				<Grid container justifyContent="flex-end">
					<IconButton
						onClick={handleEditClick}
						bgcolor={isSelectedAction ? 'secondary' : 'primary'}
					>
						<EditIcon />
						<Typography paddingLeft={1} variant="h6">
							Modifier
						</Typography>
					</IconButton>
					<IconButton
						onClick={handleDeleteClick}
						bgcolor={isSelectedAction ? 'secondary' : 'error'}
					>
						<DeleteIcon />
						<Typography paddingLeft={1} variant="h6">
							Supprimer
						</Typography>
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AvailabilityDetailHeader;

type AvailabilityDetailHeaderProps = {
	isSelectedAction: boolean;
	setIsDeletableMode: Dispatch<SetStateAction<boolean>>;
	setIsEditableMode: Dispatch<SetStateAction<boolean>>;
	setIsSelectedAction: Dispatch<SetStateAction<boolean>>;
};
