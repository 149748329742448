import * as React from 'react';
import { useDispatch } from 'react-redux';
import { TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { cloneDeep } from 'lodash';

import { ServiceTypeEnum } from '../../../../../models';
import {
	useServiceType,
	useTypedSelector,
} from '../../../../../packages/hooks';
import { Spinner } from '../../../../../packages/ui';
import {
	setCheckedServicesDeleteServicesPopIn,
	setServiceToDeleteDeleteServicesPopIn,
} from '../../../../../redux/actions/deleteServicePopIn.actions';

import TableHeader from './TableHeader';
import CustomToolbar from './Toolbar';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

const CustomTable = ({ isLoading, onValidate }: CustomTableProps) => {
	const dispatch = useDispatch();
	const [order, setOrder] = React.useState<Order>('desc');
	const [orderBy, setOrderBy] = React.useState<keyof ServiceProps>('date');
	const { serviceTypesDelete } = useServiceType();
	const { checkedServicesTs, servicesTs } = useTypedSelector(
		({ deleteServicePopInState }) => {
			return {
				checkedServicesTs: deleteServicePopInState.checkedItems,
				servicesTs: deleteServicePopInState.services,
			};
		}
	);

	const handleCheckItem = (serviceId?: number) => {
		if (serviceId) {
			let newItems = cloneDeep(checkedServicesTs);
			if (newItems.includes(serviceId)) {
				newItems = newItems.filter((id) => id !== serviceId);
			} else {
				newItems.push(serviceId);
			}
			dispatch(setCheckedServicesDeleteServicesPopIn(newItems));
			dispatch(setServiceToDeleteDeleteServicesPopIn(-1));
		}
	};

	const getServiceTypeName = (serviceTypeId: ServiceTypeEnum): string => {
		const serviceTypeObj = serviceTypesDelete.find(
			(elm) => elm.id === serviceTypeId
		);
		return serviceTypeObj ? serviceTypeObj.name : '';
	};

	const rows: ServiceProps[] = servicesTs.map((serviceTs) => {
		return {
			checked: serviceTs.checked,
			date: serviceTs.service.date.toLocaleDateString('en-GB'),
			hour: serviceTs.service.date.toLocaleTimeString('en-GB'),
			id: serviceTs.service.id ?? 0,
			lineId: serviceTs.service.lineId ?? '',
			name: getServiceTypeName(serviceTs.service.serviceTypeId),
			trainCode: serviceTs.service.trainCode ?? '',
		};
	});

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof ServiceProps
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const allServicesId = rows.map((row) => row.id);
			dispatch(setCheckedServicesDeleteServicesPopIn(allServicesId));
		} else {
			dispatch(setCheckedServicesDeleteServicesPopIn([]));
		}
	};

	const handleClick = (row: ServiceProps) => {
		handleCheckItem(row.id);
	};

	const sortRows = rows.slice().sort(getComparator(order, orderBy));

	if (isLoading) return <Spinner />;

	return servicesTs && servicesTs.length > 0 ? (
		<Box data-testid="table-box" sx={{ width: '100%' }}>
			<Paper sx={{ mb: 2, width: '100%' }}>
				<CustomToolbar
					numSelected={checkedServicesTs.length}
					onValidate={onValidate}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size="small"
					>
						<TableHeader
							numSelected={checkedServicesTs.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{sortRows.map((row, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<TableRow
										hover
										onClick={() => handleClick(row)}
										role="checkbox"
										aria-checked={row.checked}
										tabIndex={-1}
										key={row.id.toString()}
										selected={row.checked}
										sx={{ cursor: 'pointer' }}
									>
										<TableCell padding="checkbox">
											<Checkbox
												color="primary"
												checked={row.checked}
												inputProps={{
													'aria-labelledby': labelId,
												}}
											/>
										</TableCell>
										<TableCell component="th" id={labelId} scope="row">
											{row.lineId}
										</TableCell>
										<TableCell>{row.trainCode}</TableCell>
										<TableCell>{row.name}</TableCell>
										<TableCell>{row.date}</TableCell>
										<TableCell>{row.hour}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	) : (
		<Box sx={{ height: '80px', textAlign: 'center' }}>
			<Typography variant="body1" sx={{ margin: '0 auto' }}>
				Aucun élément, veuillez choisir un filtre.
			</Typography>
		</Box>
	);
};

export type ServiceProps = {
	id: number;
	checked: boolean;
	name: string;
	date: string;
	hour: string;
	trainCode: string;
	lineId: string;
};

type CustomTableProps = {
	isLoading: boolean;
	onValidate: () => void;
};

export default CustomTable;
