import { ThunkAction } from 'redux-thunk';

import { localStorageFuncs } from '../../packages/helpers';
import { setSelectedPassage } from '../actions/passage.actions';
import { ActionTypes, ApiThunks, AppStore } from '../types';

type PassageThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const getSelectedPassageUser = (): PassageThunk => async (dispatch) => {
  if (!localStorageFuncs.get('selected-passage')) {
    localStorageFuncs.set('selected-passage', 1);
    dispatch(setSelectedPassage(1));
  }
  const selectedPassageUser = localStorage.getItem('selected-passage');
  if (selectedPassageUser) dispatch(setSelectedPassage(parseInt(selectedPassageUser, 10)));
};

export const setSelectedPassageUser =
  (passage: number): PassageThunk =>
  async (dispatch) => {
    localStorageFuncs.set('selected-passage', passage);
    dispatch(setSelectedPassage(passage));
  };
