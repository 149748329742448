import { RoleEnum } from '../../models';
import { hasRole } from '../helpers/';

import { useTypedSelector } from './useTypedSelector';

export const useEntity = () => {
	const { AGENTN, ADMIN, AGENTM, PROPRETE, PROPRETESNCF, TR } = RoleEnum;

	const userTs = useTypedSelector(({ userState: { user } }) => user);

	const registrationNumber = userTs ? userTs.registration_number : '';

	const isAdmin = userTs && hasRole(userTs, [ADMIN]);
	const isAgentM = userTs && hasRole(userTs, [AGENTM]);
	const isAgentN = userTs && hasRole(userTs, [AGENTN]);
	const isRp = userTs && hasRole(userTs, [PROPRETE]);
	const isRpSNCF = userTs && hasRole(userTs, [PROPRETESNCF]);
	const isTr = userTs && hasRole(userTs, [TR]);

	return {
		isAdmin,
		isAgentM,
		isAgentN,
		isRp,
		isRpSNCF,
		isTr,
		registrationNumber,
		userTs,
	};
};
