import { Divider as MuiDivider } from '@mui/material';

const Divider = ({ sx }: { sx?: any }) => {
	const styles = { width: '80%', ...sx };
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				width: '90%',
			}}
		>
			<MuiDivider variant="middle" sx={styles} />
		</div>
	);
};

export default Divider;
