import { initRightState } from '../config/initialState';
import {
	RightActionTypes as ActionTypes,
	RightState,
	SET_RIGHT,
} from '../types/right.types';

export const rightReducer = (
	state = initRightState,
	action: ActionTypes
): RightState => {
	if (action.type === SET_RIGHT) {
		return action.payload.state as unknown as RightState;
	} else {
		return state;
	}
};

export default rightReducer;
