export const loadingState = {
	isError: false,
	isLoaded: false,
	isUpdated: false,
};

export const loadingResponseState = {
	isError: false,
	isLoaded: true,
	isUpdated: true,
};

export const loadingErrorState = {
	isError: true,
	isLoaded: false,
	isUpdated: false,
};

export const updateState = {
	isError: false,
	isLoaded: true,
	isUpdated: false,
};

export const updateResponseState = {
	isError: false,
	isLoaded: true,
	isUpdated: true,
};

export const updateErrorState = {
	isError: true,
	isLoaded: true,
	isUpdated: false,
};
