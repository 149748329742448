import { IncidentTypeRightsList } from '../../../models';
import fetchJson from '../fetch/fetch-json';

const loadIncidentTypes = async (): Promise<{
	data: IncidentTypeRightsList;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/incident-type`);
};

export default {
	loadIncidentTypes,
};
