import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { Role } from '../../models';

const MAX_TABLETTE_WIDTH = 2050;
const MAX_SMARTPHONE_WIDTH = 1090;

export function mobileCheck() {
	return maxWidthCheck(MAX_SMARTPHONE_WIDTH);
}

export function tabletteCheck() {
	return (
		minWidthCheck(MAX_SMARTPHONE_WIDTH) && maxWidthCheck(MAX_TABLETTE_WIDTH)
	);
}

export function desktopCheck() {
	return minWidthCheck(MAX_TABLETTE_WIDTH);
}

/**
 * Check if {value} <= current width
 * @param value
 */
function minWidthCheck(value: number): boolean {
	return window.matchMedia(`(min-width:  ${value}px)`).matches;
}

/**
 * Check if {value} >= current width
 * @param value
 */
function maxWidthCheck(value: number): boolean {
	return window.matchMedia(`(max-width:  ${value}px)`).matches;
}

export function someAreNil(...args: any[]): boolean {
	args.forEach((value) => {
		if (isNil(value) || !value) return true;
	});
	return false;
}

export function rolesToString(roles: Role[]) {
	return roles.map(({ name }: any) => name).join(', ');
}

export function sortASC(array: string[]) {
	if (isEmpty(array)) return [];
	else return array.sort(sortFn);
}

function sortFn(a: any, b: any): any {
	if (!a || !b) return -1;
	const _a = a.match(/^([a-z]*)(\d*)/i);
	const _b = b.match(/^([a-z]*)(\d*)/i);

	// if the alphabetic part of a is less than that of b => -1
	if (_a[1] < _b[1]) return -1;
	// if the alphabetic part of a is greater than that of b => 1
	if (_a[1] > _b[1]) return 1;

	// if the alphabetic parts are equal, check the number parts
	const _n = parseInt(_a[2]) - parseInt(_b[2]);
	if (_n === 0)
		// if the number parts are equal start a recursive test on the rest
		return sortFn(a.substr(_a[0].length), b.substr(_b[0].length));
	// else, just sort using the numbers parts
	return _n;
}

/**
 * Checks if object is instanceof T
 * @param object
 * @param args
 */
export const isClassTypeOf = <T>(
	object: any,
	...args: string[]
): object is T => {
	if (isEmpty(args) || !object) return false;
	return args?.every((arg) => arg in object);
};

export const moveElementInArray = (input: any[], from: number, to: number) => {
	let numberOfDeletedElm = 1;
	const elm = input.splice(from, numberOfDeletedElm)[0];
	numberOfDeletedElm = 0;
	input.splice(to, numberOfDeletedElm, elm);
};

export const pluralize = (
	count: number,
	word: string,
	isRegular = true
): string => {
	const plurialSufix = isRegular ? 's' : 'x';
	if (count <= 1) return word;
	return `${word}${plurialSufix}`;
};

export const capitalize = (word: string): string =>
	word.charAt(0).toUpperCase() + word.slice(1);

export const getFormattedStringArrayForSelect = (
	array: string[]
): { id: number; name: string }[] => {
	return array.map((element, index) => {
		return { id: index, name: element };
	});
};
