import { ReportingUnitStat, UnitCount } from '../../models';
import {
	IncidentReporting,
	ReportingActionTypes,
	ServiceReporting,
	SET_REPORTING_INCIDENTS,
	SET_REPORTING_LINE,
	SET_REPORTING_NCNP,
	SET_REPORTING_RS_COUNT,
	SET_REPORTING_UNIT_COUNT,
	SET_REPORTING_UNIT_STAT,
} from '../types/reporting.types';

export const setReportingRSCount = (count: number): ReportingActionTypes => ({
	payload: { count: count },
	type: SET_REPORTING_RS_COUNT,
});

export const setReportingUnitCount = (
	count: UnitCount
): ReportingActionTypes => ({
	payload: { count: count },
	type: SET_REPORTING_UNIT_COUNT,
});

export const setReportingUnitStat = (
	stat: ReportingUnitStat
): ReportingActionTypes => ({
	payload: { stat },
	type: SET_REPORTING_UNIT_STAT,
});

export const setReportingNCNP = (
	services: ServiceReporting[]
): ReportingActionTypes => ({
	payload: { services: services },
	type: SET_REPORTING_NCNP,
});

export const setReportingIncidents = (
	incidents: IncidentReporting[]
): ReportingActionTypes => ({
	payload: { incidents: incidents },
	type: SET_REPORTING_INCIDENTS,
});

export const setReportingLine = (line?: string): ReportingActionTypes => ({
	payload: { selectedLine: line },
	type: SET_REPORTING_LINE,
});

export const reportingActions = {
	setReportingIncidents,
	setReportingLine,
	setReportingNCNP,
	setReportingRSCount,
	setReportingUnitCount,
};

export default reportingActions;
