import { format } from 'date-fns';

import { RoleEnum, Unit } from '../../../../models';
import { hasRole } from '../../../../packages/helpers';
import { useTypedSelector } from '../../../../packages/hooks';
import { Button } from '../../../../packages/ui';
import SemStatus, {
	StatusEnum,
} from '../../../sem/TrackingBoard/SemStatus/SemStatus';
import { isTimeInRangeOfPassage } from '../../../sem/utils';

import '../styles.css';

const StationDetailPopInRow = ({
	onValidate,
	passage,
	unit,
}: StationDetailPopInRowProps) => {
	const userTs = useTypedSelector(({ userState: { user } }) => user);
	const isAgentM = userTs && hasRole(userTs, [RoleEnum.AGENTM]);

	const canValidatePassage = !unit[`latestServiceDate${passage}` as keyof Unit];
	const canValidateUnit =
		isTimeInRangeOfPassage(unit, passage) && canValidatePassage;

	const getServiceDate = () => {
		if (unit[`latestServiceDate${passage}` as keyof Unit]) {
			return format(
				new Date(unit[`latestServiceDate${passage}` as keyof Unit] as string),
				'HH:mm'
			);
		}
		return '-';
	};

	return (
		<section
			className={'station-detail-card-container'}
			key={unit.name + passage}
		>
			<div className={'station-detail-card__status'}>
				<SemStatus
					status={`${!canValidatePassage ? StatusEnum.DONE : StatusEnum.TODO}`}
				/>
			</div>
			<div className={'station-detail-card__unit-container-mobile show-mobile'}>
				<div className={'station-detail-card__unit'}>
					{unit.sequence}: {unit.name}
				</div>
				{canValidateUnit && (
					<div className={'station-detail-card__validate'}>
						{isAgentM && (
							<Button onClick={() => onValidate(unit)}>
								Valider à distance
							</Button>
						)}
					</div>
				)}
			</div>
			<div className={'station-detail-card__unit hide-mobile'}>
				{unit.sequence}: {unit.name}
			</div>
			{canValidateUnit && (
				<div className={'station-detail-card__validate hide-mobile'}>
					{isAgentM && (
						<Button onClick={() => onValidate(unit)}>Valider à distance</Button>
					)}
				</div>
			)}

			<div className={'station-detail-card__end'}>{getServiceDate()}</div>
		</section>
	);
};

export default StationDetailPopInRow;

type StationDetailPopInRowProps = {
	onValidate: (unit: Unit) => void;
	passage: number | undefined;
	unit: Unit;
};
