import { PopupActionTypes, PopupState } from './popup.types';

export enum components {
	AVAILABILITY = 'availability',
	INCIDENT_TYPE = 'incident_type',
	INCIDENTS = 'incidents',
	AUTH = 'auth',
	CREATE_USER = 'create_user',
	CREDENTIALS = 'credentials',
	DELETE_USER = 'delete_user',
	LINES = 'lines',
	RIGHT = 'right',
	RIGHT_BY_TYPE = 'right_by_type',
	RIGHT_TYPE = 'right_type',
	ROLES = 'roles',
	ROLLINGSTOCK = 'rollingstock',
	ROLLINGSTOCK_USED = 'rollingstock_used',
	SERVICE = 'service',
	SERVICES = 'services',
	SERVICETYPE = 'servicetype',
	SETTING = 'setting',
	SPECIAL_OPERATIONS = 'special_operations',
	SPECIAL_OPERATION_TYPE = 'special_operation_type',
	STATION = 'station',
	STATIONDETAILPOPIN = 'stationdetailpopin',
	UNIT = 'UNIT',
	USED_UPDATE = 'used_update',
}

export interface ApiState {
	incidentTypes: {
		isError: false;
		isLoaded: false;
	};
	credentials: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	rollingstock: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	services: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	lines: {
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	roles: {
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	serviceTypes: {
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	used_update?: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	create_user?: {
		isUpdated: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	delete_user?: {
		isUpdated: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	incidentReport: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	right: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	rightType: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
	rollingstockused: {
		isUpdated: boolean;
		isLoaded: boolean;
		isError: boolean;
		errorInfo?: any;
	};
}

export const REQUEST = 'REQUEST';
export const RESPONSE = 'RESPONSE';
export const ERROR = 'ERROR';

export interface RequestAction {
	type: typeof REQUEST;
	component: components;
}
export interface ResponseAction {
	type: typeof RESPONSE;
	component: components;
}
export interface ErrorAction {
	type: typeof ERROR;
	component: components;
	error: any;
}

export type ApiActionTypes = RequestAction | ResponseAction | ErrorAction;

export type ApiThunks = {
	_request: (component: components) => ApiActionTypes;
	_response: (component: components) => ApiActionTypes;
	_error: (component: components, err: any) => ApiActionTypes;
	showFeedBack: (config: PopupState) => PopupActionTypes;
};
