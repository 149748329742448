import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SemHeader from '../../components/dashboard/semHeader';
import UnitsList from '../../components/dashboard/unitList';
import LineSelector from '../../components/lineSelector';
import { ServiceTypeEnum } from '../../models';
import { dateTimeService } from '../../packages/helpers';
import {
	useDepartment,
	useEntity,
	useLine,
	useTypedSelector,
} from '../../packages/hooks';
import { openHistoryPopIn } from '../../redux/actions/historyPopIn.actions';
import { setReportingLine } from '../../redux/actions/reporting.actions';
import { loadRightByType } from '../../redux/thunks/right';
import { loadTrainCount } from '../../redux/thunks/rollingStock';
import { loadCleanings } from '../../redux/thunks/service';
import { loadSetting } from '../../redux/thunks/setting';
import { loadStationCount } from '../../redux/thunks/station';
import { loadUnitCount, loadUnitStats } from '../../redux/thunks/unit';
import { ServiceReporting } from '../../redux/types';

const { NP, NCR, NQ, PS, NC } = ServiceTypeEnum;

const SemDashboardPage = () => {
	const dispatch = useDispatch();
	const { isAdmin } = useEntity();
	const { hasMultiLines, selectedLine } = useLine();
	const { department, hasSemDepartment } = useDepartment();
	const { services } = useTypedSelector((state) => state.reportingState);

	const [selectedPeriod, setSelectedPeriod] = useState<string>('');
	const [counts, setCounts] = useState({
		ncCount: 0,
		ncrCount: 0,
		npCount: 0,
		nqCount: 0,
		prestaSupCount: 0,
	});

	const defaultService: ServiceReporting = {
		dayCount: 0,
		monthCount: 0,
		type: hasSemDepartment ? NQ : NC,
		yearCount: 0,
	};

	const getServiceByType = (type: ServiceTypeEnum): ServiceReporting => {
		const service = services.find((el) => Number(el.type) === type);
		return service ?? { ...defaultService, type: type };
	};

	const getCount = (type: ServiceTypeEnum): number => {
		if (selectedPeriod === dateTimeService.getCurrentYearString()) {
			return getServiceByType(type).yearCount ?? 0;
		}
		return getServiceByType(type).monthCount ?? 0;
	};

	const handleExportClick = (): void => {
		dispatch(openHistoryPopIn());
	};

	useEffect(() => {
		setCounts({
			ncCount: getCount(NC),
			ncrCount: getCount(NCR),
			npCount: getCount(NP),
			nqCount: getCount(NQ),
			prestaSupCount: getCount(PS),
		});
		// eslint-disable-next-line
	}, [services]);

	useEffect(() => {
		const endDate = new Date();
		const beginDate = new Date(
			endDate.getFullYear(),
			endDate.getMonth(),
			1,
			0,
			0,
			0
		);
		if (selectedLine) {
			if (hasSemDepartment) {
				dispatch(loadUnitStats(selectedLine, beginDate, endDate));
				dispatch(loadStationCount(selectedLine));
				dispatch(loadUnitCount(selectedLine));
			}
			dispatch(loadTrainCount(selectedLine, department));
			dispatch(loadSetting(selectedLine));
			dispatch(loadCleanings(beginDate, endDate, selectedLine, department));
			dispatch(setReportingLine(selectedLine));
		}
	}, [selectedLine, department, selectedPeriod]);

	useEffect(() => {
		if (isAdmin) dispatch(loadRightByType());
	}, []);

	return (
		<main>
			<SemHeader
				data-testid="dashbord-page"
				onExportClick={() => handleExportClick()}
				onPeriodChange={setSelectedPeriod}
				selectedPeriod={selectedPeriod}
			/>
			<div className="content">
				{hasMultiLines && <LineSelector showAll={true} />}

				<UnitsList counts={counts} selectedPeriod={selectedPeriod} />
			</div>
		</main>
	);
};

export default SemDashboardPage;
