// Credential form
export const CRD_FORM_ERR_LINE = 'Veuillez sélectionner une ligne.';
export const CRD_FORM_ERR_ORGANISATION = 'Veuillez saisir une entreprise.';
export const CRD_FORM_ERR_REGISTRATION_NUMBER =
	'Veuillez saisir un compte matriculaire.';
export const CRD_FORM_ERR_ROLE = 'Veuillez sélectionner un rôle.';

// Credential page
export const CRD_ADD_BTN = 'Ajouter un utilisateur';
export const CRD_ADD_MOBILE_BTN = '+ Utilisateur';
export const CRD_TITLE = 'Habilitations';
export const CRD_SUBTITLE = 'Gérez les accès à Smart Tracer';
