import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const captureScreenshotPdf = async () => {
	try {
		const exportButton = document.querySelector(
			'.reporting-header-export-button'
		);

		if (exportButton) {
			exportButton.classList.add('reporting-header-export-button-hidden');
			window.scrollTo(0, 0);
		}

		const canvas = await html2canvas(document.body);

		if (exportButton) {
			exportButton.classList.remove('reporting-header-export-button-hidden');
		}
		const dataURL = canvas.toDataURL('image/png');

		const pdfWidth = 297;
		const pdfHeight = 210;
		const marginWidth = 5;
		const marginHeight = 5;

		const usableWidth = pdfWidth - 2 * marginWidth;
		const usableHeight = pdfHeight - 2 * marginHeight;

		const ratio = Math.min(
			usableWidth / canvas.width,
			usableHeight / canvas.height
		);

		const imgWidth = canvas.width * ratio;
		const imgHeight = canvas.height * ratio;

		const x = (pdfWidth - imgWidth) / 2;
		const y = marginHeight;

		const pdf = new jsPDF('l', 'mm', 'a4');

		pdf.addImage(dataURL, 'PNG', x, y, imgWidth, imgHeight);
		pdf.save(`Rapport-smart-tracer_${format(new Date(), 'dd-MM-yyyy')}.pdf`);
	} catch (error) {
		console.error('Error capturing screenshot:', error);
	}
};
