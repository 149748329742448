import { Dispatch, SetStateAction } from 'react';
import { isEmpty, isEqual } from 'lodash';

import { ModalesState } from '../../packages/hooks';
import { Button } from '../../packages/ui';

const AvailabilityButton = ({
	row,
	getColorText,
	handleOpenModal,
	setColorNp,
	setSelectedTrainId,
}: AvailabilityButtonProps) => {
	const {
		availability,
		id: trainId,
		incidents,
		specialOperations,
		NpDeltaTime,
	} = row;

	const isVisibleAvailabilityButtons = (): boolean => {
		return (
			!isEmpty(incidents) ||
			!isEmpty(specialOperations) ||
			!isEqual(Number(NpDeltaTime), 0)
		);
	};

	if (!isVisibleAvailabilityButtons()) return <></>;

	return (
		<>
			{!isEmpty(availability) ? (
				<Button
					onClick={() => {
						setSelectedTrainId(trainId);
						setColorNp(getColorText(row, 'NP'));
						handleOpenModal('availabilityEditForm');
					}}
					style={{
						backgroundColor: 'white',
						borderColor: 'primary',
						borderRadius: '8px',
					}}
				>
					Détail
				</Button>
			) : (
				<Button
					onClick={() => {
						setSelectedTrainId(trainId);
						setColorNp(getColorText(row, 'NP'));
						handleOpenModal('availabilitySelect');
					}}
					style={{
						backgroundColor: 'white',
						borderColor: 'primary',
						borderRadius: '8px',
					}}
				>
					Mise à Disposition
				</Button>
			)}
		</>
	);
};
export default AvailabilityButton;

type AvailabilityButtonProps = {
	row: any;
	getColorText: (row: any, cell: string) => string;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	setColorNp: Dispatch<SetStateAction<string>>;
	setSelectedTrainId: Dispatch<SetStateAction<number | undefined>>;
};
