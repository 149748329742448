import { useEffect, useState } from 'react';

import { useDepartment } from './useDepartment';
import { useEntity } from './useEntity';
import { useTypedSelector } from './useTypedSelector';

const getAllLinesByDepartment = (
	hasRerDepartment: boolean,
	hasMtsDepartment: boolean,
	hasSemDepartment: boolean
): string[] => {
	if (hasRerDepartment) return allRerLines;
	if (hasMtsDepartment) return allMetroLines;
	if (hasSemDepartment) return allSemLines;
	return [];
};

const stringIsNotEmpty = (param: string): boolean => {
	return Boolean(param) && param !== '';
};

const checkmultipleLines = (
	lineIdFromUser: string,
	isAdmin: boolean | undefined
): boolean => {
	const lineInIsValid = lineIdFromUser && lineIdFromUser !== '';
	return (
		((lineInIsValid && lineIdFromUser.split(',').length > 1) || isAdmin) ??
		false
	);
};

const getLineId = (linesIdFromUser: string): string => {
	const hasManyItems = linesIdFromUser.includes(',');
	return hasManyItems ? linesIdFromUser.split(',')[0] : linesIdFromUser;
};

export const useLine = (
	showAll = false
): {
	hasMultiLines: boolean | undefined;
	selectedLine: string;
	allLines: string[];
} => {
	const { hasMtsDepartment, hasRerDepartment, hasSemDepartment, department } =
		useDepartment();

	const { isAdmin, userTs } = useEntity();
	const [selectedLine, setSelectedLine] = useState('');

	const lineIdFromState = useTypedSelector(
		({ lineState }) => lineState.selectedLine
	);
	const lineIdFromUser = userTs?.line_id ?? '';

	const hasMultiLines = checkmultipleLines(lineIdFromUser, isAdmin);

	const getAllLines = () => {
		// This data must be fetch on init in  userTs.line_id
		if (isAdmin) {
			return getAllLinesByDepartment(
				hasRerDepartment,
				hasMtsDepartment,
				hasSemDepartment
			);
		}
		return hasMultiLines ? lineIdFromUser.split(',') : [lineIdFromUser];
	};

	const getDeptToDisplay = (defaultDept: string) => {
		return showAll ? department ?? '' : defaultDept;
	};

	const allLinesResult = () => {
		if (department)
			return showAll ? [department, ...getAllLines()] : getAllLines();
	};

	useEffect(() => {
		if (stringIsNotEmpty(lineIdFromState)) {
			setSelectedLine(lineIdFromState);
		} else if (stringIsNotEmpty(lineIdFromUser)) {
			setSelectedLine(getLineId(lineIdFromUser));
		} else if (isAdmin && hasRerDepartment) {
			setSelectedLine(getDeptToDisplay('A'));
		} else if (isAdmin && hasMtsDepartment)
			setSelectedLine(getDeptToDisplay('1'));
	}, [
		hasMtsDepartment,
		hasRerDepartment,
		isAdmin,
		lineIdFromState,
		lineIdFromUser,
	]);

	return {
		allLines: allLinesResult() ?? [],
		hasMultiLines,
		selectedLine,
	};
};

const allRerLines = ['A', 'B'];
const allMetroLines = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
];
const allSemLines = [...allRerLines, ...allMetroLines];
