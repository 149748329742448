import { Typography } from '@mui/material';

import { ServiceTypeEnum } from '../../../../models';
import { pluralize } from '../../../../packages/helpers';

import { StyledUnacceptableContainer } from './styles';

import '../../../../assets/styles/constants/_colors.css';

const Unacceptable = ({ serviceType, count }: UnacceptableProps) => {
	const getServiceTypeName = (serviceType: ServiceTypeEnum): string => {
		return serviceType === ServiceTypeEnum.NP ? 'NP' : 'MAL';
	};

	return (
		<StyledUnacceptableContainer count={count}>
			<Typography style={{ fontSize: '2.25rem' }}>
				{count} {getServiceTypeName(serviceType)}
			</Typography>
			<Typography style={{ fontSize: '1.125rem' }}>
				{pluralize(count, 'inacceptable')}
			</Typography>
		</StyledUnacceptableContainer>
	);
};

export default Unacceptable;

type UnacceptableProps = {
	serviceType: ServiceTypeEnum;
	count: number;
};
