import { IncidentTypeEnum, Item, Role, ServiceTypeEnum } from '../../models';

export type HistoryPopInState = {
	meta: {
		validated: boolean;
		open: boolean;
	};
	servicesSelection: HistorySelection[];
	itemsFilter: Item[];
	usersProfilFilter: Role[];
	startDate: Date;
	endDate: Date;
	selectedLines: string[];
};

export type HistorySelection = {
	type: HistorySelectionTypeEnum;
	selected: boolean;
	id: ServiceTypeEnum | IncidentTypeEnum;
	name: string;
	disabled?: boolean;
	key?: string;
};

export enum HistorySelectionTypeEnum {
	Generic = 0,
	Service = 1,
	IncidentReport = 2,
	TrainUsage = 3,
	Sem = 4,
}

export const CLOSE_HISTORY_POPIN = 'CLOSE_HISTORY_POPIN';
export const OPEN_HISTORY_POPIN = 'OPEN_HISTORY_POPIN';
export const VALIDATED_HISTORY_POPIN = 'VALIDATED_HISTORY_POPIN';
export const SET_HISTORY_SERVICES_SELECTION = 'SET_HISTORY_SERVICES_SELECTION';
export const SET_HISTORY_START_DATE = 'SET_HISTORY_START_DATE';
export const SET_HISTORY_END_DATE = 'SET_HISTORY_END_DATE';
export const SET_HISTORY_ITEMS_FILTER = 'SET_HISTORY_ITEMS_FILTER';
export const SET_HISTORY_SELECTED_LINES = 'SET_HISTORY_SELECTED_LINES';
export const SET_HISTORY_USERS_PROFIL = 'SET_HISTORY_USERS_PROFIL';
export const SET_HISTORY_RIGHTS = 'SET_HISTORY_RIGHTS';

export interface CloseHistoryPopInAction {
	type: typeof CLOSE_HISTORY_POPIN;
}
export interface OpenHistoryPopInAction {
	type: typeof OPEN_HISTORY_POPIN;
}
export interface ValidatedHistoryPopInAction {
	type: typeof VALIDATED_HISTORY_POPIN;
	payload: boolean;
}

export interface SetHistoryServicesSelectionAction {
	type: typeof SET_HISTORY_SERVICES_SELECTION;
	payload: HistorySelection[];
}

export interface SetHistoryStartDateAction {
	type: typeof SET_HISTORY_START_DATE;
	payload: Date;
}

export interface SetHistoryEndDateAction {
	type: typeof SET_HISTORY_END_DATE;
	payload: Date;
}
export interface SetHistoryItemsFilterAction {
	type: typeof SET_HISTORY_ITEMS_FILTER;
	payload: Item[];
}

export interface SetHistorySelectedLinesAction {
	type: typeof SET_HISTORY_SELECTED_LINES;
	payload: string[];
}
export interface SetHistoryUsersProfil {
	type: typeof SET_HISTORY_USERS_PROFIL;
	payload: Role[];
}

export type HistoryPopInActionTypes =
	| CloseHistoryPopInAction
	| OpenHistoryPopInAction
	| ValidatedHistoryPopInAction
	| SetHistoryStartDateAction
	| SetHistoryEndDateAction
	| SetHistorySelectedLinesAction
	| SetHistoryServicesSelectionAction
	| SetHistoryItemsFilterAction
	| SetHistoryUsersProfil;
