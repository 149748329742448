import { Right } from '../../../models/right.model';
import { RightByType } from '../../../models/rightByType.model';
import fetchJson from '../fetch/fetch-json';


const loadRight = async (): Promise<{
	data: Right[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson('/right');
};

const loadRightByType = async (): Promise<{
	data: RightByType[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/right/description`);
};


const updateRight = async (rightId: number): Promise<{
    data: RightByType[];
    ok: boolean;
    status: number;
    statusText: string; 
}> => {
    return await fetchJson(`/right/${rightId}`, { method: 'PUT' });
};

export default {loadRight, loadRightByType,updateRight, };



