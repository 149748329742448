// prettier-ignore
import { AlertTypeEnum } from '../../packages/events/types';
import {
	ADD_TO_QUEUE_NOTIFICATION_POPIN,
	CLOSE_NOTIFICATION_POPIN,
	NotificationPopInActionTypes,
	OPEN_NOTIFICATION_POPIN,
} from '../types';

export const closeNotificationPopIn = (): NotificationPopInActionTypes => ({
	type: CLOSE_NOTIFICATION_POPIN,
});

export const openNotificationPopIn = (
	title: string,
	subTitle: string,
	rsCodes: string[],
	confirmTxt: string,
	type: AlertTypeEnum
): NotificationPopInActionTypes => ({
	payload: { confirmTxt, rsCodes, subTitle, title, type },
	type: OPEN_NOTIFICATION_POPIN,
});

export const addToNotificationQueuePopIn = (
	title: string,
	subTitle: string,
	rsCodes: string[],
	confirmTxt: string,
	type: AlertTypeEnum
): NotificationPopInActionTypes => ({
	payload: { confirmTxt, rsCodes, subTitle, title, type },
	type: ADD_TO_QUEUE_NOTIFICATION_POPIN,
});

const notificationPopinActions = {
	addToNotificationQueuePopIn,
	closeNotificationPopIn,
	openNotificationPopIn,
};

export default notificationPopinActions;
