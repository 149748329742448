export default class CookieService {
	private currentCookies = document.cookie;

	public getCookie(cookieName: string) {
		if (
			this.currentCookies
				.split(';')
				.some((item) => item.trim().startsWith(`${cookieName}=`))
		) {
			const result = this.currentCookies
				?.split('; ')
				?.find((row) => row.startsWith(`${cookieName}`))
				?.split('=')[1];

			return result;
		} else {
			return null;
		}
	}

	public setCookie(name: string, value: string, days: number) {
		let expires = '';
		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	public removeCookie(cookieName: string) {
		document.cookie = cookieName + '=; Max-Age=-99999999;';
	}

	public clear() {
		this.removeCookie('authorization');
		this.removeCookie('alert-received');
	}
}
