import { v4 as uuidv4 } from 'uuid';

export * from './auth.utils';
export * from './constants';
export * from './cookie';
export * from './dateTime';
export * from './explanations';
export * from './persistent.utils';
export * from './popup/exception.utils';
export * from './rollingstock.utils';
export * from './shared.utils';

export const getUniqueKey = (foggyKey: string): string => {
	return foggyKey + uuidv4();
};

export const getCSSLine = (selectedLine: string): string => {
	if (selectedLine === '3') return '3-3b';
	else if (selectedLine === '7') return '7-7b';
	else return selectedLine.toLowerCase();
};

export const valuesToFormData = (values: any) => {
	const formData = new FormData();
	if (values) {
		Object.entries(values).forEach((item: any) => {
			if (typeof item[1] === 'object' && item[0] !== 'files') {
				formData.append(item[0], JSON.stringify(item[1]));
			} else if (typeof item[1] === 'object' && item[0] === 'files') {
				values.files.forEach((file: File) => {
					formData.append(`files`, file);
				});
			} else {
				formData.append(item[0], item[1]);
			}
		});
	}
	return formData;
};
