import {
	IncidentTypeEnum,
	ReportingUnitStat,
	ServiceTypeEnum,
	UnitCount,
} from '../../models';

export interface ReportingState {
	periode: string;
	services: ServiceReporting[];
	incidents: IncidentReporting[];
	rsCount: number;
	unitCount: UnitCount;
	selectedLine?: string;
	unitsStats?: ReportingUnitStat;
}

export type ServiceReporting = {
	type: ServiceTypeEnum;
	yearCount?: number;
	monthCount?: number;
	dayCount?: number;
};

export type IncidentReporting = {
	type: IncidentTypeEnum;
	monthCount: number;
	yearCount: number;
};

export type IncidentReportingCard = {
	id: number;
	monthCount: number;
	yearCount: number;
	type?: IncidentTypeEnum;
	title?: string;
};

export const SET_REPORTING = 'SET_REPORTING';
export const SET_REPORTING_NCNP = 'SET_REPORTING_NCNP';
export const SET_REPORTING_INCIDENTS = 'SET_REPORTING_INCIDENTS';
export const SET_REPORTING_LINE = 'SET_REPORTING_LINE';
export const SET_REPORTING_RS_COUNT = 'SET_REPORTING_RS_COUNT';
export const SET_REPORTING_UNIT_COUNT = 'SET_REPORTING_UNIT_COUNT';
export const SET_REPORTING_UNIT_STAT = 'SET_REPORTING_UNIT_STAT';

export interface SetReportingAction {
	type: typeof SET_REPORTING;
	payload: { state: ReportingState };
}

export interface SetReportingIncidentsAction {
	type: typeof SET_REPORTING_INCIDENTS;
	payload: { incidents: IncidentReporting[] };
}

export interface SetReportingNCNPAction {
	type: typeof SET_REPORTING_NCNP;
	payload: { services: ServiceReporting[] };
}

export interface SetReportingLineAction {
	type: typeof SET_REPORTING_LINE;
	payload: { selectedLine: string | undefined };
}

export interface SetReportingRSCountAction {
	type: typeof SET_REPORTING_RS_COUNT;
	payload: { count: number };
}

export interface SetReportingUnitCountAction {
	type: typeof SET_REPORTING_UNIT_COUNT;
	payload: { count: UnitCount };
}

export interface SetReportingUnitStatAction {
	type: typeof SET_REPORTING_UNIT_STAT;
	payload: { stat: ReportingUnitStat };
}

export type ReportingActionTypes =
	| SetReportingIncidentsAction
	| SetReportingNCNPAction
	| SetReportingLineAction
	| SetReportingRSCountAction
	| SetReportingUnitCountAction
	| SetReportingUnitStatAction;
