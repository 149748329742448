import { ThunkAction } from 'redux-thunk';

import { ServiceDTO, unitService } from '../../packages/dataProviders';
import { errorConf } from '../../packages/helpers';
import { initStationDetailPopIn, setUnitsStationDetailPopIn } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

import { createServices } from './service';

const { STATIONDETAILPOPIN } = components;

type StationDetailThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const loadStationUnits =
	(
		stationId: number,
		stationName: string,
		line: string,
		passage: number
	): StationDetailThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(STATIONDETAILPOPIN));
			dispatch(setUnitsStationDetailPopIn([]));
			const units = await unitService.getUnitsFromStationId(
				stationId,
				line,
				passage
			);
			dispatch(api._response(STATIONDETAILPOPIN));
			dispatch(initStationDetailPopIn(stationName, stationId, units, passage));
			dispatch(setUnitsStationDetailPopIn(units));
		} catch (e) {
			dispatch(api._error(STATIONDETAILPOPIN, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const reloadUnits =
	(stationId: number, line: string, passage: number): StationDetailThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(STATIONDETAILPOPIN));
			const units = await unitService.getUnitsFromStationId(
				stationId,
				line,
				passage
			);
			dispatch(api._response(STATIONDETAILPOPIN));
			dispatch(setUnitsStationDetailPopIn(units));
		} catch (e) {
			dispatch(api._error(STATIONDETAILPOPIN, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};

export const createServiceAndReloadUnits =
	(
		stationId: number,
		line: string,
		service: ServiceDTO,
		passage: number
	): StationDetailThunk =>
	async (dispatch, _, api) => {
		try {
			await dispatch(createServices([service]));
			await dispatch(reloadUnits(stationId, line, passage));
		} catch (e) {
			dispatch(api._error(STATIONDETAILPOPIN, e));
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		}
	};
