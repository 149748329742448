import { ServiceTypeRightsList } from '../../models';
import { ServiceTypeActionTypes, SET_SERVICE_TYPE } from '../types';

export const setServiceTypes = (
	serviceTypes: ServiceTypeRightsList
): ServiceTypeActionTypes => ({
	payload: serviceTypes,
	type: SET_SERVICE_TYPE,
});

export const serviceTypeActions = {
	setServiceTypes,
};

export default serviceTypeActions;
