import { Unit } from '../../models';
import {
	CLOSE_STATION_DETAIL_POPIN,
	INIT_STATION_DETAIL_POPIN,
	OPEN_STATION_DETAIL_POPIN,
	SET_UNITS_STATION_DETAIL_POPIN,
	StationDetailPopInActionTypes,
} from '../types';

export const closeStationDetailPopIn = (): StationDetailPopInActionTypes => ({
	type: CLOSE_STATION_DETAIL_POPIN,
});

export const openStationDetailPopIn = (
	stationName: string,
	stationId: number
): StationDetailPopInActionTypes => ({
	payload: {
		stationId: stationId,
		stationName: stationName,
	},
	type: OPEN_STATION_DETAIL_POPIN,
});

export const setUnitsStationDetailPopIn = (
	units: Unit[]
): StationDetailPopInActionTypes => ({
	payload: { units: units },
	type: SET_UNITS_STATION_DETAIL_POPIN,
});

export const initStationDetailPopIn = (
	stationName: string,
	stationId: number,
	units: Unit[],
	passage: number
): StationDetailPopInActionTypes => ({
	payload: { passage, stationId, stationName, units },
	type: INIT_STATION_DETAIL_POPIN,
});

const stationDetailPopInActions = {
	closeStationDetailPopIn,
	initStationDetailPopIn,
	openStationDetailPopIn,
	setUnitsStationDetailPopIn,
};

export default stationDetailPopInActions;
