import { Dispatch, SetStateAction, useState } from 'react';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';

import {
	defaultSelect,
	Export,
	PERIOD_STRINGS,
	PeriodOptions,
} from '../../../../../models';
import { dateTimeService } from '../../../../../packages/helpers';
import { EXPORT_PERIOD_TITLE } from '../../../../../packages/helpers/explanations/export';
import { DatePicker, Select } from '../../../../../packages/ui';

const { LAST_MONTH_START_DAY, LAST_MONTH_END_DAY } = PeriodOptions;
const { TODAY, WEEK, LAST_MONTH, OTHER } = PeriodOptions;

const ExportPeriod = ({ setValues, values }: ExportPeriodProps) => {
	const [selectedPeriod, setSelectedPeriod] = useState({
		...defaultSelect,
		[TODAY]: true,
	});

	const handlePeriodChange = (event: SelectChangeEvent) => {
		switch (PERIOD_STRINGS.get(event.target.value)) {
			case TODAY:
				setSelectedPeriod({ ...defaultSelect, [TODAY]: true });
				setValues({
					...values,
					endDate: new Date(new Date().setHours(23, 59, 0)),
					startDate: new Date(new Date().setHours(0, 0, 0)),
				});
				break;
			case WEEK:
				setSelectedPeriod({ ...defaultSelect, [WEEK]: true });
				setValues({
					...values,
					endDate: dateTimeService.getHistoryDate(TODAY),
					startDate: dateTimeService.getHistoryDate(WEEK),
				});
				break;
			case LAST_MONTH:
				setSelectedPeriod({ ...defaultSelect, [LAST_MONTH]: true });
				setValues({
					...values,
					endDate: dateTimeService.getHistoryDate(LAST_MONTH_END_DAY),
					startDate: dateTimeService.getHistoryDate(LAST_MONTH_START_DAY),
				});
				break;
			case OTHER:
				setSelectedPeriod({ ...defaultSelect, [OTHER]: true });
				break;
			default:
				return TODAY;
		}
	};

	const formatPeriodValue = (selectedPeriod: {
		[key in string]: boolean;
	}): string => {
		const periods = Array.from(PERIOD_STRINGS).find(
			([, key]) =>
				key ===
				Object.keys(selectedPeriod).find(
					(value) => selectedPeriod[value] === true
				)
		);
		return periods ? periods[0] : '';
	};

	const handleChangeDate = (date: Date, name: string) => {
		name === 'startDate'
			? setValues({ ...values, startDate: date })
			: setValues({ ...values, endDate: date });
	};

	return (
		<>
			<Typography variant="h5" mb="1rem">
				{EXPORT_PERIOD_TITLE}
			</Typography>
			<Grid container spacing={2}>
				<Grid item md={4} xs={8}>
					<Select
						label="Période"
						onChange={handlePeriodChange}
						options={Array.from(PERIOD_STRINGS).map(([key]) => key)}
						width={120}
						value={formatPeriodValue(selectedPeriod)}
						isStringValues
					/>
				</Grid>
				<Grid item md={4} xs={6}>
					<DatePicker
						disabled={!selectedPeriod.other}
						label="Du"
						value={values.startDate}
						onChange={(newValue) =>
							newValue
								? handleChangeDate(newValue, 'startDate')
								: values.startDate
						}
					/>
				</Grid>
				<Grid item md={4} xs={6}>
					<DatePicker
						disabled={!selectedPeriod.other}
						label="Au"
						value={values.endDate}
						onChange={(newValue) =>
							newValue ? handleChangeDate(newValue, 'endDate') : values.endDate
						}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ExportPeriod;

type ExportPeriodProps = {
	setValues: Dispatch<SetStateAction<Export>>;
	values: any;
};
