import imageCompression from 'browser-image-compression';

const options = {
	maxSizeMB: 1,
	maxWidthOrHeight: 800,
	useWebWorker: true,
};

export const handleCompressImg = async (imageFile: File) => {
	try {
		const compressedBlob = await imageCompression(imageFile, options);
		const compressedFile = new File([compressedBlob], imageFile.name, {
			lastModified: Date.now(),
			type: compressedBlob.type,
		});
		return compressedFile;
	} catch (error) {
		console.error(error);
	}
};
