/* eslint-disable no-mixed-spaces-and-tabs */
import { pluralize } from '../helpers/';

import { useDepartment, useTypedSelector } from '.';

export const useRsUsed = (countOperationSpecial?: number) => {
	const { rsLabel } = useDepartment();
	const usedRsReport = useTypedSelector((state) => state.rollingStockUsedState);
	const { total, usedCount, usedRs } = usedRsReport;

	const displayUsedCount = countOperationSpecial ?? usedCount;

	const stockCountText =
		total >= 1
			? `${displayUsedCount} ${pluralize(
					displayUsedCount,
					rsLabel
			  )} ${pluralize(displayUsedCount, 'utilisé')} sur ${total} `
			: 'Aucun matériel roulant';

	return {
		countOperationSpecial,
		stockCountText,
		total,
		usedCount,
		usedRs,
	};
};
