import isEmpty from 'lodash/isEmpty';

import {
	DepartmentEnum,
	IncidentTypeEnum,
	RollingStockFilter,
	RSIncident,
} from '../../models';
import { Availability } from '../../models/availability.model';
import {
	ApiRequestUsedUpdate,
	ServiceDTO,
	TrackingBoardDTO,
} from '../../packages/dataProviders';
import { ServiceUpdateData } from '../../packages/events/types';
import {
	DELETE_ROLLINGSTOCK_AVAILABILITY,
	DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID,
	DELETE_ROLLINGSTOCK_INCIDENT,
	DELETE_ROLLINGSTOCK_SERVICE,
	DELETE_ROLLINGSTOCK_SPECIAL_OPERATION,
	RESOLVE_ROLLINGSTOCK_INCIDENT,
	RollingStockActionTypes,
	SET_ROLLINGSTOCK,
	SET_ROLLINGSTOCK_FILTER_CODE,
	SET_ROLLINGSTOCK_FILTER_STATUS,
	SET_ROLLINGSTOCK_INCIDENT,
	SET_ROLLINGSTOCK_IS_USED,
	SET_ROLLINGSTOCK_IS_USED_LIST,
	SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT,
	UPDATE_ROLLINGSTOCK_AVAILABILITY,
	UPDATE_ROLLINGSTOCK_INCIDENTS,
	UPDATE_ROLLINGSTOCK_SERVICE,
} from '../types/rollingStock.types';

// API actions
export const setRollingStock = (
	npHighLimit: number,
	trackingBoardDTO: TrackingBoardDTO[],
	department?: DepartmentEnum
): RollingStockActionTypes => ({
	department,
	npHighLimit,
	trackingBoardDTO: isEmpty(trackingBoardDTO) ? [] : trackingBoardDTO,
	type: SET_ROLLINGSTOCK,
});

export const updateRollingStockService = (
	service: ServiceUpdateData,
	today: number,
	department?: DepartmentEnum
): RollingStockActionTypes => ({
	department,
	service,
	today,
	type: UPDATE_ROLLINGSTOCK_SERVICE,
});

export const deleteRollingStockIncident = (
	incidentReportId: number,
	trainTechId: number,
	npHighLimit?: number
): RollingStockActionTypes => ({
	payload: {
		incidentReportId: incidentReportId,
		npHighLimit,
		trainTechId: trainTechId,
	},
	type: DELETE_ROLLINGSTOCK_INCIDENT,
});

export const deleteRollingStockAvailabilityByRsId = (
	trainTechId: number,
	actionType: string,
	actionTypeId?: number
): RollingStockActionTypes => ({
	payload: {
		actionType: actionType,
		actionTypeId: actionTypeId,
		trainTechId: trainTechId,
	},
	type: DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID,
});

export const deleteRollingStockSpecialOperation = (
	specialOperationId: number,
	trainTechId: number
): RollingStockActionTypes => ({
	payload: { specialOperationId: specialOperationId, trainTechId: trainTechId },
	type: DELETE_ROLLINGSTOCK_SPECIAL_OPERATION,
});

export const updateRollingStockIncident = (
	incidentReport: RSIncident,
	trainTechId: number
): RollingStockActionTypes => ({
	payload: {
		incidentReport: incidentReport,
		trainTechId: trainTechId,
	},
	type: UPDATE_ROLLINGSTOCK_INCIDENTS,
});

export const deleteRollingStockAvailability = (
	availabilityId: number
): RollingStockActionTypes => ({
	payload: { availabilityId: availabilityId },
	type: DELETE_ROLLINGSTOCK_AVAILABILITY,
});

export const updateRollingStockAvailability = (
	availability: Availability
): RollingStockActionTypes => ({
	payload: {
		availabilities: availability,
		trainTechId: availability.rollingStockId as number,
	},
	type: UPDATE_ROLLINGSTOCK_AVAILABILITY,
});

export const resolveRollingStockIncident = (
	incidentTypeId: IncidentTypeEnum,
	trainTechId: number
): RollingStockActionTypes => ({
	payload: {
		incidentTypeId,
		trainTechId,
	},
	type: RESOLVE_ROLLINGSTOCK_INCIDENT,
});

export const deleteRollingStockService = (
	service: ServiceDTO
): RollingStockActionTypes => ({
	service,
	type: DELETE_ROLLINGSTOCK_SERVICE,
});

export const setRollingstockIsUsed = (
	id: number,
	status: boolean
): RollingStockActionTypes => ({
	payload: { id, status },
	type: SET_ROLLINGSTOCK_IS_USED,
});

export const setRollingstockIsUsedList = (
	usedHistoryList: ApiRequestUsedUpdate[]
): RollingStockActionTypes => ({
	payload: usedHistoryList,
	type: SET_ROLLINGSTOCK_IS_USED_LIST,
});

// local actions
export const setFilterStatus = (
	status: RollingStockFilter | undefined
): RollingStockActionTypes => ({
	status,
	type: SET_ROLLINGSTOCK_FILTER_STATUS,
});

export const setFilterCode = (
	code: string | undefined
): RollingStockActionTypes => ({
	code,
	type: SET_ROLLINGSTOCK_FILTER_CODE,
});

export const setRollingStockIncident = (
	incident: RSIncident,
	trainTechId: number
): RollingStockActionTypes => ({
	payload: {
		incident: incident,
		trainTechId: trainTechId,
	},
	type: SET_ROLLINGSTOCK_INCIDENT,
});

export const SetRollingStockServiceStatusCount = (
	npHighLimit: number
): RollingStockActionTypes => ({
	payload: {
		npHighLimit: npHighLimit,
	},
	type: SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT,
});

export const rollingstockActions = {
	setFilterCode,
	setFilterStatus,
	setRollingStock,
	setRollingStockIncident,
	setRollingstockIsUsed,
	setRollingstockIsUsedList,
	SetRollingStockServiceStatusCount,
};

export default rollingstockActions;
