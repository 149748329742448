import Header from '../../components/header';
import IncidentReportSolved from '../../components/incidentReportSolved';
import { INC_SOLVED_TITLE } from '../../packages/helpers';

const IncidentReportSolvedPage = () => {
	return (
		<main>
			<Header title={INC_SOLVED_TITLE} />
			<IncidentReportSolved />
		</main>
	);
};

export default IncidentReportSolvedPage;
