import { initApiState } from '../config/initialState';
import * as types from '../types/api.types';

import {
	loadingErrorState,
	loadingResponseState,
	loadingState,
	updateErrorState,
	updateResponseState,
	updateState,
} from './apiStates';

const loadingComponents = [
	types.components.CREDENTIALS,
	types.components.ROLLINGSTOCK,
	types.components.LINES,
	types.components.ROLES,
	types.components.USED_UPDATE,
];

export const apiReducer = (
	state = initApiState,
	action: types.ApiActionTypes
): types.ApiState => {
	switch (action.type) {
		case types.REQUEST:
			return {
				...state,
				[action.component]: loadingComponents.some(
					(component) => component === action.component
				)
					? loadingState
					: updateState,
			};
		case types.RESPONSE:
			return {
				...state,
				[action.component]: loadingComponents.some(
					(component) => component === action.component
				)
					? loadingResponseState
					: updateResponseState,
			};
		case types.ERROR:
			return {
				...state,
				[action.component]: loadingComponents.some(
					(component) => component === action.component
				)
					? { ...loadingErrorState, errorInfo: action.error, isLoaded: true }
					: { ...updateErrorState, errorInfo: action.error },
			};

		default:
			return state;
	}
};

export default apiReducer;
