import { ProgressBar } from '../../../../packages/ui';

const ProgressBarDashboard = ({ count, target }: ProgressbarDashboardProps) => {
	const DANGER_LIMIT = 66;
	const WARNING_LIMIT = 33;

	const getPercentage = (
		count: number | undefined,
		objective: number,
		digits?: number
	): number => {
		if (typeof count === 'undefined' || count === 0 || objective === 0) {
			return 0;
		}
		if (count >= objective) {
			return 100;
		}
		return Number(((count / objective) * 100).toFixed(digits ?? 3));
	};

	const percentage = Math.ceil(getPercentage(count, target));

	const getColor = (): 'success' | 'warning' | 'error' => {
		if (percentage <= WARNING_LIMIT) return 'error';
		if (percentage > DANGER_LIMIT) return 'success';
		return 'warning';
	};

	return (
		<ProgressBar progressColor={getColor()} value={percentage} label={''} />
	);
};

export default ProgressBarDashboard;

type ProgressbarDashboardProps = {
	count: number;
	target: number;
};
