import {
	DatePicker as MuiPicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import fr from 'date-fns/locale/fr';

import { DatePickerProps } from '../../../models/ui';

const DatePicker = ({
	name,
	disabled = false,
	disabledFuture,
	disabledPast,
	helperText,
	label,
	value,
	maxDate,
	minDate,
	onChange,
	fullWidth,
	required,
	sx,
}: DatePickerProps) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
			<MuiPicker
				disabled={disabled}
				disableFuture={disabledFuture}
				disablePast={disabledPast}
				label={label}
				maxDate={maxDate}
				minDate={minDate}
				onChange={onChange}
				slotProps={{
					textField: {
						fullWidth: fullWidth,
						helperText: helperText,
						name: name,
						required: required,
						size: 'small',
					},
				}}
				value={value ?? new Date()}
				sx={sx}
			/>
		</LocalizationProvider>
	);
};

export default DatePicker;
