import { cloneDeep } from 'lodash';

import { StationUpdateData } from '../../packages/events/types';
import { initStationState } from '../config/initialState';
import {
	SET_STATIONS,
	StationActionTypes as ActionTypes,
	StationState,
	UPDATE_STATION,
} from '../types/station.types';

export const stationReducer = (
	state = initStationState,
	action: ActionTypes
) => {
	switch (action.type) {
		case SET_STATIONS:
			return { stations: action.payload.state };
		case UPDATE_STATION:
			return updateStation(action.payload.service, state);
		default:
			return state;
	}
};

function updateStation(
	service: StationUpdateData,
	state: StationState
): StationState {
	const newState = cloneDeep(state);
	const station = newState.stations.find(
		(station) =>
			station.id === service.stationId && station.line === service.lineId
	);
	if (station) {
		const passageNum = getPassageNumber(service.date);
		if (passageNum === 1) {
			station.lastStationServiceDate1 = service.date;
			const tempTotalPassage1 = parseInt(station.totalPassage1) + 1;
			station.totalPassage1 = tempTotalPassage1 + '';
		} else if (passageNum === 2) {
			station.lastStationServiceDate2 = service.date;
			const tempTotalPassage2 = parseInt(station.totalPassage2) + 1;
			station.totalPassage2 = tempTotalPassage2 + '';
		}
	}
	return newState;
}

function getPassageNumber(dateStr: string): number {
	const date = new Date(dateStr);
	if (!date) return 0;
	else if (date.getHours() < 13) return 1;
	else if (date.getHours() >= 13) return 2;
	else return 0;
}

export default stationReducer;
