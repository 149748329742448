import { RightByType } from '../../models/rightByType.model';
import { initRightByTypeState } from '../config/initialState';
import {
	CREATE_RIGHT_BY_TYPE,
	RightByTypeActionTypes as ActionTypes,
	SET_RIGHT_BY_TYPE,
	UPDATE_RIGHT_BY_TYPE,
} from '../types/rightByType.types';

export const rightByTypeReducer = (
	state: any = initRightByTypeState,
	action: ActionTypes
): any => {
	switch (action.type) {
		case SET_RIGHT_BY_TYPE:
			return action.payload.state;
		case UPDATE_RIGHT_BY_TYPE: {
			const updatedRights: RightByType[] = action.payload.updatedRight;
			const updatedState: RightByType[] = state.map((item: { id: number }) => {
				const updatedItem = updatedRights.find(
					(updatedRight) => updatedRight.id === item.id
				);
				return updatedItem ? { ...item, ...updatedItem } : item;
			});
			return updatedState;
		}
		case CREATE_RIGHT_BY_TYPE: {
			const createRights: RightByType[] = action.payload.createRight;
			const createState: RightByType[] = [...state, ...createRights];
			return { ...state, rightByTypes: createState };
		}
		default:
			return state;
	}
};

export default rightByTypeReducer;
