// prettier-ignore
import { initCredentialsState } from '../config/initialState';
import {
	CredentialsActionTypes as ActionTypes,
	CredentialsState as State,
	DELETE_USER,
	SET_CREDENTIALS,
	SET_USER,
} from '../types';

export const credentialsReducer = (
	state = initCredentialsState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case SET_CREDENTIALS:
			return {
				...state,
				users: action.payload,
			};
		case SET_USER:
			return {
				...state,
				user: action.payload,
			};

		case DELETE_USER:
			return {
				...state,
				users: state.users?.filter((user) => user.id !== action.payload.id),
			};
		default:
			return state;
	}
};
