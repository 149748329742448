import { Button, SingleButtonsBloc } from '..';

const CloseButtons = ({ onCancel }: PopInFooterProps) => {
	return (
		<SingleButtonsBloc>
			<Button
				onClick={() => {
					onCancel();
				}}
				variant="text"
				style={{
					backgroundColor: '#0A0082',
					borderRadius: '8px',
					color: 'white',
					width: '7rem',
				}}
			>
				Fermer
			</Button>
		</SingleButtonsBloc>
	);
};

export default CloseButtons;

type PopInFooterProps = {
	onCancel: () => void;
};
