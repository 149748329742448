import * as fileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { DepartmentEnum, Item, Role } from '../../models';
import { ExceptionDTO } from '../../models/exception.model';
import { PopupType } from '../../models/popup.model';
import { historyService, HttpResponse } from '../../packages/dataProviders';
import StationProvider from '../../packages/dataProviders/resources/station';
import { dateTimeService, pluralize } from '../../packages/helpers';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import { setHistoryItemsFilter } from '../actions/historyPopIn.actions';
import {
	ActionTypes,
	ApiThunks,
	AppStore,
	components,
	HistoryPopInState,
	HistorySelection,
	HistorySelectionTypeEnum,
} from '../types';

const { STATION } = components;
const { MTS, RER, SEM } = DepartmentEnum;

type HistoryThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;
export const getHistoryExport =
	(popInState: HistoryPopInState, department: DepartmentEnum): HistoryThunk =>
	async (dispatch, _, api) => {
		try {
			if ([MTS, RER].includes(department)) {
				await exportRollingStockHistory(popInState, dispatch, api);
			} else if (department === SEM) {
				await exportStationHistory(popInState, dispatch, api);
			}
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.actionRejected));
		}
	};

export const loadStations =
	(lineId: string): HistoryThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(STATION));
			const { data: allStations } = await StationProvider.getStationsByLine(
				lineId
			);
			const items: Item[] = allStations.map((station) => ({
				checked: false,
				code: station.code,
				id: station.id,
				name: station.name,
			}));
			dispatch(setHistoryItemsFilter(items));
		} catch (e) {
			dispatch(api._error(STATION, e));
		}
	};

const exportRollingStockHistory = async (
	state: HistoryPopInState,
	dispatch: ThunkDispatch<AppStore, ApiThunks, ActionTypes>,
	api: ApiThunks
) => {
	const params = getRollingStockParams(state);

	const response = await historyService.getHistoryExport(params);
	if (response.ok) {
		saveFile(response, state.startDate, state.endDate, state.selectedLines);
	} else {
		const exception = response.message as ExceptionDTO;
		dispatch(
			api.showFeedBack(
				exceptionDTOtoPopupState(exception.message, PopupType.WARN)
			)
		);
	}
};

const exportStationHistory = async (
	state: HistoryPopInState,
	dispatch: ThunkDispatch<AppStore, ApiThunks, ActionTypes>,
	api: ApiThunks
) => {
	const params = getStationParams(state);
	const response = await historyService.getStationHistoryExport(params);
	if (response.ok) {
		saveFile(response, state.startDate, state.endDate, state.selectedLines);
	} else {
		const exception = response.message as ExceptionDTO;
		dispatch(
			api.showFeedBack(
				exceptionDTOtoPopupState(exception.message, PopupType.WARN)
			)
		);
	}
};

const saveFile = (
	data: HttpResponse,
	startDate: Date,
	endDate: Date,
	lines: string[]
) => {
	const blob = data.message as Blob;
	fileSaver.saveAs(
		blob,
		`Suivi du nettoyage ${pluralize(lines.length, 'de')} ${pluralize(
			lines.length,
			'ligne'
		)} ${lines.join()} du ${dateTimeService.getDateYYYYMMDD(
			startDate
		)} au ${dateTimeService.getDateYYYYMMDD(endDate)}.xlsx`,
		{ autoBom: false }
	);
};

const getRollingStockParams = (
	popInState: HistoryPopInState
): Array<[string, string]> => {
	return [
		['startDate', popInState.startDate.toISOString()],
		['endDate', popInState.endDate.toISOString()],
		['lines', popInState.selectedLines.join()],
		['serviceTypeIds', getSelectedServices(popInState.servicesSelection)],
		['incidentTypeIds', getSelectedIncidents(popInState.servicesSelection)],
		['isUsageSelected', getTrainUsage(popInState.servicesSelection)],
		['rollingStockIds', getSelectedItems(popInState.itemsFilter)],
		['usersProfileIds', getUserProfil(popInState.usersProfilFilter)],
	];
};

const getStationParams = (
	popInState: HistoryPopInState
): Array<[string, string]> => {
	return [
		['startDate', popInState.startDate.toISOString()],
		['endDate', popInState.endDate.toISOString()],
		['lines', popInState.selectedLines.join()],
		['stationIds', getSelectedItems(popInState.itemsFilter)],
		['serviceTypeIds', getSelectedSemServices(popInState.servicesSelection)],
	];
};

function getTrainUsage(selectedServices: HistorySelection[]): string {
	const trainUsage = selectedServices.filter(
		(el) => el.selected && el.type === HistorySelectionTypeEnum.TrainUsage
	);
	return isEmpty(trainUsage) ? 'false' : trainUsage[0].selected + '';
}

function getSelectedIncidents(selectedServices: HistorySelection[]): string {
	return selectedServices
		.filter(
			(el) => el.selected && el.type === HistorySelectionTypeEnum.IncidentReport
		)
		.map((el) => el.id)
		.join(',');
}

function getSelectedServices(selectedServices: HistorySelection[]): string {
	return selectedServices
		.filter((el) => el.type === HistorySelectionTypeEnum.Service && el.selected)
		.map((el) => el.id)
		.join();
}

function getSelectedSemServices(selectedServices: HistorySelection[]): string {
	return selectedServices
		.filter((el) => el.type === HistorySelectionTypeEnum.Sem && el.selected)
		.map((el) => el.id)
		.join();
}

function getSelectedItems(items: Item[]): string {
	return items
		.filter((item) => item.checked)
		.map((item) => item.id)
		.join();
}

function getUserProfil(roles: Role[]): string {
	return roles
		.filter((role) => role.checked)
		.map((role) => role.id)
		.join(',');
}
