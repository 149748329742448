import { styled } from '@mui/system';

export const StyledCardActionSpecialOperationContainer = styled('button')<{
	isChoiceSelected?: boolean;
	isSelected?: boolean;
	backgroundColor: string;
}>(({ isChoiceSelected, isSelected, backgroundColor }) => ({
	alignItems: 'center',
	backgroundColor: backgroundColor,
	border: isChoiceSelected
		? 'var(--bg-color-grey-disabled)'
		: '1px solid var(--global-color-primary-darker)',
	borderRadius: '16px',
	color: isSelected ? 'white' : 'var(--global-color-primary-darker)',
	display: 'flex',
	height: '80px',
	margin: '5px',
	position: 'relative',
	width: '100%',
}));

export const StyledCardActionSvgContainer = styled('div')({
	margin: '0 0.8rem',
});
