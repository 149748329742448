import { PrefixInfo, User } from '../../models';

export interface UserState {
	authenticated?: boolean;
	user?: User;
	meta?: {
		prefixInfo?: PrefixInfo;
	};
}

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER_META = 'SET_USER_META';

export interface LoginAction {
	type: typeof LOGIN;
	user: User;
	prefixInfo?: PrefixInfo;
}
export interface LogoutAction {
	type: typeof LOGOUT;
}

export interface SetUserMeta {
	type: typeof SET_USER_META;
	payload: PrefixInfo;
}

export type UserActionTypes = LoginAction | LogoutAction | SetUserMeta;
