import { initHistoryPopInState } from '../config/initialState';
import * as types from '../types/historyPopIn.types';

export const historyPopInReducer = (
	state = initHistoryPopInState,
	action: types.HistoryPopInActionTypes
): types.HistoryPopInState => {
	switch (action.type) {
		case types.CLOSE_HISTORY_POPIN:
			return initHistoryPopInState;
		case types.OPEN_HISTORY_POPIN:
			return {
				...state,
				endDate: new Date(),
				meta: {
					...state.meta,
					open: true,
				},
				startDate: new Date(),
			};
		case types.VALIDATED_HISTORY_POPIN:
			return {
				...state,
				meta: {
					...state.meta,
					validated: action.payload,
				},
			};

		case types.SET_HISTORY_SERVICES_SELECTION:
			return {
				...state,
				servicesSelection: action.payload,
			};
		case types.SET_HISTORY_START_DATE:
			return {
				...state,
				startDate: action.payload,
			};
		case types.SET_HISTORY_END_DATE:
			return {
				...state,
				endDate: action.payload,
			};
		case types.SET_HISTORY_ITEMS_FILTER:
			return {
				...state,
				itemsFilter: action.payload,
			};
		case types.SET_HISTORY_SELECTED_LINES:
			return {
				...state,
				selectedLines: action.payload,
			};
		case types.SET_HISTORY_USERS_PROFIL:
			return {
				...state,
				usersProfilFilter: action.payload
			}
		default:
			return state;
	}
};
