import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { Button, Checkbox } from '../../../../../packages/ui';
import { setHistoryServicesSelection } from '../../../../../redux/actions';
import { HistorySelection as HistorySelect } from '../../../../../redux/types';

import './styles.css';

const HistorySelection = ({
	enableAllSelection = true,
	itemSelection,
	onItemSelectionChange,
}: HistorySelectionProps) => {
	const [selectedItems, setSelectedItems] = useState(itemSelection);
	const [isAllSelected, setIsAllSelected] = useState(true);

	const dispatch = useDispatch();

	const handleItemSelect = (selectedItem: HistorySelect) => {
		const newItems = selectedItems.map((item) =>
			item.key === selectedItem.key
				? { ...item, selected: !item.selected }
				: item
		);
		setSelectedItems(newItems);
		dispatch(setHistoryServicesSelection(newItems));
	};

	const handleSelectAll = () => {
		const items = selectedItems.map((item) =>
			item.disabled ? item : { ...item, selected: !isAllSelected }
		);
		setSelectedItems(items);
		onItemSelectionChange(items);
		setIsAllSelected(!isAllSelected);
	};

	useEffect(() => {
		setSelectedItems(itemSelection);
		setIsAllSelected(
			itemSelection.every(
				(selection) => selection.disabled ?? selection.selected
			)
		);
	}, [itemSelection]);

	return (
		<>
			{enableAllSelection && (
				<Button
					label={isAllSelected ? 'Aucun' : 'Tous'}
					onClick={() => {
						handleSelectAll();
					}}
				/>
			)}
			<Grid container>
				{selectedItems.map((item) => {
					return (
						<Grid item sm={4} xs={12} className="history-col" key={item.name}>
							<Checkbox
								label={item.name}
								checked={item.selected}
								disabled={item.disabled}
								onChange={() => {
									handleItemSelect(item);
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default HistorySelection;

type HistorySelectionProps = {
	enableAllSelection?: boolean;
	itemSelection: HistorySelect[];
	onItemSelectionChange: (selection: HistorySelect[]) => void;
};
