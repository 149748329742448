import { store } from '../../../redux';
import { updateRollingStockService } from '../../../redux/actions';
import { localStorageFuncs } from '../../helpers/';
import { ServiceUpdateData } from '../types';

export const serviceUpdateHandler = (e: MessageEvent) => {
  const data = JSON.parse(e.data) as ServiceUpdateData;
  localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
  store.dispatch(updateRollingStockService(data, Date.now()));
};

export default serviceUpdateHandler;
