import {
	DepartmentEnum,
	IncidentReport,
	Position,
	ServiceStatus,
	ServiceTypeEnum,
	SettingDayNightService,
} from '../../models';
import { SpecialOperation } from '../../models/specialOperation.model';
import { ServiceDTO } from '../../packages/dataProviders';

export interface ServiceSurface {
	equipment?: string;
	trainCode?: string;
	trainId?: string;
	sequenceId?: number;
	stationId?: number;
	stationName?: string;
	unitName?: string;
	lineId?: string;
	position?: Position;
	incidents?: IncidentReport[];
	specialOperations?: SpecialOperation[];
}

export type ServiceOption = {
	type: ServiceTypeEnum;
	status: ServiceStatus;
	name: string;
	isProcessed?: boolean;
	isDisabled?: boolean;
	departments?: DepartmentEnum[];
};

export type DefaultServiceOptions = [
	{
		type: typeof ServiceTypeEnum.NC;
		status: ServiceStatus;
		name: 'Nettoyage Courant';
	},
	{
		type: typeof ServiceTypeEnum.NP;
		status: ServiceStatus;
		name: 'Nettoyage Patrimonial';
	},
	{
		type: typeof ServiceTypeEnum.NQ;
		status: ServiceStatus;
		name: 'Nettoyage Quotidien';
	},
	{
		type: typeof ServiceTypeEnum.PS;
		status: ServiceStatus;
		name: 'Prestation Supplémentaire';
	},
	{
		type: typeof ServiceTypeEnum.PBS;
		status: ServiceStatus;
		name: 'Nettoyage Pare-Brise';
	},
	{
		type: typeof ServiceTypeEnum.MEP;
		status: ServiceStatus;
		name: 'Maintien En Propreté ';
	},
	{
		type: typeof ServiceTypeEnum.NCR;
		status: ServiceStatus;
		name: 'Nettoyage Courant Renforcé';
	}
];

export type SelectionByAgentN = {
	service?: ServiceOption;
	incident?: IncidentReport;
	specialOperation?: SpecialOperation;
};

export interface ServiceState extends ServiceSurface {
	services: ServiceOption[];
	selection?: SelectionByAgentN;
	incidents: IncidentReport[];
}

export const RESET_SERVICE_STATUS = 'RESET_SERVICE_STATUS';
export const RESET_SURFACE = 'RESET_SURFACE';
export const SET_INCIDENT_STATUS = 'SET_INCIDENT_STATUS';
export const SET_DAY_STATUSES = 'SET_DAY_STATUSES';
export const SET_NIGHT_STATUSES = 'SET_NIGHT_STATUSES';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_SERVICE_STATUS = 'SET_SERVICE_STATUS';
export const SET_SURFACE = 'SET_SURFACE';
export const SET_SURFACE_CODE = 'SET_SURFACE_CODE';

export interface ResetStatusAction {
	type: typeof RESET_SERVICE_STATUS;
}

export interface ResetSurfaceAction {
	type: typeof RESET_SURFACE;
}

export interface SetNightStatusAction {
	type: typeof SET_NIGHT_STATUSES;
	ServicesDTOS: ServiceDTO[];
	dayNightServices: SettingDayNightService[];
}

export interface SetDayStatusAction {
	type: typeof SET_DAY_STATUSES;
	dayNightServices: SettingDayNightService[];
}

export interface SetSelectionAction {
	type: typeof SET_SELECTION;
	payload: SelectionByAgentN;
}

export interface SetStatusAction {
	type: typeof SET_SERVICE_STATUS;
	payload: {
		status: ServiceStatus;
		typeService: ServiceTypeEnum;
	};
}

export interface SetSurfaceAction {
	type: typeof SET_SURFACE;
	payload: ServiceSurface;
}

export type ServiceActionTypes =
	| ResetStatusAction
	| ResetSurfaceAction
	| SetNightStatusAction
	| SetSelectionAction
	| SetStatusAction
	| SetSurfaceAction
	| SetDayStatusAction;
