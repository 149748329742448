import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { cloneDeep } from 'lodash';

import { UnitStat } from '../../../models';
import { dateTimeService, getUniqueKey } from '../../../packages/helpers';
import { Button } from '../../../packages/ui';
import {
	DAILY_PASSAGE_FOR_NORMAL,
	DAILY_PASSAGE_FOR_TERMINUS,
	SEM_HOUR_CHANGE_PASSAGE_1_2,
	SEM_HOUR_PASSAGE_3,
} from '../../sem/constants';

import './styles.css';

const UnitCard = ({ periode, title, units }: UnitCardProps) => {
	const [showMore, setShowMore] = useState<boolean>(false);
	const [unitOrder, setUnitOrder] = useState<UnitStat[]>([]);
	const [unitOrderLimited, setUnitOrderLimited] = useState<UnitStat[]>([]);

	const getPassageObjectiveForToday = (unit: UnitStat): number => {
		const now = new Date();
		if (now.getHours() < SEM_HOUR_CHANGE_PASSAGE_1_2) return 1;
		if (now.getHours() >= SEM_HOUR_PASSAGE_3 && unit.terminus) return 3;
		if (
			now.getHours() >= SEM_HOUR_CHANGE_PASSAGE_1_2 &&
			now.getHours() < SEM_HOUR_PASSAGE_3
		)
			return 2;
		return 0;
	};

	const getTheoreticalNQCount = (unit: UnitStat, periode: string) => {
		const totalDays =
			(periode === dateTimeService.getCurrentYearString()
				? dateTimeService.getDayInYear()
				: dateTimeService.getCurrentDayInMonth()) - 1;
		return (
			totalDays *
				(unit.terminus
					? DAILY_PASSAGE_FOR_TERMINUS
					: DAILY_PASSAGE_FOR_NORMAL) +
			getPassageObjectiveForToday(unit)
		);
	};

	useEffect(() => {
		if (showMore) {
			setShowMore(false);
		}
		//eslint-disable-next-line
	}, [units]);

	useEffect(() => {
		const unitOrdered = cloneDeep(units)
			.map((unit) => {
				const theoreticalCount = getTheoreticalNQCount(unit, periode);
				return {
					...unit,
					missingPassage: unit.count
						? Math.max(theoreticalCount - unit.count, 0)
						: theoreticalCount,
					theoreticalCount,
				};
			})
			.sort((unit1, unit2) => unit2.missingPassage - unit1.missingPassage);

		setUnitOrder(unitOrdered.slice(0, 30));
		setUnitOrderLimited(unitOrdered.slice(0, 3));
	}, [units, periode]);

	return (
		<div
			data-testid="reporting-unit-card"
			className="reporting-card reporting-card__unit"
		>
			<div className="reporting-card__header">
				<div className="reporting-card-title-group">
					<div className="reporting-card-title font-paris-bold mr-2 ">
						{title}
						<p className="show-mobile font-paris reporting-card-subtitle">
							ratio passages manquants/prévus
						</p>
					</div>
				</div>
			</div>
			<div className="reporting-card__text">
				<div className="font-paris reporting-card-period">{periode}</div>
			</div>
			<div className="reporting-card__container">
				<Grid container className="reporting-card__header hide-mobile">
					<Grid
						item
						className="font-paris reporting-card-unit-header reporting-card__station-header"
						xs={6}
					>
						Station
					</Grid>
					<Grid item className="font-paris reporting-card-unit-header" xs={4}>
						Unité concernée
					</Grid>
					<Grid item className="font-paris reporting-card-unit-header" xs={2}>
						Passages Manquants / Prévus
					</Grid>
				</Grid>

				{!showMore &&
					unitOrderLimited.map((unit) => (
						<Grid
							container
							className="reporting-card__row"
							key={getUniqueKey(`${unit.stationName}-${unit.unitName}`)}
						>
							<Grid item className="reporting-card__station" md={6} xs={8}>
								<div>{unit.stationName}</div>
								<div className="show-mobile reporting-card__unit-name-mobile">
									{unit.unitName}
								</div>
							</Grid>
							<Grid
								item
								className="reporting-card__unit-name hide-mobile"
								md={4}
								xs={0}
							>
								{unit.unitName}
							</Grid>
							<Grid item className="reporting-card__passage" md={2} xs={4}>
								{unit.missingPassage} / {unit.theoreticalCount}
							</Grid>
						</Grid>
					))}

				{showMore &&
					unitOrder.map((unit) => (
						<Grid
							container
							className="reporting-card__row"
							key={`${unit.stationName}-${unit.unitName}`}
						>
							<Grid item className="reporting-card__station" md={6} xs={8}>
								<div>{unit.stationName}</div>
								<div className="show-mobile reporting-card__unit-name-mobile">
									{unit.unitName}
								</div>
							</Grid>
							<Grid
								item
								className="reporting-card__unit-name hide-mobile"
								md={4}
								xs={0}
							>
								{unit.unitName}
							</Grid>
							<Grid item className="reporting-card__passage" md={2} xs={4}>
								{unit.missingPassage} / {unit.theoreticalCount}
							</Grid>
						</Grid>
					))}

				<div className="reporting-card__footer">
					<Button
						className="reporting-card__footer-button"
						onClick={() => setShowMore(!showMore)}
					>
						{!showMore ? "Afficher plus d'unités" : 'Masquer les unités'}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default UnitCard;

type UnitCardProps = {
	periode: string;
	title: string;
	units: UnitStat[];
};
