import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';

import { apiThunk } from '../actions';
import { rootReducer } from '../reducers';

const store = createStore(
	rootReducer,
	applyMiddleware(thunk.withExtraArgument(apiThunk))
);

export default store;
