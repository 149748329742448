import { RightByType } from '../../models/rightByType.model';
import  * as rightByType from '../types/rightByType.types';

export const setRightByType = (
	rightByTypes: RightByType[]
): rightByType.RightByTypeActionTypes => ({
	payload: {state: rightByTypes },
	type: rightByType.SET_RIGHT_BY_TYPE,
});




export const updateRight = (updatedRight: RightByType[]): rightByType.RightByTypeActionTypes => ({
	payload: { updatedRight },
	type: rightByType.UPDATE_RIGHT_BY_TYPE,
});

export const createRight = (createRight: RightByType[]): rightByType.RightByTypeActionTypes => ({
	payload: {createRight },
	type: rightByType.CREATE_RIGHT_BY_TYPE,
});

export const rightByTypeActions = {
	createRight, setRightByType, updateRight, 
};

export default rightByTypeActions;
