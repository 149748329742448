import { Typography } from '@mui/material';

import { StyledConfirmMobile } from './styles';

const ConfirmMobile = ({ isPartial }: ConfirmMobileProps) => {
	return (
		<StyledConfirmMobile>
			<Typography variant="h1">
				{isPartial ? "C'est noté" : 'Merci'} !
			</Typography>
			{!isPartial && (
				<div
					className={`svg-thanks-container svg-incident svg-thanks-symbol`}
				/>
			)}
		</StyledConfirmMobile>
	);
};

type ConfirmMobileProps = {
	isPartial?: boolean;
};

export default ConfirmMobile;
