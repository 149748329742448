import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';
import { isEmpty, isNil } from 'lodash';

import { IncidentReport, IncidentSummary } from '../../../../models';
import IncidentReportProvider from '../../../../packages/dataProviders/resources/incidentReport';
import { INC_DETAIL_TITLE } from '../../../../packages/helpers';
import { useForm, useLine, useTypedSelector } from '../../../../packages/hooks';
import { Dialog, Divider } from '../../../../packages/ui';
import PopInFooter from '../../../../packages/ui/molecules/CloseButton';
import theme from '../../../../packages/ui/theme';
import { updateAndSetIncidentReport } from '../../../../redux/thunks/incidentReport';
import {
	getEmptyIncidentReport,
	validate,
} from '../../../incidentReport/config';

import PopInActionsFooter from './footer/ActionConfirmation';
import FormContentDetails from './formDetails';
import HeaderIncidentDetails from './header';

const IncidentDetails = ({
	open,
	onClose,
	incidentSummary,
}: IncidentDetailsProps) => {
	const dispatch = useDispatch();
	const { selectedLine } = useLine();
	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
	const [incidentReports, setIncidentReports] = useState<IncidentReport[]>([]);
	const [isDeletingMode, setIsDeletingMode] = useState(false);
	const [incidentNumber, setIncidentNumber] = useState<number>();
	const [selectedIncidentReportId, setSelectedIncidentReportId] = useState<
		number | null
	>(null);

	const {
		count,
		incidentType,
		incidentType: {
			code: incidentTypeCode,
			id: incidentTypeId,
			isMultisolving,
		},
		trainId,
	} = incidentSummary;

	const rollingStocks =
		useTypedSelector((state) => state.rollingStockState.rollingStocks) ?? [];

	const selectedRs = rollingStocks.find(({ id }) => id === trainId);

	const sortedIncidents = (incidents: IncidentReport[]) => {
		return incidents.sort(
			(a, b) =>
				new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
		);
	};

	useEffect(() => {
		if (!isEmpty(selectedRs)) {
			const incidents: IncidentReport[] = [];
			Promise.all(
				selectedRs.incidents.map(({ id: incidentReportId, incidentTypeId }) => {
					if (
						!isNil(incidentReportId) &&
						incidentSummary.incidentType.id === incidentTypeId
					) {
						IncidentReportProvider.loadIncidentReportDetails(
							incidentReportId
						).then(({ data }) => {
							incidents.push(data);
							setIncidentReports(sortedIncidents(incidents));
						});
					}
				})
			);
		}
	}, [selectedRs, incidentSummary]);

	const rollingStockList = useTypedSelector(
		({ rollingStockState }) => rollingStockState.rollingStocks
	);
	const trainCode = rollingStockList.find((t) => t.id === trainId)?.trainCode;

	const handleCancel = () => {
		handleResetFiles();
		setSelectedIncidentReportId(null);
		setIsDeletingMode(false);
	};

	const handleClose = () => {
		handleCancel();
		onClose();
	};

	const handleUpdateIncident = () => {
		if (incidentType) {
			dispatch(
				updateAndSetIncidentReport(
					values as IncidentReport,
					incidentType,
					trainCode
				)
			);
		}
	};

	const {
		errors,
		files,
		handleChange,
		handleCheck,
		handleCheckOneOf,
		handleCheckChange,
		handleDateChange,
		handleDeleteFile,
		handleElementSelectOneOf,
		handleFiles,
		handleResetFiles,
		handleSelect,
		setValues,
		values,
	} = useForm({
		callback: handleUpdateIncident,
		initFormValues: getEmptyIncidentReport(incidentTypeId, selectedLine),
		validate,
	});

	return (
		<Dialog
			open={open}
			titleContent={INC_DETAIL_TITLE}
			content={
				<Grid container className="incidentDetails">
					<Grid
						item
						xs={12}
						width={isPad ? 700 : 990}
						height={94}
						zIndex={10}
						boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.1)'}
						style={{
							backgroundColor: 'white',
							paddingTop: 5,
							position: 'sticky',
							top: 0,
						}}
					>
						<HeaderIncidentDetails
							trainCode={trainCode}
							incidentCode={incidentTypeCode}
							count={count}
							isMultisolving={isMultisolving}
						/>
					</Grid>

					<Grid item xs={12} paddingTop={3} paddingBottom={3}>
						{incidentReports.map((incidentReport, index) => (
							<Fragment key={incidentReport.id}>
								<FormContentDetails
									errors={errors}
									incidentReport={incidentReport}
									incidentType={incidentType}
									incidentIndex={index + 1}
									setIsDeletingMode={setIsDeletingMode}
									setSelectedIncidentReportId={setSelectedIncidentReportId}
									isDeletingMode={isDeletingMode}
									selectedIncidentReportId={selectedIncidentReportId}
									setIncidentNumber={setIncidentNumber}
									handleChange={handleChange}
									handleCheck={handleCheck}
									handleCheckOneOf={handleCheckOneOf}
									handleCheckChange={handleCheckChange}
									handleDateChange={handleDateChange}
									handleElementSelectOneOf={handleElementSelectOneOf}
									handleSelect={handleSelect}
									setValues={setValues}
									values={values}
									files={files}
									handleFiles={handleFiles}
									handleDeleteFile={handleDeleteFile}
								/>
								{incidentReports.length - 1 !== index && <Divider />}
							</Fragment>
						))}
					</Grid>
				</Grid>
			}
			actionsContent={
				!isNil(selectedIncidentReportId) && incidentType ? (
					<PopInActionsFooter
						incidentType={incidentType}
						incidentReport={values}
						isDeletingMode={isDeletingMode}
						onCancel={handleCancel}
						onClose={handleClose}
						incidentNumber={incidentNumber}
						trainCode={trainCode}
					/>
				) : (
					<PopInFooter onCancel={handleClose} />
				)
			}
			onClose={handleClose}
		/>
	);
};

export default IncidentDetails;

type IncidentDetailsProps = {
	open: boolean;
	onClose: () => void;
	incidentSummary: IncidentSummary;
};
