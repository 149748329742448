import isEmpty from 'lodash/isEmpty';

import { Role } from '../../models';
import * as roleTypes from '../types/role.types';

export const setRoles = (roles: Role[]): roleTypes.RoleActionTypes => ({
	payload: isEmpty(roles) ? [] : roles,
	type: roleTypes.SET_ROLE,
});

export const roleActions = {
	setRoles,
};

export default roleActions;
