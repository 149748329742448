import { store } from '../../../redux';
import { deleteRollingStockIncident } from '../../../redux/actions';
import { localStorageFuncs } from '../../helpers';
import { IncidentDeleteData } from '../types';

export const incidentDeleteHandler = (e: MessageEvent) => {
	const { incidentReportId, trainId } = JSON.parse(
		e.data
	) as IncidentDeleteData;
	localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
	if (incidentReportId)
		store.dispatch(deleteRollingStockIncident(incidentReportId, trainId));
};

export default incidentDeleteHandler;
