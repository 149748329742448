import { isEmpty } from 'lodash';

import { Availability } from '../../../models/availability.model';
import {
	MultipleAvailability,
	MultipleAvailabilityTypeEnum,
} from '../../../models/multipleAvailability';
import {
	MULTIPLE_RAS_ERROR_FORM_RS,
	RSA_FORM_ERROR_SITE,
} from '../../../packages/helpers/explanations/rollingStockAvailability';

export const getEmptyMultipleAvailability = (
	multipleAvailabilityType: MultipleAvailabilityTypeEnum,
	lineId: string
): MultipleAvailability =>
	({
		availabilityRollingStocks: [],
		lineId,
		serviceTypeId: multipleAvailabilityType,
		site: undefined,
	} as unknown as MultipleAvailability);

export const validate = (values: MultipleAvailability) => {
	const errors = {} as { [key in string]: string };
	const { site, availabilityRollingStocks } = values;
	if (isEmpty(site)) errors.site = RSA_FORM_ERROR_SITE;
	const filtereddRSA = availabilityRollingStocks.filter(
		(rs: { checked: boolean }) => rs.checked === true
	);
	if (filtereddRSA.length === 0)
		errors.selectedRSA = MULTIPLE_RAS_ERROR_FORM_RS;
	return errors;
};

export const getFormattedMultipleAvailabilities = (
	values: MultipleAvailability,
	availabilityType: MultipleAvailabilityTypeEnum
): Availability[] => {
	const availableRollingStocks = values.availabilityRollingStocks.filter(
		(rollingStock) => rollingStock.checked === true
	);

	const formattedValues = availableRollingStocks.map((rollingStock) => {
		return {
			incidentTypeId:
				availabilityType === MultipleAvailabilityTypeEnum.DF
					? availabilityType
					: undefined,
			positionId: rollingStock.position?.id,
			rollingStockId: rollingStock.id,
			serviceTypeId:
				availabilityType === MultipleAvailabilityTypeEnum.NP
					? availabilityType
					: undefined,
			siteId: values.site.id,
		};
	});

	return formattedValues;
};
