import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Input } from '../../../packages/ui';

import './styles.css';

const SettingMonthlyService = ({
	ncrTarget,
	ncTarget,
	npTarget,
	onNCChange,
	onNCRChange,
	onNPChange,
	setSettingIsModified,
}: SettingMonthlyServiceProps) => {
	const handleNCChange = (event: ChangeEvent<HTMLInputElement>) => {
		onNCChange(Number(event.target.value));
		setSettingIsModified(true);
	};

	const handleNCRChange = (event: ChangeEvent<HTMLInputElement>) => {
		onNCRChange(Number(event.target.value));
		setSettingIsModified(true);
	};

	const handleNPChange = (event: ChangeEvent<HTMLInputElement>) => {
		onNPChange(Number(event.target.value));
		setSettingIsModified(true);
	};
	const handleKeyDown = (event: {
		keyCode: number;
		preventDefault: () => void;
	}) => {
		if (event.keyCode === 188 || event.keyCode === 190) {
			event.preventDefault();
		}
	};

	return (
		<div className="setting-monthly-service-container">
			<div className="monthly-service">
				<div className="input-nc-monthly-service">
					<Input
						inputProps={{ min: 0, step: 1 }}
						label="NC"
						name="nc-monthly-service-input"
						onChange={handleNCChange}
						onKeyDown={handleKeyDown}
						type="number"
						value={ncTarget}
					/>
				</div>
			</div>

			<div className="monthly-service">
				<div className="input-nc-monthly-service">
					<Input
						inputProps={{ min: 0, step: 1 }}
						label="NCR"
						name="ncr-monthly-service-input"
						onChange={handleNCRChange}
						onKeyDown={handleKeyDown}
						type="number"
						value={ncrTarget}
					/>
				</div>
			</div>

			<div className="monthly-service">
				<div className="input-np-monthly-service">
					<Input
						inputProps={{ min: 0, step: 1 }}
						label="NP"
						name="np-monthly-service-input"
						onChange={handleNPChange}
						onKeyDown={handleKeyDown}
						type="number"
						value={npTarget}
					/>
				</div>
			</div>
		</div>
	);
};

export default SettingMonthlyService;

type SettingMonthlyServiceProps = {
	ncrTarget: number;
	ncTarget: number;
	npTarget: number;
	onNCChange: Dispatch<SetStateAction<number>>;
	onNCRChange: Dispatch<SetStateAction<number>>;
	onNPChange: Dispatch<SetStateAction<number>>;
	setSettingIsModified: Dispatch<SetStateAction<boolean>>;
};
