export const initializeAppEnv = (): AppEnv => {
	return {
		backendUrl: window.___STTRCONF___?.backendUrl,
		env: window.___STTRCONF___?.env,
		muiLicense:
			window.___STTRCONF___?.muiLicense ?? process.env.REACT_APP_MUI_LICENSE,
		version: window.___STTRCONF___?.version ?? process.env.REACT_APP_VERSION,
	};
};

export type AppEnv = {
	backendUrl?: string;
	env?: string;
	muiLicense?: string;
	version?: string;
};
