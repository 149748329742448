import { styled } from '@mui/system';

export const StyledHeader = styled('div')({
	alignItems: 'center',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
	display: 'flex',
	height: '6rem',
	justifyContent: 'flex-start',
	padding: '0 2rem',
});

export const StyledLogo = styled('div')({
	paddingRight: '2rem',
});
