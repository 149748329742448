import { styled } from '@mui/system';

export const StyledUnacceptableContainer = styled('div')<{
	count: number;
}>(({ count }) => ({
	alignItems: 'center',
	backgroundColor:
		count > 0 ? 'var(--bg-color-warning)' : 'var(--bg-color-good)',
	borderRadius: '16px',
	color: '#F9F9FA',
	display: 'flex',
	flexDirection: 'column',
	height: '8.125rem',
	justifyContent: 'center',
	rowGap: '1.25rem',
	width: '13.125rem',
}));
