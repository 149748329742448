import isEmpty from 'lodash/isEmpty';

import {
	SET_LINES,
	SET_SELECTED_LINE,
	SetLines,
	SetSelectedLine,
} from '../types/line.types';

export const setLines = (lines: string[]): SetLines => ({
	payload: isEmpty(lines) ? [] : lines,
	type: SET_LINES,
});

export const setSelectedLine = (line: string): SetSelectedLine => ({
	payload: line,
	type: SET_SELECTED_LINE,
});

export const lineActions = {
	setLines,
	setSelectedLine,
};

export default lineActions;
