import { IncidentTypeEnum } from './incidentType.model';
import { Role } from './role.model';
import { RsSpecialOperation } from './specialOperation.model';

export interface IncidentReport {
	applicantOrganisation?: string;
	applicantRole?: Role;
	comment?: string;
	complement?: IncidentComplement;
	creationDate: Date;
	degradationPosition?: DegradationPosition;
	degradationSite?: IncidentSite;
	files?: File[];
	id?: number;
	impactedRailcar?: ImpactedRailcar;
	incidentTypeId: IncidentTypeEnum;
	isBlockingCase?: boolean;
	isOffensive?: boolean;
	lineId: string;
	locations: IncidentLocationDTO[];
	name?: string;
	photos?: string[];
	railcars: IncidentRailcarDTO[];
	registrant: Registrant;
	rollingStockId: number;
	rollingStockCode?: string;
	sent?: boolean;
	site: IncidentSite;
	solvingDate?: Date;
	status?: IncidentStatus;
	surface?: number;
}
export enum IncidentStatus {
	SELECTED = 'selected',
	UNSELECTED = 'unselected',
	CONFIRMED = 'confirmed',
	DISABLE = 'disable',
}

export type IncidentReporting = {
	id: number;
	monthCount: number;
	yearCount: number;
	type?: IncidentTypeEnum;
	title?: string;
};

type IdNameObject = {
	id: number;
	name: string;
};

type IdCodeNameObject = {
	id: number;
	code: string;
	name: string;
};

export type IncidentSelected = {
	incident?: BasicIncident;
	specialOperation?: RsSpecialOperation;
};

export type ImpactedRailcar = {
	id: number;
	name: number;
};

export type IncidentLocationDTO = {
	id: number;
	name: string;
	checked?: boolean;
};

export interface IncidentRailcarDTO {
	id: number;
	name: string;
	selected?: boolean;
}

export interface IncidentComplement extends IdNameObject, IdNameObject {}
export interface IncidentLocation extends IdNameObject, IdNameObject {}
export interface DegradationPosition extends IdNameObject, IdNameObject {}
export interface DegrationSite extends IdNameObject, IdNameObject {}
export interface IncidentSite extends IdNameObject, IdNameObject {}
export interface Registrant extends IdNameObject, IdNameObject {}
export interface RailCar extends IdNameObject, IdNameObject {}
export interface BasicIncident extends IdCodeNameObject, IdCodeNameObject {}
