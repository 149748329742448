import { TextField } from '@mui/material';

import { InputProps } from '../../../models/ui';

const Input = ({
	defaultValue,
	disabled = false,
	fullWidth,
	helperText,
	inputProps,
	label,
	name,
	margin,
	multiline,
	onChange,
	onKeyDown,
	placeholder,
	required,
	rows,
	size = 'small',
	value,
	type = 'text',
	variant = 'outlined',
	style,
}: InputProps) => {
	return (
		<TextField
			inputProps={inputProps}
			defaultValue={defaultValue}
			disabled={disabled}
			error={!!helperText}
			fullWidth={fullWidth}
			helperText={helperText}
			id={name}
			label={label}
			name={name}
			margin={margin}
			multiline={multiline}
			onChange={onChange}
			onKeyDown={onKeyDown}
			placeholder={placeholder}
			required={required}
			rows={rows}
			size={size}
			style={style}
			type={type}
			value={value}
			variant={variant}
		/>
	);
};

export default Input;
