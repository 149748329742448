import { useState } from 'react';
import { Grid } from '@mui/material';

import Header from '../../components/header';
import Footer from '../../components/specialOperationRsImpacted/footer';
import ImpactedRollingStockByOp from '../../components/specialOperationRsImpacted/rsImpacted';
import LineSection from '../../components/usedRS/lineSection';
import { SpecialOperation } from '../../models/specialOperation.model';
import { Rs } from '../../models/usedRs.model';
import { useRsUsed } from '../../packages/hooks/useRsUsed';

const SpecialOperationRsPage = () => {
	const [rsFiltered, setRsFiltered] = useState<Rs[]>([]);
	const [operationsSpecial, setOperationsSpecial] = useState<
		SpecialOperation[]
	>([]);

	const [countOperationSpecial, setCountOperationSpecial] = useState<number>(0);

	const { usedRs } = useRsUsed();

	const params = new URLSearchParams(window.location.search);
	const operationSpecialType = params?.get('name');

	const handleFilterChange = (value: string) => {
		setRsFiltered(usedRs.filter(({ trainCode }) => trainCode.includes(value)));
	};

	return (
		<main>
			<Header title={operationSpecialType} />
			<div className="content" data-testid="used-train-page">
				<Grid container spacing={2} justifyContent="space-between">
					<Grid item lg={3} md={4} xs={12}>
						<LineSection
							handleFilter={handleFilterChange}
							countOperationSpecial={countOperationSpecial}
						/>
					</Grid>
					<Grid item md={8} xs={12}>
						<ImpactedRollingStockByOp
							rsList={rsFiltered}
							setSpecialOperations={setOperationsSpecial}
							specialOperations={operationsSpecial}
							specialOperationType={operationSpecialType}
							setCountOperationSpecial={setCountOperationSpecial}
							setRsFiltered={setRsFiltered}
						/>
					</Grid>
				</Grid>
			</div>
			<Footer />
		</main>
	);
};

export default SpecialOperationRsPage;
