import { isEqual } from 'lodash';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';

import appEnv from '../packages/configuration/appEnv';

import devStore from './config/store.dev';
import prodStore from './config/store.prod';
import { rootReducer } from './reducers';
import { AppDevStore, AppStore } from './types';

export const store = isEqual(appEnv.env, 'local') ? devStore : prodStore;

export const createTestStore = (appStore: AppStore | AppDevStore) =>
	createStore(rootReducer, appStore, applyMiddleware(thunk));
