import { Grid, SelectChangeEvent } from '@mui/material';

import { AvailabilityPosition } from '../../../../models';
import { Availability } from '../../../../models/availability.model';
import { AvailabilitySite } from '../../../../models/site.model';
import Select from '../../../../packages/ui/atoms/Select';

const AvailabilityFormContent = ({
	availability,
	isEditableMode,
	errors,
	handleSelect,
	positions,
	sites,
	values,
}: AvailabilityFormContentProps) => {
	return (
		<Grid container spacing={3} flexDirection={'column'}>
			<Grid item xs={6} paddingTop={3}>
				<Select
					defaultValue={availability?.siteName}
					helperText={errors.site}
					label="Site"
					name="site"
					onChange={handleSelect}
					options={sites}
					value={!isEditableMode ? availability?.siteName : values.site}
					width="500px"
					disabled={!isEditableMode}
					style={{
						backgroundColor: !isEditableMode ? '#E8E8EA' : 'white',
					}}
					required
				/>
			</Grid>
			<Grid item xs={6} paddingTop={3}>
				<Select
					defaultValue={availability?.positionName}
					helperText={errors.position}
					label="Position"
					name="position"
					onChange={handleSelect}
					options={positions}
					value={!isEditableMode ? availability?.positionName : values.position}
					width="500px"
					disabled={!isEditableMode}
					style={{
						backgroundColor: !isEditableMode ? '#E8E8EA' : 'white',
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default AvailabilityFormContent;

type AvailabilityFormContentProps = {
	availability: Availability | undefined;
	isEditableMode: boolean;
	errors: {
		[key: string]: string;
	};
	handleSelect: (event: SelectChangeEvent) => void;
	positions: AvailabilityPosition[];
	sites: AvailabilitySite[];
	values: any;
};
