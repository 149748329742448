import { PopupState } from '../../../redux/types';

export enum NotifyCode {
	// info msg
	INF_FUNC_001 = "Compte créé avec succès, L'utilisateur a bien été créé.",
	INF_FUNC_002 = "Compte modifié avec succès, L'utilisateur a bien été modifié.",
	INF_FUNC_003 = "Compte supprimé avec succès, L'utilisateur a bien été supprimé.",
	INF_FUNC_004 = 'Paramètres modifiés avec succès, Les paramètres ont bien été mis à jour.',
	INF_FUNC_005 = 'Renseignement prestation, ? bien renseigné&.',
	INF_FUNC_006 = 'Renseignement signalement, ? bien renseigné.',
	INF_FUNC_007 = 'Renseignement anomalie/position, Anomalie et position bien renseignées.',
	INF_FUNC_008 = 'Mise à disposition effectuée, Un train a été mis à disposition pour ?.',
	INF_FUNC_009 = 'Service supprimé avec succès, Le service a bien été supprimé.',
	INF_FUNC_010 = 'Services supprimés avec succès, Les services ont bien été supprimés.',
	INF_FUNC_011 = 'Signalement modifié avec succès, Le signalement a bien été modifié.',
	INF_FUNC_012 = 'Signalement supprimé avec succès, Le signalement a bien été supprimé.',
	INF_FUNC_013 = 'Mise à disposition modifiée, La mise à disposition du train ? a bien été modifiée.',
	INF_FUNC_014 = 'Mise à disposition supprimée, La mise à disposition du train ? a bien été supprimée.',
	INF_FUNC_015 = 'Opération spéciale bien renseignée.',
	INF_FUNC_016 = 'Opération spéciale clôturée avec succès.',
	INF_FUNC_017 = 'Opération spéciale modifiée avec succès.',
	INF_FUNC_018 = 'Opération spéciale supprimée avec succès.',
	INF_FUNC_019 = 'Opération spéciale résolue avec succès.',
	// technical msg
	ERR_TECH_001 = `Serveur indisponible, Le serveur est actuellement indisponible.\nNous vous invitons à réessayer plus tard.`,
	ERR_TECH_002 = `Serveur indisponible, Votre action n'a pas pu être prise en compte.`,
	ERR_TECH_003 = 'Pas de connexion, Vous êtes hors ligne.\nImpossible de charger les données à jour.',
	ERR_TECH_004 = "Vous n'êtes pas habilité sur cette application.\nContactez votre Responsable Propreté",
	// functional messages
	ERR_FUNC_001 = "Attention, Vous n'êtes pas habilité sur cette application.\nContactez votre Responsable Propreté",
	ERR_FUNC_002 = 'Attention, Le numéro de train saisi n’existe pas.\nNous vous invitons à recommencer',
	ERR_FUNC_003 = 'Code erroné, Le train n’a pas pu être reconnu.\nMerci de recommencer ou saisir le numéro de train.',
	ERR_FUNC_004 = 'Attention, Cet utilisateur existe déjà.\nMerci de saisir un autre compte matriculaire',
	ERR_FUNC_005 = "Attention, Veuillez remplir l'ensemble des champs pour ajouter / modifier un utilisateur",
	ERR_FUNC_006 = 'Erreur de création, L’utilisateur n’a pas pu être créé',
	ERR_FUNC_007 = 'Erreur de modification, L’utilisateur n’a pas pu être modifié',
	ERR_FUNC_008 = 'Erreur de suppression, L’utilisateur n’a pas pu être supprimé',
	ERR_FUNC_009 = 'Demande invalide, Les données saisies ne sont pas valides',
	ERR_FUNC_010 = "Demande invalide, l'utilisateur n'existe pas",
	ERR_FUNC_011 = 'Demande invalide, La plage de date est supérieur à 1 mois',
	ERR_FUNC_017 = 'Cette prestation a déjà été saisie',
	ERR_FUNC_018 = 'Erreur de suppression, Le service n’a pas pu être supprimé',
	ERR_FUNC_019 = 'Erreur de suppression, Les services n’ont pas pu être supprimés',
	ERR_FUNC_020 = 'Erreur de suppression, Le signalement n’a pas pu être supprimé',
	ERR_FUNC_021 = 'Erreur de modification, Le signalement n’a pas pu être modifié',
	ERR_FUNC_022 = 'Erreur de résolution, Le signalement n’a pas pu être résolu',
	ERR_FUNC_023 = "Erreur de clotûre, L'opération spéciale n’a pas pu être clos.",
	ERR_FUNC_024 = "Erreur de modification, L'opération spéciale n’a pas pu être modifié.",
	ERR_FUNC_025 = "Erreur de résolution, L'opération spéciale n’a pas pu être résolu",
	ERR_TECH_027 = `Une erreur s'est produite, Veuillez recharger la page.`,
}

type NotifyType = 'success' | 'warn' | 'none' | 'error';

export type ErrorConf = {
	[key in NotifyType]: { [key in string]: PopupState };
};
