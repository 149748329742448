import { initConfirmationPopInState } from '../config/initialState';
import * as types from '../types/confirmationPopIn.types';

export const confirmationPopInReducer = (
  state = initConfirmationPopInState,
  action: types.ConfirmationPopInActionType,
): types.ConfirmationPopInState => {
  switch (action.type) {
    case types.CLOSE_CONFIRMATION_POPIN:
      return initConfirmationPopInState;
    case types.OPEN_CONFIRMATION_POPIN:
      return {
        ...state,
        meta: {
          ...state.meta,
          open: true,
        },
      };
    default:
      return state;
  }
};
