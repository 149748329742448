import { Typography } from '@mui/material';

const ValidationLabel = ({ label, isEditableForm }: ValidationLabelProps) => (
	<Typography
		variant="h6"
		fontSize={14}
		fontStyle={'italic'}
		fontWeight={'light'}
		color={!isEditableForm ? '#B0B0B0' : 'black'}
	>
		{label}
	</Typography>
);
export default ValidationLabel;

type ValidationLabelProps = {
	label: string;
	isEditableForm?: boolean;
};
