import { initRollingStockUsedState as initState } from '../config/initialState';
import {
	RsUseActionTypes as ActionTypes,
	SET_RS_USED_COUNT,
	UPDATE_USED_STATUS,
	UsedRsReportState as State,
} from '../types/rollingStockUsed.types';

export const rollingStockUsedReducer = (
	state = initState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case SET_RS_USED_COUNT:
			return action.payload as unknown as State;

		case UPDATE_USED_STATUS: {
			const { idCb, newIsUsed } = action.payload;

			const updatedUsedRs = state.usedRs.map((used) => {
				if (used.id === idCb) {
					return {
						...used,
						isUsed: newIsUsed,
						lastUpdateDate: new Date(),
					};
				}
				return used;
			});

			return {
				...state,
				usedCount: newIsUsed ? state.usedCount + 1 : state.usedCount - 1,
				usedRs: updatedUsedRs,
			};
		}
		default:
			return state;
	}
};

export default rollingStockUsedReducer;
