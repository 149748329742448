import { RoleEnum, User } from '../../models';
import appEnv from '../configuration/appEnv';
import EventProvider from '../dataProviders/resources/event';
import { getUuid } from '../helpers/auth.utils';

import { keepAliveHandler } from './handlers';
import { subscribeToEvents } from './subscriber';
export let sseSource: EventSource; // NOSONAR

export function createEventSource(lineId = '') {
	const url = lineId === '' ? '/v1/events' : `/v1/events/${lineId}`;
	keepAliveHandler();
	if (sseSource) sseSource.close();
	sseSource = new EventSource(`${appEnv.backendUrl}${url}?uuid=${getUuid()}`, {
		withCredentials: true,
	});
}

export function initEventSource(user: User, lineId = '', allowAdmin = false) {
	if (!user) return;
	const { PROPRETE, TR, TRMAL, AGENTM, ADMIN, PROPRETESNCF } = RoleEnum;
	const allowedUsers = allowAdmin
		? [PROPRETE, TR, TRMAL, AGENTM, ADMIN, PROPRETESNCF]
		: [PROPRETE, TR, TRMAL, AGENTM, PROPRETESNCF];
	if (!allowedUsers.includes(user.roles[0].id)) return;
	createEventSource(lineId);
	subscribeToEvents([
		'keepAlive',
		'usedStatusUpdate',
		'serviceUpdate',
		'serviceDelete',
		'incidentDelete',
		'incidentResolve',
		'npReminder',
		'degrafReminder',
		'tagReminder',
		'windshieldReminder',
		'stainReminder',
		'unitUpdate',
	]);
}

export function loadAlerts() {
	setTimeout(() => {
		EventProvider.loadNotifications().catch((error) => {
			console.error('Error:', error);
		});
	}, 2000);
}
