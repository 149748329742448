import { initUserState } from '../config/initialState';
import {
	LOGIN,
	LOGOUT,
	SET_USER_META,
	UserActionTypes as ActionTypes,
	UserState as State,
} from '../types';

export const userReducer = (
	state = initUserState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				authenticated: true,
				meta: {
					...state.meta,
					prefixInfo: action.prefixInfo,
				},
				user: action.user,
			};
		case LOGOUT:
			return initUserState;
		case SET_USER_META:
			return {
				...state,
				meta: {
					prefixInfo: action.payload,
				},
			};
		default:
			return state;
	}
};

export default userReducer;
