import { ExceptionDTO } from '../../../models';
import { HttpResponse } from '../apiTemplate'; // why imported?
import ApiService from '../apiTemplate/api';

class UserService extends ApiService {
	constructor() {
		super();
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/json',
			},
			{
				key: 'Content-Type',
				value: 'application/json',
			},
		]);
	}



	/**
	 * Delete User
	 */
	public async deleteUser(id: number): Promise<HttpResponse> {
		this.setMethod('DELETE');
		try {
			const res = await fetch(`${this.endPoint}/v1/user/${id}`, this.request());
			return { message: await this.getMessage(res), ok: res.ok };
		} catch (err) {
			throw Error(`Cannot delete user : --> ${err}`);
		}
	}

	public async getMessage(
		response: Response
	): Promise<ExceptionDTO | undefined> {
		try {
			return (await response.json()) as unknown as ExceptionDTO;
		} catch (error) {
			return undefined;
		}
	}
}

export const userService = new UserService();