import { ThunkAction } from 'redux-thunk';

import {
	defaultDayNightServices,
	Setting,
	SettingDayNightService,
} from '../../models';
import SettingProvider from '../../packages/dataProviders/resources/setting';
import { errorConf } from '../../packages/helpers/popup/exception.utils';
import {
	setSetting,
	setSettingMALHighLimit,
	setSettingMALLowLimit,
	setSettingNCRTarget,
	setSettingNCTarget,
	setSettingNPHighLimit,
	setSettingNPLowLimit,
	setSettingNPTarget,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { SETTING } = components;

type SettingThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const loadSetting =
	(line?: string): SettingThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SETTING));
			if (line) {
				const { data: res } = await SettingProvider.loadSetting(line);
				setDayNightIncidentDepartments(res.dayNightServices);
				dispatch(setSetting(res));
			} else {
				const { data: summedSetting } =
					await SettingProvider.loadSummedSetting();
				dispatch(setSettingNCTarget(Number(summedSetting.ncTarget)));
				dispatch(setSettingNCRTarget(Number(summedSetting.ncrTarget)));
				dispatch(setSettingNPTarget(Number(summedSetting.npTarget)));
				dispatch(setSettingNPHighLimit(summedSetting.npHighLimit));
				dispatch(setSettingNPLowLimit(summedSetting.npLowLimit));
				dispatch(setSettingMALHighLimit(summedSetting.malHighLimit));
				dispatch(setSettingMALLowLimit(summedSetting.malLowLimit));
			}
			dispatch(api._response(SETTING));
		} catch (e) {
			dispatch(api._error(SETTING, e));
		}
	};

export const saveSetting =
	(setting: Setting): SettingThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(SETTING));
			const res = await SettingProvider.saveSetting(setting);
			if (res.ok) {
				dispatch(api.showFeedBack(errorConf.success.settingUpdated));
				dispatch(api._response(SETTING));
			} else {
				dispatch(api.showFeedBack(errorConf.warn.actionRejected));
				dispatch(api._error(SETTING, res.statusText));
			}
		} catch (e) {
			dispatch(api._error(SETTING, e));
		}
	};

/**
 * Fonction pour mettre les départements autorisés sur les settings à la récupération, voir si on bouge dans le back
 * @param settingsDayNightServices
 */
const setDayNightIncidentDepartments = (
	settingsDayNightServices: SettingDayNightService[]
): void => {
	settingsDayNightServices.forEach((settingsDayNightService) => {
		const defaultSetting: SettingDayNightService | undefined =
			defaultDayNightServices.find(
				(defaultDayNightServices: SettingDayNightService) =>
					defaultDayNightServices.serviceTypeId ===
					settingsDayNightService.serviceTypeId
			);
		if (defaultSetting) {
			settingsDayNightService.departments = defaultSetting.departments;
		}
	});
};
