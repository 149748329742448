import {
	DepartmentEnum,
	IncidentTypeEnum,
	RollingStockFilter,
	RSIncident,
} from '../../models';
import { Availability } from '../../models/availability.model';
import {
	ApiRequestUsedUpdate,
	ServiceDTO,
	TrackingBoardDTO,
} from '../../packages/dataProviders';
import { ServiceUpdateData } from '../../packages/events/types';

export interface CountState {
	degrafed: number;
	toClean: number;
	used: number;
	total: number;
}

export interface RollingStockState {
	statusCounts: CountState;
	rollingStocks: TrackingBoardDTO[];
	filter: {
		status?: RollingStockFilter;
		code?: string;
	};
	lines: Array<string>;
	lastServiceRemovalDate: number;
}

export const DELETE_ROLLINGSTOCK_AVAILABILITY =
	'DELETE_ROLLINGSTOCK_AVAILABILITY';
export const DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID =
	'DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID';
export const DELETE_ROLLINGSTOCK_INCIDENT = 'DELETE_ROLLINGSTOCK_INCIDENT';
export const DELETE_ROLLINGSTOCK_SPECIAL_OPERATION =
	'DELETE_ROLLINGSTOCK_SPECIAL_OPERATION';
export const RESOLVE_ROLLINGSTOCK_INCIDENT = 'RESOLVE_ROLLINGSTOCK_INCIDENT';
export const DELETE_ROLLINGSTOCK_SERVICE = 'DELETE_ROLLINGSTOCK_SERVICE';
export const SET_ROLLINGSTOCK = 'SET_ROLLINGSTOCK';
export const SET_ROLLINGSTOCK_FILTER_CODE = 'SET_ROLLINGSTOCK_FILTER_CODE';
export const SET_ROLLINGSTOCK_FILTER_STATUS = 'SET_ROLLINGSTOCK_FILTER_STATUS';
export const SET_ROLLINGSTOCK_INCIDENT = 'SET_ROLLINGSTOCK_INCIDENT';
export const SET_ROLLINGSTOCK_IS_USED = 'SET_ROLLINGSTOCK_IS_USED';
export const SET_ROLLINGSTOCK_IS_USED_LIST = 'SET_ROLLINGSTOCK_IS_USED_LIST';
export const SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT =
	'SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT';
export const SET_ROLLINGSTOCK_SORT = 'SET_ROLLINGSTOCK_SORT';
export const UPDATE_ROLLINGSTOCK_INCIDENTS = 'UPDATE_ROLLINGSTOCK_INCIDENTS';
export const UPDATE_ROLLINGSTOCK_SPECIAL_OPERATIONS =
	'UPDATE_ROLLINGSTOCK_SPECIAL_OPERATIONS';
export const UPDATE_ROLLINGSTOCK_AVAILABILITY =
	'UPDATE_ROLLINGSTOCK_AVAILABILITY';
export const UPDATE_ROLLINGSTOCK_SERVICE = 'UPDATE_ROLLINGSTOCK_SERVICE';

export interface SetRollingStockAction {
	type: typeof SET_ROLLINGSTOCK;
	trackingBoardDTO: TrackingBoardDTO[];
	npHighLimit: number;
	department?: DepartmentEnum;
}
export interface SetRollingStockIsUsedAction {
	type: typeof SET_ROLLINGSTOCK_IS_USED;
	payload: { id: number; status: boolean };
}
export interface SetRollingStockIsUsedListAction {
	type: typeof SET_ROLLINGSTOCK_IS_USED_LIST;
	payload: ApiRequestUsedUpdate[];
}
export interface SetrollingStockFilterStatusAction {
	type: typeof SET_ROLLINGSTOCK_FILTER_STATUS;
	status?: RollingStockFilter;
}
export interface SetRollingStockFilterCodeAction {
	type: typeof SET_ROLLINGSTOCK_FILTER_CODE;
	code?: string;
}
export interface SetRollingStockSortAction {
	type: typeof SET_ROLLINGSTOCK_SORT;
	payload: { row: string; key: string };
}

export interface SetRollingStockServiceStatusCountAction {
	type: typeof SET_ROLLINGSTOCK_SERVICE_STATUS_COUNT;
	payload: { npHighLimit: number };
}

export interface DeleteRollingstockAvailabilityAction {
	type: typeof DELETE_ROLLINGSTOCK_AVAILABILITY;
	payload: { availabilityId: number };
}

export interface DeleteRollingstockAvailabilityByRsIdAction {
	type: typeof DELETE_ROLLINGSTOCK_AVAILABILITY_BY_RS_ID;
	payload: { trainTechId: number; actionType: string; actionTypeId?: number };
}

export interface UpdateRollingStockAvailability {
	type: typeof UPDATE_ROLLINGSTOCK_AVAILABILITY;
	payload: {
		availabilities: Availability;
		trainTechId: number;
	};
}

export interface UpdateRollingstockIncidents {
	type: typeof UPDATE_ROLLINGSTOCK_INCIDENTS;
	payload: {
		incidentReport: RSIncident;
		trainTechId: number;
	};
}

export interface DeleteRollingstockSpecialOperationAction {
	type: typeof DELETE_ROLLINGSTOCK_SPECIAL_OPERATION;
	payload: { specialOperationId: number; trainTechId: number };
}

export interface UpdateRollingstockService {
	type: typeof UPDATE_ROLLINGSTOCK_SERVICE;
	service: ServiceUpdateData;
	today: number;
	department?: DepartmentEnum;
}

export interface SetRollingstockIncident {
	type: typeof SET_ROLLINGSTOCK_INCIDENT;
	payload: {
		incident: RSIncident;
		trainTechId: number;
	};
}

export interface DeleteRollingstockIncidentAction {
	type: typeof DELETE_ROLLINGSTOCK_INCIDENT;
	payload: {
		incidentReportId: number;
		trainTechId: number;
		npHighLimit?: number;
	};
}

export interface ResolveRollingstockIncidentAction {
	type: typeof RESOLVE_ROLLINGSTOCK_INCIDENT;
	payload: {
		incidentTypeId: IncidentTypeEnum;
		trainTechId: number;
	};
}

export interface DeleteRollingstockService {
	type: typeof DELETE_ROLLINGSTOCK_SERVICE;
	service: ServiceDTO;
}

export type RollingStockActionTypes =
	| DeleteRollingstockAvailabilityAction
	| DeleteRollingstockAvailabilityByRsIdAction
	| DeleteRollingstockIncidentAction
	| DeleteRollingstockSpecialOperationAction
	| DeleteRollingstockService
	| ResolveRollingstockIncidentAction
	| SetRollingStockAction
	| SetRollingStockIsUsedAction
	| SetRollingStockIsUsedListAction
	| SetRollingstockIncident
	| SetRollingStockFilterCodeAction
	| SetrollingStockFilterStatusAction
	| SetRollingStockServiceStatusCountAction
	| SetRollingStockSortAction
	| UpdateRollingStockAvailability
	| UpdateRollingstockIncidents
	| UpdateRollingstockService;
