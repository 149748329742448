import {
	RSDisplayTypeEnum,
	Setting,
	SettingDayNightIncident,
	SettingDayNightService,
	SettingNotificationIncident,
	SettingNotificationService,
} from '../../models/setting.model';

export interface SettingState {
	line: string;
	ncTarget: number;
	ncrTarget: number;
	npTarget: number;
	npLowLimit: number;
	npHighLimit: number;
	malLowLimit: number;
	malHighLimit: number;
	rollingStockDisplayType: RSDisplayTypeEnum;
	dayNightServices: SettingDayNightService[];
	dayNightIncidents: SettingDayNightIncident[];
	notificationServices: SettingNotificationService[];
	notificationIncidents: SettingNotificationIncident[];
	status: boolean;
}

export const SET_SETTING = 'SET_SETTING';
export const SET_SETTING_NC_TARGET = 'SET_SETTING_NC_TARGET';
export const SET_SETTING_NCR_TARGET = 'SET_SETTING_NCR_TARGET';
export const SET_SETTING_NP_TARGET = 'SET_SETTING_NP_TARGET';
export const SET_SETTING_NP_L_LIMIT = 'SET_SETTING_NP_L_LIMIT';
export const SET_SETTING_NP_H_LIMIT = 'SET_SETTING_NP_H_LIMIT';
export const SET_SETTING_MAL_L_LIMIT = 'SET_SETTING_MAL_L_LIMIT';
export const SET_SETTING_MAL_H_LIMIT = 'SET_SETTING_MAL_H_LIMIT';
export const SET_SETTING_RS_DISPLAY_TYPE = 'SET_SETTING_RS_DISPLAY_TYPE';
export const SET_SETTING_DAY_NIGHT_INCIDENT = 'SET_SETTING_DAY_NIGHT_INCIDENT';
export const SET_SETTING_DAY_NIGHT_SERVICE = 'SET_SETTING_DAY_NIGHT_SERVICE';
export const SET_SETTING_STATUS = 'SET_SETTING_STATUS';

export interface SetSettingAction {
	type: typeof SET_SETTING;
	payload: { state: Setting };
}
export interface SetSettingNCTargetAction {
	type: typeof SET_SETTING_NC_TARGET;
	payload: { ncTarget: number };
}
export interface SetSettingNCRTargetAction {
	type: typeof SET_SETTING_NCR_TARGET;
	payload: { ncrTarget: number };
}

export interface SetSettingNPTargetAction {
	type: typeof SET_SETTING_NP_TARGET;
	payload: { npTarget: number };
}

export interface SetSettingNPLowLimitAction {
	type: typeof SET_SETTING_NP_L_LIMIT;
	payload: { npLowLimit: number };
}

export interface SetSettingNPHighLimitAction {
	type: typeof SET_SETTING_NP_H_LIMIT;
	payload: { npHighLimit: number };
}

export interface SetSettingMALLowLimitAction {
	type: typeof SET_SETTING_MAL_L_LIMIT;
	payload: { malLowLimit: number };
}

export interface SetSettingMALHighLimitAction {
	type: typeof SET_SETTING_MAL_H_LIMIT;
	payload: { malHighLimit: number };
}

export interface SetSettingRSDispalyTypeAction {
	type: typeof SET_SETTING_RS_DISPLAY_TYPE;
	payload: { rollingStockDisplayType: RSDisplayTypeEnum };
}

export interface SetSettingDayNightServiceAction {
	type: typeof SET_SETTING_DAY_NIGHT_SERVICE;
	payload: { dayNightServices: SettingDayNightService[] };
}

export interface SetSettingDayNightIncidentAction {
	type: typeof SET_SETTING_DAY_NIGHT_INCIDENT;
	payload: { dayNightIncidents: SettingDayNightIncident[] };
}
export interface SetSettingStatusAction {
	type: typeof SET_SETTING_STATUS;
	payload: { status: boolean };
}

export type SettingActionTypes =
	| SetSettingAction
	| SetSettingNCTargetAction
	| SetSettingNCRTargetAction
	| SetSettingNPTargetAction
	| SetSettingMALLowLimitAction
	| SetSettingMALHighLimitAction
	| SetSettingNPLowLimitAction
	| SetSettingNPHighLimitAction
	| SetSettingRSDispalyTypeAction
	| SetSettingDayNightServiceAction
	| SetSettingDayNightIncidentAction
	| SetSettingStatusAction;
