import { FC, ReactElement } from 'react';

import './SemStatus.css';

type Props = {
  status: string;
};

export enum StatusEnum {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

const SemStatus: FC<Props> = ({ status }): ReactElement => {
  const getLabel = () => {
    if (status === StatusEnum.TODO) {
      return 'A FAIRE';
    } else if (status === StatusEnum.IN_PROGRESS) {
      return 'EN COURS';
    } else {
      return 'TERMINE';
    }
  };
  return <p className={`title_status title_${status.toLocaleLowerCase()}`}>{getLabel()}</p>;
};

export default SemStatus;
