import { initRoleState } from '../config/initialState';
import {
	RoleActionTypes as ActionTypes,
	RolesState as State,
	SET_ROLE,
} from '../types';

export const roleReducer = (
	state = initRoleState,
	action: ActionTypes
): State => {
	if (action.type === SET_ROLE) {
		return {
			...state,
			roles: action.payload,
		};
	} else {
		return state;
	}
};

export default roleReducer;
