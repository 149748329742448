/* eslint-disable sort-keys */
import { ReactComponent as L1 } from '../../../assets/icons/metro/line-1.svg';
import { ReactComponent as L2 } from '../../../assets/icons/metro/line-2.svg';
import { ReactComponent as L3 } from '../../../assets/icons/metro/line-3-3B.svg';
import { ReactComponent as L4 } from '../../../assets/icons/metro/line-4.svg';
import { ReactComponent as L5 } from '../../../assets/icons/metro/line-5.svg';
import { ReactComponent as L6 } from '../../../assets/icons/metro/line-6.svg';
import { ReactComponent as L7 } from '../../../assets/icons/metro/line-7-7B.svg';
import { ReactComponent as L8 } from '../../../assets/icons/metro/line-8.svg';
import { ReactComponent as L9 } from '../../../assets/icons/metro/line-9.svg';
import { ReactComponent as L10 } from '../../../assets/icons/metro/line-10.svg';
import { ReactComponent as L11 } from '../../../assets/icons/metro/line-11.svg';
import { ReactComponent as L12 } from '../../../assets/icons/metro/line-12.svg';
import { ReactComponent as L13 } from '../../../assets/icons/metro/line-13.svg';
import { ReactComponent as L14 } from '../../../assets/icons/metro/line-14.svg';
import { ReactComponent as L15 } from '../../../assets/icons/metro/line-15.svg';
import { ReactComponent as L16 } from '../../../assets/icons/metro/line-16.svg';
import { ReactComponent as L17 } from '../../../assets/icons/metro/line-17.svg';
import { ReactComponent as L18 } from '../../../assets/icons/metro/line-18.svg';
import { ReactComponent as Mts } from '../../../assets/icons/metro/line-mts.svg';
import { ReactComponent as La } from '../../../assets/icons/rer/line-a.svg';
import { ReactComponent as Lb } from '../../../assets/icons/rer/line-b.svg';
import { ReactComponent as Rer } from '../../../assets/icons/rer/rer.svg';

const lineIcon: { [key in string]: any } = {
	L1: <L1 />,
	L2: <L2 />,
	L3: <L3 />,
	L4: <L4 />,
	L5: <L5 />,
	L6: <L6 />,
	L7: <L7 />,
	L8: <L8 />,
	L9: <L9 />,
	L10: <L10 />,
	L11: <L11 />,
	L12: <L12 />,
	L13: <L13 />,
	L14: <L14 />,
	L15: <L15 />,
	L16: <L16 />,
	L17: <L17 />,
	L18: <L18 />,
	MTS: <Mts />,
	RER: <Rer />,
	LA: <La />,
	LB: <Lb />,
};

const LineSvgPrintable = ({ line }: { line: string }) => {
	if (['RER', 'MTS', 'SEM'].includes(line)) return lineIcon[line];
	return lineIcon[`L${line}`];
};

export default LineSvgPrintable;
