import { useDispatch } from 'react-redux';

import * as confirmationActions from '../../redux/actions/confirmationPopIn.actions';

export const useConfirmationPopIn = () => {
	const dispatch = useDispatch();

	function closeConfirmationPopIn() {
		dispatch(confirmationActions.closeConfirmationPopIn());
	}

	function openConfirmationPopIn() {
		dispatch(confirmationActions.openConfirmationPopIn());
	}

	return {
		closeConfirmationPopIn,
		openConfirmationPopIn,
	};
};
