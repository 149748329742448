import { Department } from './department.model';
import { Role } from './role.model';

export class User {
	constructor(
		public departments: Array<Department>,
		public id: number,
		public line_id: string,
		public organisation: string,
		public registration_number: string,
		public rights: Array<{ title: string; id: string }>,
		public roles: Array<Role>,
		public currentEspace?: number
	) {}
}

export enum EspaceEnum {
	ESPACE_3 = 3,
	ESPACE_5 = 5,
}

export enum OrganizationEnum {
	RATP = 'RATP',
	EXTERNE = 'EXTERNE',
}

export const ALL_LINES = 'Toutes les lignes';

export interface UserCredential {
	id: number | null;
	line_id: string;
	lines?: string[];
	organisation: string;
	registration_number: string;
	roles: Role[];
	role?: Role;
	currentEspace?: EspaceEnum;
}
