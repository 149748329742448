import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	Typography,
	useMediaQuery,
} from '@mui/material';
import {
	DataGridPro as DataGrid,
	frFR,
	GridColDef,
	GridColumnHeaderParams,
	GridColumnMenu,
	GridColumnMenuItemProps,
	GridColumnMenuProps,
	useGridApiRef,
} from '@mui/x-data-grid-pro';

import { localStorageFuncs } from '../../../helpers';
import { RestartAltIcon } from '../../icons';
import theme from '../../theme';

import CustomToolbar from './CustomToolBar';

const resetSettings = () => {
	localStorageFuncs.del('smtr-grid-settings');
	location.reload();
};

const CustomUserItem = ({
	myCustomHandler,
	myCustomValue,
}: GridColumnMenuItemProps) => {
	return (
		<MenuItem onClick={myCustomHandler}>
			<ListItemIcon>
				<RestartAltIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>{myCustomValue}</ListItemText>
		</MenuItem>
	);
};

const ResetSettingsItem = (props: GridColumnMenuProps) => {
	return (
		<GridColumnMenu
			{...props}
			slots={{
				columnMenuUserItem: CustomUserItem,
			}}
			slotProps={{
				columnMenuUserItem: {
					displayOrder: 30,
					myCustomHandler: resetSettings,
					myCustomValue: 'Réinitialiser les colonnes',
				},
			}}
		/>
	);
};

const DataGridPro = ({
	columns,
	getRowClassName,
	getTreeDataPath,
	onCellEditStart,
	onRowClick,
	ref,
	rows,
	rowHeight = 65,
	treeData,
	sx,
	...rest
}: DataGridProProps) => {
	const apiRef = useGridApiRef();
	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

	const getSettings = () => {
		const stringifiedState = localStorageFuncs.get('smtr-grid-settings');
		let settings: any = {};

		if (stringifiedState) {
			settings = JSON.parse(stringifiedState);
		}

		if (isPad) {
			settings = {
				...settings,
				pinnedColumns: { left: ['trainCode'], right: ['actions'] },
			};
		} else {
			delete settings.pinnedColumns;
		}

		return settings;
	};

	const handleChangeSettings = () => {
		const exportedState = apiRef.current.exportState();
		localStorageFuncs.set('smtr-grid-settings', JSON.stringify(exportedState));
	};

	const getMinWidth = () => {
		return isPad ? 95 : undefined;
	};

	return (
		<DataGrid
			apiRef={apiRef}
			onStateChange={handleChangeSettings}
			initialState={getSettings() || {}}
			localeText={{
				...frFR.components.MuiDataGrid.defaultProps.localeText,
				toolbarExport: 'Export écran',
			}}
			ref={ref}
			rows={rows}
			rowHeight={rowHeight}
			columns={columns.map((col) => ({
				...col,
				align: col?.align ?? 'left',
				headerAlign: col?.headerAlign ?? 'left',
				minWidth: col.minWidth ?? getMinWidth(),
				renderHeader: (params: GridColumnHeaderParams) => (
					<Typography variant="h6">{params.colDef.headerName}</Typography>
				),
			}))}
			treeData={treeData}
			getRowClassName={getRowClassName}
			getTreeDataPath={getTreeDataPath}
			onRowClick={onRowClick ? (params) => onRowClick(params) : undefined}
			onCellEditStart={
				onCellEditStart ? (params) => onCellEditStart(params) : undefined
			}
			hideFooter
			slots={{ columnMenu: ResetSettingsItem, toolbar: CustomToolbar }}
			sx={sx}
			{...rest}
		/>
	);
};

export default DataGridPro;

type DataGridProProps = {
	columns: GridColDef[];
	pagination?: true;
	page?: number;
	pageSize?: number;
	ref?: any;
	rows: any[];
	rowHeight?: number;
	toolbar?: boolean;
	autoPageSize?: boolean;
	onRowClick?: (row: any) => void;
	onCellEditStart?: (params: any) => void;
	onCellEditStop?: (params: any) => Promise<void>;
	overrideCss?: object;
	pageSizeOptions?: number[];
	treeData?: boolean;
	getTreeDataPath?: (row: any) => string[];
	getRowClassName?: (row: any) => string;
	sx?: any;
	initialState?: any;
};
