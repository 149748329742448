/* eslint-disable no-mixed-spaces-and-tabs */
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { getUniqueKey } from '../../packages/helpers';
import { useEntity, useLine, useTypedSelector } from '../../packages/hooks';
import { Dropdown } from '../../packages/ui';
import LineSvgPrintable from '../../packages/ui/icons/LineSvgPrintable';
import { setSelectedLine } from '../../redux/actions';

import './styles.css';

const LineSelector = ({
	showAll = false,
	isUsedTrain = false,
}: LineSelectorProps) => {
	const dispatch = useDispatch();
	const { isAgentN } = useEntity();
	const { allLines, selectedLine, hasMultiLines } = useLine(showAll);

	const params = new URLSearchParams(window.location.search);
	const nameOperationSpecial = params?.get('name');

	const prefixN = isAgentN ? '-n' : '';

	const handleSelectLine = (line?: string) => {
		if (line) {
			dispatch(setSelectedLine(line));
		}
	};

	const operationSpecial = useTypedSelector(({ specialOperationTypeState }) =>
		hasMultiLines
			? specialOperationTypeState.opInProgress.find(
					(item) => item.name && item.name === nameOperationSpecial
			  )
			: null
	);

	const linesToDisplay = operationSpecial?.lines ?? allLines;

	return (
		<Grid container>
			<Grid
				item
				xs={12}
				sx={{
					display: { lg: isUsedTrain ? 'block' : 'none', xs: 'block' },
				}}
			>
				<Dropdown
					buttonId="dropdown-basic"
					buttonTitle={
						showAll && selectedLine === allLines[0]
							? 'Toutes les lignes'
							: `Ligne ${selectedLine}`
					}
					items={linesToDisplay.map((line) => {
						return {
							content: (
								<>
									{showAll && linesToDisplay[0] === line
										? 'Toutes les lignes'
										: `Ligne `}
									<div className="logo-dropdown">
										<LineSvgPrintable line={line} />
									</div>
								</>
							),
							key: getUniqueKey(line),
							onClick: () => handleSelectLine(line),
						};
					})}
					variant="outlined"
					buttonStyle={{ width: '100%' }}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sx={{
					display: { lg: isUsedTrain ? 'none' : 'block', xs: 'none' },
				}}
			>
				<Grid container spacing={3} justifyContent="center">
					{allLines.map((line) => (
						<button
							className={`reporting-line-selector-button${prefixN} ${
								selectedLine.toLocaleLowerCase() === line?.toLocaleLowerCase()
									? `reporting-line-selector-button__selected${prefixN}`
									: ''
							}`}
							key={getUniqueKey(line)}
							onClick={() => handleSelectLine(line)}
						>
							<div className={`reporting-svg-metro-container${prefixN}`}>
								<LineSvgPrintable line={line} />
							</div>
						</button>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LineSelector;

type LineSelectorProps = {
	showAll?: boolean;
	isUsedTrain?: boolean;
};
