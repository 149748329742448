import { ThunkAction } from 'redux-thunk';

import {
	DepartmentEnum,
	Item,
	PopupType,
	RollingStock,
	RollingStockCodeFormat,
	RollingStockQrFormat,
} from '../../models';
import {
	RollingstockQuery,
	rollingStockService,
} from '../../packages/dataProviders';
import { sortASC } from '../../packages/helpers';
import {
	errorConf,
	exceptionDTOtoPopupState,
} from '../../packages/helpers/popup/exception.utils';
import {
	setHistoryItemsFilter,
	setLines,
	setReportingRSCount,
	setRollingStock,
	setSurface,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';
const { ROLLINGSTOCK, LINES } = components;

type RollingStockThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

function isSurfaceQrFormat(object: any): object is RollingStockQrFormat {
	return 'id' in object;
}

export const loadAndSetRollingStock =
	(
		npHighLimit: number,
		searchedLine?: string,
		department?: DepartmentEnum
	): RollingStockThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(ROLLINGSTOCK));
			const res = await rollingStockService.loadRollingStock(searchedLine);
			dispatch(setRollingStock(npHighLimit, res, department));
			dispatch(api._response(ROLLINGSTOCK));
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(ROLLINGSTOCK, e));
		}
	};

export const loadTrainCount =
	(searchedLine?: string, department?: string): RollingStockThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(ROLLINGSTOCK));
			const count = await rollingStockService.getCountRollingStock(
				searchedLine,
				department
			);
			dispatch(setReportingRSCount(Number(count.count)));
			dispatch(api._response(ROLLINGSTOCK));
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(ROLLINGSTOCK, e));
		}
	};

export const loadTrainsCode =
	(searchedLine: string): RollingStockThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(ROLLINGSTOCK));
			const trainsCode = await rollingStockService.getRollingStockCodes(
				searchedLine
			);
			const items: Item[] = trainsCode.map((train) => ({
				checked: false,
				code: train.trainCode,
				id: train.id,
				name: train.trainCode,
			}));
			dispatch(setHistoryItemsFilter(items));
			dispatch(api._response(ROLLINGSTOCK));
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(ROLLINGSTOCK, e));
		}
	};

export const verifyAndLoadRollingStock =
	(
		newSurface: RollingStockQrFormat | RollingStockCodeFormat,
		successCb?: () => void,
		errorCb?: () => void
	): RollingStockThunk =>
	async (dispatch, _, api) => {
		try {
			let query: RollingstockQuery = {};
			if (isSurfaceQrFormat(newSurface)) {
				query = {
					equipment: newSurface.materiel,
					selectedLine: newSurface.selectedLine,
					trainId: newSurface.id,
				};
			} else {
				query = {
					selectedLine: newSurface.selectedLine,
					trainId: newSurface.trainId,
				};
			}
			dispatch(api._request(ROLLINGSTOCK));
			const res = await rollingStockService.findOneRollingstock(query);
			if (res.ok) {
				const rollingStock = res.message as RollingStock;
				dispatch(
					setSurface({
						equipment: rollingStock.equipment,
						incidents: rollingStock.incidents,
						position: rollingStock.position,
						specialOperations: rollingStock.specialOperations,
						trainCode: rollingStock.train_code,
						trainId: rollingStock.train_id,
					})
				);
				dispatch(api._response(ROLLINGSTOCK));
				if (successCb) successCb();
			} else if (res.isOffline) {
				dispatch(
					setSurface({
						equipment: query.equipment,
						trainCode: query.trainCode,
						trainId: query.trainId,
					})
				);
				dispatch(api._response(ROLLINGSTOCK));
				if (successCb) successCb();
			} else {
				dispatch(api._error(ROLLINGSTOCK, res.message));
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.message,
							query.trainId ? PopupType.ERROR : PopupType.WARN
						)
					)
				);
			}
		} catch (e) {
			api._error(ROLLINGSTOCK, e);
			console.error(e);
			if (errorCb) errorCb();
		}
	};

export const getLines = (): RollingStockThunk => async (dispatch, _, api) => {
	try {
		dispatch(api._request(LINES));
		const res = await rollingStockService.loadLines();
		dispatch(setLines(sortASC(res)));
		dispatch(api._response(LINES));
	} catch (e) {
		dispatch(api.showFeedBack(errorConf.warn.offlineMode));
		dispatch(api._error(LINES, e));
	}
};
