/* eslint-disable sort-keys */
import { RSIncident } from '../../models';

const INCIDENTS_PRIORITY: {
	[key: string]: {
		name: string;
		weight: number;
	};
} = {
	PRIORITY_GR: {
		name: 'Graffiti',
		weight: 4000,
	},
	PRIORITY_DI: {
		name: 'Signalement intérieur',
		weight: 3000,
	},
	GR: {
		name: 'Graffiti',
		weight: 200,
	},
	PB: {
		name: 'Pare-brise',
		weight: 20,
	},
	DI: {
		name: 'Signalement intérieur',
		weight: 10,
	},
};

export const getIncidentWeight = (incident: RSIncident): number => {
	const priorityPrefix = incident.isPriority ? 'PRIORITY_' : '';
	return INCIDENTS_PRIORITY[priorityPrefix + incident.code].weight;
};

export const getIncidentsOrder = (incidents: RSIncident[]): RSIncident[] => {
	return incidents.sort((a, b) => getIncidentWeight(b) - getIncidentWeight(a));
};

export const getNameIncident = (selectedIncidentCode: string) => {
	const incidentPriority = INCIDENTS_PRIORITY[selectedIncidentCode];
	return incidentPriority ? incidentPriority.name : null;
};

export { INCIDENTS_PRIORITY };
