/* eslint-disable no-mixed-spaces-and-tabs */
import {
	ChangeEvent,
	Dispatch,
	KeyboardEvent,
	SetStateAction,
	useState,
} from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';
import { isEmpty } from 'lodash';

import { ServiceType } from '../../../../../models';
import { dateTimeService } from '../../../../../packages/helpers/dateTime';
import { useDepartment, useTypedSelector } from '../../../../../packages/hooks';
import {
	Button,
	DateTimePicker,
	Input,
	Select,
} from '../../../../../packages/ui';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const DeleteServiceFilters = ({
	onEndDateChange,
	onSearch,
	onServiceChange,
	onStartDateChange,
	onTrainCodeChange,
	services,
	setDateFin,
}: DeleteServiceFiltersProps) => {
	const { startDateTs, trainCodeTs } = useTypedSelector(
		({ deleteServicePopInState }) => {
			return {
				startDateTs: deleteServicePopInState.serviceStartDate,
				trainCodeTs: deleteServicePopInState.serviceTrainCode,
			};
		}
	);
	const [startDate, setStartDate] = useState<Date>(new Date(startDateTs));
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [serviceType, setServiceType] = useState('');

	setDateFin(endDate);
	const handleServiceChange = (e: SelectChangeEvent) => {
		if (e.target.value === '') {
			onServiceChange('');
		} else {
			onServiceChange(getServiceType(e.target.value).id.toString());
		}
		setServiceType(e.target.value);
	};

	const { rsLabelwithUndefinedArticle } = useDepartment();

	const handleStartDateChange = (date: Date) => {
		let newEndDate;
		if (dateDiffInDays(endDate, date) > 30 || date > endDate) {
			newEndDate = dateTimeService.getRelativeDate(date, { month: +1 });
		} else newEndDate = endDate;
		setStartDate(date);
		setEndDate(newEndDate);
		onStartDateChange(date.toISOString());
		onEndDateChange(newEndDate.toISOString());
	};

	const handleEndDateChange = (date: Date) => {
		let newStartDate;
		if (dateDiffInDays(date, startDate) > 30 || date < startDate) {
			newStartDate = dateTimeService.getRelativeDate(date, { month: -1 });
		} else {
			newStartDate = startDate;
		}

		setEndDate(date);
		setStartDate(newStartDate);
		onEndDateChange(date.toISOString());
		onStartDateChange(newStartDate.toISOString());
	};

	const handleSearchClick = () => {
		onSearch();
	};

	const getServiceType = (type: string): ServiceType => {
		const serviceTypeObj = services.find((elm) => elm.name === type);
		return (
			serviceTypeObj ?? {
				code: '',
				id: 1,
				name: '',
			}
		);
	};

	const handleTrainCodeChange = (
		changeEvent: ChangeEvent<HTMLInputElement>
	) => {
		onTrainCodeChange(changeEvent.target.value);
	};

	const onKeyPressFilterInput = (
		keyboardEvent: KeyboardEvent<HTMLInputElement>
	) => {
		if (keyboardEvent.key === 'Enter') {
			keyboardEvent.preventDefault();
			if (!disableSearchButton()) {
				onSearch();
			}
		}
	};

	const disableSearchButton = (): boolean => {
		return isEmpty(serviceType) && isEmpty(trainCodeTs);
	};

	const dateDiffInDays = (a: Date, b: Date) => {
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
		return Math.floor((utc1 - utc2) / MS_PER_DAY) + 1;
	};

	return (
		<form id="main-service-filters" noValidate>
			<Grid container spacing={3} justifyContent="center" padding={2}>
				<Grid item xs={6}>
					<Select
						label="Choisir une prestation"
						name="choisir une prestation"
						onChange={handleServiceChange}
						options={services.map((service) => service.name)}
						value={serviceType ?? ''}
					/>
				</Grid>

				<Grid item xs={6}>
					<Input
						fullWidth
						label={`Rechercher par numéro ${rsLabelwithUndefinedArticle}`}
						name="delete-service-popin-search"
						onChange={handleTrainCodeChange}
						onKeyDown={onKeyPressFilterInput}
						type="search"
					/>
				</Grid>

				<Grid item xs={6}>
					<DateTimePicker
						label="Du"
						onChange={(newValue) =>
							newValue ? handleStartDateChange(newValue) : startDate
						}
						value={startDate}
						fullWidth
					/>
				</Grid>

				<Grid item xs={6}>
					<DateTimePicker
						fullWidth
						label="Au"
						onChange={(newValue) =>
							newValue ? handleEndDateChange(newValue) : startDate
						}
						value={endDate}
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						style={{ width: '100%' }}
						disabled={disableSearchButton()}
						onClick={handleSearchClick}
					>
						Rechercher
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default DeleteServiceFilters;

export type DeleteServiceFiltersProps = {
	onEndDateChange: (endDate: string) => void;
	onSearch: () => void;
	onServiceChange: (serviceType: string) => void;
	onStartDateChange: (startDate: string) => void;
	onTrainCodeChange: (trainCode: string) => void;
	services: ServiceType[];
	setDateFin: Dispatch<SetStateAction<Date>>;
};
