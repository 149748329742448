import { initSpecialOperationTypeState } from '../config/initialState';
import {
	CLOSE_SPECIAL_OPERATION_TYPE,
	CREATE_SPECIAL_OPERATION_TYPE,
	SET_OP_IN_PROGRESS,
	SET_OP_REPORTING,
	SpecialOperationTypeActionTypes as ActionTypes,
	SpecialOperationTypeState as State,
	UPDATE_SPECIAL_OPERATION_TYPE,
} from '../types';

export const specialOperationTypeReducer = (
	state = initSpecialOperationTypeState,
	action: ActionTypes
): State => {
	switch (action.type) {
		case CREATE_SPECIAL_OPERATION_TYPE:
			return {
				...state,
				opInProgress: [...state.opInProgress, action.payload],
			};

		case UPDATE_SPECIAL_OPERATION_TYPE:
			return {
				...state,
				opInProgress: state.opInProgress.map((specialOperationType) =>
					Number(specialOperationType.id) === Number(action.payload.id)
						? action.payload
						: specialOperationType
				),
			};

		case CLOSE_SPECIAL_OPERATION_TYPE:
			return {
				...state,
				opInProgress: state.opInProgress.filter(
					(op) => op.id !== action.payload.id
				),
				opReporting: [
					...state.opReporting,
					{ ...action.payload, endDate: new Date() },
				],
			};

		case SET_OP_IN_PROGRESS:
			return {
				...state,
				opInProgress: action.payload,
			};

		case SET_OP_REPORTING:
			return {
				...state,
				opReporting: action.payload,
			};

		default:
			return state;
	}
};

export default specialOperationTypeReducer;
