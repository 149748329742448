import { initStationDetailPopInState } from '../config/initialState';
import * as types from '../types/stationDetailPopIn.types';

export const stationDetailPopInReducer = (
	state = initStationDetailPopInState,
	action: types.StationDetailPopInActionTypes
): types.StationDetailPopInState => {
	switch (action.type) {
		case types.CLOSE_STATION_DETAIL_POPIN:
			return {
				...state,
				meta: {
					open: false,
				},
				stationId: 0,
				stationName: '',
				units: [],
			};
		case types.OPEN_STATION_DETAIL_POPIN:
			return {
				...state,
				meta: {
					...state.meta,
					open: true,
				},
				stationId: action.payload.stationId,
				stationName: action.payload.stationName,
			};
		case types.INIT_STATION_DETAIL_POPIN:
			return {
				...state,
				passage: action.payload.passage,
				stationId: action.payload.stationId,
				stationName: action.payload.stationName,
			};
		case types.SET_UNITS_STATION_DETAIL_POPIN:
			return {
				...state,
				units: action.payload.units,
			};
		default:
			return state;
	}
};
