import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Box,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';

import { useDepartment } from '../../hooks';
import { ClearIcon, SearchIcon } from '../icons';

const SearchBar = ({
	label,
	onSubmit,
	fullWidth = false,
	name,
	stockCountText,
	isPad,
	type = 'text',
}: SearchBarProps) => {
	const [value, setValue] = useState<string | number>(
		`${type === 'text' ? '' : 0}`
	);
	const dispatch = useDispatch();
	const { rsLabel } = useDepartment();

	const handleInputChange = ({
		target: { value },
	}: ChangeEvent<HTMLInputElement>) => {
		setValue(value);
	};

	const handleReset = () => {
		setValue('');
		onSubmit('');
	};

	const handleEnterPress = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			onSubmit(value);
		}
	};

	const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	useEffect(() => {
		document.addEventListener('keypress', handleEnterPress);
		return () => {
			document.removeEventListener('keypress', handleEnterPress);
		};
	}, [value, dispatch, handleEnterPress]);

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<FormControl size="small" fullWidth={fullWidth}>
				{isPad ? (
					<>
						<InputLabel shrink={true} sx={{ fontSize: 10, lineHeight: 0.9 }}>
							{stockCountText}
						</InputLabel>
						<InputLabel shrink={false}>
							{value === '' ? `Rechercher un ${rsLabel}` : value}
						</InputLabel>
					</>
				) : (
					<InputLabel>{label ?? `Rechercher un ${rsLabel}`}</InputLabel>
				)}

				<OutlinedInput
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								sx={{ visibility: value ? 'visible' : 'hidden' }}
								onClick={handleReset}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
							<IconButton
								onClick={() => onSubmit(value)}
								onMouseDown={handleMouseDownPassword}
								sx={{
									'&.Mui-focused .MuiIconButton-root': {
										color: 'primary.main',
									},
								}}
							>
								<SearchIcon fontSize="small" />
							</IconButton>
						</InputAdornment>
					}
					label={label ?? `Rechercher un ${rsLabel}`}
					margin="dense"
					name={name ?? 'searchbar-input'}
					onChange={handleInputChange}
					size="small"
					type={type}
					value={value}
					data-testid={name}
				/>
			</FormControl>
		</Box>
	);
};

export default SearchBar;

type SearchBarProps = {
	label?: string;
	name?: string;
	onSubmit: (inputValue: string | number) => void;
	fullWidth?: boolean;
	isPad?: boolean;
	stockCountText?: number | string;
	type?: 'text' | 'number';
};
