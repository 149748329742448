import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { SpecialOperationType } from '../../../../models';
import { SP_UPDATE_TITLE_MODAL } from '../../../../packages/helpers/explanations/specialOperation';
import { useForm } from '../../../../packages/hooks';
import { Dialog, ValidationButtons } from '../../../../packages/ui';
import { updateAndSetSpecialOperation } from '../../../../redux/thunks/specialOperationType';
import { validate } from '../config';
import SpecialOperationFormContent from '../formContent';

const SpecialOperationTypeEditForm = ({
	open,
	setOpen,
	specialOperationType,
	setSpecialOperationToEdit,
}: SpecialOperationTypeEditFormProps) => {
	const dispatch = useDispatch();
	const handleClose = () => {
		setSpecialOperationToEdit(undefined);
		setOpen(false);
	};

	const handleEditSpecialOperation = () => {
		const orderedLines = values.lines.sort((a: string, b: string) =>
			a.localeCompare(b, undefined, { numeric: true })
		);
		dispatch(updateAndSetSpecialOperation({ ...values, lines: orderedLines }));
		setSpecialOperationToEdit(undefined);
		handleClose();
	};

	const {
		errors,
		handleChange,
		handleDateChange,
		handleSubmit,
		isUntouched,
		setIsUntouched,
		setValues,
		values,
	} = useForm({
		callback: handleEditSpecialOperation,
		initFormValues: {
			...specialOperationType,
			startDate: new Date(specialOperationType.startDate),
		},
		validate,
	});
	return (
		<Dialog
			titleContent={SP_UPDATE_TITLE_MODAL}
			open={open}
			onClose={handleClose}
			content={
				<SpecialOperationFormContent
					errors={errors}
					handleChange={handleChange}
					handleDateChange={handleDateChange}
					setIsUntouched={setIsUntouched}
					setValues={setValues}
					values={values}
				/>
			}
			actionsContent={
				<ValidationButtons
					onValidate={handleSubmit}
					onCancel={handleClose}
					validateDisabled={isUntouched}
				/>
			}
		></Dialog>
	);
};

export default SpecialOperationTypeEditForm;

type SpecialOperationTypeEditFormProps = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	specialOperationType: SpecialOperationType;
	setSpecialOperationToEdit: Dispatch<
		SetStateAction<SpecialOperationType | undefined>
	>;
};
