import { ServiceType } from '../../models';

export interface ServiceTypeState {
	serviceTypesCreate: ServiceType[];
	serviceTypesDelete: ServiceType[];
	serviceTypesExport: ServiceType[];
}

export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';

export interface SetServiceType {
	type: typeof SET_SERVICE_TYPE;
	payload: ServiceTypeState;
}

export type ServiceTypeActionTypes = SetServiceType;
