import { Link } from 'react-router-dom';
import { Button as MuiButton } from '@mui/material';

import { ButtonProps } from '../../../models/ui';

const Button = ({
	children,
	className = '',
	color = 'primary',
	component = Link,
	disabled,
	endIcon,
	fullWidth = false,
	href,
	id,
	label,
	onClick,
	onMouseDown,
	size = 'small',
	startIcon,
	style,
	to,
	type = 'button',
	variant = 'outlined',
	...props
}: ButtonProps) => {
	return (
		<MuiButton
			style={{ ...style }}
			fullWidth={fullWidth}
			className={className}
			color={color as any}
			component={to ? (component as any) : undefined}
			disabled={disabled}
			endIcon={endIcon}
			href={href}
			id={id ?? label}
			onClick={to ? undefined : onClick}
			onMouseDown={onMouseDown}
			size={size}
			startIcon={startIcon}
			to={to}
			type={type}
			variant={variant}
			{...props}
		>
			{label ?? children}
		</MuiButton>
	);
};

export default Button;
