import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthProvider from '../../packages/dataProviders/resources/auth';
import { initEventSource } from '../../packages/events/creator';
import { useDepartment, useEntity } from '../../packages/hooks';
import { switchEspace } from '../../redux/actions/espace.actions';
import { tryLogin } from '../../redux/thunks/auth';
import { getRedirectPath } from '../../routes/getRedirectPath';

import './styles/loginPage.css';

const LoginPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userTs } = useEntity();

	const { hasSemDepartment } = useDepartment();

	const handleClick = () => {
		AuthProvider.login();
	};

	useEffect(() => {
		if (!userTs) {
			dispatch(tryLogin());
		} else {
			initEventSource(userTs);
			const hasOnlySEM = userTs?.departments.length === 1 && hasSemDepartment;
			if (hasOnlySEM) dispatch(switchEspace(3));
			navigate(getRedirectPath(userTs));
		}
	}, [dispatch, hasSemDepartment, navigate, userTs]);

	return (
		<section className="home-container">
			<header className="header-container">
				<span className="home-title font-montserrat-bold">Smart Tracer</span>
			</header>
			<div
				data-testid="login-link"
				className="content-toggle"
				onClick={handleClick}
				onKeyDown={handleClick}
				role="button"
				tabIndex={0}
			/>
			<footer className="home-footer font-paris-bold">
				<span>cliquez</span>
				<span>pour vous identifier</span>
			</footer>
		</section>
	);
};

export default LoginPage;
