import { useTypedSelector } from './useTypedSelector';

export const useEspace = () => {
	const { espace } = useTypedSelector(({ espaceState }) => espaceState);
	const ESPACE_3 = 3;
	const ESPACE_5 = 5;
	const isEspace3 = espace === ESPACE_3;
	const isEspace5 = espace === ESPACE_5;

	return {
		espace,
		ESPACE_3,
		ESPACE_5,
		isEspace3,
		isEspace5,
	};
};
