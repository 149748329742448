/* eslint-disable no-mixed-spaces-and-tabs */
import { Dispatch, SetStateAction } from 'react';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';

import {
	DataTypeEnum,
	dataTypeSelectObject,
	Export,
	ServiceSelection,
} from '../../../../../models';
import {
	DATA_TYPE_SPECIAL_OPERATION_TYPES,
	EXPORT_DATA_INFORMATION,
	EXPORT_DATA_SELECT,
	EXPORT_DATA_TITLE,
	EXPORT_INCIDENT_INFORMATION,
	EXPORT_SPECIAL_OPERATION_INFORMATION,
} from '../../../../../packages/helpers/explanations/export';
import { EXPORT_OP } from '../../../../../packages/helpers/rigths';
import { useRight } from '../../../../../packages/hooks';
import { Checkbox, Select } from '../../../../../packages/ui';

const ExportData = ({
	errors,
	handleSelect,
	setValues,
	values,
}: ExportDataProps) => {
	const handleServiceSelect = (selectedService: ServiceSelection) => {
		const newServices = values.services.map((service: ServiceSelection) =>
			service.key === selectedService.key
				? { ...service, selected: !service.selected }
				: service
		);
		setValues({ ...values, services: newServices });
	};

	const { can } = useRight();
	const dataTypeSelectObjectWithOp = can(EXPORT_OP)
		? dataTypeSelectObject.concat({
				id: DataTypeEnum.OP,
				name: DATA_TYPE_SPECIAL_OPERATION_TYPES,
		  })
		: dataTypeSelectObject;

	return (
		<>
			<Typography variant="h5" my="1rem">
				{EXPORT_DATA_TITLE}
			</Typography>
			<Select
				required
				label={EXPORT_DATA_SELECT}
				name="dataType"
				onChange={handleSelect}
				options={dataTypeSelectObjectWithOp}
				value={values.dataType}
				helperText={errors.dataType}
			/>
			{values.dataType && (
				<Typography variant="body1">{EXPORT_DATA_INFORMATION}</Typography>
			)}
			{values.dataType?.id === DataTypeEnum.SERVICE && (
				<Grid container mt="1rem">
					{values.services.map((service: ServiceSelection) => {
						return (
							<Grid item sm={4} xs={12} key={service.name}>
								<Checkbox
									label={service.name}
									checked={service.selected}
									disabled={service.disabled}
									onChange={() => {
										handleServiceSelect(service);
									}}
								/>
							</Grid>
						);
					})}
				</Grid>
			)}
			{values.dataType?.id === DataTypeEnum.INCIDENT && (
				<Typography color="var(--bg-color-grey-40)" variant="body2">
					{EXPORT_INCIDENT_INFORMATION}
				</Typography>
			)}
			{values.dataType?.id === DataTypeEnum.OP && (
				<Typography color="var(--bg-color-grey-40)" variant="body2">
					{EXPORT_SPECIAL_OPERATION_INFORMATION}
				</Typography>
			)}
		</>
	);
};

export default ExportData;

type ExportDataProps = {
	errors: { [key: string]: string };
	handleSelect: (event: SelectChangeEvent) => void;
	setValues: Dispatch<SetStateAction<Export>>;
	values: any;
};
