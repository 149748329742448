import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { pluralize } from '../../../../../packages/helpers';
import { useDepartment } from '../../../../../packages/hooks';
import { DeleteIcon } from '../../../../../packages/ui/icons';

interface CustomToolbarProps {
	numSelected: number;
	onValidate: () => void;
}

const CustomToolbar = (props: CustomToolbarProps) => {
	const { numSelected, onValidate } = props;
	const { rsLabel } = useDepartment();

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { sm: 1, xs: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			<Typography
				sx={{ flex: '1 1 100%' }}
				color="inherit"
				variant="subtitle1"
				component="div"
			>
				{numSelected} {pluralize(numSelected, rsLabel)}{' '}
				{pluralize(numSelected, 'sélectionné')}
			</Typography>
			{numSelected > 0 && (
				<Tooltip title="Delete" onClick={onValidate}>
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

export default CustomToolbar;
