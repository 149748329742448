import { shallowEqual, useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../packages/hooks';
import { Button } from '../../../packages/ui';
import { closeNotificationPopIn } from '../../../redux/actions';

import './styles.css';

const NotificationPopIn = () => {
	const dispatch = useDispatch();

	const { open, title, subTitle, rsCodes, confirmTxt } = useTypedSelector(
		({ notificationPopInState }) => notificationPopInState,
		shallowEqual
	);

	const onValidate = () => {
		dispatch(closeNotificationPopIn());
	};

	return (
		<>
			{open && (
				<div className="notification-container" id="popup-container">
					<div
						className={`popup-body popup-save container-dimension popup-pos-height`}
						id="popup"
					>
						<div className="popup-header">
							<h3 className="title-text font-paris-bold">{title}</h3>
						</div>
						<div className="popup-message-text font-paris">
							<div>{subTitle}</div>
							<div>
								<ul className="custom-element-list pt-3">
									{rsCodes.map((datum) => (
										<li className="font-weight-bold" key={datum}>
											{datum}
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="popin-button-group">
							<Button
								className="popin-confirm-button hover"
								onClick={() => {
									onValidate();
								}}
							>
								{confirmTxt}
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default NotificationPopIn;
