import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { Grid, SelectChangeEvent } from '@mui/material';

import { Role, RoleEnum } from '../../../../models';
import { ALL_LINES, UserCredential } from '../../../../models/user.model';
import {
	useEntity,
	useEspace,
	useTypedSelector,
} from '../../../../packages/hooks';
import { Input, Select } from '../../../../packages/ui';

const { TR, TRMAL, ADMIN, PRDD, CSDD, AGENTN, AGENTM } = RoleEnum;

const FormContent = ({
	errors,
	handleChange,
	handleSelect,
	user,
}: FormContentProps) => {
	const { isRp, isRpSNCF } = useEntity();
	const { isEspace3, isEspace5 } = useEspace();

	const linesList = useTypedSelector(
		({ lineState: { lines } }) => lines,
		shallowEqual
	);

	const rolesList: Role[] = useTypedSelector(({ roleState: { roles } }) => {
		const listOfRoles = roles.filter((role) => role.id !== 999);
		return isEspace5
			? listOfRoles
			: listOfRoles.filter((r) => r.id !== TR && r.id !== TRMAL);
	}, shallowEqual);

	const isAllowedAllLines = useMemo(
		() =>
			[ADMIN, PRDD, CSDD].includes(user.role?.id as RoleEnum) ||
			(isEspace3 && [AGENTN, AGENTM].includes(user.role?.id as RoleEnum)),
		[user.role]
	);

	const isRoleFixeLineChoice = isRp || isRpSNCF || isAllowedAllLines; // nosonar

	return (
		<form noValidate>
			<Grid
				container
				spacing={3}
				paddingTop={3}
				paddingBottom={3}
				paddingLeft={18}
			>
				<Grid item xs={10}>
					<Input
						defaultValue={user.registration_number}
						label="Compte matriculaire"
						name="registration_number"
						helperText={errors.registration_number}
						inputProps={{ style: { textTransform: 'uppercase' } }}
						onChange={handleChange}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={10}>
					<Select
						defaultValue={user.role}
						value={user.role}
						label="Rôle"
						name="role"
						helperText={errors.role}
						onChange={handleSelect}
						options={rolesList}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={10}>
					{isRoleFixeLineChoice ? (
						<Input
							label={`Ligne${isAllowedAllLines ? 's' : ''}`}
							name="line_id"
							disabled={true}
							defaultValue={isAllowedAllLines ? ALL_LINES : user.line_id}
							fullWidth
						/>
					) : (
						<Select
							defaultValue={user.lines ?? []}
							fullWidth
							helperText={errors.lines}
							label="Lignes"
							multiple
							name="lines"
							onChange={handleSelect}
							options={linesList}
							value={user.lines ?? []}
							required
							isStringValues={true}
						/>
					)}
				</Grid>
				<Grid item xs={10}>
					<Input
						defaultValue={user.organisation}
						label="Entreprise"
						name="organisation"
						onChange={handleChange}
						fullWidth
						required={true}
						helperText={errors.organisation}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default FormContent;

type FormContentProps = {
	errors: { [key: string]: string };
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	user: UserCredential;
};

export type State = {
	validated: boolean;
	employeeId: string;
	roleName: string;
	line: string;
	organisation: string;
};
