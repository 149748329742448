import { ThunkAction } from 'redux-thunk';

import { ExceptionDTO, PopupType } from '../../models';
import SpecialOperationProvider from '../../packages/dataProviders/resources/specialOperation';
import { errorConf, exceptionDTOtoPopupState } from '../../packages/helpers';
import { NotifyCode } from '../../packages/helpers/popup/exception.config';
import {
	deleteRollingStockAvailabilityByRsId,
	deleteRollingStockSpecialOperation,
} from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { SPECIAL_OPERATIONS } = components;

type SpecialOperationThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const solveSpecialOperation =
	(specialOperationId: number, trainId: number): SpecialOperationThunk =>
	async (dispatch, _, api) => {
		let res;
		try {
			dispatch(api._request(SPECIAL_OPERATIONS));
			res = await SpecialOperationProvider.solveSpecialOperation(
				specialOperationId
			);
			if (res.ok) {
				dispatch(api._response(SPECIAL_OPERATIONS));
				dispatch(
					deleteRollingStockAvailabilityByRsId(
						trainId,
						'OP',
						res.data.specialOperationTypeId
					)
				);
				dispatch(
					deleteRollingStockSpecialOperation(specialOperationId, trainId)
				);
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							NotifyCode.INF_FUNC_019 as string,
							PopupType.SUCCESS,
							false,
							false
						)
					)
				);
			} else {
				dispatch(
					api.showFeedBack(
						exceptionDTOtoPopupState(
							res.statusText as unknown as ExceptionDTO,
							PopupType.WARN
						)
					)
				);
			}
		} catch (e) {
			dispatch(api._error(SPECIAL_OPERATIONS, e));
			dispatch(api.showFeedBack(errorConf.error['specialOperationNotSolved']));
		}
	};
