import { Grid, Typography } from '@mui/material';

import { DASHBOARD_TITLE } from '../../../packages/helpers/explanations/dashboard';
import { useRight } from '../../../packages/hooks';
import { Button } from '../../../packages/ui';
import { DownloadIcon } from '../../../packages/ui/icons';

import './style.css';

const ReportingHeader = ({ onClick }: ReportingHeaderProps) => {
	const { canDisplayExportButton } = useRight();

	return (
		<Grid
			container
			className="reporting-header-container"
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid item xs={6}>
				<Typography width="100%" variant="h2">
					{DASHBOARD_TITLE}
				</Typography>
			</Grid>
			<Grid item className="reporting-header-export-button" xs={6}>
				{canDisplayExportButton() && (
					<Button
						endIcon={<DownloadIcon />}
						label="Exporter en PDF"
						onClick={() => onClick()}
						variant="text"
						style={{
							fontSize: '1.1rem',
						}}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default ReportingHeader;

type ReportingHeaderProps = {
	onClick: () => void;
};
