import { Dispatch, memo, SetStateAction } from 'react';

import {
	IncidentReport,
	IncidentTypeEnum,
	ServiceTypeEnum,
} from '../../models';
import { SelectionByAgentN, ServiceOption } from '../../redux/types';

import './styles.css';

const CardAction = ({
	incident,
	isService,
	service,
	setAgentSelection,
	isSelected,
	setIsChoiceSelected,
	isChoiceSelected,
}: CardActionProps) => {
	const serviceOrIncident = isService
		? {
				name:
					service?.name === 'Nettoyage Courant Renforcé'
						? 'Nettoyage Renforcé'
						: service?.name,
				type: service?.type,
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  }
		: {
				name: incident?.name,
				type: incident?.incidentTypeId,
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  };

	const setBackground = (
		type: ServiceTypeEnum | IncidentTypeEnum | undefined
	): string => {
		const incidentClassUnselected = isChoiceSelected
			? 'card-action-unselected'
			: 'card-action-initial';

		const selectedClass = isSelected
			? 'card-action-selected '
			: incidentClassUnselected;

		const selectedIncident = isSelected
			? 'card-incident-selected '
			: incidentClassUnselected;

		const ServiceOrIncident = isService ? selectedClass : selectedIncident;

		const selectedSvg = isSelected ? 'sel' : 'sm';
		return `svg-${
			isService ? 'service' : 'incident'
		}-symbol-${type}-${selectedSvg} ${ServiceOrIncident}`;
	};

	const handleClick = () => {
		isService
			? setAgentSelection({
					service: {
						name: serviceOrIncident.name,
						type: serviceOrIncident.type,
					} as ServiceOption,
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  })
			: setAgentSelection({ incident: incident });
		setIsChoiceSelected(true);
	};

	return (
		<button
			className={`card-action-container ${
				isService ? 'border-service' : 'border-incident'
			} svg-service ${setBackground(serviceOrIncident.type)}`}
			onClick={handleClick}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					handleClick();
				}
			}}
		>
			<span>{serviceOrIncident.name}</span>
		</button>
	);
};

export default memo(CardAction);

type CardActionProps = {
	setAgentSelection: Dispatch<SetStateAction<SelectionByAgentN>>;
	incident?: IncidentReport;
	isService?: boolean;
	isSelected: boolean;
	isChoiceSelected: boolean;
	service?: ServiceOption;
	setIsChoiceSelected: Dispatch<SetStateAction<boolean>>;
};
