import { cloneDeep } from 'lodash';

import { IncidentTypeEnum, PrefixInfo } from '../../models';
import { CountState } from '../../redux/types';
import {
	ApiRollingStockResponse,
	TrackingBoardDTO,
} from '../dataProviders/rollingStockService/rollingStock.api.types';

import { dateTimeService } from './dateTime';
import { getIncidentWeight } from './incident.utils';

export const PREFIXES: {
	[lineId: string]: PrefixInfo;
} = {
	'1': {
		defaultFilter: 'N2-5',
		fullPtrn: /^N2 -5\\d{2}$/,
		maxLength: 7,
		prefixTxt: 'N2 -5',
		startPtrn: /^N2 -5/,
	},
	'2': {
		defaultFilter: 'N30',
		fullPtrn: /^N 30\\d{3}$/,
		maxLength: 7,
		prefixTxt: 'N 30',
		startPtrn: /^N 30/,
	},
	'3': {
		defaultFilter: '',
		fullPtrn: /^NA 12\\d{3}$/,
		maxLength: 8,
		prefixTxt: 'NA 12',
		startPtrn: /^NA 12/,
	},
	'3B': {
		defaultFilter: '',
		fullPtrn: /^B14\\d{3}$/,
		maxLength: 6,
		prefixTxt: 'B14',
		startPtrn: /^B14/,
	},
	'4': {
		defaultFilter: 'CC-',
		fullPtrn: /^CC-\\d{3}$/,
		maxLength: 6,
		prefixTxt: 'CC-',
		startPtrn: /^CC-/,
	},
	'5': {
		defaultFilter: 'N30',
		fullPtrn: /^N 30\\d{3}$/,
		maxLength: 7,
		prefixTxt: 'N 30',
		startPtrn: /^N 30/,
	},
	'6': {
		defaultFilter: 'A65',
		fullPtrn: /^A 65\\d{3}$/,
		maxLength: 7,
		prefixTxt: 'A 65',
		startPtrn: /^A 65/,
	},
	'7': {
		defaultFilter: '',
		fullPtrn: /^NA31\\d{3}$/,
		maxLength: 7,
		prefixTxt: 'NA31',
		startPtrn: /^NA31/,
	},
	'7B': {
		defaultFilter: '',
		fullPtrn: /^B00\\d$/,
		maxLength: 4,
		prefixTxt: 'B00',
		startPtrn: /^B00/,
	},
	'8': {
		defaultFilter: 'NA31',
		fullPtrn: /^NA 31\\d{3}$/,
		maxLength: 8,
		prefixTxt: 'NA 31',
		startPtrn: /^NA 31/,
	},
	'9': {
		defaultFilter: 'NA30',
		fullPtrn: /^N 30\\d{3}$/,
		maxLength: 7,
		prefixTxt: 'N 30',
		startPtrn: /^N 30/,
	},
	// eslint-disable-next-line sort-keys
	'10': {
		defaultFilter: 'NA12',
		fullPtrn: /^NA 12\\d{3}$/,
		maxLength: 8,
		prefixTxt: 'NA 12',
		startPtrn: /^NA 12/,
	},
	'11': {
		defaultFilter: 'A65',
		fullPtrn: /^A65\\d{2}$/,
		maxLength: 5,
		prefixTxt: 'A65',
		startPtrn: /^A65/,
	},
	'12': {
		defaultFilter: 'A12',
		fullPtrn: /^A13\\d{3}$/,
		maxLength: 6,
		prefixTxt: 'A13',
		startPtrn: /^A13/,
	},
	'13': {
		defaultFilter: 'NA31',
		fullPtrn: /^NA 31\\d{3}$/,
		maxLength: 8,
		prefixTxt: 'NA 31',
		startPtrn: /^NA 31/,
	},
	'14': {
		defaultFilter: 'CA-',
		fullPtrn: /^CA-\\d{2}$/,
		maxLength: 5,
		prefixTxt: 'CA-',
		startPtrn: /^CA-/,
	},
	A: {
		defaultFilter: '',
		fullPtrn: /^\\d{4}$`/,
		maxLength: 4,
		prefixTxt: '',
		startPtrn: /^$/,
	},
	B: {
		defaultFilter: '',
		fullPtrn: /^\\d{4,5}$/,
		maxLength: 5,
		prefixTxt: '',
		startPtrn: /^$/,
	},
};

export const calcStatusCount = (
	rollingStocks: TrackingBoardDTO[],
	npHighLimit?: number
): CountState => {
	const count: CountState = {
		degrafed: 0,
		toClean: 0,
		total: 0,
		used: 0,
	};
	rollingStocks.forEach(({ isUsed, NpDeltaTime, incidents }) => {
		const deltaValue = NpDeltaTime ?? 0.5;
		const delta = Number(deltaValue);
		if (npHighLimit && delta >= npHighLimit) count.toClean++;

		incidents.forEach(({ incidentTypeId }) => {
			if (incidentTypeId === IncidentTypeEnum.GR) {
				count.degrafed++;
			}
		});

		count.total++;

		if (isUsed) count.used++;
	});
	return count;
};

export const transformToValidTrainCode = (code: string) =>
	code.replace(/\s+/g, '').replace(/^(.*)-0$/, '$1-');

export const isRerDepartment = (lineId: string | undefined) => {
	return lineId === `A` || lineId === `B`;
};
export const RerLines = ['A', 'B'];
export const MtsLines = [
	'm',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
];

export const setCleanStatus = (rollingStock: TrackingBoardDTO): void => {
	const { NC, NP, NCR, MAL, PBS } = rollingStock;

	rollingStock.NpDeltaTime = dateTimeService.dayDiff(NP, new Date());
	rollingStock.NcDeltaTime = dateTimeService.dayDiff(NC, new Date());
	rollingStock.NcrDeltaTime = dateTimeService.dayDiff(NCR, new Date());
	rollingStock.MalDeltaTime = dateTimeService.dayDiff(MAL, new Date());
	rollingStock.PbsDeltaTime = dateTimeService.dayDiff(PBS, new Date());
};

export const setRollingStocksOrder = (
	rollingStocks: ApiRollingStockResponse
): ApiRollingStockResponse => {
	let newRollingStocks = rollingStocks.map((el) => cloneDeep(el));

	newRollingStocks.forEach((rs) => {
		let incidentsOrderValue = 1;
		let npDaysOrderValue = 1;
		let isUsedValue = 0;

		rs.incidents.forEach((incident) => {
			incidentsOrderValue += getIncidentWeight(incident);
		});

		const npDaysNumber =
			rs.NpDeltaTime !== undefined ? parseInt(rs.NpDeltaTime) : 0.5;
		npDaysOrderValue += npDaysNumber;

		if (rs.isUsed) {
			isUsedValue = 1;
		}

		rs.incidentsOrder = incidentsOrderValue;
		rs.npDaysOrder = npDaysOrderValue;
		rs.isUsedOrder = isUsedValue;
	});

	newRollingStocks = sortRollingStock(newRollingStocks);

	return newRollingStocks;
};

export const sortRollingStock = (
	newRollingStocks: ApiRollingStockResponse
): ApiRollingStockResponse => {
	newRollingStocks.sort(
		(a, b) => (b.incidentsOrder ?? 0) - (a.incidentsOrder ?? 0)
	);

	newRollingStocks.sort((a, b) =>
		a.incidentsOrder === b.incidentsOrder
			? (b.npDaysOrder ?? 0) - (a.npDaysOrder ?? 0)
			: 0
	);
	newRollingStocks.sort((a, b) => (b.isUsedOrder ?? 0) - (a.isUsedOrder ?? 0));

	newRollingStocks.sort(function (a, b) {
		if (
			a.cleanedOrder &&
			b.cleanedOrder &&
			a.incidentsOrder === b.incidentsOrder &&
			a.npDaysOrder === b.npDaysOrder
		) {
			return b.cleanedOrder - a.cleanedOrder;
		}
		return 0;
	});

	return newRollingStocks;
};
