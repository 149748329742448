import { ReactComponent as QRCode } from '../../../assets/icons/button/qrcode-s.svg';

import './ScannerButton.css';

const ScannerButton = () => {
	return (
		<button className="scanner-btn">
			<div className="blue-card">
				<div className="round svg-container">
					<QRCode />
				</div>
			</div>
		</button>
	);
};

export default ScannerButton;
