import {
	IncidentComplement,
	IncidentLocationDTO,
	IncidentRailcarDTO,
	IncidentReport,
	IncidentSite,
	Registrant,
} from '../../../models/incidentReport.model';
import { valuesToFormData } from '../../helpers';
import fetchJson from '../fetch/fetch-json';

const loadIncidentReportDetails = async (
	incidentReportId: number
): Promise<{
	data: IncidentReport;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/incident-report/${incidentReportId}`);
};

const createIncidentReport = async (
	incidentReport: IncidentReport
): Promise<{
	data: IncidentReport;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		'/incident-report/',
		{
			body: valuesToFormData(incidentReport),
			method: 'POST',
		},
		true,
		true
	);
};

const updateIncidentReport = async (
	incidentReport: IncidentReport
): Promise<{
	data: IncidentReport;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/incident-report/${incidentReport.id}`,
		{
			body: valuesToFormData(incidentReport),
			method: 'PUT',
		},
		true,
		true
	);
};

const deleteIncidentReport = async (
	incidentReportId: number
): Promise<{
	data: string;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/incident-report/${incidentReportId}`, {
		method: 'DELETE',
	});
};

const loadIncidentComplements = async (
	incidentTypeId: number
): Promise<{
	data: IncidentComplement[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/incident-report/complements/list?incidentTypeId=${incidentTypeId}`
	);
};

const loadIncidentLocations = async (
	incidentTypeId: number
): Promise<{
	data: IncidentLocationDTO[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const locationfecth = await fetchJson(
		`/incident-report/locations/list?incidentTypeId=${incidentTypeId}`
	);
	const { data: locationsDTO } = locationfecth;
	return { ...locationfecth, data: locationsDTO as IncidentLocationDTO[] };
};

const loadIncidentRailCars = async (
	rollingStockId: number
): Promise<{
	data: IncidentRailcarDTO[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const railcarsfecth = await fetchJson(
		`/incident-report/railcars/list?rollingStockId=${rollingStockId}`
	);
	const { data: railcarsDTO } = railcarsfecth;
	return {
		...railcarsfecth,
		data: railcarsDTO as IncidentRailcarDTO[],
	};
};

const loadIncidentRegistrants = async (): Promise<{
	data: Registrant[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/incident-report/registrants/list`);
};

const loadIncidentSites = async (
	lineId: string
): Promise<{
	data: IncidentSite[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/incident-report/sites/report-sites/list?lineId=${lineId}`
	);
};

const loadIncidentDegradationSites = async (
	lineId: string
): Promise<{
	data: IncidentSite[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/incident-report/sites/degradation-sites/list?lineId=${lineId}`
	);
};

const loadIncidentDegradationPositions = async (
	siteId: number
): Promise<{
	data: IncidentSite[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(
		`/incident-report/sites/degradation-sites/positions/list?siteId=${siteId}`
	);
};

export default {
	createIncidentReport,
	deleteIncidentReport,
	loadIncidentComplements,
	loadIncidentDegradationPositions,
	loadIncidentDegradationSites,
	loadIncidentLocations,
	loadIncidentRailCars,
	loadIncidentRegistrants,
	loadIncidentReportDetails,
	loadIncidentSites,
	updateIncidentReport,
};
