import { Navigate } from 'react-router-dom';

import NavigatorTablet from '../components/navBar';
import NavigatorPhone from '../components/navBar/mobile';
import { RoleEnum } from '../models';
import {
	hasRole,
	LocalStorageEntries,
	localStorageFuncs,
} from '../packages/helpers';
import { useDepartment, useEspace, useTypedSelector } from '../packages/hooks';
import { useEntity } from '../packages/hooks/useEntity';
import { Endpoints, routesConf } from '../routes/endpoint.config';

const PrivateRoute = ({ Component, path }: Props) => {
	const { user, authenticated } = useTypedSelector(
		({ userState }) => userState
	);
	const { isAdmin, isAgentN } = useEntity();
	const { departmentEnum: department } = useDepartment();
	const { isEspace3 } = useEspace();

	if (!authenticated || !user) return <Navigate replace to="/" />;

	const hasPathRole = (): boolean => {
		const { roles } = user;
		if (!roles || roles.length === 0) return false;
		const routeConf = Object.entries(routesConf).find(
			([route_endpoint]) => route_endpoint === path
		);
		return roles.some((role) => routeConf?.[1].authorized.includes(role.id));
	};

	const hasPathDepartment = (): boolean => {
		const { departments } = user;
		if (!departments || departments.length === 0) return false;
		const routeInfo = Object.entries(routesConf).find(
			([route_endpoint]) => route_endpoint === path
		);
		return departments.some((department) =>
			routeInfo?.[1].departments.includes(department.id)
		);
	};

	const getHeader = () => {
		if (!isAgentN) return <NavigatorTablet />;
		return <NavigatorPhone />;
	};

	if ((hasPathDepartment() && hasPathRole()) || (isEspace3 && isAdmin)) {
		if (!hasRole(user, [RoleEnum.AGENTN]))
			localStorageFuncs.set(
				`last_location_${department}_${user.roles[0].id}` as LocalStorageEntries,
				window.location.pathname
			);
		return (
			<>
				{getHeader()}
				<Component />
			</>
		);
	}
	return <Navigate replace to="/" />;
};

export default PrivateRoute;

type Props = { path: Endpoints; Component: any };
