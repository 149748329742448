import { initReportingState } from '../config/initialState';
import {
	ReportingActionTypes as ActionTypes,
	ServiceReporting,
	SET_REPORTING_INCIDENTS,
	SET_REPORTING_LINE,
	SET_REPORTING_NCNP,
	SET_REPORTING_RS_COUNT,
	SET_REPORTING_UNIT_COUNT,
	SET_REPORTING_UNIT_STAT,
} from '../types/reporting.types';

export const reportingReducer = (
	state = initReportingState,
	action: ActionTypes
) => {
	switch (action.type) {
		case SET_REPORTING_INCIDENTS:
			return {
				...state,
				incidents: action.payload.incidents,
			};
		case SET_REPORTING_NCNP:
			return {
				...state,
				services: handlers[SET_REPORTING_NCNP](
					state.services,
					action.payload.services
				),
			};
		case SET_REPORTING_LINE:
			return {
				...state,
				selectedLine: action.payload.selectedLine,
			};
		case SET_REPORTING_RS_COUNT:
			return {
				...state,
				rsCount: action.payload.count,
			};
		case SET_REPORTING_UNIT_COUNT:
			return {
				...state,
				unitCount: action.payload.count,
			};
		case SET_REPORTING_UNIT_STAT:
			return {
				...state,
				unitsStats: action.payload.stat,
			};
		default:
			return state;
	}
};

const handlers = {
	SET_REPORTING_NCNP(
		services: ServiceReporting[],
		newServices: ServiceReporting[]
	) {
		return services.map((sr) => {
			const newService = newServices.find((el) => el.type === sr.type);
			if (newService) return { ...newService };
			else return { ...sr };
		});
	},
};

export default reportingReducer;
