import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';

import DialogBox from '../../components/dialogBox';
import Header from '../../components/header';
import ReportingLineSelector from '../../components/lineSelector';
import SettingDayNight from '../../components/setting/settingDayNight';
import SettingLastMaL from '../../components/setting/settingLastMaL';
import SettingLastNP from '../../components/setting/settingLastNP';
import SettingMonthlyService from '../../components/setting/settingMonthlyService/index';
import SettingNotification from '../../components/setting/settingNotification';
import {
	DayNightEnum,
	defaultDayNightIncidents,
	defaultDayNightServices,
	defaultNotificationIncidents,
	defaultNotificationServices,
	NotificationRole,
	Role,
	SettingDayNightIncident,
	SettingDayNightService,
	SettingNotificationIncident,
	SettingNotificationService,
} from '../../models';
import {
	CANCEL,
	SET_SUBTITLE,
	SET_TITLE,
	VALIDATE,
} from '../../packages/helpers';
import {
	useDepartment,
	useEntity,
	useLine,
	useTypedSelector,
} from '../../packages/hooks';
import useCallPrompt from '../../packages/hooks/usePrompt';
import { Button, Divider, SingleButtonsBloc } from '../../packages/ui';
import { setSetting } from '../../redux/actions';
import { getRoles } from '../../redux/thunks/roles';
import { loadSetting, saveSetting } from '../../redux/thunks/setting';

import 'rc-slider/assets/index.css';

const SettingPage = () => {
	const dispatch = useDispatch();
	const { userTs, isAdmin } = useEntity();
	const { selectedLine } = useLine();
	const { hasMtsDepartment } = useDepartment();
	const setting = useTypedSelector((state) => state.settingState);
	const roles = useTypedSelector((state) => state.roleState.roles);

	const [npRangeValues, setNpRangeValues] = useState<number[]>([0, 0, 50]);
	const [malRangeValues, setMalRangeValues] = useState<number[]>([0, 0, 50]);
	const [monthlyNP, setMonthlyNP] = useState<number>(setting.npTarget);
	const [monthlyNC, setMonthlyNC] = useState<number>(setting.ncTarget);
	const [monthlyNCR, setMonthlyNCR] = useState<number>(setting.ncrTarget);

	const [dayNightServices, setDayNightServices] = useState<
		SettingDayNightService[]
	>(defaultDayNightServices);
	const [dayNightIncidents, setDayNightIncidents] = useState<
		SettingDayNightIncident[]
	>(defaultDayNightIncidents);
	const [notificationServices, setNotificationServices] = useState<
		SettingNotificationService[]
	>(defaultNotificationServices);
	const [notificationIncidents, setNotificationIncidents] = useState<
		SettingNotificationIncident[]
	>(defaultNotificationIncidents);
	const [settingIsModified, setSettingIsModified] = useState(false);

	const { showPrompt, saveAndQuit, quitWithoutSaving } = useCallPrompt(
		settingIsModified,
		handleSave
	);

	useEffect(() => {
		dispatch(loadSetting(selectedLine));
		dispatch(getRoles());
	}, [dispatch, selectedLine]);

	useEffect(() => {
		refreshNotificationRoleServices();
		refreshNotificationRoleIncidents();
	}, [roles]);

	const getNameByRole = (incidentRoles: NotificationRole[]) => {
		return incidentRoles.forEach((role) => {
			const newRole = roles.find((r) => r.id === role.roleId);
			if (newRole) role.name = newRole.name;
		});
	};

	const refreshNotificationRoleIncidents = useCallback(() => {
		const newNotificationIncidents = cloneDeep(notificationIncidents);
		newNotificationIncidents.forEach((incident) => {
			getNameByRole(incident.roles);
		});
		setNotificationIncidents(newNotificationIncidents);
	}, [roles]);

	const refreshNotificationRoleServices = useCallback(() => {
		if (isEmpty(roles)) return;

		const newNotificationService = cloneDeep(notificationServices);
		updateRolesInNotificationServices(newNotificationService, roles);
	}, [roles]);

	const updateRolesInNotificationServices = (
		notificationServices: SettingNotificationService[],
		roles: Role[]
	) => {
		notificationServices.forEach((service) => {
			updateRolesInService(service, roles);
		});
	};

	const updateRolesInService = (
		service: SettingNotificationService,
		roles: Role[]
	) => {
		service.roles.forEach((role) => {
			updateRoleName(role, roles);
		});
	};

	const updateRoleName = (role: NotificationRole, roles: Role[]) => {
		const newRole = roles.find((r) => r.id === role.roleId);
		if (newRole) role.name = newRole.name;
	};

	const refreshDayNightServices = useCallback(async () => {
		let newDayNightServices = cloneDeep(dayNightServices);
		newDayNightServices = newDayNightServices.map((service) => {
			const newService = setting?.dayNightServices?.find(
				(s: SettingDayNightService) =>
					s.serviceTypeId === service.serviceTypeId && s.id
			);
			if (newService) {
				return newService;
			} else {
				const dayNightService = cloneDeep(service);
				dayNightService.dayNightType = DayNightEnum.DAYNIGHT;
				return dayNightService;
			}
		});
		setDayNightServices(newDayNightServices);
	}, [dayNightServices, setting]);

	const refreshDayNightIncidents = useCallback(async () => {
		if (setting.dayNightIncidents?.length === 0) {
			setDayNightIncidents(defaultDayNightIncidents);
			return;
		}

		let newDayNightIncidents = cloneDeep(dayNightIncidents);
		newDayNightIncidents = newDayNightIncidents.map(
			(incident) =>
				setting?.dayNightIncidents?.find(
					(i) => i.incidentTypeId === incident.incidentTypeId && i.id
				) ?? incident
		);
		setDayNightIncidents(newDayNightIncidents);
	}, [dayNightIncidents, setting]);

	const getNewRoles = (
		IncidentOrServiceRoles: NotificationRole[],
		foundRoles: NotificationRole[]
	) => {
		return IncidentOrServiceRoles.map(
			(role) => foundRoles.find((r) => r.roleId === role.roleId) ?? role
		);
	};

	const refreshNotificationServices = useCallback(async () => {
		if (
			isEmpty(setting.notificationServices) &&
			setting.notificationServices === undefined
		)
			return;
		if (setting.notificationServices.length === 0) {
			setNotificationServices(defaultNotificationServices);
			return;
		}
		let newNotificationServices = cloneDeep(notificationServices);

		newNotificationServices = newNotificationServices.map((service) => {
			const serviceFound = setting.notificationServices.find(
				(s: SettingNotificationService) =>
					s.serviceTypeId === service.serviceTypeId
			);
			if (serviceFound) {
				const newService = cloneDeep(serviceFound);
				const newRoles = getNewRoles(service.roles, serviceFound.roles);
				newService.roles = cloneDeep(newRoles);
				return newService;
			}
			return service;
		});
		setNotificationServices(newNotificationServices);
	}, [notificationServices, setting.notificationServices]);

	const refreshNotificationIncidents = useCallback(async () => {
		if (
			isEmpty(setting.notificationIncidents) &&
			setting.notificationIncidents === undefined
		)
			return;
		let newNotificationIncidents = cloneDeep(notificationIncidents);
		newNotificationIncidents = newNotificationIncidents.map((incident) => {
			const incidentFound = setting.notificationIncidents.find(
				(a: SettingNotificationIncident) =>
					a.incidentTypeId === incident.incidentTypeId
			);
			if (incidentFound) {
				const newIncident = cloneDeep(incidentFound);
				const newRoles = getNewRoles(incident.roles, incidentFound.roles);
				newIncident.roles = cloneDeep(newRoles);
				return newIncident;
			} else {
				const newIncident = cloneDeep(incident);
				newIncident.isEnabled = false;
				return newIncident;
			}
		});
		setNotificationIncidents(newNotificationIncidents);
	}, [notificationIncidents, setting]);

	useEffect(() => {
		setNpRangeValues([setting?.npLowLimit, setting?.npHighLimit, 50]);
		setMalRangeValues([setting?.malLowLimit, setting?.malHighLimit, 50]);
		setMonthlyNP(setting.npTarget);
		setMonthlyNC(setting.ncTarget);
		setMonthlyNCR(setting.ncrTarget);
		refreshDayNightServices().catch((error) => {
			console.error(
				'an error occured while refreshing day night services',
				error
			);
		});
		refreshDayNightIncidents().catch((error) => {
			console.error(
				'an error occured while refreshing day night incident',
				error
			);
		});
		refreshNotificationServices().catch((error) => {
			console.error(
				'an error occured while refreshing notification service',
				error
			);
		});
		refreshNotificationIncidents().catch((error) => {
			console.error(
				'an error occured while refreshing notification incident',
				error
			);
		});
		setSettingIsModified(false);
	}, [setting, selectedLine]);

	function handleRadioChange(lowLimit: number, highLimit: number) {
		setNpRangeValues([lowLimit, highLimit, 50]);
		setSettingIsModified(true);
	}

	function handleRadioMaLChange(lowMalLimit: number, highMalLimit: number) {
		setMalRangeValues([lowMalLimit, highMalLimit, 50]);
		setSettingIsModified(true);
	}

	function handleSave(): void {
		const newSetting = { ...setting };
		newSetting.line = selectedLine ?? '';
		newSetting.npLowLimit = npRangeValues[0];
		newSetting.npHighLimit = npRangeValues[1];
		newSetting.malLowLimit = malRangeValues[0];
		newSetting.malHighLimit = malRangeValues[1];
		newSetting.ncTarget = monthlyNC;
		newSetting.ncrTarget = monthlyNCR;
		newSetting.npTarget = monthlyNP;
		newSetting.dayNightServices = dayNightServices;
		newSetting.dayNightIncidents = dayNightIncidents;
		newSetting.notificationServices = notificationServices;
		newSetting.notificationIncidents = notificationIncidents;
		dispatch(setSetting(newSetting));
		dispatch(saveSetting(newSetting));
		setSettingIsModified(false);
	}

	function handleCancel(): void {
		if (setting) {
			setNpRangeValues([setting.npLowLimit, setting.npHighLimit, 50]);
			setMalRangeValues([setting.malLowLimit, setting.malHighLimit, 50]);
			setMonthlyNP(setting.npTarget);
			setMonthlyNC(setting.ncTarget);
			setMonthlyNCR(setting.ncrTarget);
			setDayNightServices(setting.dayNightServices);
			setDayNightIncidents(setting.dayNightIncidents);
			setNotificationServices(setting.notificationServices);
			setNotificationIncidents(setting.notificationIncidents);
		}
		setSettingIsModified(false);
	}

	return (
		<main>
			<Header title={SET_TITLE} subTitle={SET_SUBTITLE} />
			<div className="content">
				{isAdmin && <ReportingLineSelector />}

				<Typography variant="h5">Nombre de nettoyages</Typography>
				<Typography variant="subtitle1">
					Ajustez le nombre de prestations mensuelles tel que défini dans le
					marché
				</Typography>
				<SettingMonthlyService
					ncrTarget={monthlyNCR}
					ncTarget={monthlyNC}
					npTarget={monthlyNP}
					onNCChange={setMonthlyNC}
					onNCRChange={setMonthlyNCR}
					onNPChange={setMonthlyNP}
					setSettingIsModified={setSettingIsModified}
				/>
				<Divider />

				<SettingLastNP
					handleNpRangeChange={handleRadioChange}
					npRangeValues={npRangeValues}
				/>

				<Divider />

				<SettingLastMaL
					handleMalRangeChange={handleRadioMaLChange}
					malRangeValues={malRangeValues}
				/>

				<Divider />
				{hasMtsDepartment && (
					<>
						<SettingNotification
							handleNotificationIncidentsChange={setNotificationIncidents}
							handleNotificationServicesChange={setNotificationServices}
							notificationIncidents={notificationIncidents}
							notificationServices={notificationServices}
							npAlert={npRangeValues[1]}
							setSettingIsModified={setSettingIsModified}
						/>

						<Divider />
					</>
				)}

				<SettingDayNight
					dayNightIncidents={dayNightIncidents}
					dayNightServices={dayNightServices}
					handleIncidentsChange={setDayNightIncidents}
					handleServicesChange={setDayNightServices}
					setSettingIsModified={setSettingIsModified}
					user={userTs}
				/>

				<SingleButtonsBloc>
					<Button
						disabled={!settingIsModified}
						onClick={handleCancel}
						variant="text"
					>
						{CANCEL}
					</Button>
					<Button disabled={!settingIsModified} onClick={handleSave}>
						{VALIDATE}
					</Button>
				</SingleButtonsBloc>
			</div>
			<DialogBox
				handleCancel={quitWithoutSaving}
				handleConfirm={saveAndQuit}
				showDialog={showPrompt}
			/>
		</main>
	);
};

export default SettingPage;
