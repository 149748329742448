import { styled } from '@mui/system';

export const StyledContentContainer = styled('div')({
	margin: '2rem auto',
	width: '50%',
});

export const StyledFieldContainer = styled('div')({
	margin: '1rem 0',
});
