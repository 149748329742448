import {
	IncidentReport,
	Position,
	ServiceStatus,
	ServiceTypeEnum,
	SettingDayNightService,
} from '../../models';
import { SpecialOperation } from '../../models/specialOperation.model';
import { ServiceDTO } from '../../packages/dataProviders';
import {
	RESET_SERVICE_STATUS,
	RESET_SURFACE,
	SelectionByAgentN,
	ServiceActionTypes,
	SET_DAY_STATUSES,
	SET_NIGHT_STATUSES,
	SET_SELECTION,
	SET_SERVICE_STATUS,
	SET_SURFACE,
} from '../types';

export const resetServiceStatus = (): ServiceActionTypes => ({
	type: RESET_SERVICE_STATUS,
});

export const resetSurface = (): ServiceActionTypes => ({
	type: RESET_SURFACE,
});

export const setDayServiceStatus = (
	dayNightServices: SettingDayNightService[]
): ServiceActionTypes => ({
	dayNightServices,
	type: SET_DAY_STATUSES,
});

export const setNightServiceStatus = (
	ServicesDTOS: ServiceDTO[],
	dayNightServices: SettingDayNightService[]
): ServiceActionTypes => ({
	dayNightServices,
	ServicesDTOS,
	type: SET_NIGHT_STATUSES,
});

export const setSelection = (
	selection: SelectionByAgentN
): ServiceActionTypes => ({
	payload: selection,
	type: SET_SELECTION,
});

export const setServiceStatus = (
	status: ServiceStatus,
	service: ServiceTypeEnum
): ServiceActionTypes => ({
	payload: {
		status,
		typeService: service,
	},
	type: SET_SERVICE_STATUS,
});

export const setSurface = (params: {
	trainId?: string;
	equipment?: string;
	trainCode?: string;
	incidents?: IncidentReport[];
	position?: Position;
	sequenceId?: number;
	stationId?: number;
	stationName?: string;
	lineId?: string;
	unitName?: string;
	specialOperations?: SpecialOperation[];
}): ServiceActionTypes => ({
	payload: params,
	type: SET_SURFACE,
});

export const serviceActions = {
	resetSurface,
	setNightServiceStatus,
	setServiceStatus,
	setSurface,
};

export default serviceActions;
