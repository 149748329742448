import { EspaceEnum, Role, RoleEnum, UserCredential } from '../../../models';
import {
	CRD_FORM_ERR_LINE,
	CRD_FORM_ERR_ORGANISATION,
	CRD_FORM_ERR_REGISTRATION_NUMBER,
	CRD_FORM_ERR_ROLE,
} from '../../../packages/helpers';

export const validate = (credentialValues: UserCredential) => {
	const errors = {} as { [key in string]: string };
	const hasAllLines = isAllowedAllLines(
		credentialValues.currentEspace,
		credentialValues.role?.id
	);

	const isEmptyField = (field: string | undefined) =>
		field?.trim() === '' || field === undefined;

	const { organisation, registration_number, role, lines } = credentialValues;
	if (isEmptyField(organisation))
		errors.organisation = CRD_FORM_ERR_ORGANISATION;
	if (!role) errors.role = CRD_FORM_ERR_ROLE;
	if (
		(lines === undefined || lines.length === 0 || lines[0] === '') &&
		!hasAllLines
	)
		errors.lines = CRD_FORM_ERR_LINE;
	if (isEmptyField(registration_number))
		errors.registration_number = CRD_FORM_ERR_REGISTRATION_NUMBER;
	return errors;
};

const isAllowedAllLines = (espace: EspaceEnum = 5, role?: RoleEnum) => {
	if (role === undefined) return false;
	const { ADMIN, PRDD, CSDD, AGENTN, AGENTM } = RoleEnum;
	return (
		[ADMIN, PRDD, CSDD].includes(role) ||
		(espace === 3 && [AGENTN, AGENTM].includes(role))
	);
};

export const getFormattedCredential = (
	values: UserCredential,
) => {
	const hasAllLines = isAllowedAllLines(values.currentEspace, values.role?.id);
	const credentialRoles = [values.role];
	const credentialLines = hasAllLines ? '' : values.lines?.join(',');
	delete values.lines;
	const credentialRegistrationNumber = values.registration_number.toUpperCase();

	return {
		...values,
		line_id: credentialLines,
		registration_number: credentialRegistrationNumber,
		roles: credentialRoles,
	};
};

export const getEmptyCredential = (
	espace: EspaceEnum,
	selectedLine?: string
): UserCredential => ({
	currentEspace: espace,
	id: null,
	line_id: selectedLine ?? '',
	lines: selectedLine?.split(',') ?? [],
	organisation: '',
	registration_number: '',
	role: {
		code: '',
		id: null,
		name: '',
	} as unknown as Role,
	roles: [],
});
