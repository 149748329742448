import { initServiceTypeState } from '../config/initialState';
import {
	ServiceTypeActionTypes as ActionTypes,
	ServiceTypeState as State,
	SET_SERVICE_TYPE,
} from '../types';

export const serviceTypeReducer = (
	state = initServiceTypeState,
	action: ActionTypes
): State => {
	if (action.type === SET_SERVICE_TYPE) {
		return {
			...state,
			serviceTypesCreate: action.payload.serviceTypesCreate,
			serviceTypesDelete: action.payload.serviceTypesDelete,
			serviceTypesExport: action.payload.serviceTypesExport,
		};
	}
	return state;
};

export default serviceTypeReducer;
