import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import {
	AvailabilityPosition,
	IncidentSelected,
	ServiceTypeEnum,
} from '../../../../models';
import { Availability } from '../../../../models/availability.model';
import { AvailabilitySite } from '../../../../models/site.model';
import AvailabilityProvider from '../../../../packages/dataProviders/resources/availability';
import {
	RSA_CREATE_FORM_SUBTITLE,
	RSA_CREATE_FORM_TITLE,
} from '../../../../packages/helpers/explanations/rollingStockAvailability';
import { ModalesState, useForm, useLine } from '../../../../packages/hooks';
import { Dialog } from '../../../../packages/ui';
import PopInFooterWithGoBack from '../../../../packages/ui/molecules/PopInFooterWithGoBack';
import { createAvailability } from '../../../../redux/thunks/availability';
import {
	getEmptyRollingStockAvailability,
	validate,
} from '../../../availability/config';
import { StyledServiceRender } from '../../../trackingBoard/list/styles';
import ModalHeader from '../../header';
import AvailabilityFormContent from '../availabilityFormContent';

const RollingStockAvailabilityCreateForm = ({
	colorNp,
	daysCount,
	incidentSelected,
	isEditableMode = true,
	open,
	rsId,
	setIncidentSelected,
	setIsEditableMode,
	handleCloseModal,
	handleOpenModal,
	trainId,
	trainNumber,
}: RollingStockAvailabilityCreateFormProps) => {
	const [sites, setSites] = useState<AvailabilitySite[]>([]);
	const [positions, setPositions] = useState<AvailabilityPosition[]>([]);
	const { selectedLine } = useLine();
	const dispatch = useDispatch();

	const handleClose = () => {
		setIncidentSelected(null);
		handleCloseModal();
		setIsEditableMode(false);
	};

	const getIncidentSelectedName = (): string => {
		return incidentSelected?.incident?.name ?? '';
	};

	const handleCreateAvailability = () => {
		const availability: Availability = {
			positionId: values.position ? values.position.id : undefined,
			rollingStockId: trainId,
			siteId: values.site.id,
		};
		const code = incidentSelected?.incident?.code;
		switch (code) {
			case 'NP':
				availability.serviceTypeId = ServiceTypeEnum.NP;
				break;
			case code?.startsWith('OP') ? code : '':
				const specialOperationTypeId = parseInt(code.split('-')[1]);
				availability.specialOperationTypeId = specialOperationTypeId;
				break;
			default:
				availability.incidentTypeId = incidentSelected?.incident?.id;
				break;
		}

		dispatch(
			createAvailability(
				[availability],
				selectedLine,
				getIncidentSelectedName()
			)
		);
		handleClose();
	};

	const { errors, handleSelect, handleSubmit, isUntouched, values, setValues } =
		useForm({
			callback: handleCreateAvailability,
			initFormValues: getEmptyRollingStockAvailability(),
			validate,
		});

	useEffect(() => {
		if (selectedLine)
			AvailabilityProvider.loadAvailabilitySites(selectedLine).then(
				({ data: sites }) => setSites(sites)
			);
	}, [selectedLine]);

	useEffect(() => {
		if (values.site) {
			setValues({ ...values, position: undefined });
			AvailabilityProvider.loadAvailabilityPositions(values.site.id).then(
				({ data: positions }) => setPositions(positions)
			);
		}
	}, [values.site]);

	return (
		<Dialog
			open={open}
			titleContent={`${RSA_CREATE_FORM_TITLE} ${getIncidentSelectedName()}`}
			content={
				<>
					<ModalHeader>
						<div className="incident-header-train-number">
							<Typography variant="h6">{trainNumber}</Typography>
							<Typography variant="h5">{rsId}</Typography>
						</div>
						{!isEmpty(daysCount) && (
							<div className="lastNp-header">
								<Typography variant="h6">{'Dernier NP:'}</Typography>
								<StyledServiceRender color={colorNp}>
									<Typography variant="h5">{daysCount}</Typography>
								</StyledServiceRender>
							</div>
						)}
					</ModalHeader>
					<Grid
						container
						spacing={3}
						paddingTop={5}
						paddingBottom={4}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<Grid item xs={12}>
							<Typography variant="h5">{RSA_CREATE_FORM_SUBTITLE}</Typography>
						</Grid>
						<Grid item xs={12}>
							<AvailabilityFormContent
								availability={values}
								isEditableMode={isEditableMode}
								errors={errors}
								handleSelect={handleSelect}
								positions={positions}
								sites={sites}
								values={values}
							/>
						</Grid>
					</Grid>
				</>
			}
			actionsContent={
				<PopInFooterWithGoBack
					onCancel={handleClose}
					onValidate={handleSubmit}
					handleOpenModal={handleOpenModal}
					validateDisabled={isUntouched}
				/>
			}
			onClose={handleClose}
		/>
	);
};

export default RollingStockAvailabilityCreateForm;

type RollingStockAvailabilityCreateFormProps = {
	daysCount: string;
	colorNp: string;
	incidentSelected: IncidentSelected | null;
	isEditableMode?: boolean;
	open: boolean;
	setIncidentSelected: Dispatch<SetStateAction<IncidentSelected | null>>;
	setIsEditableMode: Dispatch<SetStateAction<boolean>>;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	rsId: string | undefined;
	trainId: number;
	trainNumber: string;
};
