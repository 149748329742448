import { RsService } from '../../components/modals/deleteServicePopIn';

export type DeleteServicePopInState = {
	meta: {
		validated: boolean;
		open: boolean;
	};
	services: RsService[];
	serviceStartDate: string;
	serviceEndDate: string;
	serviceTypeId: string;
	serviceTrainCode: string;
	checkedItems: number[];
	serviceToDelete: RsService | undefined;
};

export const CLOSE_DELETE_SERVICE_POPIN = 'CLOSE_DELETE_SERVICE_POPIN';
export const OPEN_DELETE_SERVICE_POPIN = 'OPEN_DELETE_SERVICE_POPIN';
export const SET_SERVICES_DELETE_SERVICES_POPIN =
	'SET_SERVICES_DELETE_SERVICES_POPIN';
export const SET_SERVICE_START_DATE_DELETE_SERVICES_POPIN =
	'SET_SERVICE_START_DATE_DELETE_SERVICES_POPIN';
export const SET_SERVICE_END_DATE_DELETE_SERVICES_POPIN =
	'SET_SERVICE_END_DATE_DELETE_SERVICES_POPIN';
export const SET_SERVICE_TYPE_DELETE_SERVICES_POPIN =
	'SET_SERVICE_TYPE_DELETE_SERVICES_POPIN';
export const SET_SERVICE_TRAIN_CODE_DELETE_SERVICES_POPIN =
	'SET_SERVICE_TRAIN_CODE_DELETE_SERVICES_POPIN';
export const SET_CHECKED_SERVICES_DELETE_SERVICES_POPIN =
	'SET_CHECKED_SERVICES_DELETE_SERVICES_POPIN';
export const SET_SERVICE_TO_DELETE_DELETE_SERVICE_POPIN =
	'SET_SERVICE_TO_DELETE_DELETE_SERVICE_POPIN';

export interface CloseDeleteServicePopInAction {
	type: typeof CLOSE_DELETE_SERVICE_POPIN;
}
export interface OpenDeleteServicePopInAction {
	type: typeof OPEN_DELETE_SERVICE_POPIN;
}
export interface SetServicesDeleteServicesPopIn {
	type: typeof SET_SERVICES_DELETE_SERVICES_POPIN;
	payload: RsService[];
}
export interface SetServiceStartDateDeleteServicesPopIn {
	type: typeof SET_SERVICE_START_DATE_DELETE_SERVICES_POPIN;
	payload: string;
}
export interface SetServiceEndDateDeleteServicesPopIn {
	type: typeof SET_SERVICE_END_DATE_DELETE_SERVICES_POPIN;
	payload: string;
}
export interface SetServiceTypeDeleteServicesPopIn {
	type: typeof SET_SERVICE_TYPE_DELETE_SERVICES_POPIN;
	payload: string;
}
export interface SetServiceTrainCodeDeleteServicesPopIn {
	type: typeof SET_SERVICE_TRAIN_CODE_DELETE_SERVICES_POPIN;
	payload: string;
}
export interface SetCheckedServicesDeleteServicesPopIn {
	type: typeof SET_CHECKED_SERVICES_DELETE_SERVICES_POPIN;
	payload: number[];
}
export interface SetServiceToDeleteDeleteServicesPopIn {
	type: typeof SET_SERVICE_TO_DELETE_DELETE_SERVICE_POPIN;
	payload: number;
}

export type DeleteServicePopInActionType =
	| CloseDeleteServicePopInAction
	| OpenDeleteServicePopInAction
	| SetServicesDeleteServicesPopIn
	| SetServiceStartDateDeleteServicesPopIn
	| SetServiceEndDateDeleteServicesPopIn
	| SetServiceTypeDeleteServicesPopIn
	| SetServiceTrainCodeDeleteServicesPopIn
	| SetCheckedServicesDeleteServicesPopIn
	| SetServiceToDeleteDeleteServicesPopIn;
