import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';

import { pluralize } from '../../../../packages/helpers';

const Box = ({ children, isTwoElement, needVerticalDivider }: BoxProps) => {
	return (
		<Grid
			item
			xs={isTwoElement ? 6 : 12}
			height="7.5rem"
			style={{
				backgroundColor: isTwoElement
					? ''
					: 'var(--bg-color-green-dark-opacity)',
				borderRight: needVerticalDivider
					? '1px solid var(--border-color-divider)'
					: '',
			}}
			display="flex"
			flexDirection={isTwoElement ? 'row' : 'column'}
			justifyContent="center"
			alignItems="center"
		>
			{children}
		</Grid>
	);
};

const GraffitiContent = ({
	railcarsCount,
	surface,
	trainsCount,
}: GraffitiContentProps) => {
	const trainsCountText =
		pluralize(trainsCount, 'train') + ' ' + pluralize(trainsCount, 'graffité');

	const railcarsCountText =
		pluralize(railcarsCount, 'voiture') +
		' ' +
		pluralize(railcarsCount, 'déclarée') +
		' ' +
		pluralize(railcarsCount, 'graffitée');

	const surfaceText =
		pluralize(surface, 'estimée') + ' ' + pluralize(surface, 'graffitée');
	return (
		<Grid container>
			<Box>
				<Typography fontSize="2.25rem" fontWeight="bold" marginRight="1rem">
					{trainsCount}
				</Typography>
				<Typography variant="h5">{trainsCountText}</Typography>
			</Box>
			<Box isTwoElement needVerticalDivider>
				<div>
					<Typography fontSize="2.25rem" fontWeight="bold" margin="0 1rem">
						{railcarsCount}
					</Typography>
				</div>
				<div>
					<Typography fontSize="1.125rem">{railcarsCountText}</Typography>
				</div>
			</Box>
			<Box isTwoElement>
				<div>
					<Typography
						fontSize="2.25rem"
						fontWeight="bold"
						marginRight="1rem"
						whiteSpace="nowrap"
						marginLeft="1rem"
					>
						{surface}
						{' m²'}
					</Typography>
				</div>
				<div>
					<Typography fontSize="1.125rem">{surfaceText}</Typography>
				</div>
			</Box>
		</Grid>
	);
};

export default GraffitiContent;

type GraffitiContentProps = {
	trainsCount: number;
	railcarsCount: number;
	surface: number;
};

type BoxProps = {
	children: ReactNode;
	isTwoElement?: boolean;
	needVerticalDivider?: boolean;
};
