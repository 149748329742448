import { DepartmentEnum } from './department.model';
import { IncidentTypeEnum } from './incidentType.model';
import { RoleEnum } from './role.model';
import { ServiceTypeEnum } from './serviceType.model';

export interface Setting {
	id?: number;
	line?: string;
	ncTarget: number;
	ncrTarget: number;
	npTarget: number;
	npLowLimit: number;
	npHighLimit: number;
	malLowLimit: number;
	malHighLimit: number;
	rollingStockDisplayType: RSDisplayTypeEnum;
	dayNightServices: SettingDayNightService[];
	dayNightIncidents: SettingDayNightIncident[];
	notificationServices: SettingNotificationService[];
	notificationIncidents: SettingNotificationIncident[];
}

export interface SummedSetting {
	ncTarget: number;
	ncrTarget: number;
	npTarget: number;
	npLowLimit: number;
	npHighLimit: number;
	malLowLimit: number;
	malHighLimit: number;
}

export interface SettingDayNight {
	id?: number;
	settingId?: number;
	dayNightType: DayNightEnum;
}

export interface SettingDayNightService extends SettingDayNight {
	serviceTypeId: number;
	departments: DepartmentEnum[];
}

export interface SettingDayNightIncident extends SettingDayNight {
	incidentTypeId: IncidentTypeEnum;
}

export interface SettingNotification {
	isEnabled: boolean;
	hour: number;
	freqReminder: number;
	roles: NotificationRole[];
}

export interface SettingNotificationService extends SettingNotification {
	serviceTypeId: number;
}

export interface SettingNotificationIncident extends SettingNotification {
	incidentTypeId: number;
}

export interface NotificationRole {
	isEnabled: boolean;
	roleId: RoleEnum;
	name: string;
}

export enum RSDisplayTypeEnum {
	BY_ID = 0,
	BY_CODE = 1,
}

export enum DayNightEnum {
	DAY = 0,
	NIGHT = 1,
	DAYNIGHT = 2,
	NEVER = 3,
}

export const defaultDayNightServices: SettingDayNightService[] = [
	{
		dayNightType: DayNightEnum.DAYNIGHT,
		departments: [DepartmentEnum.MTS, DepartmentEnum.SEM, DepartmentEnum.RER],
		serviceTypeId: ServiceTypeEnum.NC,
	},
	{
		dayNightType: DayNightEnum.DAYNIGHT,
		departments: [DepartmentEnum.MTS, DepartmentEnum.RER],
		serviceTypeId: ServiceTypeEnum.NCR,
	},
	{
		dayNightType: DayNightEnum.DAYNIGHT,
		departments: [DepartmentEnum.MTS, DepartmentEnum.SEM, DepartmentEnum.RER],
		serviceTypeId: ServiceTypeEnum.NP,
	},
	{
		dayNightType: DayNightEnum.DAYNIGHT,
		departments: [DepartmentEnum.MTS, DepartmentEnum.SEM, DepartmentEnum.RER],
		serviceTypeId: ServiceTypeEnum.MEP,
	},
	{
		dayNightType: DayNightEnum.DAYNIGHT,
		departments: [DepartmentEnum.MTS, DepartmentEnum.SEM, DepartmentEnum.RER],
		serviceTypeId: ServiceTypeEnum.PBS,
	},
];

export const defaultDayNightIncidents: SettingDayNightIncident[] = [
	{
		dayNightType: DayNightEnum.DAYNIGHT,
		incidentTypeId: IncidentTypeEnum.GR,
	},
];

export const defaultNotificationRole: NotificationRole[] = [
	{
		isEnabled: true,
		name: 'Équipe propreté',
		roleId: RoleEnum.PROPRETE,
	},
	{
		isEnabled: true,
		name: 'Transport',
		roleId: RoleEnum.TR,
	},
	{
		isEnabled: true,
		name: 'Agent de maîtrise prestataire',
		roleId: RoleEnum.AGENTM,
	},
	{
		isEnabled: true,
		name: 'Equipe Propreté SNCF',
		roleId: RoleEnum.PROPRETESNCF,
	},
];

export const defaultNotificationServices: SettingNotificationService[] = [
	{
		freqReminder: 0,
		hour: 12,
		isEnabled: false,
		roles: defaultNotificationRole,
		serviceTypeId: ServiceTypeEnum.NP,
	},
];

export const defaultNotificationIncidents: SettingNotificationIncident[] = [
	{
		freqReminder: 0,
		hour: 12,
		incidentTypeId: IncidentTypeEnum.DI,
		isEnabled: false,
		roles: defaultNotificationRole,
	},
	{
		freqReminder: 0,
		hour: 12,
		incidentTypeId: IncidentTypeEnum.GR,
		isEnabled: false,
		roles: defaultNotificationRole,
	},
	{
		freqReminder: 0,
		hour: 12,
		incidentTypeId: IncidentTypeEnum.PB,
		isEnabled: false,
		roles: defaultNotificationRole,
	},
];
