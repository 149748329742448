import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';

import { useMediaQuery } from '../../packages/hooks';
import { Button } from '../../packages/ui';
import ExportBtn from '../../packages/ui/molecules/DataGridPro/ExportBtn';

import './styles.css';

const Header = ({
	buttonLabel,
	buttonLabelMobile,
	setOpen,
	subTitle,
	title,
	dataGridId,
}: HeaderProps) => {
	const { isDesktop } = useMediaQuery();
	const btnLabel = isDesktop ? buttonLabel : buttonLabelMobile ?? buttonLabel;

	return (
		<div
			className="credentials-header-container margin-custom"
			data-testid="header"
		>
			<div className="credentials-header">
				<div>
					<Typography variant="h2">{title}</Typography>
					<Typography variant="subtitle1">{subTitle}</Typography>
				</div>

				<div className="header-container-action-right pr-sm-3">
					{buttonLabel && !!setOpen && (
						<Button label={btnLabel} onClick={() => setOpen(true)} />
					)}
					{dataGridId && <ExportBtn dataGridId={dataGridId} />}
				</div>
			</div>
		</div>
	);
};

export default Header;

type HeaderProps = {
	buttonLabel?: string;
	buttonLabelMobile?: string;
	dataGridId?: string;
	setOpen?: Dispatch<SetStateAction<boolean>>;
	subTitle?: string;
	title: string | null;
};
