import { CloseIcon, DoneIcon } from '../../../../../packages/ui/icons';

const ValidationIcon = ({ hasValue }: ValidationIconProps) => {
	return hasValue ? (
		<DoneIcon
			sx={{
				color: '#00AA91',
				height: '16px',
				paddingRight: '0.5rem',
				width: '16px',
			}}
		/>
	) : (
		<CloseIcon
			sx={{
				color: 'red',
				height: '16px',
				paddingRight: '0.5rem',
				width: '16px',
			}}
		/>
	);
};
export default ValidationIcon;

type ValidationIconProps = {
	hasValue: boolean;
};
