import { Station } from '../../models/station.model';
import { StationUpdateData } from '../../packages/events/types';

export interface StationState {
  stations: Station[];
}

export const SET_STATIONS = 'SET_STATIONS';
export const UPDATE_STATION = 'UPDATE_STATION';

export interface SetStationsActions {
  type: typeof SET_STATIONS;
  payload: { state: Station[] };
}

export interface UpdateStationActions {
  type: typeof UPDATE_STATION;
  payload: { service: StationUpdateData };
}

export type StationActionTypes = SetStationsActions | UpdateStationActions;
