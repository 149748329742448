import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';

import {
	DegradationPosition,
	DegrationSite,
	ImpactedRailcar,
	IncidentComplement,
	IncidentRailcarDTO,
	IncidentReport,
	IncidentSite,
	IncidentTypeEnum,
	Registrant,
} from '../../../../models';
import { INC_IS_BLOCKING_CASE } from '../../../../packages/helpers';
import {
	Checkbox,
	DateTimePicker,
	Input,
	Select,
} from '../../../../packages/ui';
import FilesUploader from '../../../uploader/filesUploader';

import IncidentReportComment from './IncidentReportComment';
import IncidentReportLocation from './IncidentReportLocation';
import IncidentReportRailcar from './IncidentReportRailcar';
import IncidentValidationSendSur from './IncidentValidationSendSur';

const IncidentReportFormContentComponent = ({
	complementOptions,
	degradationSiteOptions,
	errors,
	files,
	handleChange,
	handleCheck,
	handleCheckChange,
	handleCheckOneOf,
	handleDateChange,
	handleDeleteFile,
	handleElementSelectOneOf,
	handleFiles,
	handleSelect,
	incidentReport,
	isEditableForm,
	isIncidentDetails,
	isRer,
	degradationPositionOptions,
	registrantOptions,
	siteOptions,
}: IncidentReportFormContentComponentProps) => {
	let blockCount = 0;
	const {
		surface,
		comment,
		complement,
		creationDate,
		incidentTypeId,
		isBlockingCase = false,
		isOffensive = false,
		sent = false,
		locations,
		impactedRailcar,
		railcars,
		registrant,
		degradationSite,
		degradationPosition,
		site,
	} = incidentReport;
	const bgColor = isIncidentDetails && !isEditableForm ? '#E8E8EA' : 'white';

	const isGR = incidentTypeId === IncidentTypeEnum.GR;
	const isDI = incidentTypeId === IncidentTypeEnum.DI;
	const isPB = incidentTypeId === IncidentTypeEnum.PB;

	const impactedRailcarOptions: ImpactedRailcar[] = [];
	railcars.forEach((railcar, index) => {
		if (isRer)
			impactedRailcarOptions.push({
				id: (index + 0.5) * 100,
				name: index + 0.5,
			});

		impactedRailcarOptions.push({ id: (index + 1) * 100, name: index + 1 });
	});

	return (
		<Grid
			container
			spacing={3}
			paddingTop={2}
			paddingRight={isIncidentDetails ? 30 : 8}
			paddingLeft={8}
			paddingBottom={4}
		>
			<Grid item xs={12}>
				<Typography variant="h5">{++blockCount}. Découverte</Typography>
				<Typography
					style={{ fontSize: '1rem', marginTop: '20px' }}
					variant="subtitle1"
				>
					Lieu de découverte
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Select
					defaultValue={site}
					helperText={errors.site}
					label="Site"
					name="site"
					onChange={handleSelect}
					options={siteOptions}
					value={site}
					disabled={!isEditableForm || isNil(siteOptions)}
					style={{
						backgroundColor: bgColor,
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<Select
					defaultValue={registrant}
					helperText={errors.registrant}
					label="Fonction de l'agent découvreur"
					name="registrant"
					onChange={handleSelect}
					options={registrantOptions}
					required
					value={registrant}
					disabled={!isEditableForm}
					style={{
						backgroundColor: bgColor,
					}}
				/>
			</Grid>

			<Grid item xs={6}>
				<DateTimePicker
					helperText={errors.creationDate}
					label="Date et heure de découverte"
					onChange={(value) => handleDateChange('creationDate', value)}
					required
					maxDate={new Date()}
					value={new Date(creationDate)}
					disabled={isIncidentDetails}
					sx={{
						'& input': {
							backgroundColor: isIncidentDetails ? '#E8E8EA' : 'white',
						},
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<Checkbox
					name="isBlockingCase"
					label={INC_IS_BLOCKING_CASE}
					checked={isBlockingCase}
					onChange={handleCheck}
					disabled={!isEditableForm}
				/>
			</Grid>
			{isGR && (
				<>
					<Grid item xs={12}>
						<Typography
							style={{ fontSize: '1rem', marginTop: '8px' }}
							variant="subtitle1"
						>
							Lieu de la dégradation
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Select
							defaultValue={degradationSite}
							helperText={errors.degradationSite}
							label="Site"
							name="degradationSite"
							onChange={handleSelect}
							options={degradationSiteOptions}
							value={degradationSite}
							disabled={!isEditableForm || isNil(degradationSiteOptions)}
							style={{
								backgroundColor: bgColor,
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Select
							defaultValue={degradationPosition}
							helperText={errors.degradationPosition}
							label="Position"
							name="degradationPosition"
							onChange={handleSelect}
							options={degradationPositionOptions}
							value={degradationPosition}
							disabled={
								!isEditableForm ||
								isNil(degradationSite) ||
								isNil(degradationPositionOptions)
							}
							style={{
								backgroundColor: bgColor,
							}}
						/>
					</Grid>
				</>
			)}
			{isDI && (
				<>
					<Grid item xs={12}>
						<Typography variant="h5">{++blockCount}. Nature</Typography>
					</Grid>
					<Grid item xs={12}>
						<Select
							defaultValue={complement}
							helperText={errors.complement}
							label="Nature"
							name="complement"
							onChange={handleSelect}
							options={complementOptions}
							required={isDI}
							value={complement}
							disabled={!isEditableForm}
							style={{
								backgroundColor: bgColor,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<IncidentReportComment
							bgColor={bgColor}
							comment={comment}
							errors={errors}
							handleChange={handleChange}
							isEditableForm={isEditableForm}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				<Typography variant="h5">{++blockCount}. Détails</Typography>
			</Grid>
			<Grid item xs={12}>
				{!isDI && (
					<IncidentReportComment
						bgColor={bgColor}
						comment={comment}
						errors={errors}
						handleChange={handleChange}
						isEditableForm={isEditableForm}
					/>
				)}
			</Grid>
			{!isEmpty(locations) && (
				<IncidentReportLocation
					errors={errors}
					handleCheckOneOf={handleCheckOneOf}
					isEditableForm={isEditableForm}
					locations={locations}
				/>
			)}
			{!isEmpty(railcars) && (
				<IncidentReportRailcar
					handleElementSelectOneOf={handleElementSelectOneOf}
					incidentReport={incidentReport}
					isEditableForm={isEditableForm}
					isPB={isPB}
					helperText={errors.railcars}
				/>
			)}
			{isGR && (
				<>
					<Grid item xs={12}>
						<Typography
							style={{ fontSize: '1rem', marginTop: '8px' }}
							variant="subtitle1"
						>
							Surface estimée (facultatif)
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: '0px' }}>
						<Input
							value={surface ?? ''}
							name="surface"
							label="Saisir la surface en m2 (ex: 15)"
							onChange={handleChange}
							type="number"
							disabled={!isEditableForm}
							style={{
								backgroundColor: bgColor,
								width: '50%',
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							style={{
								fontSize: '1rem',
								marginTop: '8px',
							}}
							variant="subtitle1"
						>
							Indiquer le nombre de voiture concernée{' '}
							{isRer ? '' : '(facultatif)'}
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: '0px' }}>
						<Select
							name="impactedRailcar"
							defaultValue={impactedRailcar}
							helperText={errors.impactedRailcar}
							label="Nombre de voiture(s) dégradée(s)"
							onChange={handleSelect}
							options={impactedRailcarOptions}
							required={isRer}
							value={impactedRailcar}
							disabled={!isEditableForm}
							style={{
								backgroundColor: bgColor,
								width: '50%',
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{++blockCount}. Spécificités</Typography>
					</Grid>
					<Grid item xs={12}>
						<Checkbox
							name="isOffensive"
							label="Prise en charge prioritaire (caractère injurieux, raciste, religieux, etc)"
							checked={isOffensive}
							onChange={handleCheck}
							disabled={!isEditableForm}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">
							{++blockCount}. Photos{' '}
							<span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
								(10 maximum)
							</span>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<FilesUploader
							files={files}
							name="files"
							handleFiles={handleFiles}
							urlsLink={incidentReport.photos}
							handleDeleteFile={handleDeleteFile}
							disabled={!isEditableForm}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{++blockCount}. Envoi à SUR</Typography>
					</Grid>
					<IncidentValidationSendSur
						handleCheckChange={handleCheckChange}
						photos={incidentReport.photos}
						sent={sent}
						degradationSite={degradationSite}
						impactedRailcar={impactedRailcar}
						surface={surface}
						files={files}
						isEditableForm={isEditableForm}
					/>
				</>
			)}
		</Grid>
	);
};
export default IncidentReportFormContentComponent;

type IncidentReportFormContentComponentProps = {
	isRer?: boolean;
	errors: { [key: string]: string };
	registrantOptions: Registrant[];
	degradationPositionOptions: DegradationPosition[];
	degradationSiteOptions: DegrationSite[];
	isIncidentDetails: boolean;
	siteOptions: IncidentSite[];
	complementOptions: IncidentComplement[];
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheckOneOf: (
		event: React.ChangeEvent<HTMLInputElement>,
		of: string
	) => void;
	handleDateChange: any;
	handleElementSelectOneOf: (element: IncidentRailcarDTO, of: string) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	incidentReport: IncidentReport;
	files?: any;
	isEditableForm?: boolean;
	handleFiles?: any;
	handleDeleteFile: (event: any, filename: string, isFile?: boolean) => void;
};
