import { isEmpty } from 'lodash';

import {
	DepartmentEnum,
	IncidentReport,
	IncidentType,
	IncidentTypeEnum,
} from '../../models';
import {
	INC_FORM_ERR_COMMENT,
	INC_FORM_ERR_COMPLEMENT,
	INC_FORM_ERR_IMPACTED_RAILCAR,
	INC_FORM_ERR_LOCATIONS,
	INC_FORM_ERR_RAILCARS,
	INC_FORM_ERR_REGISTRANT,
} from '../../packages/helpers';
import {
	isEmptyLocationChecked,
	isEmptyRailcarsChecked,
	isTooLongComment,
} from '../../packages/helpers/form.utils';
import { handleCompressImg } from '../../packages/helpers/photo.utils';
import { ValidateOptions } from '../../packages/hooks';

export const validate = (
	incidentReportValues: IncidentReport,
	validateOptions?: ValidateOptions
) => {
	const errors = {} as { [key in string]: string };
	const {
		comment,
		complement,
		locations,
		impactedRailcar,
		railcars,
		registrant,
		incidentTypeId,
	} = incidentReportValues;
	const isGr = incidentTypeId === 0;
	const isDi = incidentTypeId === 1;
	const isPB = incidentTypeId === 2;
	const isRER = validateOptions?.department === DepartmentEnum.RER;

	if (isTooLongComment(comment ?? '')) errors.comment = INC_FORM_ERR_COMMENT;

	if (isEmptyLocationChecked(locations))
		errors.locations = INC_FORM_ERR_LOCATIONS;
	if (isEmpty(registrant)) errors.registrant = INC_FORM_ERR_REGISTRANT;
	if (isDi && isEmpty(complement)) errors.complement = INC_FORM_ERR_COMPLEMENT;
	if (isPB && isEmptyRailcarsChecked(railcars))
		errors.railcars = INC_FORM_ERR_RAILCARS;
	if (isGr && isRER && isEmpty(impactedRailcar))
		errors.impactedRailcar = INC_FORM_ERR_IMPACTED_RAILCAR;
	return errors;
};

export const getIncidentType = (
	incidentsTypes: IncidentType[],
	id: number
): IncidentType | undefined => {
	return incidentsTypes.find((incidentType) => incidentType.id === id);
};

export const getEmptyIncidentReport = (
	incidentTypeId: IncidentTypeEnum | undefined,
	lineId: string
): IncidentReport =>
	({
		comment: '',
		creationDate: new Date(Date.now()),
		files: [],
		incidentTypeId,
		isBlockingCase: false,
		isOffensive: false,
		lineId,
		locations: [],
		photos: [],
		railcars: [],
	} as unknown as IncidentReport);

export const getFormattedIncidentReport = async (
	values: IncidentReport
): Promise<IncidentReport> => {
	const incidentReportPhotos = values.files
		? Object.values(values?.files)
		: undefined;
	let compressedFiles;
	if (incidentReportPhotos !== undefined) {
		compressedFiles = await Promise.all(
			incidentReportPhotos.map(async (file) => await handleCompressImg(file))
		);
	}
	return {
		...values,
		files: (compressedFiles as File[]) ?? undefined,
	};
};
