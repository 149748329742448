import { Divider, Grid, Typography } from '@mui/material';

const TitleDivider = ({ title }: TitleDividerProps) => {
	return (
		<Grid container justifyContent="space-between">
			<Divider
				sx={{
					borderBottomWidth: '2px',
					borderColor: 'var(--bg-color-blue-light)',
					width: '30%',
				}}
			/>
			<Typography variant="h4" fontSize={'1.7rem'}>
				{title}
			</Typography>
			<Divider
				sx={{
					borderBottomWidth: '2px',
					borderColor: 'var(--bg-color-blue-light)',
					width: '30%',
				}}
			/>
		</Grid>
	);
};

type TitleDividerProps = { title: string };

export default TitleDivider;
