import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CredentialDataGrid from '../../components/credentials/credentialDataGrid';
import Header from '../../components/header';
import UserCredentialPopinForm from '../../components/modals/userCredentialForm';
import {
	getEmptyCredential,
	getFormattedCredential,
	validate,
} from '../../components/modals/userCredentialForm/config';
import { User, UserCredential } from '../../models';
import {
	CRD_ADD_BTN,
	CRD_ADD_MOBILE_BTN,
	CRD_SUBTITLE,
	CRD_TITLE,
} from '../../packages/helpers';
import { useEspace, useForm, useLine } from '../../packages/hooks';
import { loadAndSetCredentials } from '../../redux/thunks/credentials';
import { createAndSetUser } from '../../redux/thunks/user';

const Credentials = () => {
	const dispatch = useDispatch();
	const { espace } = useEspace();
	const { selectedLine } = useLine();

	const handleSelectCredential = (credentialSelected: UserCredential) => {
		const { line_id, roles } = credentialSelected;
		setValues({
			...credentialSelected,
			currentEspace: espace,
			lines: line_id?.split(',') || [],
			role: roles[0],
		});
		setOpen(true);
	};

	const handleCreateOrUpdateUser = () => {
		const formattedValues = getFormattedCredential(values);
		dispatch(createAndSetUser(formattedValues as User, espace));
		handleClose();
	};

	const {
		errors,
		handleChange,
		handleClose,
		handleSelect,
		handleSubmit,
		isUntouched,
		open,
		setOpen,
		setValues,
		values,
	} = useForm({
		callback: handleCreateOrUpdateUser,
		initFormValues: getEmptyCredential(espace, selectedLine),
		validate,
	});

	useEffect(() => {
		dispatch(loadAndSetCredentials(espace));
	}, []);

	return (
		<main>
			<Header
				buttonLabel={CRD_ADD_BTN}
				buttonLabelMobile={CRD_ADD_MOBILE_BTN}
				setOpen={setOpen}
				subTitle={CRD_SUBTITLE}
				title={CRD_TITLE}
				dataGridId="habilitations"
			/>
			<div className="content" data-testid="credential-datagrid">
				<CredentialDataGrid handleSelectCredential={handleSelectCredential} />
				<UserCredentialPopinForm
					errors={errors}
					handleChange={handleChange}
					handleSelect={handleSelect}
					handleClose={handleClose}
					handleSubmit={handleSubmit}
					open={open}
					user={values}
					isUntouched={isUntouched}
				/>
			</div>
		</main>
	);
};

export default Credentials;
