import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup as MuiRadioGroup,
} from '@mui/material';

import { RadioGroupProps } from '../../../models/ui';

const RadioGroup = ({
	label,
	name,
	onChange,
	options,
	radioGroupValue,
}: RadioGroupProps) => {
	return (
		<FormControl>
			<Grid container alignItems="center">
				<Grid item lg={2} xs={4}>
					<FormLabel id="radio-buttons-group">{label}</FormLabel>
				</Grid>
				<MuiRadioGroup aria-labelledby="radio-buttons-group" name={name}>
					<Grid container>
						{options?.map(({ id, label, value }) => (
							<Grid item key={id}>
								<FormControlLabel
									value={value}
									control={
										<Radio
											onChange={onChange}
											checked={radioGroupValue === value}
										/>
									}
									label={label}
								/>
							</Grid>
						))}
					</Grid>
				</MuiRadioGroup>
			</Grid>
		</FormControl>
	);
};

export default RadioGroup;
