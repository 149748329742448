import { IncidentTypeRightsList } from '../../models';
import {
	IncidentTypeActionTypes,
	SET_INCIDENT_TYPE,
} from '../types/incidentType.type';

export const setIncidentTypes = (
	incidentTypes: IncidentTypeRightsList
): IncidentTypeActionTypes => ({
	payload: incidentTypes,
	type: SET_INCIDENT_TYPE,
});

export const incidentTypeActions = {
	setIncidentTypes,
};

export default incidentTypeActions;
