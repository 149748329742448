import { ThunkAction } from 'redux-thunk';

import StationProvider from '../../packages/dataProviders/resources/station';
import { errorConf } from '../../packages/helpers';
import { setReportingRSCount } from '../actions';
import { setStations } from '../actions/station.actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { STATION } = components;

type StationThunk = ThunkAction<
	Promise<void>,
	AppStore,
	ApiThunks,
	ActionTypes
>;

export const getStations = (): StationThunk => async (dispatch, _, api) => {
	try {
		dispatch(api._request(STATION));
		const { data: allStations } = await StationProvider.getStations();
		dispatch(setStations(allStations));
	} catch (e) {
		dispatch(api._error(STATION, e));
	}
};

export const getStationsByLine =
	(lineId: string): StationThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(STATION));
			const { data: filteredStation } = await StationProvider.getStationsByLine(
				lineId
			);
			dispatch(setStations(filteredStation));
		} catch (e) {
			dispatch(api._error(STATION, e));
		}
	};

export const loadStationCount =
	(lineId: string): StationThunk =>
	async (dispatch, _, api) => {
		try {
			dispatch(api._request(STATION));
			const { data: countStation } = await StationProvider.getCountStations(
				lineId
			);
			dispatch(setReportingRSCount(Number(countStation)));
			dispatch(api._response(STATION));
		} catch (e) {
			dispatch(api.showFeedBack(errorConf.warn.offlineMode));
			dispatch(api._error(STATION, e));
		}
	};
