export const EXPORT_MODAL_TITLE = 'Exporter';
export const EXPORT_PERIOD_TITLE = '1. Période';
export const EXPORT_DATA_TITLE = '2. Données à exporter';
export const EXPORT_ELEMENTS_TITLE = '3. Matériel roulant';
export const EXPORT_LINES_TITLE = '4. Lignes à exporter';
export const EXPORT_PROFILS_TITLE = " Profils d'intervenants";

export const EXPORT_DATA_SELECT = 'Type de données';
export const EXPORT_DATA_INFORMATION = 'Informations à inclure';

export const DATA_TYPE_SERVICES = 'Prestations';
export const DATA_TYPE_INCIDENTS = 'Anomalies';
export const DATA_TYPE_SPECIAL_OPERATION_TYPES = 'Opérations spéciales';

export const EXPORT_SPECIAL_OPERATION_INFORMATION =
	'Tous les traitements effectués dans la période sélectionnée.';
export const EXPORT_INCIDENT_INFORMATION =
	'Toutes les anomalies résolues dans la période sélectionnée.';

export const EXPORT_FORM_ERR_DATATYPE =
	'Veuillez renseigner un type de données.';
export const EXPORT_FORM_ERR_LINES =
	'Veuillez sélectionner au moins une ligne.';
