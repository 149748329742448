export type ConfirmationPopInState = {
  meta: {
    validated: boolean;
    open: boolean;
  };
};

export const CLOSE_CONFIRMATION_POPIN = 'CLOSE_CONFIRMATION_POPIN';
export const OPEN_CONFIRMATION_POPIN = 'OPEN_CONFIRMATION_POPIN';

export interface CloseConfirmationPopInAction {
  type: typeof CLOSE_CONFIRMATION_POPIN;
}
export interface OpenConfirmationPopInAction {
  type: typeof OPEN_CONFIRMATION_POPIN;
}

export type ConfirmationPopInActionType =
  | CloseConfirmationPopInAction
  | OpenConfirmationPopInAction;
