import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Exit } from '../../assets/icons/frames/exit-app.svg';
import { ReactComponent as Grid } from '../../assets/icons/frames/repeat-grid.svg';
import { CardButton } from '../../packages/ui';
import { authLogout } from '../../redux/thunks/auth';
import { Endpoints } from '../../routes/endpoint.config';

import './styles/logoutPage.css';

const Logout = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { previousPage } = location.state as { previousPage: Endpoints };

	const handleLogout = () => {
		dispatch(authLogout());
	};

	return (
		<section className="lg-container-fix">
			<div className="logout-container-grid logout-svg-container">
				<Grid />
			</div>
			<section className="logout-container">
				<section className="logout-container-main">
					<div className="logout-answer">
						<span className="font-paris">Quitter ?</span>
						<Exit />
					</div>
					<section className="logout-container-button">
						<CardButton
							action={handleLogout}
							bgColor="var(--bg-color-jade)"
							text="oui"
							textColor="#FFFFFF"
						/>
						<Link to={previousPage}>
							<CardButton bgColor="#EAEAEA" text="non" textColor="#D72F6E" />
						</Link>
					</section>
				</section>
			</section>
		</section>
	);
};

export default Logout;
