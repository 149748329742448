import { isEmpty } from 'lodash';

import { useIncidentType } from './useIncidentType';
import { useServiceType } from './useServiceType';
import { useTypedSelector } from './useTypedSelector';

export const useRight = () => {
	const userTs = useTypedSelector(({ userState: { user } }) => user);
	const {
		incidentTypesCreate,
		incidentTypesDelete,
		incidentTypesExport,
		incidentTypesSolve,
	} = useIncidentType();

	const { serviceTypesCreate, serviceTypesDelete, serviceTypesExport } =
		useServiceType();

	const can = (rightTypeTitle: string) => {
		if (userTs?.rights && userTs.rights.length > 0) {
			const filterRights = userTs.rights.filter(
				(rigth) => rigth.title === rightTypeTitle
			);
			return filterRights.length > 0;
		} else return false;
	};

	const canDisplayServiceCreateButon = (): boolean =>
		!isEmpty(serviceTypesCreate);
	const canDisplayServiceDeleteButon = (): boolean =>
		!isEmpty(serviceTypesDelete);

	const canDisplayIncidentCreateButton = (): boolean =>
		!isEmpty(incidentTypesCreate);
	const canDisplayIncidentDeleButton = (): boolean =>
		!isEmpty(incidentTypesDelete);
	const canDisplayIncidentResolveButton = (): boolean =>
		!isEmpty(incidentTypesSolve);

	const canDisplayExportButton = (): boolean => {
		return !isEmpty(incidentTypesExport) || !isEmpty(serviceTypesExport);
	};

	return {
		can,
		canDisplayExportButton,
		canDisplayIncidentCreateButton,
		canDisplayIncidentDeleButton,
		canDisplayIncidentResolveButton,
		canDisplayServiceCreateButon,
		canDisplayServiceDeleteButon,
	};
};
