import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import CardAction from '../../components/cardAction';
import CardActionSpecialOperation from '../../components/cardAction/cardActionSpecialOperation';
import { DayNightEnum } from '../../models';
import { hasSomeDepartments } from '../../packages/helpers';
import {
	useDepartment,
	useEntity,
	useTypedSelector,
} from '../../packages/hooks';
import { Divider, TitleSubtitle } from '../../packages/ui';
import OkButton from '../../packages/ui/Buttons/OkButton';
import { setSelection } from '../../redux/actions';
import { SelectionByAgentN, ServiceOption } from '../../redux/types';
import { Endpoints } from '../../routes/endpoint.config';

import './styles/selectionPage.css';

const Selection = () => {
	const dispatch = useDispatch();
	const [isChoiceSelected, setIsChoiceSelected] = useState(false);
	const [agentSelection, setAgentSelection] = useState<SelectionByAgentN>({});
	const { userTs } = useEntity();
	const { rsLabel } = useDepartment();

	const { trainCode, trainId, services, incidents, specialOperations } =
		useTypedSelector((state) => state.serviceState);
	const setting = useTypedSelector((state) => state.settingState);

	const generateServiceActions = (service: ServiceOption, i: number) => {
		const { type, departments } = service;
		const serviceSetting = setting.dayNightServices.find(
			(el) => el.serviceTypeId === type
		);
		if (
			(userTs && departments && !hasSomeDepartments(userTs, departments)) ||
			serviceSetting?.dayNightType === DayNightEnum.NEVER
		) {
			return null;
		}

		return (
			<CardAction
				key={i}
				isService
				service={service}
				setAgentSelection={setAgentSelection}
				isSelected={agentSelection.service?.type === service.type}
				setIsChoiceSelected={setIsChoiceSelected}
				isChoiceSelected={isChoiceSelected}
			/>
		);
	};

	const groupedIncidents = incidents?.reduce((acc: any, incident) => {
		const { incidentTypeId } = incident;
		if (!acc[incidentTypeId]) {
			acc[incidentTypeId] = [incident];
		} else {
			acc[incidentTypeId].push(incident);
		}
		return acc;
	}, {});

	return (
		<>
			<section className="presta-selection-container">
				<TitleSubtitle
					title={`Qu'avez-vous fait ?`}
					subtitle1={`${rsLabel} n° ${trainCode ?? trainId}`}
				/>
				<section className="presta-title">
					<Typography
						variant="h5"
						color={'#0A0082'}
						fontSize={'15px'}
						style={{ display: 'flex', justifyContent: 'flex-start' }}
						width={'20%'}
					>
						Prestations
					</Typography>
					<Divider />
				</section>

				<section className="presta-selection-card-container">
					{services.map(generateServiceActions)}
				</section>

				<section className="presta-title">
					{Object.keys(groupedIncidents).length !== 0 && (
						<>
							<Typography
								variant="h5"
								color={'#0A0082'}
								fontSize={'15px'}
								style={{ display: 'flex', justifyContent: 'flex-start' }}
								width={'20%'}
								borderBottom={'1px solid #red'}
							>
								Anomalies
							</Typography>
							<Divider />
						</>
					)}
				</section>
				<section className="incident-selection-card-container">
					{groupedIncidents &&
						Object.values(groupedIncidents).map((group: any) => (
							<CardAction
								key={group[0].incidentTypeId}
								incident={group[0]}
								setAgentSelection={setAgentSelection}
								isSelected={
									agentSelection.incident?.incidentTypeId ===
									group[0].incidentTypeId
								}
								setIsChoiceSelected={setIsChoiceSelected}
								isChoiceSelected={isChoiceSelected}
							/>
						))}
				</section>
				<section className="presta-title">
					{!isEmpty(specialOperations) && (
						<>
							<Typography
								variant="h5"
								color={'#0A0082'}
								fontSize={'15px'}
								style={{ display: 'flex', justifyContent: 'flex-start' }}
								width={'20%'}
								borderBottom={'1px solid #red'}
							>
								Opérations spéciales
							</Typography>
							<Divider />
						</>
					)}
				</section>
				<section className="incident-selection-card-container">
					{specialOperations?.map((specialOperation) => (
						<CardActionSpecialOperation
							key={specialOperation.id}
							isSelected={
								agentSelection.specialOperation?.id === specialOperation.id
							}
							setIsChoiceSelected={setIsChoiceSelected}
							specialOperation={specialOperation}
							setAgentSelection={setAgentSelection}
							isChoiceSelected={isChoiceSelected}
						/>
					))}
				</section>
			</section>
			<section className="presta-selection-button-container">
				{isChoiceSelected ? (
					<Link className="code-link" to={Endpoints.AGENTN_CONFIRM}>
						<OkButton
							isValidInput={isChoiceSelected}
							action={() => dispatch(setSelection(agentSelection))}
						/>
					</Link>
				) : (
					<div className="code-link">
						<OkButton isValidInput={isChoiceSelected} />
					</div>
				)}
			</section>
		</>
	);
};

export default Selection;
