import { Role } from '../../../models';
import fetchJson from '../fetch/fetch-json';

const loadRoles = async (): Promise<{
	data: Role[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson('/role');
};

export default { loadRoles };
