import * as passageTypes from '../types/passage.types';

export const setSelectedPassage = (
	passage: number
): passageTypes.SetSelectedPassage => ({
	payload: passage,
	type: passageTypes.SET_SELECTED_PASSAGE,
});

export const passageActions = {
	setSelectedPassage,
};

export default passageActions;
