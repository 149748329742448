import { Dispatch, SetStateAction, useState } from 'react';

import { IncidentTypeEnum, Item } from '../../../../models';
import { IncidentSelected } from '../../../../models/incidentReport.model';
import { ModalesState, useDepartment } from '../../../../packages/hooks';
import { useIncidentType } from '../../../../packages/hooks/useIncidentType';

import IncidentCreateSelect from './IncidentCreateSelect';
import IncidentResolveSelect from './IncidentResolveSelect';

import './styles.css';

const IncidentSelect = ({
	incidentSelected,
	isResolvingIncident,
	open,
	setIncidentSelected,
	setIsResolvingIncident,
	handleCloseModal,
	handleOpenModal,
	trainId,
	rsCode,
}: IncidentSelectProps) => {
	const { hasRerDepartment } = useDepartment();
	const { incidentTypesCreate } = useIncidentType();
	const [isDFSelected, setIsDFSelected] = useState(false);

	const isTouched = !!incidentSelected;
	const trainLabel = `${hasRerDepartment ? "de l'élément :" : 'du train :'}`;
	const trainNumber = `Numéro ${trainLabel}`;

	const handleClickIncident = ({ code, id, name }: Item) => {
		setIsDFSelected(isResolvingIncident && id === IncidentTypeEnum.GR);
		setIncidentSelected({ incident: { code, id, name } });
	};

	const onValidateInput = () => {
		if (!isTouched) return;
		handleCloseModal('incidentSelect');
		handleOpenModal('incidentReportCreateForm');
	};

	const handleClose = () => {
		setIncidentSelected(null);
		handleCloseModal('incidentSelect');
		setIsResolvingIncident(false);
		setIsDFSelected(false);
	};

	const classNameIncident = (incident: Item): string => {
		return `incident-toggle ${
			incident.id === incidentSelected?.incident?.id ? 'incident-selected' : ''
		}`;
	};

	return !isResolvingIncident ? (
		<IncidentCreateSelect
			incidentTypesCreate={incidentTypesCreate}
			isTouched={isTouched}
			classNameIncident={classNameIncident}
			handleClick={handleClickIncident}
			handleClose={handleClose}
			onValidateInput={onValidateInput}
			open={open}
			rsCode={rsCode}
			trainNumber={trainNumber}
		/>
	) : (
		<IncidentResolveSelect
			incidentSelected={incidentSelected}
			isDFSelected={isDFSelected}
			isTouched={isTouched}
			handleClickIncident={handleClickIncident}
			handleClose={handleClose}
			classNameIncident={classNameIncident}
			open={open}
			rsCode={rsCode}
			setIncidentSelected={setIncidentSelected}
			setIsDFSelected={setIsDFSelected}
			setIsResolvingIncident={setIsResolvingIncident}
			trainId={trainId}
			trainNumber={trainNumber}
		/>
	);
};

export default IncidentSelect;

type IncidentSelectProps = {
	incidentSelected: IncidentSelected | null;
	isResolvingIncident: boolean;
	open: boolean;
	setIncidentSelected: Dispatch<SetStateAction<IncidentSelected | null>>;
	setIsResolvingIncident: Dispatch<SetStateAction<boolean>>;
	handleCloseModal: (modalName?: keyof ModalesState) => void;
	handleOpenModal: (modalName: keyof ModalesState) => void;
	trainId?: number;
	rsCode?: string;
};
