import { styled } from '@mui/system';

export const StyledMultipleAvailability = styled('div')(() => ({
	alignItems: 'center',
	backgroundColor: 'white',
	bottom: '0px',
	boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
	display: 'flex',
	justifyContent: 'end',
	minHeight: '68px',
	paddingRight: '3em',
	position: 'sticky',
}));
