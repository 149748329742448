import { AvailabilityPosition } from '../../../models';
import { Availability } from '../../../models/availability.model';
import { AvailabilitySite } from '../../../models/site.model';
import fetchJson from '../fetch/fetch-json';

const createAvailabilities = async (
	availabilities: Availability[],
	line: string
): Promise<{
	data: {
		result: string;
		token?: string;
		message?: string;
		code?: string | number;
		type?: string;
	};
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const options = {
		body: JSON.stringify({
			availabilities,
			line,
		}),
		method: 'POST',
	};
	return await fetchJson(`/availability`, options);
};

const deleteAvailability = async (
	availabilityId: number
): Promise<{
	data: string;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/availability/${availabilityId}`, {
		method: 'DELETE',
	});
};

const loadAvailabilitySites = async (
	line: string
): Promise<{
	data: AvailabilitySite[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const sitesfeched = await fetchJson(`/availability/sites/list?line=${line}`);

	const { data: sites } = sitesfeched;
	return {
		...sitesfeched,
		data: sites as AvailabilitySite[],
	};
};

const loadAvailabilityPositions = async (
	siteId: number
): Promise<{
	data: AvailabilityPosition[];
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const positionsfeched = await fetchJson(
		`/availability/sites/positions/list?siteId=${siteId}`
	);

	const { data: positions } = positionsfeched;
	return {
		...positionsfeched,
		data: positions as AvailabilityPosition[],
	};
};

const updateAvailabilities = async (
	availabilities: Availability[],
	availabilityId: number,
	line: string
): Promise<{
	data: {
		result: string;
		token?: string;
		message?: string;
		code?: string | number;
		type?: string;
	};
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const options = {
		body: JSON.stringify({
			availabilities,
			line,
		}),
		method: 'PUT',
	};
	return await fetchJson(`/availability/${availabilityId}`, options);
};

export default {
	createAvailabilities,
	deleteAvailability,
	loadAvailabilityPositions,
	loadAvailabilitySites,
	updateAvailabilities,
};
