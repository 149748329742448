/* eslint-disable no-mixed-spaces-and-tabs */
import {
	ExceptionDTO,
	RollingStock,
	RollingStockPositionDTO,
} from '../../../models';
import { dateTimeService, transformToValidTrainCode } from '../../helpers/';
import { HttpResponse, RequestBody } from '../apiTemplate';
import ApiService from '../apiTemplate/api';

import {
	ApiRequestUsedUpdate,
	ApiRollingStockResponse,
	Count,
	RollingStockCode,
	RollingstockQuery,
	TrackingBoardDTO,
} from './rollingStock.api.types';

class RollingStockService extends ApiService {
	constructor() {
		super();
		this.endPoint = `${this.endPoint}/v1/rolling-stock`;
		this.credentials = 'include';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/json',
			},
			{
				key: 'Content-Type',
				value: 'application/json',
			},
		]);
	}
	/**
	 * appel à l'api pour les matériels roulants
	 */
	public async loadRollingStock(
		searchedLine?: string
	): Promise<ApiRollingStockResponse> {
		this.setMethod('GET');
		const searchedLineType = searchedLine ? `?lineId=${searchedLine}` : '';
		try {
			const res = await fetch(
				`${this.endPoint}/services${searchedLineType}`,
				this.request()
			);
			const rawData = await res.text();

			const data =
				dateTimeService.parseJsonWithDateStrings<TrackingBoardDTO[]>(rawData);

			const response: ApiRollingStockResponse = data;

			return response;
		} catch (error) {
			throw Error(`load rolling-stock information failed --> ${error}`);
		}
	}

	/**
	 * appel à l'api pour les matériels roulants
	 */
	public async findOneRollingstock(
		query: RollingstockQuery
	): Promise<HttpResponse> {
		this.setMethod('GET');
		if (query.trainCode)
			query.trainCode = transformToValidTrainCode(query.trainCode);
		try {
			const res = await fetch(
				`${this.endPoint}/search${this.getSearchParams(query)}`,
				this.request()
			);
			const isOffline = res.status >= 500;
			return res.ok
				? { message: (await res.json()) as RollingStock, ok: true }
				: {
						isOffline,
						message: isOffline
							? 'offline'
							: ((await res.json()) as ExceptionDTO),
						ok: false,
				  };
		} catch (error) {
			return {
				isOffline: true,
				message: 'offline',
				ok: false,
			};
		}
	}

	/**
	 * Ajouter un changement d'utilisation d'un train dans l'historique d'utilisation
	 */
	public async postUsedHistory(
		trainId: number,
		isUsed: boolean
	): Promise<HttpResponse> {
		this.setMethod('POST');
		try {
			const infoBody = new RequestBody<ApiRequestUsedUpdate>({
				isUsed,
				trainId,
			});
			const res = await fetch(
				`${this.endPoint}/used`,
				this.request(infoBody.requestBody)
			);
			const msg = await res.text();
			return { message: msg, ok: res.ok };
		} catch (error) {
			throw Error(`add used-history of rolling-stock failed --> ${error}`);
		}
	}

	public async createRollingStockPosition(
		position: RollingStockPositionDTO
	): Promise<HttpResponse> {
		this.setMethod('POST');
		try {
			const res = await fetch(
				`${this.endPoint}/position`,
				this.request(position)
			);
			const msg = await res.text();
			return { message: msg, ok: res.ok };
		} catch (error) {
			throw Error(`failed retrieve position information`);
		}
	}

	/**
	 * load rolling stock lines
	 */
	public async loadLines(): Promise<Array<string>> {
		this.setMethod('GET');
		try {
			const res = await fetch(`${this.endPoint}/lines`, this.request());
			return await res.json();
		} catch (error) {
			throw Error(`failed to get line list`);
		}
	}

	/**
	 * get
	 */
	public async getCountRollingStock(
		line?: string,
		department?: string
	): Promise<Count> {
		this.setMethod('GET');
		const lineType = line ?? `all${department}`;
		try {
			const res = await fetch(
				`${this.endPoint}/count/line/${lineType}`,
				this.request()
			);
			return (await res.json()) as Count;
		} catch (error) {
			throw Error(`failed to get line list`);
		}
	}

	/**
	 * Get train codes
	 */
	public async getRollingStockCodes(line: string): Promise<RollingStockCode[]> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/train-code/line/${line}`,
				this.request()
			);
			return (await res.json()) as RollingStockCode[];
		} catch (error) {
			throw Error(`failed to get line list`);
		}
	}
}
export const rollingStockService = new RollingStockService();

