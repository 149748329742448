import { ServiceReporting } from '../../../redux/types/reporting.types';
import {
	dateTimeService,
	getUuid,
	transformToValidTrainCode,
} from '../../helpers/';
import ApiService from '../apiTemplate/api';
import { HttpResponse } from '../apiTemplate/api.types';

import {
	ApiServiceResponse,
	ApiServicesResponse,
	ApiTotalServicesResponse,
	QueriedService,
	ServiceDTO,
	ServiceQuery,
	TotalServiceQuery,
} from './service.api.types';
import { ApiDetailedServicesResponse } from '.';

class ServiceService extends ApiService {
	constructor() {
		super();
		this.endPoint = `${this.endPoint}/v1/service`;
		this.credentials = 'include';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/json',
			},
			{
				key: 'Content-Type',
				value: 'application/json',
			},
		]);
	}

	public async createServices(services: ServiceDTO[]): Promise<HttpResponse> {
		this.setMethod('POST');
		services.forEach((service) => {
			if (service.trainCode) {
				service.trainCode = transformToValidTrainCode(service.trainCode);
			}
		});
		try {
			//const request = this.request<ServiceDTO[]>(services);

			const res = await fetch(
				`${this.endPoint}/list?uuid=${getUuid()}`,
				this.request<ServiceDTO[]>(services)
			);

			if (res.status !== 201) throw Error('server error');
			const msg = await res.text();
			return { message: msg, ok: res.ok };
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { message: 'offline', ok: false };
			} else {
				throw Error(`service creation failed --> ${error}`);
			}
		}
	}

	public async deleteServiceById(serviceId: number): Promise<HttpResponse> {
		this.setMethod('DELETE');
		try {
			const res = await fetch(`${this.endPoint}/${serviceId}`, this.request());
			if (res.status !== 204) throw Error('server error');
			const msg = await res.text();
			return { message: msg, ok: res.ok };
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { message: 'offline', ok: false };
			} else {
				throw Error(`service deletion failed --> ${error}`);
			}
		}
	}

	public async deleteServicesByIds(
		serviceIds: number[],
		serviceTypeIds: string
	): Promise<HttpResponse> {
		this.setMethod('DELETE');
		try {
			const deleteRequests = serviceIds.map((id) =>
				fetch(`${this.endPoint}/${id}?serviceTypeIds=${serviceTypeIds}`, this.request())
			);
			const deleteResponses = await Promise.all(deleteRequests);
			deleteResponses.forEach((resp) => {
				if (resp.status !== 204) throw Error('server error');
			});
			return { message: 'Services deleted successfully', ok: true };
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { message: 'offline', ok: false };
			} else {
				throw Error(`service deletion failed --> ${error}`);
			}
		}
	}

	public async getServicesSince(
		query?: ServiceQuery
	): Promise<ApiServicesResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}${this.getSearchParams(query)}`,
				this.request()
			);

			if (res.status >= 300) return { ok: false, services: [] };

			const rawData = await res.text();
			let data =
				dateTimeService.parseJsonWithDateStrings<QueriedService[]>(rawData);
			data = this.filterNonNightServices(data);

			const response: ApiServicesResponse = { ok: true, services: data };
			return response;
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { ok: true, services: [] };
			} else {
				throw Error(`service creation failed --> ${error}`);
			}
		}
	}

	public async getMostRecentService(
		serviceId: number
	): Promise<ApiServiceResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/${serviceId}/most-recent`,
				this.request()
			);
			if (res.status >= 300) return { ok: false };

			const mostRecentService = (await res
				.clone()
				.json()
				.catch(() => res.text())) as ServiceDTO;
			const response: ApiServiceResponse = {
				ok: true,
				service: mostRecentService,
			};
			return response;
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { ok: true };
			} else {
				console.error("Most recent service's HTTP call failed");
				return { ok: false };
			}
		}
	}

	public async getServicesByQuery(
		query?: ServiceQuery
	): Promise<ApiDetailedServicesResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/most-recent${this.getSearchParams(query)}`,
				this.request()
			);

			if (res.status >= 300) return { ok: false, services: [] };

			const rawData = await res.text();
			const data =
				dateTimeService.parseJsonWithDateStrings<ServiceDTO[]>(rawData);
			const response: ApiDetailedServicesResponse = {
				ok: true,
				services: data,
			};
			return response;
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { ok: true, services: [] };
			} else {
				throw Error(`service creation failed --> ${error}`);
			}
		}
	}

	public async findTotalServices(
		query?: TotalServiceQuery
	): Promise<ApiTotalServicesResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/counting${this.getSearchParams(query)}`,
				this.request()
			);

			if (res.status >= 300) return { ok: false };
			const servicesCount = (await res.json()) as ServiceReporting[];

			const response: ApiTotalServicesResponse = {
				ok: true,
				services: servicesCount,
			};
			return response;
		} catch (error) {
			if (error instanceof TypeError) {
				// fetch returns typeError if network disconnected
				return { ok: true };
			} else {
				throw Error(`service creation failed --> ${error}`);
			}
		}
	}

	private filterNonNightServices(data: QueriedService[]) {
		return data.filter((service) =>
			dateTimeService.isBetween(22, 5, service.date.getHours())
		);
	}
}

export const serviceService = new ServiceService();
