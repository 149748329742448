export enum ServiceTypeEnum {
	NC = 1,
	NP = 2,
	MEP = 3,
	MAL = 7,
	NCR = 8,
	NQ = 9,
	PS = 10,
	PBS = 11,
}

export const ServiceTypeMap: Map<ServiceTypeEnum, string> = new Map([
	[ServiceTypeEnum.NC, 'Nettoyage Courant'],
	[ServiceTypeEnum.NP, 'Nettoyage Patrimonial'],
	[ServiceTypeEnum.MAL, 'Machine à laver'],
	[ServiceTypeEnum.NCR, 'Nettoyage Courant Renforcé'],
	[ServiceTypeEnum.MEP, 'Maintien En Propreté'],
	[ServiceTypeEnum.PBS, 'Nettoyage Pare-Brise'],
]);

export const ServiceTypeCodeMap: Map<ServiceTypeEnum, string> = new Map([
	[ServiceTypeEnum.NC, 'NC'],
	[ServiceTypeEnum.NP, 'NP'],
	[ServiceTypeEnum.MEP, 'MEP'],
	[ServiceTypeEnum.MAL, 'MAL'],
	[ServiceTypeEnum.NCR, 'NCR'],
	[ServiceTypeEnum.NQ, 'NQ'],
	[ServiceTypeEnum.PS, 'PS'],
	[ServiceTypeEnum.PBS, 'PBS'],
]);

export interface ServiceType {
	id: ServiceTypeEnum;
	code: string;
	name: string;
}

export interface ServiceTypeRightsList {
	serviceTypesCreate: ServiceType[];
	serviceTypesExport: ServiceType[];
	serviceTypesDelete: ServiceType[];
}
