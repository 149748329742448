import { DepartmentEnum, User } from '../../models';
import { hasDepartment } from '../helpers/';

import { useTypedSelector } from './useTypedSelector';

const { RER, SEM, MTS } = DepartmentEnum;

const getRsLabel = (department?: DepartmentEnum): string => {
	if (department === RER) return 'élément';
	else if (department === SEM) return 'station';
	else return 'train';
};

const getRsLabelPluralize = (department?: DepartmentEnum): string => {
	if (department === RER) return 'Eléments';
	else if (department === SEM) return 'Stations';
	else return 'Trains';
};

const getRsLabelWithArticle = (
	department?: DepartmentEnum,
	isDefinedArticle = true
): string => {
	if (department === RER) return `${isDefinedArticle ? 'l' : 'd'}'élément`;
	else if (department === SEM)
		return `${isDefinedArticle ? 'la' : 'de'} station`;
	else return `${isDefinedArticle ? 'le' : 'de'} train`;
};

const userHasDepartment = (department: DepartmentEnum, user?: User) => {
	if (!user) return false;
	return hasDepartment(user, department);
};

export const useDepartment = () => {
	const user = useTypedSelector(({ userState: { user } }) => user);

	const hasMtsDepartment = userHasDepartment(MTS, user);
	const hasRerDepartment = userHasDepartment(RER, user);
	const hasSemDepartment = userHasDepartment(SEM, user);

	const getDepartment = (): string | undefined => {
		if (hasMtsDepartment) return 'MTS';
		if (hasRerDepartment) return 'RER';
		if (hasSemDepartment) return 'SEM';
		return undefined;
	};

	const getDepartmentEnum = (): DepartmentEnum | undefined => {
		if (hasMtsDepartment) return MTS;
		if (hasRerDepartment) return RER;
		if (hasSemDepartment) return SEM;
		return undefined;
	};

	const rsLabel = getRsLabel(getDepartmentEnum());
	const rsLabelPluralize = getRsLabelPluralize(getDepartmentEnum());
	const rsLabelwithArticle = getRsLabelWithArticle(getDepartmentEnum());
	const rsLabelwithUndefinedArticle = getRsLabelWithArticle(
		getDepartmentEnum(),
		false
	);

	return {
		allDepartments: user?.departments ?? [],
		department: getDepartment(),
		departmentEnum: getDepartmentEnum(),
		hasMtsDepartment,
		hasRerDepartment,
		hasSemDepartment,
		rsLabel,
		rsLabelPluralize,
		rsLabelwithArticle,
		rsLabelwithUndefinedArticle,
	};
};
