import { styled } from '@mui/system';

export const DualButtonsBloc = styled('div')({
	display: 'flex',
	gap: '1rem',
	justifyContent: 'space-between',
	width: '100%',
});

export const SingleButtonsBloc = styled('div')<{
	justifyContent?: string;
}>(({ justifyContent = 'flex-end' }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	gap: '1rem',
	justifyContent,
	width: '100%',
}));
