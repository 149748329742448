import { sseSource } from './creator';
import { EventType } from './types';
import { handlers } from './';

export function subscribeToEvents(subscribedEvents: EventType[]) {
	subscribedEvents.forEach((subscription) => {
		sseSource.addEventListener(
			subscription,
			handlers[subscription] as EventListener
		);
	});
}
