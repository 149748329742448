import {
	DateTimePicker as MuiPicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import fr from 'date-fns/locale/fr';

import { DateTimePickerProps } from '../../../models/ui';

const DateTimePicker = ({
	name,
	disabled = false,
	disabledFuture,
	disabledPast,
	helperText,
	label,
	value,
	maxDate,
	minDate,
	onChange,
	fullWidth,
	required,
	sx,
}: DateTimePickerProps) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
			<MuiPicker
				disabled={disabled}
				disableFuture={disabledFuture}
				disablePast={disabledPast}
				label={label}
				maxDate={maxDate}
				minDate={minDate}
				onChange={onChange}
				slotProps={{
					textField: {
						fullWidth: fullWidth,
						helperText: helperText,
						name: name,
						required: required,
						size: 'small',
					},
				}}
				value={value ?? new Date()}
				sx={sx}
			/>
		</LocalizationProvider>
	);
};

export default DateTimePicker;
