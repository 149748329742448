import { EspaceEnum } from '../../models';

export type EspaceState = {
	espace: EspaceEnum;
};

export const SWITCH_ESPACE = 'SWITCH_ESPACE';

export interface SwitchEspaceAction {
	type: typeof SWITCH_ESPACE;
	payload: EspaceEnum;
}
export type SwitchEspaceActionTypes = SwitchEspaceAction;
