import { getHours } from 'date-fns';

import { Station, Unit } from '../../models';

import { StatusEnum } from './TrackingBoard/SemStatus/SemStatus';
import {
	SEM_HOUR_CHANGE_PASSAGE_1_2,
	SEM_HOUR_PASSAGE_3,
	SemPassages,
} from './constants';

const { TODO, IN_PROGRESS, DONE } = StatusEnum;
const { PASSAGE_1, PASSAGE_2, PASSAGE_3, PASSAGE_4 } = SemPassages;

export const getStationStatus = (
	totalDone: number,
	totalUnits: number
): string => {
	if (totalDone === 0) {
		return TODO;
	} else if (totalDone === totalUnits) {
		return DONE;
	} else {
		return IN_PROGRESS;
	}
};

export const compareStatusFunc = (status: string) => {
	switch (status) {
		case TODO:
			return 0;
		case IN_PROGRESS:
			return 1;
		case DONE:
			return 2;
		default:
			return 0;
	}
};

export const sortByStatus = (
	a: Station,
	b: Station,
	passage: number
): number => {
	const stationTotalPassageA = parseInt(
		a[`totalPassage${passage}` as keyof Station] as string,
		10
	);
	const stationTotalPassageB = parseInt(
		b[`totalPassage${passage}` as keyof Station] as string,
		10
	);

	const stationTotalUnitsA = parseInt(
		a[`totalUnitPassage${passage}` as keyof Station] as string,
		10
	);
	const stationTotalUnitsB = parseInt(
		b[`totalUnitPassage${passage}` as keyof Station] as string,
		10
	);

	if (
		compareStatusFunc(
			getStationStatus(stationTotalPassageA, stationTotalUnitsA)
		) >
		compareStatusFunc(
			getStationStatus(stationTotalPassageB, stationTotalUnitsB)
		)
	) {
		return 1;
	}
	if (
		compareStatusFunc(
			getStationStatus(stationTotalPassageA, stationTotalUnitsA)
		) <
		compareStatusFunc(
			getStationStatus(stationTotalPassageB, stationTotalUnitsB)
		)
	) {
		return -1;
	}
	return 0;
};

export const isTimeInRangeOfPassage = (
	unit: Unit,
	passage: number | undefined
) => {
	if (passage === PASSAGE_4) {
		return true;
	}
	const today = new Date();
	if (unit.terminus) {
		return (
			(passage === PASSAGE_1 &&
				getHours(today) < SEM_HOUR_CHANGE_PASSAGE_1_2) ||
			(passage === PASSAGE_2 &&
				getHours(today) >= SEM_HOUR_CHANGE_PASSAGE_1_2 &&
				getHours(today) < SEM_HOUR_PASSAGE_3) ||
			(passage === PASSAGE_3 && getHours(today) >= SEM_HOUR_PASSAGE_3)
		);
	} else {
		return (
			(passage === PASSAGE_1 &&
				getHours(today) < SEM_HOUR_CHANGE_PASSAGE_1_2) ||
			(passage === 2 && getHours(today) >= SEM_HOUR_CHANGE_PASSAGE_1_2)
		);
	}
};
