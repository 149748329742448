import { ChangeEvent } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';

import { ServiceType, ServiceTypeEnum } from '../../../models';
import { RStock } from '../../../packages/hooks';
import {
	DateTimePicker,
	Dialog,
	Select,
	ValidationButtons,
} from '../../../packages/ui';
import SearchBar from '../../../packages/ui/atoms/SearchBar';

import TrainListCard from './trainCardList';

const ServicePopInComponent = ({
	validateDisabled,
	errors,
	handleCheckOneOf,
	handleDateChange,
	handleFilterChange,
	handleSelect,
	handleSubmit,
	open,
	resetAndClose,
	rsLabel,
	serviceTypes,
	values,
}: ServiceContentProps) => {
	const { serviceDate, trains, serviceTypeId } = values;

	return (
		<Dialog
			data-testid="add-service-popin"
			open={open}
			titleContent={`Renseigner une prestation sur ces ${rsLabel}s`}
			content={
				<Grid container spacing={3} padding={3}>
					<Grid item xs={6}>
						<Select
							label="Choisir une prestation"
							onChange={handleSelect}
							options={serviceTypes}
							name="serviceTypeId"
							helperText={errors.serviceType}
							value={serviceTypeId}
							isStringValues
						/>
					</Grid>
					<Grid item xs={6}>
						<DateTimePicker
							value={serviceDate}
							onChange={(value) => handleDateChange('serviceDate', value)}
							disabledFuture
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={6}>
						<SearchBar
							onSubmit={(value) => handleFilterChange(value as string)}
							name="add-service-popin-searchBar"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TrainListCard
							trains={trains}
							handleCheckOneOf={handleCheckOneOf}
						/>
					</Grid>
				</Grid>
			}
			actionsContent={
				<ValidationButtons
					onCancel={resetAndClose}
					onValidate={handleSubmit}
					validateText="Renseigner"
					validateDisabled={validateDisabled}
				/>
			}
			onClose={resetAndClose}
		/>
	);
};

export default ServicePopInComponent;

type ServiceContentProps = {
	errors: { [key: string]: string };
	handleCheckOneOf: (event: ChangeEvent<HTMLInputElement>, of: string) => void;
	handleDateChange: any;
	handleFilterChange: (contain: string) => void;
	handleSelect: (event: SelectChangeEvent) => void;
	handleSubmit: () => void;
	open: boolean;
	resetAndClose: () => void;
	rsLabel: string;
	serviceTypes: ServiceType[];
	validateDisabled: boolean;
	values: {
		serviceDate: Date;
		serviceTypeId: ServiceTypeEnum;
		trains: RStock[];
	};
};
