import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { DropdownProps } from '../../../models/ui';

const Dropdown = ({
	buttonStyle,
	buttonId,
	buttonTitle,
	endIcon,
	isMultipleAvailabity,
	items,
	variant,
	fullWidth,
	size,
}: DropdownProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleItemClick = (itemOnClick: (() => void) | undefined) => {
		if (itemOnClick) {
			itemOnClick();
		}
		handleClose();
	};

	const filteredItems = items.filter((item) => item.isValid !== false);

	return (
		<>
			<Button
				fullWidth={fullWidth}
				id={buttonId}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				endIcon={endIcon}
				onClick={handleClick}
				variant={variant}
				style={buttonStyle}
				size={size}
			>
				{buttonTitle}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{filteredItems.map((item) => (
					<MenuItem
						sx={{
							color: isMultipleAvailabity ? 'var(--text-color-blue)' : null,
							fontWeight: isMultipleAvailabity ? 'bold' : null,
							padding: '10px',
							width: '100%',
						}}
						key={item.key}
						onClick={() => handleItemClick(item.onClick)}
					>
						{item.content}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default Dropdown;
