// prettier-ignore
import { Item, Role } from '../../models';
import {
	CLOSE_HISTORY_POPIN,
	HistoryPopInActionTypes,
	HistorySelection,
	OPEN_HISTORY_POPIN,
	SET_HISTORY_END_DATE,
	SET_HISTORY_ITEMS_FILTER,
	SET_HISTORY_SELECTED_LINES,
	SET_HISTORY_SERVICES_SELECTION,
	SET_HISTORY_START_DATE,
	SET_HISTORY_USERS_PROFIL,
	VALIDATED_HISTORY_POPIN,
} from '../types';

export const closeHistoryPopIn = (): HistoryPopInActionTypes => ({
	type: CLOSE_HISTORY_POPIN,
});
export const openHistoryPopIn = (): HistoryPopInActionTypes => ({
	type: OPEN_HISTORY_POPIN,

});
export const validatedHistoryPopIn = (
	value: boolean
): HistoryPopInActionTypes => ({
	payload: value,
	type: VALIDATED_HISTORY_POPIN,
});

export const setHistoryServicesSelection = (
	services: HistorySelection[]
): HistoryPopInActionTypes => ({
	payload: services,
	type: SET_HISTORY_SERVICES_SELECTION,
});

export const setHistoryStartDate = (
	startDate: Date
): HistoryPopInActionTypes => ({
	payload: startDate,
	type: SET_HISTORY_START_DATE,
});

export const setHistoryEndDate = (endDate: Date): HistoryPopInActionTypes => ({
	payload: endDate,
	type: SET_HISTORY_END_DATE,
});

export const setHistoryItemsFilter = (
	itemsFilter: Item[]
): HistoryPopInActionTypes => ({
	payload: itemsFilter,
	type: SET_HISTORY_ITEMS_FILTER,
});

export const setHistorySelectedLines = (
	lines: string[]
): HistoryPopInActionTypes => ({
	payload: lines,
	type: SET_HISTORY_SELECTED_LINES,
});
export const setHistoryUsersProfilFilter = (
	usersProfilFilter: Role[]
): HistoryPopInActionTypes => ({
	payload: usersProfilFilter,
	type: SET_HISTORY_USERS_PROFIL,
})


const historiesPopinActions = {
	closeHistoryPopIn,
	openHistoryPopIn,
	setHistoryEndDate,
	setHistoryItemsFilter,
	setHistorySelectedLines,
	setHistoryServicesSelection,
	setHistoryStartDate,
	setHistoryUsersProfilFilter,
	validatedHistoryPopIn,
};

export default historiesPopinActions;
