import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as QrCodeSymbol } from '../../assets/icons/button/qrcode.svg';
import ReportingLineSelector from '../../components/lineSelector';
import { triggerSyncEvent } from '../../packages/helpers';
import { useDepartment, useLine } from '../../packages/hooks';
import { CodeButton, TiltedButton, TitleSubtitle } from '../../packages/ui';
import { resetSurface } from '../../redux/actions';
import { Endpoints } from '../../routes/endpoint.config';

import './styles/landingPage.css';

const Landing = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { rsLabel, hasRerDepartment, hasSemDepartment } = useDepartment();
	const { hasMultiLines } = useLine();

	const landingTitle = `Identifiez un ${rsLabel}...`;
	const landingDescription = `LE QR CODE ${
		hasRerDepartment ? "DE L'ÉLÉMENT" : ' DU TRAIN'
	}`;
	const inputLabel = `SAISIR LE NUMERO \n ${
		hasRerDepartment ? "DE L'ÉLÉMENT" : 'DU TRAIN'
	}`;

	useEffect(() => {
		triggerSyncEvent().catch((error) => {
			console.error('An error occurred during decoding initialization:', error);
		});
		dispatch(resetSurface());
	}, [dispatch]);

	const handleRedirectScanner = () => navigate(Endpoints.AGENTN_QR_SCANNER);

	return (
		<main className={`landing-page${hasSemDepartment ? '-sem ' : ' '}page`}>
			{hasSemDepartment ? (
				<h2>Bonjour !</h2>
			) : (
				<TitleSubtitle title={hasSemDepartment ? '' : landingTitle} />
			)}
			<TiltedButton action={handleRedirectScanner} variant="btn-qr-landing">
				<div className="round svg-container">
					<QrCodeSymbol />
				</div>
				<span className="qr-scanner-text">
					{hasSemDepartment ? (
						'SCANNER UNE UNITÉ'
					) : (
						<>
							SCANNER
							<br /> {landingDescription}
						</>
					)}
				</span>
			</TiltedButton>
			{hasMultiLines && <ReportingLineSelector />}
			{!hasSemDepartment && (
				<CodeButton label={inputLabel} variant="code-btn-landing" />
			)}
		</main>
	);
};

export default Landing;
