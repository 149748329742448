// RollingStock availability Select
export const RSA_SELECT_TITLE = 'Motif de la mise à disposition';
export const RSA_SELECT_SUBTITLE = "Sélectionner l'anomalie";

// RollingStock availability Create Form
export const RSA_CREATE_FORM_TITLE = 'Mise à disposition pour ';
export const RSA_CREATE_FORM_SUBTITLE = 'Détails de la mise à disposition :';
export const RSA_FORM_ERROR_SITE = 'Un site doit être sélectionné';

// Multiple availability
export const MULTIPLE_RAS_ERROR_FORM_RS =
	'Veullez sélectionner au moins une voiture';
//RollingStock avalability Edit Form
export const RSA_EDIT_FORM_TITLE = 'Détail de la mise à disposition';
export const RSA_EDIT_FORM_SUBTITLE = 'Motif de la mise à disposition :';
