/* eslint-disable no-mixed-spaces-and-tabs */
import { pluralize } from '../helpers/';

import { useDepartment, useTypedSelector } from '.';

export const useTrackingBoardHeader = () => {
	const { rsLabel } = useDepartment();
	const {
		statusCounts: { used, total },
		statusCounts: stockcounts,
		filter: { status },
		rollingStocks,
	} = useTypedSelector((state) => state.rollingStockState);

	const stockCountText =
		total >= 1
			? `${used} ${pluralize(used, rsLabel)} ${pluralize(
					used,
					'utilisé'
			  )} sur ${total} `
			: 'Aucun matériel roulant';

	return {
		rollingStocks,
		status,
		stockcounts,
		stockCountText,
	};
};
