import {
	AllSpecialOperationTypeDTO,
	SpecialOperationType,
	SpecialOperationTypeDTO,
} from '../../../models/specialOperationType.model';
import fetchJson from '../fetch/fetch-json';

const createSpecialOperationType = async (
	specialOperationType: SpecialOperationType
): Promise<{
	data: SpecialOperationType;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson('/special-operation-type/', {
		body: JSON.stringify(specialOperationType),
		method: 'POST',
	});
};

const updateSpecialOperationType = async (
	specialOperationType: SpecialOperationType
): Promise<{
	data: SpecialOperationType;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/special-operation-type/${specialOperationType.id}`, {
		body: JSON.stringify(specialOperationType),
		method: 'PUT',
	});
};

const closeOperationSpecialType = async (
	specialOperationTypeId: number
): Promise<{
	data: SpecialOperationType;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return await fetchJson(`/special-operation-type/${specialOperationTypeId}`, {
		method: 'PATCH',
	});
};

const loadSpecialOperationType = async (): Promise<{
	data: AllSpecialOperationTypeDTO;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	const specialOperationFetch = await fetchJson('/special-operation-type');
	const { data } = specialOperationFetch;
	return {
		...specialOperationFetch,
		data: {
			opInProgress: [
				...data.opInProgress.map((op: SpecialOperationTypeDTO) => ({
					...op,
					startDate: new Date(op.startDate),
				})),
			],
			opReporting: [
				...data.opReporting.map((op: SpecialOperationTypeDTO) => ({
					...op,
					endDate: new Date(op.endDate),
					startDate: new Date(op.startDate),
				})),
			],
		},
	};
};

export default {
	closeOperationSpecialType,
	createSpecialOperationType,
	loadSpecialOperationType,
	updateSpecialOperationType,
};
