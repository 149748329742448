/* eslint-disable no-mixed-spaces-and-tabs */
import { zonedTimeToUtc } from 'date-fns-tz';
import isNil from 'lodash/isNil';

import { PeriodOptions } from '../../../models';

export class DateTimeService {
	TIMEZONE = 'Europe/Paris';

	public parseJsonWithDateStrings<T>(rawData: string): T {
		const dateTimeCreator = (_: any, value: any) => {
			if (typeof value !== 'string') return value;
			const isISOString = this.isoDateParser(value, true);

			if (isISOString) return new Date(value);
			return value;
		};
		return JSON.parse(rawData, dateTimeCreator) as T;
	}

	public getReadableDate(date: Date | undefined): {
		calendar: string;
		clock: string;
	} {
		const display = { calendar: '00/00/0000', clock: '00:00' };
		if (typeof date === 'undefined') return display;
		const localDate = zonedTimeToUtc(date, this.TIMEZONE);
		const calendar = this.getDate(localDate);
		const clock = this.getTime(localDate);
		return { calendar, clock };
	}

	public getHistoryDate = (period: PeriodOptions) => {
		const { TODAY, WEEK, LAST_MONTH_START_DAY, LAST_MONTH_END_DAY } =
			PeriodOptions;
		const today = new Date();
		today.setHours(0, 0, 0);
		let usedDate = new Date();
		if (period === TODAY) {
			usedDate = new Date();
			usedDate.setHours(23, 59, 0);
		} else if (period === WEEK) {
			usedDate = new Date(today.setDate(today.getDate() - 7));
			usedDate.setHours(0, 0, 0);
		} else if (period === LAST_MONTH_START_DAY) {
			// prevStartDate
			usedDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
			usedDate.setHours(0, 0, 0);
		} else if (period === LAST_MONTH_END_DAY) {
			// prevEndDate
			usedDate = new Date(today.getFullYear(), today.getMonth() - 1 + 1, 0);
			usedDate.setHours(23, 59, 0);
		}
		return usedDate;
	};

	public getSyncDateString(date: Date): string[] {
		return [
			this.getTime(date).replace('.', ':'),
			this.getDate(date).slice(0, 5),
		];
	}

	public dayDiff(d1: Date | undefined, d2: Date): string | undefined {
		if (isNil(d1) || isNil(d2)) return undefined;
		const diff = Math.abs(d1.getTime() - d2.getTime());
		return Math.floor(diff / (1000 * 3600 * 24)).toString();
	}

	public getRelativeDate(
		date: Date,
		param: { year?: number; month?: number; day?: number },
		todayMax = false
	) {
		const newDate = new Date(
			date.getFullYear() + (param.year ?? 0),
			date.getMonth() + (param.month ?? 0),
			date.getDate() + (param.day ?? 0),
			date.getHours(),
			date.getMinutes()
		);
		if (todayMax && newDate > new Date()) return new Date();
		return newDate;
	}

	public isBetween(givenHour: number, lowerHour = 22, upperHour = 5): boolean {
		if (lowerHour < upperHour)
			return givenHour >= lowerHour && givenHour < upperHour;
		else if (lowerHour === upperHour) return givenHour === lowerHour;
		else return givenHour >= lowerHour || givenHour <= upperHour;
	}
	/**
	 * Get local date in DD/MM/YYYY format
	 * @param date
	 */
	public getDate(date: Date | undefined, separator = '/'): string {
		if (isNil(date)) return '';
		const day = ('0' + date.getDate()).slice(-2);
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		return `${day}${separator}${month}${separator}${date.getFullYear()}`;
	}

	public getDateYYYYMMDD(date: Date) {
		return date.toLocaleDateString('fr-CA');
	}

	/**
	 * Get local time in HH.MM format
	 */
	public getTime(date: Date | undefined): string {
		if (isNil(date)) return '';
		return date
			? `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(
					-2
			  )}`
			: '00:00';
	}

	getCurrentMonthString() {
		const monthNames = [
			'Janvier',
			'Février',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Décembre',
		];
		return monthNames[new Date().getMonth()];
	}

	getCurrentYearString() {
		return `${new Date().getFullYear()}`;
	}

	getCurrentStartNightDate(startNightHour: number): Date {
		const date = new Date();
		const currentHour = date.getHours();
		date.setHours(startNightHour, 0, 0, 0);
		if (startNightHour > currentHour) {
			date.setDate(date.getDate() - 1);
		}
		return date;
	}
	getCurrentDayInMonth(): number {
		return new Date().getDate();
	}

	getTotalDaysInCurrentMonth(): number {
		const today = new Date();
		return new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
	}

	getDayInYear(): number {
		const today = new Date();
		return Math.ceil(
			(today.valueOf() - new Date(today.getFullYear(), 0, 1).valueOf()) /
				86400000
		);
	}

	// HELPERS
	private isoDateParser(
		isoString: string,
		isTest: boolean
	): RegExpExecArray | null | boolean {
		return isTest
			? /(\d{4}-\d{2}-\d{2})[A-Z]+(\d{2}:\d{2}:\d{2}).([0-9-:]+[A-Z])/.test(
					isoString
			  )
			: /(\d{4}-\d{2}-\d{2})[A-Z]+(\d{2}:\d{2}:\d{2}).([0-9-:]+[A-Z])/.exec(
					isoString
			  );
	}
}

export default DateTimeService;
