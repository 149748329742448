import { IncidentTypeEnum } from '../../models';
import { IncidentDashboard } from '../../models/dashboard.model';

export const sentenceWithFirstUpperLetter = (sentence: string) => {
	return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const findTrainsCountByIncidentType = (
	incidents: IncidentDashboard[],
	incidentType: IncidentTypeEnum
): number => {
	const incident = incidents.find(
		(incident) => incident.incidentType === incidentType
	);
	return incident ? incident.trainsCount : 0;
};

export const findPriorityCount = (incidents: IncidentDashboard[]): number => {
	const incident = incidents.find(
		(incident) => incident.incidentType === IncidentTypeEnum.DI
	);
	return incident?.priorityCount ? incident.priorityCount : 0;
};
