import { ThunkAction } from 'redux-thunk';

import { authService } from '../../packages/dataProviders';
import { loadAlerts, sseSource } from '../../packages/events/creator';
import {
	clearAllCache,
	cookieService,
	errorConf,
	PREFIXES,
} from '../../packages/helpers';
import { userLogin, userLogout } from '../actions';
import { ActionTypes, ApiThunks, AppStore, components } from '../types';

const { AUTH } = components;

type AuthThunk = ThunkAction<Promise<void>, AppStore, ApiThunks, ActionTypes>;

export const tryLogin = (): AuthThunk => async (dispatch, _, api) => {
	try {
		dispatch(api._request(AUTH));
		const user = await authService.checkLoginSSO();
		const cookie = cookieService.getCookie('authorization');
		if (cookie === 'ACCESS_DENIED') {
			cookieService.clear();
			dispatch(api._error(AUTH, 'no login'));
			dispatch(api.showFeedBack(errorConf.warn.invalidLogin));
		} else if (user) {
			await handleEventSource();
			dispatch(userLogin(user, PREFIXES[user.line_id]));
			dispatch(api._response(AUTH));
		}
	} catch (e) {
		dispatch(api._error(AUTH, e));
	}
};

export const authLogout = (): AuthThunk => async (dispatch, _, api) => {
	try {
		dispatch(api._request(AUTH));
		await authService.logout();
	} catch (e) {
		dispatch(api._error(AUTH, e));
	} finally {
		dispatch(userLogout());
		if (sseSource) sseSource.close();
		clearAllCache();
		window.location.href = `/`;
	}
};

const handleEventSource = async () => {
	const oldCookie = await cookieService.getCookie('alert-received');
	if (oldCookie === 'true') return;
	cookieService.setCookie('alert-received', 'true', 1);
	loadAlerts();
};
