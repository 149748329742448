import { useDispatch } from 'react-redux';

import { RoleEnum } from '../../../../models';
import { hasRole } from '../../../../packages/helpers';
import { useTypedSelector } from '../../../../packages/hooks';
import { Button } from '../../../../packages/ui';
import { openHistoryPopIn } from '../../../../redux/actions';
import LineSelector from '../../../lineSelector';
import { SemPassages, SemServices } from '../../constants';
import SemDropdown from '../SemDropdown/SemDropDown';

import './SemHeader.css';

const { PASSAGE_1, PASSAGE_2, PASSAGE_3 } = SemPassages;
const { NETTOYAGE } = SemServices;

export const SemHeader = ({
	selectedLine,
	onPeriodChange,
	selectedPeriod,
	selectedPassage,
	setSelectedPassage,
}: SemHeaderProps) => {
	const userTs = useTypedSelector(({ userState: { user } }) => user);
	const isUserAm = userTs && hasRole(userTs, [RoleEnum.AGENTM]);
	const lineId = userTs?.line_id ?? undefined;

	const dispatch = useDispatch();

	const handleOpenExport = () => {
		dispatch(openHistoryPopIn());
	};

	return (
		<>
			<div className="sem-header-container">
				<SemDropdown onPeriodChange={onPeriodChange} />

				{(!lineId || isUserAm) && (
					<div className="sem-header-line-selection show-xlarge-screen">
						<LineSelector showAll={false} />
					</div>
				)}

				<Button label="Exporter" onClick={handleOpenExport} />
			</div>
			<div className="content">
				{(!lineId || isUserAm) && (
					<div className="sem-header-line-selection hide-xlarge-screen">
						<LineSelector showAll={false} />
					</div>
				)}

				<div className="sem-header-mobile-button-container">
					<SemDropdown onPeriodChange={onPeriodChange} />
					{selectedPeriod === NETTOYAGE && (
						<div className="sem-header-mobile-button-passage-container">
							<button
								className={`sem-button-title ${
									selectedPassage === PASSAGE_1 ? 'selected' : ''
								}`}
								onClick={() => setSelectedPassage(PASSAGE_1)}
							>
								Passage 1
							</button>
							<button
								className={`sem-button-title ${
									selectedPassage === PASSAGE_2 ? 'selected' : ''
								}`}
								onClick={() => setSelectedPassage(PASSAGE_2)}
							>
								Passage 2
							</button>
							{selectedLine !== '1' && selectedLine !== '14' && (
								<button
									className={`sem-button-title ${
										selectedPassage === PASSAGE_3 ? 'selected' : ''
									}`}
									onClick={() => setSelectedPassage(PASSAGE_3)}
								>
									Passage 3
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SemHeader;

type SemHeaderProps = {
	selectedLine: string;
	onPeriodChange: (period: string) => void;
	selectedPassage: SemPassages;
	setSelectedPassage: (selectedPassage: SemPassages) => void;
	selectedPeriod: string;
};
