import { FormHelperText, Grid, Typography, useMediaQuery } from '@mui/material';

import {
	IncidentRailcarDTO,
	IncidentReport,
} from '../../../../../models/incidentReport.model';
import { Button } from '../../../../../packages/ui';
import theme from '../../../../../packages/ui/theme';

import '../../../../../assets/styles/constants/_colors.css';

const IncidentReportRailcar = ({
	handleElementSelectOneOf,
	helperText = '',
	incidentReport,
	isEditableForm,
	isPB,
}: IncidentReportRailcarProps) => {
	const isPad = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
	return (
		<>
			<Grid item xs={12}>
				<Typography
					style={{ fontSize: '1rem', marginTop: '8px' }}
					variant="subtitle1"
				>
					{`Sélectionner la ou les voiture(s) dégradée(s) ${
						isPB ? '*' : ' (facultatif)'
					}`}
				</Typography>
			</Grid>
			{incidentReport.railcars.map((railcar, index) => {
				const isDisabled =
					isPB && index !== 0 && index !== incidentReport.railcars.length - 1;

				const setBackgroundColor = () => {
					switch (true) {
						case isDisabled:
							return 'var(--bg-color-grey-secondary)';
						case !isEditableForm && railcar.selected:
							return 'var(--bg-color-grey-primary)';
						case !isEditableForm && !railcar.selected:
							return 'var(--bg-color-grey-secondary)';
						case isEditableForm && railcar.selected:
							return 'var(--bg-color-blue-dark)';
						case isEditableForm && !railcar.selected:
							return 'var(--bg-color-white)';

						default:
							return 'initial';
					}
				};

				return (
					<Grid key={railcar.id} item xs={2} marginRight={1.6}>
						<Button
							style={{
								backgroundColor: setBackgroundColor(),
								borderColor:
									isEditableForm && !railcar.selected
										? 'var(--global-color-primary-darker)'
										: 'transparent',
								borderRadius: '8px',
								boxShadow: `${isDisabled ? '0' : '0px 2px 5px grey'}`,
								color: `${
									railcar.selected
										? 'var(--text-color-white)'
										: 'var(--text-color-black)'
								}`,
								height: '4rem',
								width: isPad ? '5.5rem' : '7rem',
							}}
							onClick={() => handleElementSelectOneOf(railcar, 'railcars')}
							disabled={isDisabled}
							variant={'text'}
						>
							<Typography style={{ fontSize: '1rem' }} variant="subtitle2">
								{railcar.name}
							</Typography>
						</Button>
					</Grid>
				);
			})}
			<Grid item xs={12}>
				<FormHelperText error>{helperText}</FormHelperText>
			</Grid>
		</>
	);
};
export default IncidentReportRailcar;

type IncidentReportRailcarProps = {
	handleElementSelectOneOf: (element: IncidentRailcarDTO, of: string) => void;
	helperText: string;
	incidentReport: IncidentReport;
	isEditableForm?: boolean;
	isPB?: boolean;
};
