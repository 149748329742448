import { SpecialOperationType } from '../../models';
import {
	CLOSE_SPECIAL_OPERATION_TYPE,
	CREATE_SPECIAL_OPERATION_TYPE,
	SET_OP_IN_PROGRESS,
	SET_OP_REPORTING,
	SpecialOperationTypeActionTypes,
	UPDATE_SPECIAL_OPERATION_TYPE,
} from '../types';

export const createNewSpecialOperationType = (
	specialOperationType: SpecialOperationType
): SpecialOperationTypeActionTypes => ({
	payload: specialOperationType,
	type: CREATE_SPECIAL_OPERATION_TYPE,
});

export const updateSpecialOperationType = (
	updatedSpecialOperationType: SpecialOperationType
): SpecialOperationTypeActionTypes => ({
	payload: updatedSpecialOperationType,
	type: UPDATE_SPECIAL_OPERATION_TYPE,
});

export const closeSpecialOperationType = (
	specialOperationType: SpecialOperationType
): SpecialOperationTypeActionTypes => ({
	payload: specialOperationType,
	type: CLOSE_SPECIAL_OPERATION_TYPE,
});

export const setSpecialOperationTypeInProgress = (
	specialOperationTypeInProgress: SpecialOperationType[]
): SpecialOperationTypeActionTypes => ({
	payload: specialOperationTypeInProgress,
	type: SET_OP_IN_PROGRESS,
});

export const setSpecialOperationTypeReporting = (
	specialOperationTypeReporting: SpecialOperationType[]
): SpecialOperationTypeActionTypes => ({
	payload: specialOperationTypeReporting,
	type: SET_OP_REPORTING,
});

export const SpecialOperationTypeActions = {
	closeSpecialOperationType,
	createNewSpecialOperationType,
	setSpecialOperationTypeInProgress,
	setSpecialOperationTypeReporting,
	updateSpecialOperationType,
};

export default SpecialOperationTypeActions;
