import { useTypedSelector } from './useTypedSelector';

export const useIncidentType = () => {
	const {
		incidentTypesCreate,
		incidentTypesDelete,
		incidentTypesExport,
		incidentTypesSolve,
	} = useTypedSelector(({ incidentTypeState }) => incidentTypeState);

	const getIncidentTypeName = (incidentTypeId: number) => {
		const incidentType = incidentTypesCreate.find(
			({ id }) => incidentTypeId === id
		);
		if (!incidentType) return '';
		return incidentType.name;
	};

	const canEditIncidentReport = (incidentTypeId: number) =>
		!!incidentTypesCreate.find(({ id }) => incidentTypeId === id);
	const canDeleteIncidentReport = (incidentTypeId: number) =>
		!!incidentTypesDelete.find(({ id }) => incidentTypeId === id);
	const canSolveIncidentReport = (incidentTypeId: number) =>
		!!incidentTypesSolve.find(({ id }) => incidentTypeId === id);
	const canExportIncidentReport = (incidentTypeId: number) =>
		!!incidentTypesExport.find(({ id }) => incidentTypeId === id);

	return {
		canDeleteIncidentReport,
		canEditIncidentReport,
		canExportIncidentReport,
		canSolveIncidentReport,
		getIncidentTypeName,
		incidentTypesCreate: incidentTypesCreate || [],
		incidentTypesDelete: incidentTypesDelete || [],
		incidentTypesExport: incidentTypesExport || [],
		incidentTypesSolve: incidentTypesSolve || [],
	};
};
