import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@mui/material';

import {
	IncidentTypeMap,
	SettingNotificationIncident,
} from '../../../../models';
import { getUniqueKey } from '../../../../packages/helpers';
import { Checkbox, Switch } from '../../../../packages/ui';

import './styles.css';

const NotificationIncident = ({
	handleNotificationIncidentsChange,
	notificationIncidents,
	setSettingIsModified,
}: NotificationIncidentProps) => {
	const hourValues = 24;
	const freqValues = [0, 2, 4, 6, 12];

	const handleEnableIncident = (e: ChangeEvent<HTMLInputElement>): void => {
		const newNotificationIncidents = notificationIncidents.map((incident) => {
			if (incident.incidentTypeId === parseInt(e.target.name.slice(2))) {
				return {
					...incident,
					isEnabled: !incident.isEnabled,
				};
			}
			return incident;
		});
		handleNotificationIncidentsChange(newNotificationIncidents);
		setSettingIsModified(true);
	};

	const handleIncidentRoleChange = (
		e: ChangeEvent<HTMLInputElement>,
		roleId: string
	): void => {
		const newNotificationIncidents = notificationIncidents.map((incident) => {
			if (incident.incidentTypeId === parseInt(e.target.name.slice(3))) {
				const newRoles = incident.roles.map((role) => {
					if (role.roleId === parseInt(roleId, 10)) {
						return { ...role, isEnabled: !role.isEnabled };
					}
					return role;
				});
				return { ...incident, roles: newRoles };
			}
			return incident;
		});
		handleNotificationIncidentsChange(newNotificationIncidents);
		setSettingIsModified(true);
	};
	const handleChangeHourPicker = (e: ChangeEvent<HTMLSelectElement>): void => {
		const newNotificationIncidents = notificationIncidents.map((incident) => {
			if (incident.incidentTypeId === parseInt(e.target.id.slice(3))) {
				return { ...incident, [e.target.name]: parseInt(e.target.value) };
			}
			return incident;
		});
		handleNotificationIncidentsChange(newNotificationIncidents);
		setSettingIsModified(true);
	};

	return (
		<form>
			{notificationIncidents.map((notification) => (
				<div className="mb-3" key={`a-${notification.incidentTypeId}`}>
					<div className="notification___section-separator" />
					<div className="setting-notification__item-header">
						<Grid container>
							<Grid item md={5} xs={12}>
								<Grid container flexDirection="row">
									<Switch
										checked={notification.isEnabled}
										handler={handleEnableIncident}
										name={`a-${notification.incidentTypeId}`}
									/>
									<Typography variant="body1">
										{IncidentTypeMap.get(notification.incidentTypeId)}
									</Typography>
								</Grid>
								{!notification.isEnabled && (
									<Typography variant="caption">
										* être informé dès que cette anomalie est détectée.
									</Typography>
								)}
							</Grid>
							{notification.isEnabled && (
								<>
									<Grid item lg={3} md={3} sm={12} xs={12}>
										<span className={'notification__select__time__label'}>
											Horaire de notification :
										</span>
										<select
											id={`ah-${notification.incidentTypeId}`}
											name="hour"
											onChange={handleChangeHourPicker}
											value={notification.hour}
										>
											{[...Array(hourValues)].map((e, i) => (
												<option key={getUniqueKey(i.toString())} value={i}>
													{i}h00
												</option>
											))}
										</select>
									</Grid>
									<Grid item lg={3} md={3} sm={12} xs={12}>
										<span className={'notification__select__time__label'}>
											Fréquence de rappel :
										</span>
										<select
											data-testid="select-hour"
											id={`af-${notification.incidentTypeId}`}
											name="freqReminder"
											onChange={handleChangeHourPicker}
											value={notification.freqReminder}
										>
											{freqValues.map((e) => (
												<option key={getUniqueKey(e.toString())} value={e}>
													{e === 0 ? 'jamais' : `${e}h`}
												</option>
											))}
										</select>
									</Grid>
								</>
							)}
						</Grid>
					</div>
					{notification.isEnabled && (
						<div className="setting-notification__item-content">
							<p className="notification__roles__title">
								Afficher cette notification pour :
							</p>
							{notification.roles.map((role) => (
								<Checkbox
									checked={role.isEnabled}
									key={role.roleId}
									label={role.name}
									name={`ar-${notification.incidentTypeId}`}
									onChange={(e) =>
										handleIncidentRoleChange(e, role.roleId.toString())
									}
								/>
							))}
						</div>
					)}
				</div>
			))}
		</form>
	);
};

export default NotificationIncident;

type NotificationIncidentProps = {
	notificationIncidents: SettingNotificationIncident[];
	handleNotificationIncidentsChange: Dispatch<
		SetStateAction<SettingNotificationIncident[]>
	>;
	setSettingIsModified: Dispatch<SetStateAction<boolean>>;
};
