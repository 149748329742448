import { ChangeEvent } from 'react';

import { RStock } from '../../../packages/hooks';
import Checkbox from '../../../packages/ui/atoms/Checkbox';

import { StyledTrainCard } from './styles';

const TrainListCard = ({ trains, handleCheckOneOf }: TrainListCardProps) => (
	<>
		{trains?.map(({ checked, id, name }) => (
			<StyledTrainCard key={id} className="service-item">
				<Checkbox
					checked={checked}
					label={name}
					onChange={(event) => handleCheckOneOf(event, 'trains')}
					name={id.toString()}
				/>
			</StyledTrainCard>
		))}
	</>
);
export default TrainListCard;

type TrainListCardProps = {
	trains: RStock[];
	handleCheckOneOf: (event: ChangeEvent<HTMLInputElement>, of: string) => void;
};
