import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SuccessFaceWithSplash } from '../../assets/icons/faces/success-with-splash-sem.svg';
import { Endpoints } from '../../routes/endpoint.config';

import './styles/confirmationPage.css';
import './styles/successSemPage.css';

const Success = () => {
	const navigate = useNavigate();
	const [counter, setCounter] = useState(3);

	useEffect(() => {
		const interval = setInterval(() => {
			if (counter === 1) {
				navigate(Endpoints.AGENTN);
			} else {
				setCounter(counter - 1);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [counter, navigate]);

	return (
		<div className={'sucess-page page'}>
			<h2 className="success-page__title">Merci !</h2>
			<div className="success-page__icon">
				<SuccessFaceWithSplash />
			</div>
			<div className="success-page__counter">{counter}</div>
		</div>
	);
};

export default Success;
