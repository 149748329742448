import { Grid, Typography } from '@mui/material';

import theme from '../../packages/ui/theme';

const NotFound = () => {
	return (
		<Grid
			container
			alignItems="center"
			sx={{
				backgroundColor: theme.palette.primary.main,
				color: 'white',
				height: '100vh',
			}}
		>
			<Grid item textAlign="center" xs={12}>
				<Typography variant="h1">404 </Typography>
				<Typography variant="h2">Cette page n'existe pas</Typography>
			</Grid>
		</Grid>
	);
};

export default NotFound;
