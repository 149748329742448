import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';

import { SpecialOperation } from '../../../models/specialOperation.model';
import { IncidentSvgPrintable } from '../../../packages/ui/icons';
import { SelectionByAgentN } from '../../../redux/types';

import {
	StyledCardActionSpecialOperationContainer,
	StyledCardActionSvgContainer,
} from './styles';

const CardActionSpecialOperation = ({
	isChoiceSelected,
	setAgentSelection,
	setIsChoiceSelected,
	isSelected,
	specialOperation,
}: CardActionSpecialOperationProps) => {
	const handleClick = () => {
		setAgentSelection({ specialOperation: specialOperation });
		setIsChoiceSelected(true);
	};

	const getBackgroundColor = (
		isChoiceSelected: boolean,
		isSelected: boolean
	): string => {
		if (isSelected) return 'var(--global-color-primary-darker)';
		else if (isChoiceSelected) return 'var(--bg-color-grey-disabled)';
		else return 'white';
	};

	return (
		<StyledCardActionSpecialOperationContainer
			isChoiceSelected={isChoiceSelected}
			isSelected={isSelected}
			backgroundColor={getBackgroundColor(isChoiceSelected, isSelected)}
			onClick={handleClick}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					handleClick();
				}
			}}
		>
			<StyledCardActionSvgContainer>
				<IncidentSvgPrintable name="OP" />
			</StyledCardActionSvgContainer>
			<Typography variant="body2">
				{specialOperation.specialOperationTypeName}
			</Typography>
		</StyledCardActionSpecialOperationContainer>
	);
};

export default CardActionSpecialOperation;

type CardActionSpecialOperationProps = {
	isChoiceSelected: boolean;
	isSelected: boolean;
	setIsChoiceSelected: Dispatch<SetStateAction<boolean>>;
	setAgentSelection: Dispatch<SetStateAction<SelectionByAgentN>>;
	specialOperation: SpecialOperation;
};
