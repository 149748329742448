import { Typography } from '@mui/material';

import { ServiceTypeEnum } from '../../../../models';
import { getUniqueKey } from '../../../../packages/helpers';
import {
	useConfirmationPopIn,
	useServiceType,
} from '../../../../packages/hooks';
import { ValidationButtons } from '../../../../packages/ui';
import { RsService } from '..';

import './styles.css';

const ConfirmationContent = ({
	listItems,
	onValidate,
}: ConfirmationContentProps) => {
	const { closeConfirmationPopIn } = useConfirmationPopIn();
	const { serviceTypesCreate } = useServiceType();

	function handleValidate() {
		onValidate();
		closeConfirmationPopIn();
	}

	function getServiceTypeName(
		serviceTypeId: ServiceTypeEnum
	): string | undefined {
		const serviceTypeObj = serviceTypesCreate.find(
			(elm) => elm.id === serviceTypeId
		);
		return serviceTypeObj?.name;
	}

	return (
		<>
			<div>
				<ul className="list-group list-group-flush">
					{listItems.map((item) => {
						return (
							<li
								className="list-group-item"
								key={getUniqueKey((item.service.id ?? '').toString())}
							>
								<Typography variant="h6">{`N° train : ${item.service.trainCode}`}</Typography>
								<Typography variant="h6">{`Service  : ${getServiceTypeName(
									item.service.serviceTypeId
								)}`}</Typography>
								<Typography variant="h6">{`Le : ${item.service.date.toLocaleDateString(
									'en-GB'
								)} à : ${item.service.date?.toLocaleTimeString(
									'en-GB'
								)}`}</Typography>
							</li>
						);
					})}
				</ul>
			</div>
			<ValidationButtons
				onCancel={closeConfirmationPopIn}
				onValidate={handleValidate}
			/>
		</>
	);
};

export default ConfirmationContent;

type ConfirmationContentProps = {
	listItems: RsService[];
	onValidate: () => void;
};
