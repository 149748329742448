import { store } from '../../../redux';
import { deleteRollingStockService } from '../../../redux/actions';
import { ServiceDTO } from '../../dataProviders';
import { localStorageFuncs } from '../../helpers/';

export const serviceDeleteHandler = (e: MessageEvent) => {
	const data = JSON.parse(e.data) as ServiceDTO;
	localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());
	store.dispatch(deleteRollingStockService(data));
};

export default serviceDeleteHandler;
