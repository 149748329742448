import {
	alpha,
	FormControl,
	styled,
	Switch as MuiSwitch,
	Typography,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import { SwitchProps } from '../../../models/ui';

const GreenSwitch = styled(MuiSwitch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		'&:hover': {
			backgroundColor: alpha(green[900], theme.palette.action.hoverOpacity),
		},
		color: grey[50],
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: green[800],
		opacity: 1,
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		height: 16,
		margin: 2,
		width: 16,
	},
	'& .MuiSwitch-track': {
		'&:after': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
		'&:before': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12,
		},
		'&:before, &:after': {
			content: '""',
			height: 16,
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
		},
		borderRadius: 22 / 2,
	},
	padding: 8,
}));

const Switch = ({
	checked,
	disabled = false,
	isGreen,
	label,
	name,
	handler,
}: SwitchProps) => {
	return (
		<FormControl component="fieldset">
			<FormGroup>
				<FormControlLabel
					control={
						isGreen ? (
							<GreenSwitch
								data-testid="switch"
								name={name}
								checked={checked}
								onChange={handler}
							/>
						) : (
							<MuiSwitch
								data-testid="switch"
								name={name}
								checked={checked}
								onChange={handler}
							/>
						)
					}
					disabled={disabled}
					label={
						<Typography variant="subtitle1" color="text.secondary">
							{label}
						</Typography>
					}
				/>
			</FormGroup>
		</FormControl>
	);
};

export default Switch;
