import { cloneDeep } from 'lodash';

import { initDeleteServicePopInState } from '../config/initialState';
import * as types from '../types/deleteServicePopIn.types';

export const deleteServicePopInReducer = (
	state = initDeleteServicePopInState,
	action: types.DeleteServicePopInActionType
): types.DeleteServicePopInState => {
	switch (action.type) {
		case types.CLOSE_DELETE_SERVICE_POPIN:
			return initDeleteServicePopInState;
		case types.OPEN_DELETE_SERVICE_POPIN:
			return {
				...state,
				meta: {
					...state.meta,
					open: true,
				},
			};
		case types.SET_SERVICES_DELETE_SERVICES_POPIN:
			return {
				...state,
				services: action.payload,
			};
		case types.SET_SERVICE_START_DATE_DELETE_SERVICES_POPIN:
			return {
				...state,
				serviceStartDate: action.payload,
			};
		case types.SET_SERVICE_END_DATE_DELETE_SERVICES_POPIN:
			return {
				...state,
				serviceEndDate: action.payload,
			};
		case types.SET_SERVICE_TYPE_DELETE_SERVICES_POPIN:
			return {
				...state,
				serviceTypeId: action.payload,
			};
		case types.SET_SERVICE_TRAIN_CODE_DELETE_SERVICES_POPIN:
			return {
				...state,
				serviceTrainCode: action.payload,
			};
		case types.SET_CHECKED_SERVICES_DELETE_SERVICES_POPIN: {
			let services = cloneDeep(state.services);
			services = services.map((item) => {
				if (item.service.id && action.payload.includes(item.service.id)) {
					item.checked = true;
				} else {
					item.checked = false;
				}
				return item;
			});
			return {
				...state,
				checkedItems: action.payload,
				services: services,
			};
		}

		case types.SET_SERVICE_TO_DELETE_DELETE_SERVICE_POPIN:
			return {
				...state,
				serviceToDelete: cloneDeep(
					state.services.find((item) => item.service.id === action.payload)
				),
			};
		default:
			return state;
	}
};
