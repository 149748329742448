import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';

import { User } from '../../../models';
import { Dialog as MuiDialog } from '../../../packages/ui';
import { getRoles } from '../../../redux/thunks/roles';
import { getLines } from '../../../redux/thunks/rollingStock';

import FormContent from './formContent';
import FormFooter from './formFooter';

const UserCredentialPopinForm = ({
	errors,
	handleChange,
	handleSelect,
	handleClose,
	handleSubmit,
	isUntouched,
	open,
	user,
}: UserCredentialPopinFormProps) => {
	const isEditMod = !!user.id;
	const popinTitle = `${isEditMod ? 'Modifier' : 'Ajouter'} un utilisateur`;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getLines());
		dispatch(getRoles());
	}, []);

	return (
		<MuiDialog
			open={open}
			titleContent={popinTitle}
			content={
				<FormContent
					errors={errors}
					handleChange={handleChange}
					handleSelect={handleSelect}
					user={user}
				/>
			}
			actionsContent={
				<FormFooter
					user={user}
					handleClose={handleClose}
					handleSubmit={handleSubmit}
					isUntouched={isUntouched}
				/>
			}
			onClose={handleClose}
		/>
	);
};

export default UserCredentialPopinForm;

type UserCredentialPopinFormProps = {
	errors: { [key in string]: string };
	handleSelect: (event: SelectChangeEvent) => void;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleClose: () => void;
	handleSubmit: () => void;
	isUntouched: boolean;
	open: boolean;
	user: User;
};
