import { useEffect, useMemo } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import {
	DepartmentEnum,
	HistoryDefaultElementUsageSelection,
	HistoryDefaultSemSelection,
	HistoryDefaultTrainUsageSelection,
	Item,
	Role,
	ServiceType,
} from '../../../../models';
import {
	capitalize,
	getUniqueKey,
	RerLines,
} from '../../../../packages/helpers';
import {
	useDepartment,
	useEntity,
	useEspace,
	useLine,
	useServiceType,
	useTypedSelector,
} from '../../../../packages/hooks';
import { useIncidentType } from '../../../../packages/hooks/useIncidentType';
import {
	setHistoryEndDate,
	setHistoryItemsFilter,
	setHistorySelectedLines,
	setHistoryServicesSelection,
	setHistoryStartDate,
	setHistoryUsersProfilFilter,
} from '../../../../redux/actions';
import { loadStations } from '../../../../redux/thunks/history';
import { getRoles } from '../../../../redux/thunks/roles';
import { loadTrainsCode } from '../../../../redux/thunks/rollingStock';
import {
	HistorySelection as HistorySelect,
	HistorySelectionTypeEnum,
} from '../../../../redux/types';

import HistoryPeriod from './historyPeriod';
import HistorySelection from './historySelection';

import './styles.css';

const { RER, MTS, SEM } = DepartmentEnum;
const { Service, IncidentReport } = HistorySelectionTypeEnum;

const changetoSelectObject = (array: string[]): SelectLineProps[] => {
	return array.map((element, index) => {
		return { label: element, value: index };
	});
};

const changeItemIncidentTypesExportObjectToSelectionObject = (
	incidentTypes: Item[]
): HistorySelect[] => {
	return incidentTypes.map((incidentType) => {
		return {
			id: incidentType.id,
			key: getUniqueKey(incidentType.name),
			name: incidentType.name,
			selected: true,
			type: IncidentReport,
		};
	});
};

const changeItemServiceObjectToSelectionObject = (
	services: ServiceType[]
): HistorySelect[] =>
	services.map((service) => {
		return {
			id: service.id,
			key: getUniqueKey(service.name),
			name: service.name,
			selected: true,
			type: Service,
		};
	});

const HistoryPopInContent = () => {
	const dispatch = useDispatch();
	const { userTs, isAdmin } = useEntity();
	const { isEspace5 } = useEspace();
	const { serviceTypesExport } = useServiceType();
	const {
		departmentEnum: department,
		rsLabel,
		hasSemDepartment,
		hasMtsDepartment,
	} = useDepartment();

	const { allLines, hasMultiLines, selectedLine } = useLine();

	const stateTs = useTypedSelector((state) => state.historyPopInState);

	const { incidentTypesExport } = useIncidentType();

	const handleItemFilterChange = (values: Item[]) => {
		const selectedIds = values.map((value) => value.id);
		const items = stateTs.itemsFilter.map((item) => {
			if (selectedIds.includes(item.id)) {
				return { ...item, checked: true };
			} else {
				return item;
			}
		});
		dispatch(setHistoryItemsFilter(items));
	};

	const handleLineSelectionChange = (values: SelectLineProps[]) => {
		const selectedLines = values.map((value) => value.label);
		dispatch(setHistorySelectedLines(selectedLines));
	};

	const handleUserProfilesFilterChange = (values: Role[]) => {
		const selectedIds = values.map((value) => value.id);
		const items = stateTs.usersProfilFilter.map((item) => {
			return { ...item, checked: selectedIds.includes(item.id) };
		});
		dispatch(setHistoryUsersProfilFilter(items));
	};

	const handleStartDateChange = (date: Date) => {
		dispatch(setHistoryStartDate(date));
	};

	const handleEndDateChange = (date: Date) => {
		dispatch(setHistoryEndDate(date));
	};

	const loadItems = (selectedLine: string) => {
		if (hasSemDepartment) {
			dispatch(loadStations(selectedLine));
		} else {
			dispatch(loadTrainsCode(selectedLine));
			dispatch(getRoles());
		}
	};

	const isRerAndLineSelected = (): boolean => {
		let selectedLineOfTrain = selectedLine;

		if (selectedLineOfTrain === undefined) selectedLineOfTrain = '';

		let selectTrainToPrint = false;

		if (!isAdmin) selectTrainToPrint = true;

		if (isAdmin && department === RER && RerLines.includes(selectedLineOfTrain))
			selectTrainToPrint = true;

		return selectTrainToPrint;
	};

	const placeholderByDepartement = useMemo(
		() => `Tous les ${rsLabel}s`,
		[department]
	);

	const filterTitleByDepartment = useMemo(
		() => `3. ${capitalize(rsLabel)}s`,
		[department]
	);

	const serviceTitleByDepartment = useMemo(() => {
		if (department === SEM) return '2. Prestations';
		else return '';
	}, [department]);

	const filterTitleByDepartmentForProfilSelection = useMemo(() => {
		return `${hasMtsDepartment ? '3' : '4'}. Profils`;
	}, [department]);

	const filterTitleByDepartmentForSelectedLines = useMemo(() => {
		return `${hasMtsDepartment ? '4' : '5'}. Lignes sélectionnées`;
	}, [department]);

	const handleItemSelectionChange = (selection: HistorySelect[]) => {
		dispatch(setHistoryServicesSelection(selection));
	};

	const getServicesSelectionByDepartment = (
		department: DepartmentEnum
	): HistorySelect[] => {
		if ([MTS, RER].includes(department)) {
			return [
				...changeItemIncidentTypesExportObjectToSelectionObject(
					incidentTypesExport
				),
				...changeItemServiceObjectToSelectionObject(serviceTypesExport),
				...getTrainUsageSelection(department),
			];
		} else if (department === SEM) {
			return getSemSelection();
		}
		return [];
	};

	const getTrainUsageSelection = (
		department: DepartmentEnum
	): HistorySelect[] => [
		department === MTS
			? { ...HistoryDefaultTrainUsageSelection }
			: { ...HistoryDefaultElementUsageSelection },
	];

	const getSemSelection = (): HistorySelect[] => {
		return HistoryDefaultSemSelection;
	};

	useEffect(() => {
		if (department !== undefined) {
			if (!isEmpty(selectedLine)) {
				loadItems(selectedLine);
			} else if (userTs?.line_id && userTs?.line_id.includes(',')) {
				loadItems(userTs.line_id.split(',')[0]);
			} else if (userTs?.line_id) {
				loadItems(userTs?.line_id);
			}
		}
		if (!hasMultiLines) dispatch(setHistorySelectedLines([selectedLine]));
		// eslint-disable-next-line
	}, [selectedLine, department, isAdmin]);

	useEffect(() => {
		if (department !== undefined) {
			const selection = getServicesSelectionByDepartment(department);
			dispatch(setHistoryServicesSelection(selection));
		}
	}, [department]);

	return (
		<div className="history-modal-container">
			<h4 className="font-paris-bold mt-3 mb-3">1. Période</h4>
			<div>
				<HistoryPeriod
					onEndDateChange={handleEndDateChange}
					onStartDateChange={handleStartDateChange}
				/>
			</div>
			<div className="history-modal-info">
				<h4 className="font-paris-bold mt-3 mb-3">
					{serviceTitleByDepartment}
				</h4>
				<HistorySelection
					enableAllSelection={isEspace5}
					itemSelection={stateTs.servicesSelection}
					onItemSelectionChange={handleItemSelectionChange}
				/>
			</div>
			{isRerAndLineSelected() && (
				<div className="history-modal-trains">
					<h4 className="font-paris-bold mt-3 mb-3">
						{filterTitleByDepartment}
					</h4>
					<Select
						color="#0a0082"
						labelField="name"
						multi
						onChange={(values) => handleItemFilterChange(values)}
						options={stateTs.itemsFilter}
						placeholder={`${placeholderByDepartement} (${stateTs.itemsFilter.length})`}
						searchBy="name"
						valueField="id"
						values={[]}
					/>
				</div>
			)}
			<div className="history-modal-userProfiles">
				<h4 className="font-paris-bold mt-3 mb-3">
					{filterTitleByDepartmentForProfilSelection}
				</h4>
				<Select
					color="#0a0082"
					labelField="name"
					multi
					onChange={(values) => handleUserProfilesFilterChange(values)}
					options={stateTs.usersProfilFilter}
					placeholder={`Tous les profils d'utilisateurs (${stateTs.usersProfilFilter.length})`}
					searchBy="name"
					valueField="id"
					values={[]}
				/>
			</div>
			{hasMultiLines && (
				<div className="history-modal-trains">
					<h4 className="font-paris-bold mt-3 mb-3">
						{filterTitleByDepartmentForSelectedLines}
					</h4>
					<Select
						color="#0a0082"
						multi
						onChange={(values) => handleLineSelectionChange(values)}
						options={changetoSelectObject(allLines)}
						placeholder="Toutes les lignes"
						searchBy="line"
						values={changetoSelectObject([selectedLine])}
					/>
				</div>
			)}
		</div>
	);
};

type SelectLineProps = {
	value: number;
	label: string;
};

export default HistoryPopInContent;
