import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { SP_MODAL_TITLE } from '../../../../packages/helpers/explanations/specialOperation';
import { useForm } from '../../../../packages/hooks';
import { Dialog, ValidationButtons } from '../../../../packages/ui';
import { createAndSetSpecialOperationType } from '../../../../redux/thunks/specialOperationType';
import { getEmptyspecialOperationType, validate } from '../config';
import SpecialOperationFormContent from '../formContent';

const SpecialOperationTypeCreateForm = ({
	openSpecialOperationForm,
	setOpenSpecialOperationForm,
}: SpecialOperationFormProps) => {
	const dispatch = useDispatch();

	const handleClose = () => {
		setOpenSpecialOperationForm(false);
	};

	const handleCreateSpecialOperation = () => {
		dispatch(createAndSetSpecialOperationType(values));
		handleClose();
	};

	const {
		errors,
		handleChange,
		handleDateChange,
		handleSubmit,
		isUntouched,
		setIsUntouched,
		setValues,
		values,
	} = useForm({
		callback: handleCreateSpecialOperation,
		initFormValues: getEmptyspecialOperationType(),
		validate,
	});

	return (
		<Dialog
			titleContent={SP_MODAL_TITLE}
			open={openSpecialOperationForm}
			onClose={handleClose}
			content={
				<SpecialOperationFormContent
					errors={errors}
					handleChange={handleChange}
					handleDateChange={handleDateChange}
					setValues={setValues}
					values={values}
					setIsUntouched={setIsUntouched}
				/>
			}
			actionsContent={
				<ValidationButtons
					onValidate={handleSubmit}
					onCancel={handleClose}
					validateDisabled={isUntouched}
				/>
			}
		></Dialog>
	);
};

interface SpecialOperationFormProps {
	openSpecialOperationForm: boolean;
	setOpenSpecialOperationForm: Dispatch<SetStateAction<boolean>>;
}

export default SpecialOperationTypeCreateForm;
