import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import appEnv from '../../../packages/configuration/appEnv';

import { StyledImg } from './styles';

const DisplayPhoto = ({ urlLink = '', file }: DisplayPhotoProps) => {
	const [alt, setAlt] = useState('');
	const [source, setSource] = useState<string>('');
	const [renderImg, setRenderImg] = useState(true);

	const getImg = async () => {
		if (file) {
			setSource(URL.createObjectURL(file));
			setAlt(file.name);
		} else if (urlLink && appEnv.env === 'local') {
			setSource(appEnv.backendUrl + '/v1/incident-report-photos/' + urlLink);
			setAlt(urlLink);
		} else if (urlLink) {
			setSource(`/api/v1/incident-report/photos/${urlLink}`);
			setAlt(urlLink);
		} else {
			setRenderImg(false);
		}
	};

	useEffect(() => {
		getImg();
	}, [file, urlLink]);

	return renderImg ? (
		<Grid item xs={6}>
			<StyledImg alt={alt} crossOrigin="anonymous" src={source} />
		</Grid>
	) : (
		<></>
	);
};
export default DisplayPhoto;

export type DisplayPhotoProps = {
	file?: any;
	urlLink?: string;
};
