export interface LineState {
	lines: string[];
	selectedLine: string;
}

export const SET_LINES = 'RESPONSE_LINES';
export const SET_SELECTED_LINE = 'SET_SELECTED_LINE';

export interface SetLines {
	type: typeof SET_LINES;
	payload: Array<string>;
}

export interface SetSelectedLine {
	type: typeof SET_SELECTED_LINE;
	payload: string;
}

export type LineActionTypes = SetLines | SetSelectedLine;
