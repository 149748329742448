import { DataTypeEnum, Export, ServiceSelection } from '../../../models';
import { ExceptionDTO } from '../../../models/exception.model';
import { HttpResponse } from '../apiTemplate';
import ApiService from '../apiTemplate/api';

class ExportService extends ApiService {
	constructor() {
		super();
		this.endPoint = `${this.endPoint}/v1/export`;
		this.credentials = 'include';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/vnd.ms-excel',
			},
		]);
	}

	public async getExport(exportValues: Export): Promise<HttpResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/export/${this.getExportPathByDataType(
					exportValues.dataType.id
				)}/?${new URLSearchParams(this.getExportParams(exportValues))}`,
				this.request()
			);
			if (res.ok) {
				return { message: await res.blob(), ok: res.ok };
			} else {
				const history = (await res.json()) as ExceptionDTO;
				return { message: history, ok: res.ok };
			}
		} catch (error) {
			throw Error(`load export information failed --> ${error}`);
		}
	}

	private getExportParams(exportValues: Export): Array<[string, string]> {
		return [
			['startDate', exportValues.startDate.toISOString()],
			['endDate', exportValues.endDate.toISOString()],
			['lines', exportValues.lines.join()],
			['serviceTypeIds', this.getSelectedServices(exportValues.services)],
			['rollingStockIds', exportValues.rollingStockIds.join()],
			['roleIds', exportValues.roleIds.join()],
			['dataType', exportValues.dataType.id.toString()],
		];
	}

	private getExportPathByDataType(dataType: DataTypeEnum): string {
		if (dataType === DataTypeEnum.INCIDENT) return 'incident';
		if (dataType === DataTypeEnum.OP) return 'special-operation';
		return 'service';
	}

	private getSelectedServices(selectedServices: ServiceSelection[]): string {
		return selectedServices
			.filter((service) => service.selected)
			.map((service) => service.id)
			.join();
	}
}
export const exportService = new ExportService();
