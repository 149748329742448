import { store } from '../../../redux';
import { deleteRollingStockIncident } from '../../../redux/actions';
import { localStorageFuncs } from '../../helpers';
import { IncidentResolveData } from '../types';

export const incidentResolveHandler = (e: MessageEvent) => {
	const { incidentReportId, trainId } = JSON.parse(
		e.data
	) as IncidentResolveData;
	localStorageFuncs.set('lastSyncTimeStamp', new Date().getTime());

	store.dispatch(deleteRollingStockIncident(incidentReportId, trainId));
};

export default incidentResolveHandler;
