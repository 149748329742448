import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { SpecialOperationType } from '../../../models';
import { getUniqueKey } from '../../../packages/helpers';
import { SPECIAL_OPERATION_IN_PROGRESS_TITLE } from '../../../packages/helpers/explanations/specialOperation';
import { useTypedSelector } from '../../../packages/hooks/useTypedSelector';
import { getSpecialOperationType } from '../../../redux/thunks/specialOperationType';

import CardOpInProgess from './cardOpInProgress';

const OpInProgress = ({
	setSpecialOperationToEdit,
	setOpenSpecialOperationTypeEditForm,
}: OpInProgressProps) => {
	const dispatch = useDispatch();

	const opInProgress: SpecialOperationType[] = useTypedSelector(
		({ specialOperationTypeState }) => specialOperationTypeState.opInProgress
	);

	useEffect(() => {
		if (isEmpty(opInProgress)) {
			dispatch(getSpecialOperationType());
		}
	}, []);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h3" marginBottom="24px">
					{SPECIAL_OPERATION_IN_PROGRESS_TITLE} ({opInProgress?.length})
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container xs={10}>
					{isEmpty(opInProgress) ? (
						<Typography fontSize="40px" fontWeight="bold" color="grey ">
							Aucune opération en cours
						</Typography>
					) : (
						opInProgress.map((specialOperationType) => (
							<CardOpInProgess
								key={getUniqueKey(specialOperationType.name)}
								specialOperationType={specialOperationType}
								setSpecialOperationToEdit={setSpecialOperationToEdit}
								setOpenSpecialOperationTypeEditForm={
									setOpenSpecialOperationTypeEditForm
								}
							/>
						))
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default OpInProgress;

type OpInProgressProps = {
	setSpecialOperationToEdit: Dispatch<
		SetStateAction<SpecialOperationType | undefined>
	>;
	setOpenSpecialOperationTypeEditForm: Dispatch<SetStateAction<boolean>>;
};
