import { ServiceTypeRightsList } from '../../../models';
import fetchJson from '../fetch/fetch-json';

const loadServiceTypes = async (): Promise<{
	data: ServiceTypeRightsList;
	ok: boolean;
	status: number;
	statusText: string;
}> => {
	return fetchJson(`/service-type`);
};

export default {
	loadServiceTypes,
};
