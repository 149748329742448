import { Right } from '../../../models/right.model';
import ApiService from '../apiTemplate/api';

class RightService extends ApiService {
	constructor() {
		super();
		this.credentials = 'include';
		this.redirect = 'follow';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/json',
			},
			{
				key: 'Content-Type',
				value: 'application/json',
			},
		]);
	}



	public async createRight(idRight: number, idRole: number): Promise<any> {
		this.setMethod('POST');
		try {
			const res = await fetch(
				`${this.endPoint}/v1/right`,
				this.request({ rightTypeId: idRight, roleId: idRole })
			);
			const data = await res.json();
			return data as Right;
		} catch (error) {
			throw Error(`failed retrieve right information`);
		}
	}

}

export const rightService = new RightService();
