import { ParamsExportHistory } from '../../../models';
import { ExceptionDTO } from '../../../models/exception.model';
import { HttpResponse } from '../apiTemplate';
import ApiService from '../apiTemplate/api';
class HistoryService extends ApiService {
	constructor() {
		super();
		this.endPoint = `${this.endPoint}/v1/history`;
		this.credentials = 'include';
		this.setHeaders([
			{
				key: 'Accept',
				value: 'application/vnd.ms-excel',
			},
		]);
	}

	/**
	 * getHistory
	 */
	public async getHistory(
		params: Array<[ParamsExportHistory, string]>
	): Promise<HttpResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/excel/?${new URLSearchParams(params)}`,
				this.request()
			);
			if (res.ok) {
				return { message: await res.blob(), ok: res.ok };
			} else {
				const history = (await res.json()) as ExceptionDTO;
				return { message: history, ok: res.ok };
			}
		} catch (error) {
			throw Error(`load history information failed --> ${error}`);
		}
	}

	/**
	 * getHistory
	 */
	public async getHistoryExport(
		params: Array<[string, string]>,
	): Promise<HttpResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/rolling-stock/excelV2?${new URLSearchParams(params)}`,
				this.request()
			);
			if (res.ok) {
				return { message: await res.blob(), ok: res.ok };
			} else {
				const history = (await res.json()) as ExceptionDTO;
				return { message: history, ok: res.ok };
			}
		} catch (error) {
			throw Error(`load history information failed --> ${error}`);
		}
	}

	/**
	 * getHistory
	 */
	public async getStationHistoryExport(
		params: Array<[string, string]>
	): Promise<HttpResponse> {
		this.setMethod('GET');
		try {
			const res = await fetch(
				`${this.endPoint}/station/excelV2?${new URLSearchParams(params)}`,
				this.request()
			);
			if (res.ok) {
				return { message: await res.blob(), ok: res.ok };
			} else {
				const history = (await res.json()) as ExceptionDTO;
				return { message: history, ok: res.ok };
			}
		} catch (error) {
			throw Error(`load history information failed --> ${error}`);
		}
	}
}
export const historyService = new HistoryService();
